// --------------------------------------------------------------------------
// -- You can add your custom CSS here and it will overwrite template styles.
// --------------------------------------------------------------------------
@import "./variables";

//html, body {
//    scroll-behavior: smooth;
//}

.image-bg {
    background-color: map-get($theme-scheme, image-bg) !important;
}

.gap-2rem {
    gap: 2rem !important;
}

.gap-1rem {
    gap: 1rem !important;
}

.gap-05rem {
    gap: 0.5rem !important;
}

.gap-10 {
    gap: 10px !important;
}

.gap-8 {
    gap: 8px !important;
}

.flex-3 {
    flex: 3 !important;
}

.flex-2 {
    flex: 2 !important;
}

.flex-1 {
    flex: 1 !important;
}

.background-main {
    background-color: map-get($theme-scheme, main);
}

.color-main {
    color: map-get($theme-scheme, main);
}

.fill-svg-main {
    svg {
        fill: map-get($theme-scheme, main);
    }
}

.color-font {
    color: map-get($theme-scheme, fill);
}

.color-contrast {
    color: map-get($theme-scheme, contrast-text);
}

.cursor-pointer {
    cursor: pointer;
}
