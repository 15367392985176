@charset "UTF-8";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1380px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.375rem 0.625rem;
  margin-left: 0;
  line-height: 1.25;
  color: #007bff;
  background-color: transparent;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #f0f0f0;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.page-content li {
  margin-bottom: 1rem;
}
.page-content li::marker {
  color: #F28B00;
}
.page-content a {
  color: #F28B00;
}
.page-content h1, .page-content h2, .page-content h3, .page-content h4, .page-content h5, .page-content h6 {
  font-weight: 700;
}

.page-content__step {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F28B00;
  margin-bottom: 3px;
}
.page-content__step:nth-child(even) {
  background: aliceblue;
}

.page-content__step-number {
  font-size: 24px;
  font-weight: 700;
  background: #F28B00;
  color: #FFF;
  align-self: stretch;
  padding: 20px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-content__step-content {
  color: #242424;
  padding: 20px;
}

.page-content__modal h4 {
  margin: unset;
  color: #242424;
  font-weight: 600;
  font-size: 1.2rem;
}

.page--centered-block {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 1.2rem;
  border: 1px solid #F28B00;
  color: #242424;
  font-weight: 500;
}
.page--centered-block p {
  margin-bottom: 0;
}

.page--bordered-block {
  padding: 1.2rem;
  margin: 1rem 0;
  border: 1px solid #E3E3E3;
}

.page--contrast-block {
  margin: 1rem -3rem;
  padding: 1rem 3rem;
  background-color: #242424;
  color: #BDBDBD;
}
.page--contrast-block h2, .page--contrast-block h3, .page--contrast-block h4, .page--contrast-block h5, .page--contrast-block h6 {
  color: #FFF;
  margin-bottom: 1.2rem;
}
.page--contrast-block .page--centered-block {
  color: #BDBDBD;
}

@media (max-width: 575.98px) {
  .page--contrast-block {
    margin: 1rem -1.4rem;
  }
  .page-content__step-number {
    min-width: 40px;
    padding: 10px;
    font-size: 20px;
  }
}
.page--contrast-span {
  color: #F28B00;
}

.site-layout--row {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

.site-layout-nav {
  background-color: rgba(227, 227, 227, 0.3);
}

.site-layout-nav__title {
  padding: 1.375rem 1.5rem;
  margin-bottom: 0;
  background-color: #242424;
}
.site-layout-nav__title .site-layout-nav__title-name {
  font-weight: 700;
  color: #FFF;
}
.site-layout-nav__title .site-layout-nav__title-email {
  font-size: clamp(11px, 0.68vw, 13px);
  color: #646464;
}

.site-layout-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 20px;
}

.site-layout-nav__item a {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #646464;
  padding: 14px 1.5rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}
.site-layout-nav__item a svg {
  fill: #646464;
}

.site-layout-nav__item:hover a {
  background: #f2f2f2;
}

.site-layout-nav__item--active a {
  color: inherit;
  background-color: rgba(242, 139, 0, 0.07);
}
.site-layout-nav__item--active a svg {
  fill: #F28B00;
}

.site-layout-nav__divider {
  height: 1px;
  background: #ebebeb;
  margin: 10px 0;
}

@media (min-width: 992px) {
  .site-layout-nav__list {
    padding-bottom: 1.375rem;
  }
  [dir=ltr] .site-layout-nav__item--active a {
    box-shadow: -3px 0 #F28B00 inset;
  }
  [dir=rtl] .site-layout-nav__item--active a {
    box-shadow: --3px 0 #F28B00 inset;
  }
}
@media (max-width: 991.98px) {
  .site-layout-nav {
    overflow-x: auto;
    max-width: 100%;
  }
  .site-layout-nav__list {
    display: flex;
    white-space: nowrap;
    padding: 0 8px;
  }
  .site-layout-nav__title {
    display: none;
  }
  .site-layout-nav__item a {
    padding: 14px 20px;
  }
  .site-layout-nav__item--active a {
    box-shadow: 0 -3px #F28B00 inset;
  }
}
/* ----------------------------------------
// Common
// ---------------------------------------- */
/*
// animations
*/
@keyframes loader-animation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
/*
// base
*/
html {
  height: 100%;
}

body {
  height: 100%;
  background: #FFF;
  color: #242424;
  font-family: "Montserrat", "sans-serif";
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  line-height: 1.5;
  overflow-y: scroll;
  backface-visibility: hidden;
}
[dir=ltr] body {
  text-align: left;
  direction: ltr;
}
[dir=rtl] body {
  text-align: right;
  direction: rtl;
}

a {
  color: #F28B00;
}
a.inverse {
  color: #242424;
}

a:hover {
  color: #F28B00;
  text-decoration: none;
}

label {
  margin-bottom: 0;
  font-size: 15px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.75rem;
  font-weight: 500;
}

h4 {
  font-size: 1.5rem;
  font-weight: 500;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

h6 {
  font-size: 1rem;
  font-weight: 500;
}

[dir=ltr] .reflect-rtl {
  transform: scaleX(1);
}
[dir=rtl] .reflect-rtl {
  transform: scaleX(-1);
}

/*
// .document
*/
.document {
  max-width: 760px;
  margin: 0 auto;
}

.document__header {
  text-align: center;
  padding: 44px 0 52px;
}

.document__title {
  font-weight: 700;
  letter-spacing: 0.01em;
  margin: 0;
}

.document__subtitle {
  font-size: 15px;
  color: #999;
  margin-top: 8px;
  margin-bottom: -4px;
}

.document__content {
  padding: 50px;
}

.document__signature {
  margin-top: 2.5rem;
}

@media (max-width: 1199.98px) {
  .document__header {
    padding: 44px 0 48px;
  }
  .document__subtitle {
    margin-top: 4px;
  }
}
@media (max-width: 991.98px) {
  .document__content {
    padding: 40px;
  }
}
@media (max-width: 767.98px) {
  .document__header {
    padding: 36px 0 40px;
  }
  .document__title {
    font-size: 36px;
  }
  .document__content {
    padding: 28px;
  }
}
@media (max-width: 575.98px) {
  .document__title {
    font-size: 32px;
  }
  .document__subtitle {
    font-size: 14px;
  }
  .document__content {
    padding: 24px;
  }
  .document__content .typography {
    font-size: 15px;
  }
  .document__signature {
    margin-top: 24px;
  }
}
/*
// grid
*/
.col-1of5 {
  padding: 0 15px;
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
}

.col-2of5 {
  padding: 0 15px;
  flex: 0 0 40%;
  max-width: 40%;
  position: relative;
  width: 100%;
}

.col-3of5 {
  padding: 0 15px;
  flex: 0 0 60%;
  max-width: 60%;
  position: relative;
  width: 100%;
}

.col-4of5 {
  padding: 0 15px;
  flex: 0 0 80%;
  max-width: 80%;
  position: relative;
  width: 100%;
}

.col-5of5 {
  padding: 0 15px;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
}

/*
// header
*/
@media (min-width: 1200px) {
  .mobile-header,
  .mobile-menu {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .header {
    display: none;
  }
}
/*
// .site
*/
.site {
  height: 100%;
}

.site__container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.site__header {
  flex-shrink: 0;
  background-color: #242424;
  position: sticky;
  top: 0;
  z-index: 100;
}

.site__body {
  flex-grow: 1;
}

.site__footer {
  flex-shrink: 0;
}

/* ----------------------------------------
// Components
// ---------------------------------------- */
/*
// .alert
*/
.alert {
  border: none;
  border-radius: 2px;
  font-size: 15px;
  line-height: 20px;
  padding: 0.75rem 1rem;
}
.alert a {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  color: currentColor;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 1.5px;
  padding: 0 3px;
  cursor: pointer;
}
.alert a:hover {
  background: rgba(255, 255, 255, 0.85);
}
.alert a:active {
  background: rgba(255, 255, 255, 0.5);
}
.alert hr {
  border-color: currentColor;
  opacity: 0.12;
}
.alert .close svg {
  display: block;
}

.alert-primary {
  background: #b2d9ff;
  color: #36414d;
  border: 1px solid #F28B00;
}

.alert-secondary {
  background: #f9f9f9;
  color: #4d4d4d;
  border: 1px solid #F28B00;
}

.alert-info {
  background: #b8e2e5;
  color: #2d3e40;
  border: 1px solid #F28B00;
}

.alert-success {
  background: #bde5a1;
  color: #34402d;
  border: 1px solid #F28B00;
}

.alert-danger {
  background: #ffb8b8;
  color: #473333;
  border: 1px solid #F28B00;
}

.alert-warning {
  background: #f2e291;
  color: #4d472e;
  border: 1px solid #F28B00;
}

.alert-xs {
  font-size: 14px;
  line-height: 1.25;
  padding: 6px 9px;
}

.alert-sm {
  font-size: 15px;
  padding: 7px 12px;
}

.alert-lg {
  font-size: 16px;
  padding: 1.125rem 1.375rem;
}

[dir=ltr] .alert-dismissible {
  padding-right: 3rem;
}
[dir=rtl] .alert-dismissible {
  padding-left: 3rem;
}

.alert-dismissible .close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;
  transition: all 0.2s;
  fill: rgba(0, 0, 0, 0.3);
  background: transparent;
}
[dir=ltr] .alert-dismissible .close {
  right: 0;
  border-top-right-radius: 1.5px;
  border-bottom-right-radius: 1.5px;
}
[dir=rtl] .alert-dismissible .close {
  left: 0;
  border-top-left-radius: 1.5px;
  border-bottom-left-radius: 1.5px;
}
.alert-dismissible .close:focus {
  outline: none;
}

.alert-dismissible .close:not(:disabled):not(.disabled):focus,
.alert-dismissible .close:not(:disabled):not(.disabled):hover {
  background: rgba(0, 0, 0, 0.08);
  fill: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

/*
// .arrow
*/
.arrow {
  display: block;
}

.arrow__button {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: clamp(2.34375rem, 2.6041666667vw, 3.125rem);
  height: clamp(2.34375rem, 2.6041666667vw, 3.125rem);
  background: transparent;
  transition: color 0.15s;
  color: #242424;
  background: transparent;
  border: 1px solid rgba(36, 36, 36, 0.3);
}
.arrow__button svg {
  fill: currentColor;
  display: block;
  height: auto;
  width: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
}
.arrow__button:focus {
  outline: none;
}
.arrow__button:hover {
  color: #FFF;
  background: #242424;
  border: 1px solid #242424;
}
.arrow__button:active {
  color: #FFF;
  background: #242424;
  border: 1px solid #242424;
}
.arrow__button:active, .arrow__button:active:before {
  transition-duration: 0s;
}

/*
// .breadcrumb
*/
.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  color: #242424;
}
.breadcrumb:after {
  content: "";
  clear: both;
}

.breadcrumb__list {
  display: block;
  list-style: none;
  padding: 0;
  margin: -4px 0 0;
  width: 100%;
}

.breadcrumb__item {
  margin-top: 4px;
}
[dir=ltr] .breadcrumb__item {
  margin-right: -7.12435px;
  float: left;
}
[dir=rtl] .breadcrumb__item {
  margin-left: -7.12435px;
  float: right;
}

.breadcrumb__item-link {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  height: 21px;
  line-height: 1;
  padding: 0 8px;
  color: #646464;
  font-weight: 400;
}
.breadcrumb__item-link:hover {
  color: #F28B00;
}
.breadcrumb__item-link::after {
  content: "/";
  margin-left: 5px;
  color: #242424;
  font-weight: 500;
}

[dir=ltr] .breadcrumb__item--first .breadcrumb__item-link {
  padding-left: 7px;
}
[dir=rtl] .breadcrumb__item--first .breadcrumb__item-link {
  padding-right: 7px;
}
.breadcrumb__item--first .breadcrumb__item-link:after {
  content: "/";
}
[dir=ltr] .breadcrumb__item--first .breadcrumb__item-link:after {
  left: 0;
  right: 12.12435px;
}
[dir=rtl] .breadcrumb__item--first .breadcrumb__item-link:after {
  right: 0;
  left: 12.12435px;
}

[dir=ltr] .breadcrumb__item--last {
  margin-right: 0;
}
[dir=rtl] .breadcrumb__item--last {
  margin-left: 0;
}
.breadcrumb__item--last .breadcrumb__item-link {
  pointer-events: none;
  color: #242424;
  font-weight: 500;
}
.breadcrumb__item--last .breadcrumb__item-link:after {
  content: "";
}
[dir=ltr] .breadcrumb__item--last .breadcrumb__item-link:after {
  left: 0;
  right: 12.12435px;
}
[dir=rtl] .breadcrumb__item--last .breadcrumb__item-link:after {
  right: 0;
  left: 12.12435px;
}

.breadcrumb__title-safe-area {
  display: block;
  height: 21px;
  width: calc(50% + var(--block-header-title-width, 0px) * .5 + 64px);
  margin-top: 4px;
}
[dir=ltr] .breadcrumb__title-safe-area {
  float: left;
}
[dir=rtl] .breadcrumb__title-safe-area {
  float: right;
}
@media (max-width: 1199.98px) {
  .breadcrumb__title-safe-area {
    display: none;
  }
}

.breadcrumb__spaceship-safe-area {
  display: block;
  height: 21px;
  width: calc(30% + 480px * .5 + 20px);
  margin-top: 4px;
}
[dir=ltr] .breadcrumb__spaceship-safe-area {
  float: right;
}
[dir=rtl] .breadcrumb__spaceship-safe-area {
  float: left;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .breadcrumb__spaceship-safe-area {
    width: calc(50% + 440px * .5 + 20px);
  }
}
@media (max-width: 1199.98px) {
  .breadcrumb__spaceship-safe-area {
    display: none;
  }
}

/*
// .btn
*/
.btn {
  border: none;
  border-radius: 0;
  fill: currentColor;
  font-weight: 500;
}
.btn.disabled, .btn:disabled {
  cursor: default;
}

.btn-icon {
  padding: 0;
}
.btn-icon svg {
  display: block;
  margin: auto;
}

.btn-loading {
  position: relative;
}
.btn-loading:after { /* nl size (default) */
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
  border-width: 2px;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
.btn-loading.btn-xl:after { /* xl size */
  left: calc(50% - 21px);
  top: calc(50% - 21px);
  width: 42px;
  height: 42px;
  border-radius: 21px;
}
.btn-loading.btn-lg:after, .btn-group-lg > .btn-loading.btn:after { /* lg size */
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
.btn-loading.btn-sm:after, .btn-group-sm > .btn-loading.btn:after { /* sm size */
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.btn-loading.btn-xs:after { /* xs size */
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.btn:not(.btn-icon) {
  font-size: 16px;
  line-height: 19px;
  padding: calc(17px * .5) 10px;
}
.btn.btn-icon {
  height: 36px;
  width: 36px;
}

.btn-sm:not(.btn-icon), .btn-group-sm > .btn:not(.btn-icon) {
  font-size: 14px;
  line-height: 17px;
  padding: calc(13px * .5) 10px;
}
.btn-sm.btn-icon, .btn-group-sm > .btn-icon.btn {
  height: 30px;
  width: 30px;
}

.btn-xs:not(.btn-icon) {
  font-size: 13px;
  line-height: 15px;
  padding: calc(9px * .5) 9px;
}
.btn-xs.btn-icon {
  height: 24px;
  width: 24px;
}

.btn-lg:not(.btn-icon), .btn-group-lg > .btn:not(.btn-icon) {
  font-size: 18px;
  line-height: 22px;
  padding: calc(20px * .5) 10px;
}
.btn-lg.btn-icon, .btn-group-lg > .btn-icon.btn {
  height: 42px;
  width: 42px;
}

.btn-xl:not(.btn-icon) {
  font-size: 20px;
  line-height: 26px;
  padding: calc(34px * .5) 10px;
}
.btn-xl.btn-icon {
  height: 60px;
  width: 60px;
}

.btn-primary:hover {
  border-color: #333;
  background: #333;
  color: #fff;
}
.btn-primary, .btn-primary.disabled, .btn-primary:disabled {
  border-color: #F28B00;
  background: #F28B00;
  color: #fff;
}
.btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #333;
  background: #333;
  color: #fff;
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  border-color: #4d4d4d;
  background: #4d4d4d;
  color: #fff;
}
.btn-primary.btn-loading:not(:disabled):not(.disabled), .btn-primary.btn-loading:not(:disabled):not(.disabled):active, .btn-primary.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #F28B00;
  background: #F28B00;
  cursor: default;
}
.btn-primary.btn-loading {
  /* hide button content */
}
.btn-primary.btn-loading, .btn-primary.btn-loading:hover, .btn-primary.btn-loading.focus, .btn-primary.btn-loading:focus, .btn-primary.btn-loading:not(:disabled):not(.disabled).active, .btn-primary.btn-loading:not(:disabled):not(.disabled):active, .btn-primary.btn-loading.disabled, .btn-primary.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-primary.btn-loading:after {
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
}

.btn-secondary:hover {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
}
.btn-secondary, .btn-secondary.disabled, .btn-secondary:disabled {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
}
.btn-secondary.focus, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
  box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus {
  border-color: #d6d6d6;
  background: #d6d6d6;
  color: #333;
}
.btn-secondary.btn-loading:not(:disabled):not(.disabled), .btn-secondary.btn-loading:not(:disabled):not(.disabled):active, .btn-secondary.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #f0f0f0;
  background: #f0f0f0;
  cursor: default;
}
.btn-secondary.btn-loading {
  /* hide button content */
}
.btn-secondary.btn-loading, .btn-secondary.btn-loading:hover, .btn-secondary.btn-loading.focus, .btn-secondary.btn-loading:focus, .btn-secondary.btn-loading:not(:disabled):not(.disabled).active, .btn-secondary.btn-loading:not(:disabled):not(.disabled):active, .btn-secondary.btn-loading.disabled, .btn-secondary.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-secondary.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-light:hover {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
}
.btn-light, .btn-light.disabled, .btn-light:disabled {
  border-color: transparent;
  background: transparent;
  color: #333;
}
.btn-light.focus, .btn-light:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
  border-color: #f0f0f0;
  background: #f0f0f0;
  color: #333;
  box-shadow: none;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus {
  border-color: #e1e1e1;
  background: #e1e1e1;
  color: #333;
}
.btn-light.btn-loading:not(:disabled):not(.disabled), .btn-light.btn-loading:not(:disabled):not(.disabled):active, .btn-light.btn-loading:not(:disabled):not(.disabled).active {
  border-color: transparent;
  background: transparent;
  cursor: default;
}
.btn-light.btn-loading {
  /* hide button content */
}
.btn-light.btn-loading, .btn-light.btn-loading:hover, .btn-light.btn-loading.focus, .btn-light.btn-loading:focus, .btn-light.btn-loading:not(:disabled):not(.disabled).active, .btn-light.btn-loading:not(:disabled):not(.disabled):active, .btn-light.btn-loading.disabled, .btn-light.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-light.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-muted:hover {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
}
.btn-muted, .btn-muted.disabled, .btn-muted:disabled {
  border-color: #fff;
  background: #fff;
  color: #ccc;
}
.btn-muted.focus, .btn-muted:focus, .btn-muted:not(:disabled):not(.disabled).active:focus, .btn-muted:not(:disabled):not(.disabled):active:focus {
  border-color: #f2f2f2;
  background: #f2f2f2;
  color: #4d4d4d;
  box-shadow: none;
}
.btn-muted:not(:disabled):not(.disabled):active, .btn-muted:not(:disabled):not(.disabled).active, .btn-muted:not(:disabled):not(.disabled).active:focus, .btn-muted:not(:disabled):not(.disabled):active:focus {
  border-color: #ebebeb;
  background: #ebebeb;
  color: #4d4d4d;
}
.btn-muted.btn-loading:not(:disabled):not(.disabled), .btn-muted.btn-loading:not(:disabled):not(.disabled):active, .btn-muted.btn-loading:not(:disabled):not(.disabled).active {
  border-color: #fff;
  background: #fff;
  cursor: default;
}
.btn-muted.btn-loading {
  /* hide button content */
}
.btn-muted.btn-loading, .btn-muted.btn-loading:hover, .btn-muted.btn-loading.focus, .btn-muted.btn-loading:focus, .btn-muted.btn-loading:not(:disabled):not(.disabled).active, .btn-muted.btn-loading:not(:disabled):not(.disabled):active, .btn-muted.btn-loading.disabled, .btn-muted.btn-loading:disabled {
  color: transparent;
  fill: transparent;
}
.btn-muted.btn-loading:after {
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
}

.btn-br-4 {
  border-radius: 4px;
}

.btn-shadow {
  box-shadow: 0 0 4px 0 #F28B00;
}

.default-pargo-button {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #E3E3E3;
  border-radius: 0;
  padding: 12px !important;
  transition: all 0.2s ease-in-out;
  background-color: #F2F2F2;
}
.default-pargo-button span {
  text-transform: uppercase;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 600;
  color: #242424;
  transition: all 0.2s ease-in-out;
}
.default-pargo-button svg {
  fill: #242424;
  transition: all 0.2s ease-in-out;
  height: clamp(11px, 0.68vw, 13px);
  width: auto;
}
.default-pargo-button:hover, .default-pargo-button.hover {
  border-color: #F28B00;
  background: #F28B00;
}
.default-pargo-button:hover span, .default-pargo-button.hover span {
  color: #FFF;
}
.default-pargo-button:hover svg, .default-pargo-button.hover svg {
  fill: #FFF;
}

.default-pargo-button-inverse {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #F28B00;
  border-radius: 0;
  padding: 12px !important;
  transition: all 0.2s ease-in-out;
  background-color: #F28B00;
}
.default-pargo-button-inverse span {
  text-transform: uppercase;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 600;
  color: #FFF;
  transition: all 0.2s ease-in-out;
}
.default-pargo-button-inverse svg {
  fill: #FFF;
  transition: all 0.2s ease-in-out;
  height: clamp(11px, 0.68vw, 13px);
  width: auto;
}
.default-pargo-button-inverse:hover, .default-pargo-button-inverse.hover {
  border-color: #F28B00;
  background: #F2F2F2;
}
.default-pargo-button-inverse:hover span, .default-pargo-button-inverse.hover span {
  color: #F28B00;
}
.default-pargo-button-inverse:hover svg, .default-pargo-button-inverse.hover svg {
  fill: #F28B00;
}
.default-pargo-button-inverse.button-responsive {
  width: 100%;
}
@media (min-width: 576px) {
  .default-pargo-button-inverse.button-responsive {
    margin-left: 0;
    margin-right: auto;
    width: unset;
  }
}

.outlined-icon-button {
  display: block;
  border: 1px solid #E3E3E3;
  border-radius: 0;
  padding: 12px 18px !important;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
}
@media (max-width: 575.98px) {
  .outlined-icon-button {
    padding: 6px 10px !important;
    flex: 1 1 45%;
  }
}
.outlined-icon-button svg {
  fill: #242424;
  transition: all 0.2s ease-in-out;
  height: clamp(11px, 0.68vw, 13px);
  width: auto;
}
.outlined-icon-button:hover {
  border-color: #F28B00;
}

.buttons-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  justify-content: flex-start;
}
@media (min-width: 576px) {
  .buttons-wrapper {
    width: unset;
  }
}

.button-mobile-only {
  display: block;
}
@media (min-width: 576px) {
  .button-mobile-only {
    display: none;
  }
}

/*
// .button-toggle
*/
.button-toggle__list {
  display: flex;
}

.button-toggle__item {
  margin: 0;
}

.button-toggle__button {
  margin: 0;
  background: #f0f0f0;
  color: #333;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  padding: 5px 9px;
  cursor: pointer;
  user-select: none;
  transition: background 0.12s, color 0.12s;
}
.button-toggle__button:hover {
  background: #e5e5e5;
  color: #333;
}
.button-toggle__button:active {
  background: #d6d6d6;
  color: #333;
}

[dir=ltr] .button-toggle__item:first-child .button-toggle__button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
[dir=rtl] .button-toggle__item:first-child .button-toggle__button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

[dir=ltr] .button-toggle__item:last-child .button-toggle__button {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
[dir=rtl] .button-toggle__item:last-child .button-toggle__button {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.button-toggle__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.button-toggle__input:checked ~ .button-toggle__button {
  background: #F28B00;
  color: #fff;
}

.buy-button {
  width: 50%;
}
.buy-button .buy-button__btn {
  border-radius: unset;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 700;
  padding-top: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
  padding-bottom: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
  border: 1px solid #F28B00;
  width: 100%;
}
@media (max-width: 1199.98px) {
  .buy-button .buy-button__btn {
    width: 100%;
  }
}
.buy-button .buy-button__btn .buy-button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.buy-button .buy-button__btn .buy-button__content svg {
  height: auto;
  width: clamp(0.890625rem, 0.9895833333vw, 1.1875rem);
}
.buy-button .buy-button__btn--loading {
  cursor: default;
}
.buy-button .buy-button__btn--loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.buy-button .wait-button__btn {
  border-radius: unset;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 700;
  padding-top: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
  padding-bottom: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
  background-color: transparent;
  border: 1px solid #F28B00;
}
@media (min-width: 576px) {
  .buy-button .wait-button__btn {
    max-width: 200px;
  }
}
.buy-button .wait-button__btn .wait-button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #F28B00;
}
.buy-button .wait-button__btn .wait-button__content svg {
  height: auto;
  width: clamp(0.5625rem, 0.625vw, 0.75rem);
  color: #F28B00;
}
.buy-button .wait-button__btn .wait-button__content span {
  white-space: nowrap;
}
.buy-button .wait-button__btn--loading {
  cursor: default;
}
.buy-button .wait-button__btn--loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

/*
// .card
*/
.card {
  background-color: #fff;
  border: none;
  border-radius: 0;
}

.card-bordered {
  border: 1px solid #E3E3E3;
}

.card__loader {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.card__loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #fff;
  opacity: 0.9;
}
.card__loader:after {
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.card__loader:before {
  opacity: 1;
}

.card--loading .card__loader {
  pointer-events: auto;
  opacity: 1;
}

.card-body .mat-elevation-z8 {
  box-shadow: unset !important;
}

.card-body--padding--2 {
  padding: 2rem;
}
@media (max-width: 575.98px) {
  .card-body--padding--2 {
    padding: 1.5rem;
  }
}

.card-insert-body {
  padding: 20px;
  border: 1px solid #E3E3E3;
  background: rgba(227, 227, 227, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  height: 100%;
}
.card-insert-body h6 {
  font-weight: 700;
}

.card-divider {
  height: 1px;
  background: #ebebeb;
}

.card-header {
  border-radius: 0;
  background: transparent;
  padding: 1.25rem 2rem;
  border: none;
  align-items: center;
}
@media (max-width: 767.98px) {
  .card-header {
    padding: 1.25rem 0.5rem;
  }
}
@media (max-width: 767.98px) {
  .card-header > h1 {
    font-size: 1.25rem;
  }
}
.card-header > h5 {
  margin-bottom: 0;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .card-header > h5 {
    font-size: clamp(14px, 0.83vw, 16px);
  }
}

.card-footer {
  border-radius: 0;
  background: transparent;
  padding: 1.25rem 2rem;
  border: none;
}

.card-footer__body {
  display: grid;
  grid-template-columns: 1fr 300px;
  align-items: center;
}
@media (max-width: 767.98px) {
  .card-footer__body {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

.card-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: -4px;
}

.card-title--lg {
  font-size: 28px;
}

.card-bottom-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F28B00;
  color: #FFF;
  transition: all ease-in-out 0.2s;
  border: unset;
  padding: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.card-bottom-button:hover {
  background-color: #242424;
}

/*
// .card-table
*/
.card-table {
  font-size: 15px;
}
.card-table a:hover {
  text-decoration: underline;
}
.card-table table {
  width: 100%;
  min-width: 500px;
}
[dir=ltr] .card-table table {
  text-align: left;
}
[dir=rtl] .card-table table {
  text-align: right;
}
.card-table thead th {
  border-bottom: 1px solid #ebebeb;
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px 12px;
}
.card-table tbody th,
.card-table tbody td,
.card-table tfoot th,
.card-table tfoot td {
  padding: 12px 12px;
}
.card-table tfoot th,
.card-table tfoot td {
  padding-top: 16px;
  padding-bottom: 16px;
}
.card-table tbody + tbody tr th,
.card-table tbody + tbody tr td,
.card-table tbody + tfoot tr th,
.card-table tbody + tfoot tr td,
.card-table tbody tr + tr th,
.card-table tbody tr + tr td {
  border-top: 1px solid #ebebeb;
}
.card-table tbody tr:hover > *,
.card-table tfoot tr:hover > * {
  background-color: #f7f7f7;
}
[dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
  padding-left: 2rem;
}
[dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
  padding-right: 2rem;
}
[dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
  padding-right: 2rem;
}
[dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
  padding-left: 2rem;
}
.card-table .card-table__body--merge-rows th,
.card-table .card-table__body--merge-rows td {
  padding-top: 3px;
  padding-bottom: 3px;
}
.card-table .card-table__body--merge-rows tr:first-child th,
.card-table .card-table__body--merge-rows tr:first-child td {
  padding-top: 12px;
}
.card-table .card-table__body--merge-rows tr:last-child th,
.card-table .card-table__body--merge-rows tr:last-child td {
  padding-bottom: 12px;
}
.card-table .card-table__body--merge-rows tr + tr th,
.card-table .card-table__body--merge-rows tr + tr td {
  border-top: none;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-table {
    font-size: 14px;
  }
  [dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
    padding-left: 1.5rem;
  }
  [dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
    padding-left: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .card-table {
    font-size: 14px;
  }
  [dir=ltr] .card-table th:first-child, [dir=ltr] .card-table td:first-child {
    padding-left: 1.375rem;
  }
  [dir=ltr] .card-table th:last-child, [dir=ltr] .card-table td:last-child {
    padding-right: 1.375rem;
  }
  [dir=rtl] .card-table th:first-child, [dir=rtl] .card-table td:first-child {
    padding-right: 1.375rem;
  }
  [dir=rtl] .card-table th:last-child, [dir=rtl] .card-table td:last-child {
    padding-left: 1.375rem;
  }
}
/*
// .clients
*/
.clients {
  display: block;
  position: relative;
}

.clients__button {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: none;
  font-family: inherit;
  transition: color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  fill: rgba(255, 255, 255, 0.7);
}
.clients__button:focus {
  outline: none;
}
.clients__button:hover {
  color: #F28B00;
  fill: #F28B00;
}

.clients__button-title {
  font-size: clamp(11px, 0.68vw, 13px);
  transition: color 0.2s;
}

.clients__button-arrow {
  transition: transform 0.2s, color 0.2s;
}
.clients__button-arrow svg {
  display: block;
  height: auto;
  width: clamp(0.421875rem, 0.46875vw, 0.5625rem);
}

.clients__menu {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}

.clients__body {
  pointer-events: auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  color: #242424;
  padding: 15px 20px;
}

.clients__list {
  white-space: nowrap;
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.clients__link {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  color: #646464;
  fill: #646464;
}
.clients__link:hover {
  color: #F28B00;
  fill: #F28B00;
}

.clients__item {
  color: inherit;
  display: block;
  position: relative;
  padding: 5px 0;
}

.clients__link-icon {
  margin-right: 15px;
}

.clients--open .clients__menu {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.clients--open .clients__button-arrow {
  transform: rotate(180deg);
}

.clients__button {
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.clients__arrow {
  width: 41px;
}

.clients--menu .clients__button {
  height: auto;
}
.clients--menu .clients__button-title {
  font-size: 13px;
}
.clients--menu .clients__button-arrow svg {
  width: 11px;
}
.clients--menu.clients--open .clients__menu {
  max-height: max-content;
}
.clients--menu .clients__menu {
  position: unset;
  max-height: 0;
}
.clients--menu .clients__body {
  background-color: transparent;
  box-shadow: unset;
}
.clients--menu .clients__link {
  color: rgba(255, 255, 255, 0.7);
  fill: rgba(255, 255, 255, 0.7);
  font-size: 13px;
}

.clipboard_button {
  padding: 0;
  border: none;
  background-color: transparent;
}
.clipboard_button:active svg {
  fill: #F28B00;
}

/*
// .collapse-area
*/
.collapse-area {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.collapse-area--open {
  height: auto;
  opacity: 1;
  overflow: visible;
}

/*
// .departments
*/
.collapse-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.collapse-picker__button {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 1px solid #E3E3E3;
  padding: 10px 20px;
  transition: color 0.2s;
}

.collapse-picker__button-title {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 600;
  text-align: start;
}

.collapse-picker__button-arrow {
  transition: background-color 0.2s, transform 0.2s, color 0.2s;
}
.collapse-picker__button-arrow svg {
  display: block;
  height: auto;
  width: clamp(1.734375rem, 1.9270833333vw, 2.3125rem);
}

.collapse-picker__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 0;
  overflow: hidden;
  padding: 8px 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, height 0.3s, transform 0.3s, visibility 0s 0.3s;
}

.collapse-picker__search {
  width: 100%;
}

.collapse-picker__items {
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
}

.collapse-picker__items-button {
  width: 33.33333%;
  background-color: transparent;
  padding-top: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
  padding-bottom: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
  border: 1px solid #E3E3E3;
  transition: box-shadow 0.3s ease, color 0.3s ease;
}
.collapse-picker__items-button:hover, .collapse-picker__items-button.selected {
  color: #F28B00;
  border: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
}

.collapse-picker--open .collapse-picker__body {
  transition-delay: 0s;
  height: auto;
  transform: translateY(0);
  overflow: visible;
  visibility: visible;
  opacity: 1;
}
.collapse-picker--open .collapse-picker__button {
  background-color: #F28B00;
  color: #FFF;
  border: 1px solid #FFF;
}
.collapse-picker--open .collapse-picker__button-arrow {
  transform: rotate(180deg);
}

.collapse-picker__button {
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.collapse-picker__arrow {
  width: 41px;
}

.collapse-picker__items-title {
  margin-top: 8px;
  font-weight: 600;
}

.collapse-picker__loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  pointer-events: auto;
  transition: opacity 0.2s ease-in-out;
}
.collapse-picker__loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #ebebeb;
  opacity: 0.9;
}
.collapse-picker__loader:after {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.correct-old-value {
  text-decoration: line-through;
  color: #ff3333;
  transform: scale(1.5);
  font-weight: 700;
  margin-right: 10px;
}

.correct-correct-value {
  transform: scale(1.2);
  font-weight: 600;
}

.complex-pictogram {
  position: relative;
  height: 60px;
  width: 30%;
}
.complex-pictogram .complex-pictogram__item {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: auto !important;
}
.complex-pictogram .complex-pictogram__item-value {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -10px;
  transform: translate(-50%, -50%);
  font-size: 11px;
  font-weight: 600;
  width: max-content;
  letter-spacing: normal;
}

/*
// .container
*/
.container {
  max-width: 1920px;
}
@media (min-width: 576px) {
  .container {
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  .container {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .container {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .container {
    padding: 0 80px;
  }
}
@media (min-width: 1400px) {
  .container {
    padding: 0 clamp(80px, 5.7vw, 110px);
  }
}

.container .container {
  padding: 0;
}

.container .page-container {
  max-width: 1410px;
  margin: 0 auto;
}

/*
// .decor
*/
.decor {
  display: block;
}

.decor--type--center .decor__body {
  overflow: hidden;
  position: relative;
}
.decor--type--center .decor__start,
.decor--type--center .decor__end {
  position: absolute;
}
.decor--type--center .decor__start {
  transform-origin: right top;
}
.decor--type--center .decor__end {
  transform-origin: left top;
}
.decor--type--center .decor__center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.decor--type--center .decor__body {
  height: 31px;
  margin: 0 -10px -10px;
}
.decor--type--center .decor__start,
.decor--type--center .decor__end {
  height: 21px;
  width: calc(50% - 22.12435px);
}
.decor--type--center .decor__start {
  left: 10px;
  transform: skewX(30deg);
}
.decor--type--center .decor__end {
  right: 10px;
  transform: skewX(-30deg);
}
.decor--type--center .decor__center {
  height: 21px;
  width: 26.2487px;
}

.decor--type--bottom {
  width: 100%;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  height: 31px;
}
.decor--type--bottom .decor__start,
.decor--type--bottom .decor__end {
  position: absolute;
  width: calc((100% - 1350px - 160px) * 0.5);
  bottom: 0;
}
.decor--type--bottom .decor__start {
  left: 0;
  transform-origin: right bottom;
}
.decor--type--bottom .decor__end {
  right: 0;
  transform-origin: left bottom;
}
.decor--type--bottom .decor__start,
.decor--type--bottom .decor__end {
  height: 21px;
}
.decor--type--bottom .decor__start {
  border-top-right-radius: 2px;
}
.decor--type--bottom .decor__end {
  border-top-left-radius: 2px;
}
.decor--type--bottom .decor__start {
  transform: skewX(30deg);
}
.decor--type--bottom .decor__end {
  transform: skewX(-30deg);
}

.expansion-content {
  --mat-expansion-container-background-color: transparent;
  --mat-expansion-header-hover-state-layer-color: transparent;
  --mat-expansion-container-shape: 0;
  --mat-expansion-header-indicator-color: #f28b00;
}
.expansion-content .mat-expansion-panel,
.expansion-content .mat-expansion-panel:not([class*=mat-elevation-z]) {
  --mat-expansion-container-shape: 0;
  box-shadow: none !important;
}
.expansion-content .mat-expansion-panel {
  transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1);
}
.expansion-content .mat-expansion-panel-body,
.expansion-content .mat-expansion-panel-header {
  padding: 0;
}
.expansion-content .mat-expansion-panel-header {
  padding-right: 2px;
}

.expansion-content-title {
  font-size: 1.6rem;
  font-weight: 600;
}

/*
// .filters-button
*/
.filters-button {
  display: flex;
  background: #f0f0f0;
  border: none;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  color: inherit;
  font-family: inherit;
  transition: background-color 0.12s;
  padding-top: 7px;
  padding-bottom: 7px;
}
[dir=ltr] .filters-button {
  padding-left: 9px;
  padding-right: 7px;
}
[dir=rtl] .filters-button {
  padding-right: 9px;
  padding-left: 7px;
}
.filters-button:focus {
  outline: none;
}
.filters-button:hover {
  background: #ebebeb;
}
.filters-button:active {
  background: #e0e0e0;
}

.filters-button__icon {
  fill: #404040;
}
[dir=ltr] .filters-button__icon {
  margin-right: 7px;
}
[dir=rtl] .filters-button__icon {
  margin-left: 7px;
}
.filters-button__icon svg {
  display: block;
}

.filters-button__title {
  padding-top: 1px;
}

.filters-button__counter {
  background: #F28B00;
  color: #fff;
  padding: 3px 4px 2px;
  font-size: 11px;
  border-radius: 1.5px;
}
[dir=ltr] .filters-button__counter {
  margin-left: 6px;
}
[dir=rtl] .filters-button__counter {
  margin-right: 6px;
}

/*
// .form
*/
[dir=ltr] .form-check {
  padding-left: 1.5rem;
}
[dir=rtl] .form-check {
  padding-right: 1.5rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.1875rem;
}
[dir=ltr] .form-check-input {
  margin-left: -1.5rem;
}
[dir=rtl] .form-check-input {
  margin-right: -1.5rem;
}

/*
// .form-control
*/
.form-control {
  border-radius: 0;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0.15s ease-in-out, opacity 0.15s ease-in-out;
  color: #262626;
  background-color: #fff;
  border-color: #ebebeb;
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  padding: calc(clamp(2.109375rem, 2.34375vw, 2.8125rem)-19px-2px * .5) 10px;
  font-size: clamp(11px, 0.68vw, 13px);
  line-height: 19px;
}
.form-control::placeholder {
  color: #6c757d;
}
.form-control[readonly] {
  color: #262626;
  background-color: #fff;
  border-color: #ebebeb;
}
.form-control[readonly]::placeholder {
  color: #6c757d;
}
.form-control:hover {
  border-color: #d9d9d9;
}
.form-control:focus {
  box-shadow: none;
  background-color: #fff;
  border-color: #d9d9d9;
  border-bottom: 1px solid #F28B00;
}
.form-control:disabled {
  opacity: 0.7;
  color: #262626;
  background-color: #fff;
  border-color: #ebebeb;
}
.form-control:disabled::placeholder {
  color: #6c757d;
}
.was-validated .form-control:invalid:focus, .was-validated .form-control:valid:focus, .form-control.is-invalid:focus, .form-control.is-valid:focus {
  box-shadow: none;
}
.was-validated .form-control:invalid, .was-validated .form-control:valid, .form-control.is-invalid, .form-control.is-valid {
  background-image: none;
}
.was-validated .form-control:valid, .form-control.is-valid {
  color: #262626;
  background-color: #e2f2da;
  border-color: #e2f2da;
}
.was-validated .form-control:valid::placeholder, .form-control.is-valid::placeholder {
  color: #6c757d;
}
.was-validated .form-control:valid:hover, .form-control.is-valid:hover {
  border-color: #c3d9b8;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  background-color: #fff;
  border-color: #88cc66;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  color: #262626;
  background-color: #f2dada;
  border-color: #f2dada;
}
.was-validated .form-control:invalid::placeholder, .form-control.is-invalid::placeholder {
  color: #6c757d;
}
.was-validated .form-control:invalid:hover, .form-control.is-invalid:hover {
  border-color: #d9a3a3;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  background-color: #fff;
  border-color: #cc6666;
}

.form-control-lg {
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  padding: calc(clamp(2.109375rem, 2.34375vw, 2.8125rem)-21px-2px * .5) 12px;
  font-size: 18px;
  line-height: 21px;
}

.form-control-sm {
  height: 30px;
  padding: calc(30px-17px-2px * .5) 8px;
  font-size: 14px;
  line-height: 17px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.form-control:not(:disabled) {
  cursor: pointer;
}
select.form-control, .was-validated select.form-control:invalid, .was-validated select.form-control:valid, select.form-control.is-invalid, select.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 6' fill='rgba(0, 0, 0, 0.55)' width='11' height='6'%3E%3Cpath d='M5.5 3.98L1.38 0.16C1.27 0.05 1.12 0 0.96 0C0.8 0 0.64 0.05 0.53 0.16L0.17 0.49C0.06 0.6 0 0.74 0 0.89C0 1.04 0.06 1.18 0.17 1.28L5.07 5.83C5.18 5.94 5.34 6 5.5 6C5.66 6 5.81 5.94 5.92 5.83L10.82 1.29C10.93 1.18 11 1.04 11 0.89C11 0.74 10.93 0.6 10.82 0.5L10.46 0.16C10.22 -0.06 9.84 -0.06 9.61 0.16L5.5 3.98Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 11px 6px;
}
select.form-control::-ms-expand {
  display: none;
}

.is-sticky select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.is-sticky select.form-control:not(:disabled) {
  cursor: pointer;
}
.is-sticky select.form-control, .was-validated .is-sticky select.form-control:invalid, .was-validated .is-sticky select.form-control:valid, .is-sticky select.form-control.is-invalid, .is-sticky select.form-control.is-valid {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 6' fill='%23fff' width='11' height='6'%3E%3Cpath d='M5.5 3.98L1.38 0.16C1.27 0.05 1.12 0 0.96 0C0.8 0 0.64 0.05 0.53 0.16L0.17 0.49C0.06 0.6 0 0.74 0 0.89C0 1.04 0.06 1.18 0.17 1.28L5.07 5.83C5.18 5.94 5.34 6 5.5 6C5.66 6 5.81 5.94 5.92 5.83L10.82 1.29C10.93 1.18 11 1.04 11 0.89C11 0.74 10.93 0.6 10.82 0.5L10.46 0.16C10.22 -0.06 9.84 -0.06 9.61 0.16L5.5 3.98Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 11px 6px;
}
.is-sticky select.form-control::-ms-expand {
  display: none;
}

[dir=ltr] select.form-control {
  padding-right: 24px;
  background-position: right 10px center;
}
[dir=rtl] select.form-control {
  padding-left: 24px;
  background-position: left 10px center;
}

[dir=ltr] select.form-control-lg {
  padding-right: 28px;
  background-position: right 12px center;
}
[dir=rtl] select.form-control-lg {
  padding-left: 28px;
  background-position: left 12px center;
}

[dir=ltr] select.form-control-sm {
  padding-right: 20px;
  background-position: right 8px center;
}
[dir=rtl] select.form-control-sm {
  padding-left: 20px;
  background-position: left 8px center;
}

.valid-feedback {
  color: #388020;
}

.invalid-feedback {
  color: #b32d2d;
}

/** Кнопка в map-info-window кнопка закрытия окна и title */
.gm-style-iw-chr {
  display: none;
}

/** Окно map-info-window */
.gm-style-iw-t {
  opacity: 0.95;
}

.gm-style-iw-tc {
  opacity: 0.9;
}

.google-map--window {
  display: flex;
  max-width: 220px;
}
.google-map--window a {
  cursor: pointer;
}

.google-map--close {
  cursor: pointer;
  font-size: 16px;
  margin: 0 5px;
}

/*
// .image
*/
.image--type--product .image__body,
.image--type--category .image__body {
  display: block;
  position: relative;
  width: 100%;
}
.image--type--product .image__body:hover .owl-nav,
.image--type--category .image__body:hover .owl-nav {
  opacity: 1;
}
.image--type--product .image__body .owl-nav,
.image--type--category .image__body .owl-nav {
  position: absolute;
  top: 42%;
  width: 100%;
  color: #F28B00;
  opacity: 0;
  transition: opacity 0.4s;
}
.image--type--product .image__body .owl-nav .owl-prev,
.image--type--category .image__body .owl-nav .owl-prev {
  position: absolute;
  transform: scaleY(3);
  left: 4px;
  width: 20px;
  padding: 0 4px;
}
.image--type--product .image__body .owl-nav .owl-prev:hover,
.image--type--category .image__body .owl-nav .owl-prev:hover {
  transform: scaleY(3.2);
}
.image--type--product .image__body .owl-nav .owl-next,
.image--type--category .image__body .owl-nav .owl-next {
  position: absolute;
  transform: scaleY(3);
  right: 4px;
  width: 20px;
  text-align: end;
  padding: 0 4px;
}
.image--type--product .image__body .owl-nav .owl-next:hover,
.image--type--category .image__body .owl-nav .owl-next:hover {
  transform: scaleY(3.2);
}
.image--type--product .image__body .owl-nav .disabled,
.image--type--category .image__body .owl-nav .disabled {
  cursor: default;
  filter: grayscale(1);
}
.image--type--product .image__body .owl-nav .disabled:hover,
.image--type--category .image__body .owl-nav .disabled:hover {
  transform: scaleY(3);
}
.image--type--product .image__tag,
.image--type--category .image__tag {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.image--type--product .image__tag-carousel-item,
.image--type--category .image__tag-carousel-item {
  position: unset;
}

.image--type--product .image__body {
  padding-bottom: 100%;
}

.image--type--category .image__body {
  padding-bottom: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.input-car-plate {
  position: relative;
  height: 47px;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  border: 1px solid #E3E3E3;
  color: #646464;
  text-align: left;
  overflow: hidden;
  min-width: 200px;
}
.input-car-plate input {
  font-size: 16px;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  border: none;
  outline: none;
  text-align: center;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.input-car-plate input:focus {
  outline: none;
  box-shadow: none;
}
.input-car-plate input:active {
  outline: none;
  box-shadow: none;
}
.input-car-plate input::placeholder {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: normal;
  text-transform: none;
  color: #a9a9a9;
}
.input-car-plate .icon-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.input-car-plate .icon-search svg {
  fill: #a9a9a9;
  width: 17px;
  height: 17px;
}

.input-car-plate__flag {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #FFF;
  background: rgb(0, 44, 218);
  font-size: 13px;
  border-radius: 4px 0 0 4px;
}

.input-car-vin {
  position: relative;
  height: 47px;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  border: 1px solid #E3E3E3;
  color: #646464;
  text-align: left;
  overflow: hidden;
  min-width: 260px;
}
.input-car-vin input {
  font-size: 16px;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
  border: none;
  outline: none;
  text-align: center;
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.input-car-vin input:focus {
  outline: none;
  box-shadow: none;
}
.input-car-vin input:active {
  outline: none;
  box-shadow: none;
}
.input-car-vin input::placeholder {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: normal;
  text-transform: none;
  color: #a9a9a9;
}
.input-car-vin .icon-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.input-car-vin .icon-search svg {
  fill: #a9a9a9;
  width: 17px;
  height: 17px;
}

.input-car-vin__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 38px;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: #FFF;
  background: #F28B00;
  font-size: 13px;
  border-radius: 4px 0 0 4px;
}

/*
// .input-check
*/
.input-check {
  display: inline-block;
}

.input-check__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-check__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-check__box {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 1.2px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) inset;
  transition: background 0.15s, box-shadow 0.15s;
}

.input-check__icon {
  pointer-events: none;
  position: absolute;
  left: 4px;
  top: 5px;
  fill: #fff;
  transform: scale(0);
  transition: fill 0.15s, transform 0.15s, visibility 0s 0.15s;
}
.input-check__icon svg {
  display: block;
}

.input-check__input:checked ~ .input-check__box {
  background: #F28B00;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) inset;
}
.input-check__input:checked ~ .input-check__icon {
  fill: #fff;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-check__input:disabled ~ .input-check__box {
  background: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}
.input-check__input:disabled ~ .input-check__icon {
  fill: rgba(0, 0, 0, 0.2);
}

/*
// .input-check-color
*/
.input-check-color {
  display: inline-block;
}

.input-check-color__body {
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  overflow: hidden;
  cursor: inherit;
  margin: 0;
}

.input-check-color__input {
  position: absolute;
  visibility: hidden;
}

.input-check-color__box {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 1.2px;
  background: currentColor;
  cursor: inherit;
}

.input-check-color__icon {
  position: absolute;
  left: 5px;
  top: 6px;
  fill: #fff;
  transform: scale(0);
  transition: transform 0.15s;
}
.input-check-color__icon svg {
  display: block;
}

.input-check-color__input:checked ~ .input-check-color__icon {
  transform: scale(1);
}

.input-check-color__input:disabled {
  cursor: default;
}

.input-check-color__input:disabled ~ .input-check-color__box {
  position: relative;
  left: 0px;
  top: 0px;
  width: 22px;
  height: 22px;
  opacity: 0.5;
  cursor: default;
}
.input-check-color__input:disabled ~ .input-check-color__stick {
  pointer-events: none;
  display: block;
  position: absolute;
  width: 2px;
  border-radius: 1px;
  height: 34px;
  left: calc(50% - 1px);
  top: -6px;
  background: rgba(102, 102, 102, 0.9);
  transform: rotateZ(45deg);
  transform-origin: center center;
}

.input-check-color--light .input-check-color__icon {
  fill: #3d464d;
}

.input-check-color--white .input-check-color__box {
  box-shadow: 0 0 0 2px #d9d9d9 inset;
}
.input-check-color--white .input-check-color__icon {
  fill: #bfbfbf;
}

/*
// .input-number
*/
.input-number {
  display: block;
  width: 100%;
  position: relative;
}

.input-number__input {
  position: relative;
  -moz-appearance: textfield;
  display: block;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  font-weight: 700;
}
.input-number__input::-webkit-inner-spin-button, .input-number__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number__sub,
.input-number__add {
  position: absolute;
  height: 100%;
  width: 40px;
  top: 0;
  cursor: pointer;
  user-select: none;
  opacity: 0.3;
  transition: opacity 0.18s;
}
.input-number__sub:before, .input-number__sub:after,
.input-number__add:before,
.input-number__add:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: currentColor;
}
.input-number__sub:before,
.input-number__add:before {
  width: 10px;
  height: 2px;
}
.input-number__sub:hover,
.input-number__add:hover {
  opacity: 1;
}

[dir=ltr] .input-number__sub {
  left: 1px;
}
[dir=rtl] .input-number__sub {
  right: 1px;
}

[dir=ltr] .input-number__add {
  right: 1px;
}
[dir=rtl] .input-number__add {
  left: 1px;
}
.input-number__add:after {
  width: 2px;
  height: 10px;
}

.input-number__warning-text {
  font-size: 12px;
}

/*
// .input-radio
*/
.input-radio {
  display: inline-block;
}

.input-radio__body {
  display: block;
  position: relative;
  width: 16px;
  height: 16px;
}

.input-radio__input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: inherit;
}

.input-radio__circle {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.17) inset;
  transition: background 0.2s, box-shadow 0.2s;
}
.input-radio__circle:after {
  display: block;
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: #fff;
  transform: scale(0);
  transition: background 0.2s, transform 0.2s, visibility 0s 0.2s;
  visibility: hidden;
}

.input-radio__input:checked ~ .input-radio__circle {
  background: #F28B00;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset;
}
.input-radio__input:checked ~ .input-radio__circle:after {
  background: #fff;
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  transform: scale(1);
}

.input-radio__input:disabled ~ .input-radio__circle {
  background: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) inset;
}
.input-radio__input:disabled ~ .input-radio__circle:after {
  background: rgba(0, 0, 0, 0.2);
}

/*
// .input-radio-color
*/
.input-radio-color {
  display: block;
}

.input-radio-color__list {
  padding: 1px 0;
  display: flex;
  margin: -2px;
}

.input-radio-color__item {
  width: 28px;
  height: 28px;
  display: block;
  margin: 2px;
  cursor: pointer;
}
.input-radio-color__item input {
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.input-radio-color__item span {
  position: relative;
  width: 28px;
  height: 28px;
  display: block;
  border-radius: 14px;
  background: currentColor;
  transform: scale(0.7857142857);
  transition: transform 0.2s;
}
.input-radio-color__item span:before, .input-radio-color__item span:after {
  display: block;
  position: absolute;
  content: "";
  border-radius: 1000px;
}
.input-radio-color__item span:before {
  left: 3px;
  top: 3px;
  width: 22px;
  height: 22px;
  background: #fff;
  transform: scale(0);
  transition: transform 0.2s;
}
.input-radio-color__item span:after {
  left: 7px;
  top: 7px;
  width: 14px;
  height: 14px;
  background: currentColor;
  transform: scale(0);
  transition: transform 0.2s;
}
.input-radio-color__item:hover input:enabled ~ span {
  transform: scale(0.8571428571);
}

.input-radio-color__item input:enabled:checked ~ span, .input-radio-color__item input:enabled:checked ~ span:before, .input-radio-color__item input:enabled:checked ~ span:after {
  transform: scale(1);
}

.input-radio-color__item--disabled {
  cursor: default;
}
.input-radio-color__item--disabled span {
  transform: scale(0.75);
}
.input-radio-color__item--disabled span:before {
  transform: scale(1.33) rotateZ(45deg);
  left: 13px;
  top: -1px;
  width: 2px;
  height: calc(100% + 2px);
  background: rgba(0, 0, 0, 0.3);
}

.input-radio-color__item--white span {
  background: #fff;
  box-shadow: inset 0 0 0 2px #ccc;
}
.input-radio-color__item--white span:after {
  box-shadow: inset 0 0 0 2px #ccc;
}

/*
// .input-radio-label
*/
.input-radio-label__list {
  display: flex;
  flex-wrap: wrap;
  margin: -3px;
}

.input-radio-label__item {
  margin: 3px;
}

.input-radio-label__input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.input-radio-label__title {
  display: flex;
  align-items: center;
  background: #fff;
  height: 28px;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  font-size: 13px;
  font-weight: 500;
  padding: 0 12px 2px;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.15s;
}
.input-radio-label__title:hover {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1) inset;
}
.input-radio-label__title:active {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2) inset;
}

.input-radio-label__input:checked ~ .input-radio-label__title {
  box-shadow: 0 0 0 2px #F28B00 inset;
}

.input-radio-label__input:disabled ~ .input-radio-label__title {
  color: #a6a6a6;
  font-weight: 400;
  cursor: default;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.item-option {
  border-bottom: 1px dotted #E3E3E3;
}

/*
// .layout-switcher
*/
.layout-switcher__list {
  display: flex;
}
@media (max-width: 767.98px) {
  .layout-switcher__list {
    display: none;
  }
}

.layout-switcher__button {
  margin: 0;
  border-radius: 0;
  border: 1px solid #E3E3E3;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: #95a0a6;
}
.layout-switcher__button:focus {
  outline: none;
}
.layout-switcher__button svg {
  display: block;
  fill: currentColor;
}

.layout-switcher__button:hover {
  background-color: #f0f0f0;
}

.layout-switcher__button--active {
  background-color: #F28B00;
  border-color: #F28B00;
  color: #fff;
}
.layout-switcher__button--active:hover {
  background-color: #F28B00;
  border-color: #F28B00;
  color: #fff;
}

/*
// .loading-bar
*/
.loading-bar {
  position: fixed;
  z-index: 9999;
  height: 2px;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.loading-bar__inner {
  position: relative;
  height: inherit;
  width: 100%;
  transition: transform 0s 0s, opacity 0s 0s;
  background-color: #fff;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.17), 0 0 0 1px rgba(0, 0, 0, 0.02);
}
[dir=ltr] .loading-bar__inner {
  transform: translateX(-110%);
}
[dir=rtl] .loading-bar__inner {
  transform: translateX(110%);
}
.loading-bar__inner:before {
  content: "";
  display: block;
  height: 100%;
  width: 48px;
  background-color: #F28B00;
  box-shadow: 0 0 6px 1px rgba(242, 139, 0, 0.5);
}
[dir=ltr] .loading-bar__inner:before {
  margin-right: -1px;
  float: right;
}
[dir=rtl] .loading-bar__inner:before {
  margin-left: -1px;
  float: left;
}

.loading-bar--start .loading-bar__inner {
  transition: transform 0.8s 0s, opacity 0s 0s;
}
[dir=ltr] .loading-bar--start .loading-bar__inner {
  transform: translateX(-80%);
}
[dir=rtl] .loading-bar--start .loading-bar__inner {
  transform: translateX(80%);
}

.loading-bar--complete .loading-bar__inner {
  transform: translateX(0);
  transition: transform 0.8s 0s, opacity 0.2s 0.9s;
  opacity: 0;
}
[dir=ltr] .loading-bar--complete .loading-bar__inner {
  transform: translateX(0);
}
[dir=rtl] .loading-bar--complete .loading-bar__inner {
  transform: translateX(0);
}

.gm-style-iw-d {
  overflow: visible !important;
}

.gm-style-iw-c {
  padding: 0.8rem !important;
}

/*
// .modal
*/
.modal {
  -webkit-overflow-scrolling: touch;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: 0 !important;
}

.modal-backdrop {
  background: rgba(51, 51, 51, 0.8);
}

.modal-backdrop.show {
  opacity: 1;
}

.modal-content {
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-radius: 0 !important;
}
.modal-header svg {
  fill: #242424;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrapper__content {
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-wrapper__close-button {
  position: absolute;
  right: -30px;
  top: -30px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
}

.modal-login__header {
  background-color: #242424;
}
.modal-login__header .modal-login__header-icon svg {
  width: 100px;
}

.ngx-dropdown-button {
  border: 1px solid rgb(232, 232, 232) !important;
  border-radius: 0 !important;
  min-height: 40px !important;
}
.ngx-dropdown-button .display-text {
  color: #242424;
}
.ngx-dropdown-button .nsdicon-angle-down:before {
  border-style: solid;
  border-width: 2px 2px 0 0 !important;
  border-color: #646464;
  top: 3px !important;
  transform: scale(0.8, 0.7) rotate(135deg) !important;
  transition: transform 0.3s;
}
.ngx-dropdown-button .nsdicon-angle-down:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  height: 39px;
  border-left: 1px solid #e2e2e2;
}
.ngx-dropdown-button .nsdicon-angle-down {
  right: 5px !important;
}
.ngx-dropdown-button .nsdicon-angle-down.up {
  transform: unset !important;
  transition: unset !important;
}
.ngx-dropdown-button .nsdicon-angle-down.up:before {
  transform: scale(0.8, 0.7) rotate(315deg) !important;
  transition: transform 0.3s;
}

.ngx-dropdown-container input {
  border-color: #F28B00 !important;
}
.ngx-dropdown-container .available-item {
  font-weight: 500 !important;
  line-height: 1.6 !important;
}

/*
// .ngx-slider
*/
.ngx-slider-custom {
  height: 16px;
  padding: 5px 0;
}
.ngx-slider-custom .ngx-slider {
  display: block;
  height: 6px;
  margin: 0;
}
.ngx-slider-custom .ngx-slider .ngx-slider-bubble {
  display: none;
}
.ngx-slider-custom .ngx-slider .ngx-slider-bar {
  height: 6px;
  border-radius: 3px;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  top: -5px;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer::after {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  top: 5px;
  left: 5px;
}
.ngx-slider-custom .ngx-slider .ngx-slider-bar {
  background: #e0e0e0;
}
.ngx-slider-custom .ngx-slider .ngx-slider-selection {
  background: #F28B00;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer,
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:hover {
  cursor: ew-resize;
  background: #F28B00;
  box-shadow: 0 0 0 0 rgba(242, 139, 0, 0);
  transition: box-shadow 0.2s;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer::after,
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:hover::after {
  background: #fff;
  transition: transform 0.2s;
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(242, 139, 0, 0.3);
}
.ngx-slider-custom .ngx-slider .ngx-slider-pointer:focus::after {
  transform: scale(0);
}

/*
// .ngx-toastr
*/
.toast-container {
  max-width: calc(100% - 24px);
  z-index: 1000;
}
.toast-container .ngx-toastr {
  user-select: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 0.875rem 1rem;
  transition: background 0.12s ease-in-out;
  max-width: 100%;
}
.toast-container .ngx-toastr:hover {
  transition-duration: 0.1s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
}
.toast-container .ngx-toastr:active {
  transition-duration: 0s;
}
.toast-container .ngx-toastr {
  background: #b2d9ff;
  color: #36414d;
}
.toast-container .ngx-toastr:hover {
  background: #9ecfff;
}
.toast-container .toast-warning {
  background: #f2e291;
  color: #4d472e;
}
.toast-container .toast-warning:hover {
  background: #f0dd7f;
}
.toast-container .toast-info {
  background: #b8e2e5;
  color: #2d3e40;
}
.toast-container .toast-info:hover {
  background: #a9dce0;
}
.toast-container .toast-error {
  background: #ffb8b8;
  color: #473333;
}
.toast-container .toast-error:hover {
  background: #ffa4a4;
}
.toast-container .toast-success {
  background: #bde5a1;
  color: #34402d;
}
.toast-container .toast-success:hover {
  background: #b2e191;
}

.ngx-toastr {
  font-size: 15px;
  line-height: 1.375;
}

.toast-title {
  font-weight: 500;
}

.toast-message {
  font-size: 14px;
}

.toast-title + .toast-message {
  margin-top: 0.3125rem;
}

/*
// .nouislider
*/
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  height: 100%;
  width: 100%;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  height: 16px;
}

.noUi-base {
  padding-top: 5px;
}

.noUi-connects {
  background: #e0e0e0;
  border-radius: 3px;
  height: 6px;
}

.noUi-connect {
  border-radius: 2px;
  background: #F28B00;
}

.noUi-horizontal .noUi-handle {
  cursor: ew-resize;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #F28B00;
  box-shadow: 0 0 0 0 rgba(242, 139, 0, 0);
  transition: box-shadow 0.2s;
  left: auto;
  right: -8px;
}
.noUi-horizontal .noUi-handle:after {
  display: block;
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  margin: 5px;
  border-radius: 3px;
  background: #fff;
  transition: transform 0.2s;
  top: 0;
}

.noUi-horizontal .noUi-handle:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(242, 139, 0, 0.3);
}
.noUi-horizontal .noUi-handle:focus:after {
  transform: scale(0);
}

.noUi-horizontal .noUi-handle {
  top: 0px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -8px;
  right: auto;
}

/*
// .owl-prevent-click
*/
.owl-prevent-click.owl-carousel::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
}

/*
// .pagination
*/
.pagination {
  margin-bottom: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: center;
}
[dir=ltr] .pagination .page-item {
  margin-right: 6px;
}
[dir=rtl] .pagination .page-item {
  margin-left: 6px;
}
[dir=ltr] .pagination .page-item:last-child {
  margin-right: 0;
}
[dir=rtl] .pagination .page-item:last-child {
  margin-left: 0;
}

.page-link {
  width: 50px;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 18px;
  padding: 2px;
  transition: background 0.15s;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: unset;
  color: #262626;
}
.page-link:hover {
  background-color: #e5e5e5;
  color: #262626;
}
.page-link:active {
  transition-duration: 0s;
  background-color: #e0e0e0;
  color: #262626;
}
.page-link:focus {
  box-shadow: none;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  border-radius: 2px;
}

.page-item.disabled .page-link {
  background-color: unset;
  color: #b3b3b3;
}

.page-item.active .page-link {
  background-color: #F28B00;
  color: #fff;
  cursor: default;
}

.pagination__dots {
  position: relative;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 4px;
}
.pagination__dots:before, .pagination__dots:after {
  display: block;
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}
.pagination__dots:before {
  top: -5px;
}
.pagination__dots:after {
  bottom: -5px;
}

.page-item--dots {
  display: flex;
  align-items: center;
}

.page-link--with-arrow {
  display: flex;
  align-items: center;
}

.page-link__arrow {
  display: block;
  margin: 3px 0 4px;
  fill: currentColor;
  opacity: 0.8;
}
[dir=ltr] .page-link__arrow {
  transform: scaleX(1);
}
[dir=rtl] .page-link__arrow {
  transform: scaleX(-1);
}
.page-link__arrow svg {
  display: block;
  height: 18px;
  width: 10px;
}

[dir=ltr] .page-link__arrow--left {
  margin-left: -1px;
  margin-right: 1px;
}
[dir=rtl] .page-link__arrow--left {
  margin-right: -1px;
  margin-left: 1px;
}

[dir=ltr] .page-link__arrow--right {
  margin-left: 1px;
  margin-right: -1px;
}
[dir=rtl] .page-link__arrow--right {
  margin-right: 1px;
  margin-left: -1px;
}

@media (max-width: 575.98px) {
  .pagination .page-item:nth-child(5):nth-last-child(5),
  .pagination .page-item:nth-child(6):nth-last-child(4) {
    display: none;
  }
  .pagination .page-link {
    width: 40px;
    height: 40px;
  }
}
.payment-details .payment-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  word-break: break-all;
}
.payment-details .payment-table th, .payment-details .payment-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}
.payment-details .payment-table th {
  width: 200px;
  background-color: #f8f9fa;
  font-weight: 500;
  color: #495057;
}
.payment-details .payment-table td {
  color: #212529;
}
.payment-details .payment-table tr:hover {
  background-color: #f8f9fa;
}
.payment-details .payment-table tr:hover th {
  background-color: #f1f3f5;
}
.payment-details .payment-table .purpose-description-row {
  word-break: break-word;
}
.payment-details .payment-table .purpose-description-row td {
  padding: 16px;
  background-color: #f8f9fa;
  border-bottom: none;
  font-style: italic;
  color: #666;
}
.payment-details .payment-table .value-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}
.payment-details .payment-table .value-cell app-clipboard-button {
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .payment-details .payment-table {
    font-size: 10px;
  }
  .payment-details .payment-table th, .payment-details .payment-table td {
    padding: 8px;
    width: unset;
  }
}

.product-brand.product-brand__link {
  cursor: pointer;
  transition: color 0.2s;
}
.product-brand.product-brand__link:hover, .product-brand.product-brand__link:focus {
  color: #F28B00;
}
.product-brand img {
  width: 36px;
  max-height: 22px;
  object-fit: contain;
}

.product-label {
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  overflow: hidden;
  min-height: 22px;
  color: #FFF;
}

.sale-label {
  background-color: #ff9800;
}

.novelty-label {
  background-color: #4caf50;
}

.markdown-label {
  background-color: #f44336;
}

.label-info {
  display: inline-block;
  cursor: default;
  width: 100%;
  text-align: center;
  border-radius: 2px;
  padding: 5px;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
}

.page-product {
  position: absolute;
  top: 40px;
  right: 40px;
  flex-direction: row;
  justify-content: flex-start;
  opacity: 1;
  padding-bottom: 1rem;
  flex-wrap: wrap;
  z-index: 3;
}

.product-replace {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 500;
  cursor: help;
}
.product-replace.inside-box {
  cursor: default;
}
.product-replace svg {
  cursor: help;
}
.product-replace .product-replace-title span {
  color: #F28B00;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.product-replace .product-replace-title svg {
  width: 20px;
  height: 20px;
}
.product-replace em {
  color: #ff3333;
  font-style: normal;
  font-weight: 700;
}
.product-replace .product-replace-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  border: 1px solid #F28B00;
  padding: 10px;
  color: inherit;
  background-color: #f5f5f5;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}
.product-replace .inside-replace {
  position: absolute;
  background-color: #858181;
  border-radius: 4px;
  color: white;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  padding: 15px 10px;
  white-space: wrap;
  transition: opacity 0.3s;
  width: 240px;
}
.product-replace .available-replace-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  right: 0;
  bottom: 120%;
  width: 100%;
  border: 1px solid #F28B00;
  padding: 15px 10px;
  color: inherit;
  background-color: #f5f5f5;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}
.product-replace .visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  z-index: 99;
  transition-delay: 0s;
}
.product-replace:hover .product-replace-content {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  z-index: 99;
}
.product-replace .product-replace-content-header {
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .product-replace .product-replace-title svg {
    width: 7px;
    height: 9px;
  }
}

.radio {
  margin: 0.3rem;
}
.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.radio input[type=radio] + .radio-label:before {
  content: "";
  flex-shrink: 0;
  background: #fff;
  border-radius: 100%;
  border: 1px solid #e3e3e3;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  margin-right: 0.5em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type=radio]:checked + .radio-label:before {
  background-color: #F28B00;
  box-shadow: inset 0 0 0 2px #fff;
  border: 1px solid #F28B00;
}
.radio input[type=radio]:focus + .radio-label:before {
  outline: none;
  border-color: #F28B00;
}
.radio input[type=radio]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #fff;
  border-color: #bfbfbf;
  background: #bfbfbf;
}
.radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0;
}
.radio .radio-label {
  display: flex;
  align-items: center;
}
.radio .radio-label .label-content {
  display: flex;
  align-items: center;
  gap: 14px;
}
.radio .label-no-center {
  align-items: unset;
}

/*
// .rating
*/
.rating__body {
  display: flex;
}

.rating__star {
  width: 13px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d0d5d9' width='13' height='12'%3E%3Cpath d='M6.5,1.5l1.6,3L8.4,5H9h2.4l-2,1.6L8.9,7L9,7.6l0.7,3L7,9.1L6.5,8.9L6,9.1l-2.8,1.5l0.7-3L4.1,7L3.6,6.6L1.6,5 H4h0.6l0.3-0.5L6.5,1.5 M6.5,0C6.3,0,6.1,0.1,6,0.3L4,4H0.5C0,4.1-0.2,4.8,0.2,5.1L3,7.4l-1,4C1.9,11.7,2.2,12,2.5,12 c0.1,0,0.2,0,0.3-0.1L6.5,10l3.7,1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.3,0.5-0.6l-1-4l2.8-2.3c0.3-0.3,0.1-1-0.3-1.1H9L7,0.3 C6.9,0.1,6.7,0,6.5,0L6.5,0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0, 0, 0, 0.08)' width='13' height='12'%3E%3Cpath d='M7,0.3L9,4h3.5c0.4,0.1,0.6,0.8,0.3,1.1L10,7.4l1,4c0.1,0.4-0.4,0.8-0.8,0.6L6.5,10l-3.7,1.9c-0.4,0.2-0.9-0.1-0.8-0.6l1-4 L0.2,5.1C-0.2,4.8,0,4.1,0.5,4H4l2-3.7C6.2-0.1,6.8-0.1,7,0.3z'/%3E%3C/svg%3E");
}
[dir=ltr] .rating__star + .rating__star {
  margin-left: 3px;
}
[dir=rtl] .rating__star + .rating__star {
  margin-right: 3px;
}

.rating__star--active {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba(0, 0, 0, 0.08)' width='13' height='12'%3E%3Cpath d='M6.5,1.5l1.6,3L8.4,5H9h2.4l-2,1.6L8.9,7L9,7.6l0.7,3L7,9.1L6.5,8.9L6,9.1l-2.8,1.5l0.7-3L4.1,7L3.6,6.6L1.6,5 H4h0.6l0.3-0.5L6.5,1.5 M6.5,0C6.3,0,6.1,0.1,6,0.3L4,4H0.5C0,4.1-0.2,4.8,0.2,5.1L3,7.4l-1,4C1.9,11.7,2.2,12,2.5,12 c0.1,0,0.2,0,0.3-0.1L6.5,10l3.7,1.9c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.3,0.5-0.6l-1-4l2.8-2.3c0.3-0.3,0.1-1-0.3-1.1H9L7,0.3 C6.9,0.1,6.7,0,6.5,0L6.5,0z'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffd333' width='13' height='12'%3E%3Cpath d='M7,0.3L9,4h3.5c0.4,0.1,0.6,0.8,0.3,1.1L10,7.4l1,4c0.1,0.4-0.4,0.8-0.8,0.6L6.5,10l-3.7,1.9c-0.4,0.2-0.9-0.1-0.8-0.6l1-4 L0.2,5.1C-0.2,4.8,0,4.1,0.5,4H4l2-3.7C6.2-0.1,6.8-0.1,7,0.3z'/%3E%3C/svg%3E");
}

.btn-scroll-to-top {
  position: fixed;
  right: 24px;
  bottom: 10px;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  z-index: 1;
}
.btn-scroll-to-top.btn-scroll-to-top--hiding {
  opacity: 0;
  transform: translateY(100px);
}
.btn-scroll-to-top.btn-scroll-to-top--hide {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #F28B00;
  border-radius: 4px;
}

html, html * {
  scrollbar-color: #F28B00 #e0e0e0;
  scrollbar-width: thin;
}

/*
// .section-header
*/
.section-header__body {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.section-header__title {
  font-size: clamp(18px, 2vw, 40px);
  font-weight: 700;
  margin: 0;
  position: relative;
  top: 2px;
  max-width: 50%;
}
@media (max-width: 991.98px) {
  .section-header__title {
    max-width: unset;
  }
}

.section-header__spring {
  flex-grow: 1;
}

.section-header__divider {
  width: 100%;
  height: 2px;
  margin-top: 8px;
  background: #ebebeb;
}

.section-header__arrows {
  display: flex;
}

[dir=ltr] .section-header__arrow--next {
  margin-left: 10px;
}
[dir=rtl] .section-header__arrow--next {
  margin-right: 10px;
}

.section-header__links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
}

[dir=ltr] .section-header__links-item {
  padding-right: 18px;
}
[dir=rtl] .section-header__links-item {
  padding-left: 18px;
}
[dir=ltr] .section-header__links-item:last-child {
  padding-right: 0;
}
[dir=rtl] .section-header__links-item:last-child {
  padding-left: 0;
}

.section-header__links-link {
  color: #6c757d;
  position: relative;
  transition: color 0.1s;
}

.section-header__links-link:hover {
  color: inherit;
}

[dir=ltr] .section-header__links + .section-header__arrows {
  margin-left: 18px;
}
[dir=rtl] .section-header__links + .section-header__arrows {
  margin-right: 18px;
}

.section-header__groups {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

[dir=ltr] .section-header__groups-item + .section-header__groups-item {
  margin-left: -5.37131px;
}
[dir=rtl] .section-header__groups-item + .section-header__groups-item {
  margin-right: -5.37131px;
}

.section-header__groups-button {
  font-size: 14px;
  height: 23px;
  border: none;
  padding: 0 18.37131px;
  line-height: 23px;
  display: block;
  position: relative;
  z-index: 0;
  pointer-events: none;
  transition: color 0.1s;
  background: transparent;
  color: #6c757d;
}

.section-header__groups-button:focus {
  outline: none;
}

.section-header__groups-button:after {
  position: absolute;
  display: block;
  content: "";
  width: calc(100% - 8.37131px);
  height: 100%;
  top: 0;
  background: transparent;
  z-index: -1;
  pointer-events: auto;
  transition: background 0.1s;
}
[dir=ltr] .section-header__groups-button:after {
  left: 0;
  transform: skewX(-20deg);
  transform-origin: left bottom;
  border-top-left-radius: 2px;
  border-top-right-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2px;
}
[dir=rtl] .section-header__groups-button:after {
  right: 0;
  transform: skewX(20deg);
  transform-origin: right bottom;
  border-top-right-radius: 2px;
  border-top-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  border-bottom-left-radius: 2px;
}

.section-header__groups-button:hover {
  color: #242424;
}
.section-header__groups-button:hover:after {
  background: #ebebeb;
}

.section-header__groups-button:active {
  color: #242424;
}
.section-header__groups-button:active:after {
  background: #e0e0e0;
}

.section-header__groups-button--active,
.section-header__groups-button--active:hover,
.section-header__groups-button--active:active {
  color: #fff;
  cursor: default;
}
.section-header__groups-button--active:after,
.section-header__groups-button--active:hover:after,
.section-header__groups-button--active:active:after {
  background: #333;
}

[dir=ltr] .section-header__groups + .section-header__arrows {
  margin-left: -5.37131px;
}
[dir=rtl] .section-header__groups + .section-header__arrows {
  margin-right: -5.37131px;
}

@media (max-width: 767.98px) {
  .section-header__body {
    flex-wrap: wrap;
  }
  .section-header__links {
    order: 1;
    width: 100%;
    padding: 9px 0 10px;
    border-bottom: 2px solid #ebebeb;
  }
  .section-header__links-item {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .section-header__groups {
    order: 1;
    width: 100%;
    border-bottom: 2px solid #ebebeb;
    padding: 10px 0 11px;
  }
  .section-header__divider {
    width: 100%;
    height: 2px;
    background: #ebebeb;
    margin-top: 8px;
  }
}
/*
// .select2
*/
.form-control + .select2-container {
  width: 100%;
}
.form-control + .select2-container .select2-selection__arrow {
  display: none;
}
.form-control + .select2-container .select2-selection--single {
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  background-clip: border-box;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 10'%3E%3Cpath fill='rgba(0, 0, 0, 0.55)' d='M4.503,4 L0.497,4 C0.094,4 -0.142,3.492 0.089,3.122 L2.095,0.233 C2.293,-0.084 2.712,-0.084 2.911,0.233 L4.911,3.122 C5.142,3.492 4.906,4 4.503,4 ZM0.497,6 L4.503,6 C4.906,6 5.142,6.504 4.911,6.871 L2.905,9.737 C2.707,10.052 2.288,10.052 2.089,9.737 L0.089,6.871 C-0.142,6.504 0.094,6 0.497,6 Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 5px 10px;
  transition: border-color 0.15s ease-in-out, background 0.15s ease-in-out;
}
.form-control + .select2-container .select2-selection--single:focus {
  outline: none;
}
.form-control + .select2-container .select2-selection__rendered {
  box-sizing: border-box;
}

.form-control + .select2-container .select2-selection--single {
  background-color: #fff;
  border-color: #ebebeb;
}
.form-control + .select2-container .select2-selection__rendered {
  color: #262626;
}

.form-control + .select2-container--disabled {
  opacity: 0.7;
}

.form-control + .select2-container:not(.select2-container--open):not(.select2-container--disabled):hover .select2-selection--single {
  border-color: #d9d9d9;
}
.form-control + .select2-container--open .select2-selection--single {
  background-color: #fff;
  border-color: #d9d9d9;
}
.form-control + .select2-container .select2-selection--single {
  height: 36px;
}
[dir=ltr] .form-control + .select2-container .select2-selection--single {
  background-position: right 10px center;
}
[dir=rtl] .form-control + .select2-container .select2-selection--single {
  background-position: left 10px center;
}
.form-control + .select2-container .select2-selection__rendered {
  line-height: 19px;
  padding: calc(15px * .5) 10px;
  font-size: 16px;
}
[dir=ltr] .form-control + .select2-container .select2-selection__rendered {
  padding-right: 27px;
}
[dir=rtl] .form-control + .select2-container .select2-selection__rendered {
  padding-left: 27px;
}

.form-control-lg + .select2-container .select2-selection--single {
  height: 42px;
}
[dir=ltr] .form-control-lg + .select2-container .select2-selection--single {
  background-position: right 12px center;
}
[dir=rtl] .form-control-lg + .select2-container .select2-selection--single {
  background-position: left 12px center;
}
.form-control-lg + .select2-container .select2-selection__rendered {
  line-height: 21px;
  padding: calc(19px * .5) 12px;
  font-size: 18px;
}
[dir=ltr] .form-control-lg + .select2-container .select2-selection__rendered {
  padding-right: 31px;
}
[dir=rtl] .form-control-lg + .select2-container .select2-selection__rendered {
  padding-left: 31px;
}

.form-control-sm + .select2-container .select2-selection--single {
  height: 30px;
}
[dir=ltr] .form-control-sm + .select2-container .select2-selection--single {
  background-position: right 8px center;
}
[dir=rtl] .form-control-sm + .select2-container .select2-selection--single {
  background-position: left 8px center;
}
.form-control-sm + .select2-container .select2-selection__rendered {
  line-height: 17px;
  padding: calc(11px * .5) 8px;
  font-size: 14px;
}
[dir=ltr] .form-control-sm + .select2-container .select2-selection__rendered {
  padding-right: 23px;
}
[dir=rtl] .form-control-sm + .select2-container .select2-selection__rendered {
  padding-left: 23px;
}

.select2-dropdown {
  background: #fff;
  border: none;
  border-radius: 0;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1);
}

.select2-search--dropdown {
  padding: 0;
  border-bottom: 1px solid #e5e5e5;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  padding: 6px 10px;
  font-size: 15px;
}
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  outline: none;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f2f2f2;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #d9ecff;
  color: #36414d;
}

.select2-results__option {
  padding: 4px 10px;
  font-size: 15px;
}

.select2-results__options:before, .select2-results__options:after {
  display: list-item;
  content: "";
  height: 6px;
}

:root {
  --select2-single-height: 40px;
  --select2-selection-line-height: 40px;
  --select2-selection-border-radius: 0;
  --select2-selection-focus-border-color: #F28B00;
  --select2-selection-border-color: #ebebeb;
  --select2-selection-text-color: #242424;
  --select2-option-highlighted-background: #F28B00;
}

.select2-selection--single span {
  font-weight: 500;
}

/*
// .share-links
*/
.share-links {
  display: block;
}

.share-links__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

[dir=ltr] .share-links__item + .share-links__item {
  margin-left: 4px;
}
[dir=rtl] .share-links__item + .share-links__item {
  margin-right: 4px;
}

.share-links__item a {
  border-radius: 1.5px;
  display: block;
  padding: 2px 7px 1px;
}
.share-links__item a, .share-links__item a:hover {
  color: inherit;
  text-decoration: none;
}

.share-links__item--type--like a {
  background: #4267b2;
  color: #fff;
}
.share-links__item--type--like a:hover {
  color: #fff;
  background: #3b5c9f;
}

.share-links__item--type--tweet a {
  background: #1b95e0;
  color: #fff;
}
.share-links__item--type--tweet a:hover {
  color: #fff;
  background: #1886c9;
}

.share-links__item--type--pin a {
  background: #e63939;
  color: #fff;
}
.share-links__item--type--pin a:hover {
  color: #fff;
  background: #e32222;
}

.share-links__item--type--counter a {
  background: #fff;
  color: #737373;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
}
.share-links__item--type--counter a:hover {
  color: #666666;
}

.shared-article-parent {
  overflow: hidden;
}

.shared-article {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}
.shared-article .shared-article__text {
  font-family: inherit;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/*
// .shop-features
*/
.shop-features__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  list-style: none;
  padding: 14px 0;
}

.shop-features__item {
  padding: 10px 28px;
  display: flex;
  align-items: center;
}

.shop-features__item-icon {
  color: #F28B00;
}
[dir=ltr] .shop-features__item-icon {
  margin-right: 18px;
}
[dir=rtl] .shop-features__item-icon {
  margin-left: 18px;
}
.shop-features__item-icon svg {
  width: 38px;
  height: 38px;
  fill: currentColor;
  vertical-align: middle;
}

.shop-features__item-title {
  font-size: 14px;
  font-weight: 500;
}

.shop-features__item-subtitle {
  font-size: 14px;
  color: #6c757d;
  margin-top: -2px;
}

.shop-features__item {
  width: calc((100% - 0px) / 1);
}

.shop-features__divider {
  width: 1px;
}
.shop-features__divider:nth-child(2n) {
  width: 100%;
  height: 1px;
}
.shop-features__divider:nth-child(2n):last-child {
  display: none;
}

/*
// .sidebar
*/
.sidebar__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar__header {
  display: none;
}

@media (max-width: 991.98px) {
  .sidebar--offcanvas--mobile {
    visibility: hidden;
    transition: visibility 0s 0.25s;
  }
  .sidebar--offcanvas--mobile .sidebar__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(51, 51, 51, 0.8);
    opacity: 0;
    will-change: opacity;
    transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .sidebar--offcanvas--mobile .sidebar__body {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    width: 300px;
    transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    will-change: transform;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  [dir=ltr] .sidebar--offcanvas--mobile .sidebar__body {
    left: 0;
    transform: translateX(-100%);
  }
  [dir=rtl] .sidebar--offcanvas--mobile .sidebar__body {
    right: 0;
    transform: translateX(100%);
  }
  .sidebar--offcanvas--mobile .sidebar__content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sidebar--offcanvas--mobile.sidebar--open {
    visibility: visible;
    transition-delay: 0s;
  }
  .sidebar--offcanvas--mobile.sidebar--open .sidebar__backdrop {
    opacity: 1;
  }
  .sidebar--offcanvas--mobile.sidebar--open .sidebar__body {
    transform: translateX(0);
  }
  .sidebar--offcanvas--mobile .sidebar__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    flex-shrink: 0;
  }
  .sidebar--offcanvas--mobile .sidebar__title {
    flex-grow: 1;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 20px;
  }
  .sidebar--offcanvas--mobile .sidebar__close {
    width: 48px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    fill: currentColor;
    padding: 0 0 2px;
    flex-shrink: 0;
    transition: background-color 0.15s, color 0.15s;
    background-color: #fff;
    color: #ccc;
  }
  .sidebar--offcanvas--mobile .sidebar__close:hover {
    background-color: #f2f2f2;
    color: #4d4d4d;
  }
  .sidebar--offcanvas--mobile .sidebar__close:active {
    background-color: #ebebeb;
    color: #4d4d4d;
  }
  .sidebar--offcanvas--mobile .sidebar__close:focus {
    outline: none;
  }
  .sidebar--offcanvas--mobile .widget-filter-divider {
    display: none;
  }
}

.sidebar--offcanvas--always {
  visibility: hidden;
  transition: visibility 0s 0.25s;
}
.sidebar--offcanvas--always .sidebar__backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(51, 51, 51, 0.8);
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.sidebar--offcanvas--always .sidebar__body {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
  width: 300px;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
[dir=ltr] .sidebar--offcanvas--always .sidebar__body {
  left: 0;
  transform: translateX(-100%);
}
[dir=rtl] .sidebar--offcanvas--always .sidebar__body {
  right: 0;
  transform: translateX(100%);
}
.sidebar--offcanvas--always .sidebar__content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar--offcanvas--always.sidebar--open {
  visibility: visible;
  transition-delay: 0s;
}
.sidebar--offcanvas--always.sidebar--open .sidebar__backdrop {
  opacity: 1;
}
.sidebar--offcanvas--always.sidebar--open .sidebar__body {
  transform: translateX(0);
}
.sidebar--offcanvas--always .sidebar__header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  flex-shrink: 0;
}
.sidebar--offcanvas--always .sidebar__title {
  flex-grow: 1;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 20px;
}
.sidebar--offcanvas--always .sidebar__close {
  width: 48px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: currentColor;
  padding: 0 0 2px;
  flex-shrink: 0;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.sidebar--offcanvas--always .sidebar__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.sidebar--offcanvas--always .sidebar__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
.sidebar--offcanvas--always .sidebar__close:focus {
  outline: none;
}
.sidebar--offcanvas--always .widget-filter-divider {
  display: none;
}

.widget-filter-divider {
  padding-top: 20px;
  background: #F2F2F2;
}

.sign-form {
  display: flex;
  color: #242424;
}

.sign-form-logo {
  display: flex;
  align-items: center;
  background-color: #EBEDEC;
}
.sign-form-logo img {
  width: 369px;
  height: 298px;
}

.sign-form-content {
  display: flex;
  flex-direction: column;
  flex: auto;
  width: 480px;
}

.sign-form-content__list {
  display: flex;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  overflow-x: auto;
}

.sign-form-content__title {
  text-align: center;
  font-weight: 700;
}

.sign-form-content__subheader {
  font-size: 16px;
  text-align: center;
  margin-bottom: 1rem;
}

.sign-form-content__body {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ebebeb;
  padding-top: 20px;
}
.sign-form-content__body .form-group {
  margin-bottom: 0.6rem;
}

.sign-form-content__item {
  position: relative;
  flex: auto;
}
.sign-form-content__item span {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #646464;
  display: flex;
  justify-content: center;
  padding: 8px 18px;
  transition: background 0.12s, box-shadow 0.12s;
}
.sign-form-content__item span:hover {
  background: #f7f7f7;
  box-shadow: 0 -1px #e5e5e5 inset;
}
.sign-form-content__item span:active {
  background: #f2f2f2;
  box-shadow: 0 -1px #e0e0e0 inset;
}

.sign-form-content__item--active span, .sign-form-content__item--active span:hover, .sign-form-content__item--active span:active {
  background: transparent;
  color: #F28B00;
  box-shadow: 0 -1px #F28B00 inset;
}

.sign-form label {
  font-size: inherit;
}
.sign-form .sign-form-label {
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 400;
}

.sign-form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sign-form__password-container {
  position: relative;
  display: flex;
  align-items: center;
}
.sign-form__password-container .form-control {
  padding-right: 40px;
}

.sign-form__toggle-password, .reset-form__toggle-password {
  position: absolute;
  right: 12px;
  border: none;
  background: none;
  cursor: pointer;
  color: #a7a7a7;
}

.reset-form__toggle-password {
  transform: translateY(50%);
}

.sign-form__submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 13px !important;
  font-size: clamp(14px, 0.83vw, 16px) !important;
  font-weight: 600;
  border-radius: unset;
}

.sign-form__field-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(14px, 0.83vw, 16px) !important;
  font-weight: 400;
  border-radius: unset;
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  padding: calc(clamp(2.109375rem, 2.34375vw, 2.8125rem)-19px-2px * .5) 10px;
  font-size: clamp(11px, 0.68vw, 13px);
  line-height: 19px;
}

.sign-form-link a {
  color: #F28B00;
  text-decoration: underline;
}

.sign-form__divider {
  width: 100%;
  line-height: 1;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #999;
  margin: 1rem 0;
}
.sign-form__divider:before, .sign-form__divider:after {
  display: block;
  content: "";
  height: 1px;
  flex-grow: 1;
  background: #ebebeb;
}
[dir=ltr] .sign-form__divider:before {
  margin-right: 10px;
}
[dir=rtl] .sign-form__divider:before {
  margin-left: 10px;
}
[dir=ltr] .sign-form__divider:after {
  margin-left: 10px;
}
[dir=rtl] .sign-form__divider:after {
  margin-right: 10px;
}

.sign-form__service-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem auto;
  max-height: 40px;
}
.sign-form__service-buttons a {
  border: 1px solid #E3E3E3;
  border-radius: 100%;
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
// .social-links
*/
.social-links__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: -4px;
}

.social-links__item {
  margin: 4px;
}
.social-links__item a {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  width: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  border-radius: 100%;
  transition: all 0.2s;
}
.social-links__item a:hover {
  opacity: 0.8;
}
.social-links__item svg {
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  width: clamp(2.109375rem, 2.34375vw, 2.8125rem);
}

.social-links__item--facebook a {
  background: #3c5a99;
  color: #fff;
}

.social-links__item--twitter a {
  background: #00a2e8;
  color: #fff;
}

.social-links__item--youtube a {
  background: #e52e2e;
  color: #fff;
}

.social-links__item--instagram a {
  background: #815dc7;
  color: #fff;
}

.social-links__item--rss a {
  background: #ffc338;
  color: #664729;
}

.spinner-loading {
  position: relative;
  pointer-events: none;
  /* Анимация вращения */
}
.spinner-loading:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.spinner-loading:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  /* Фиксированные размеры для гарантии круглой формы */
  width: 40px;
  height: 40px;
  /* Центрирование с помощью transform */
  transform: translate(-50%, -50%);
  /* Круглая форма */
  border-radius: 50%;
  /* Стиль границы */
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: #F28B00;
  /* Поверх затемняющего слоя */
  z-index: 2;
  /* Анимация вращения */
  animation: spin 1s linear infinite;
}
.spinner-loading.small::after {
  width: 30px;
  height: 30px;
  border-width: 1px;
}
.spinner-loading.large::after {
  width: 60px;
  height: 60px;
  border-width: 4px;
}
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/*
// .status-badge
*/
.status-badge {
  display: inline-block;
  vertical-align: middle;
  cursor: default;
}

.status-badge__body {
  position: relative;
  min-height: 23px;
  border-radius: 11.5px;
  min-width: 31px;
}

.status-badge__icon {
  fill: currentColor;
  position: absolute;
  top: calc(50% - 6.5px);
  opacity: 0.85;
}
[dir=ltr] .status-badge__icon {
  left: 9px;
}
[dir=rtl] .status-badge__icon {
  right: 9px;
}
.status-badge__icon svg {
  display: block;
}

.status-badge__text {
  font-size: 13px;
  line-height: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}
[dir=ltr] .status-badge__text {
  padding-left: 12px;
  padding-right: 12px;
}
[dir=rtl] .status-badge__text {
  padding-right: 12px;
  padding-left: 12px;
}

.status-badge__tooltip {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.status-badge__tooltip:focus {
  outline: none;
}

.status-badge--has-text .status-badge__tooltip {
  display: none;
}

[dir=ltr] .status-badge--has-icon .status-badge__text {
  padding-left: 28px;
}
[dir=rtl] .status-badge--has-icon .status-badge__text {
  padding-right: 28px;
}

.status-badge--style--success .status-badge__body {
  background-color: #e2f2da;
  color: #44782a;
}

.status-badge--style--failure .status-badge__body {
  background-color: #f2dada;
  color: #993d3d;
}

.status-badge--style--warning .status-badge__body {
  background-color: #f2eeda;
  color: #786b2a;
}

.status-badge--style--unknown .status-badge__body {
  background-color: #ebebeb;
  color: #6a6a6a;
}

/*
// .tag-badge
*/
.tag-badge {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  z-index: 0;
  height: 18px;
  padding: 4px 14px 0;
}
.tag-badge:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
  transform-origin: center;
}
.tag-badge:before {
  left: 3.5px;
  right: 3.5px;
}
[dir=ltr] .tag-badge:before {
  transform: skewX(-20deg);
}
[dir=rtl] .tag-badge:before {
  transform: skewX(20deg);
}

.tag-badge--theme {
  color: #fff;
}
.tag-badge--theme:before {
  background: #F28B00;
}

.tag-badge--sale {
  color: #fff;
}
.tag-badge--sale:before {
  background: #ff3333;
}

.tag-badge--new {
  color: #fff;
}
.tag-badge--new:before {
  background: #3377ff;
}

.tag-badge--hot {
  color: #fff;
}
.tag-badge--hot:before {
  background: #614ba6;
}

/*
// .tags
*/
.tags {
  font-size: 13px;
}

.tags__list {
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  margin: -2px;
}
.tags__list a {
  display: block;
  margin: 2px;
  background: #f2f2f2;
  padding: 4px 11px;
  color: inherit;
  border-radius: 1.5px;
  transition: background 0.12s;
}
.tags__list a:hover {
  color: inherit;
  background: #e5e5e5;
}
.tags__list a:active {
  transition-duration: 0s;
  background: #d9d9d9;
}

.tags--sm .tags__list a {
  padding: 2px 8px;
}

/*
// .teammate
*/
.teammate {
  background-color: #fff;
}

.teammate__info {
  padding: 14px 16px 16px;
  text-align: center;
}

.teammate__position {
  font-size: 14px;
  color: #6c757d;
}

/*
// .timer
*/
.timer {
  display: flex;
}

.timer__dots {
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timer__dots:before, .timer__dots:after {
  display: block;
  content: "";
  width: 4px;
  height: 4px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin: 4px 0;
}

.timer__part {
  border-radius: 2px;
  width: 50px;
  line-height: 1;
  padding: 8px 0;
  text-align: center;
  background: #ffdf40;
  color: #262626;
}

.timer__part-value {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.04em;
}

.timer__part-label {
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 4px;
  color: #99752e;
}

@media (max-width: 767.98px) {
  .timer__dots {
    width: 18px;
  }
  .timer__dots:before, .timer__dots:after {
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
  }
  .timer__part {
    width: 40px;
    padding: 5px 0 6px;
  }
  .timer__part-value {
    font-size: 20px;
  }
  .timer__part-label {
    font-size: 9px;
    margin-top: 3px;
  }
}
/*
// .tooltip
*/
.tooltip {
  pointer-events: none;
  transition-duration: 0s;
  font-family: inherit;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background: #333;
  color: #fff;
  border-radius: 2px;
  position: relative;
  padding: 5px 8px;
  box-sizing: content-box;
  font-size: 14px;
  line-height: 1.25;
}

.tooltip .arrow {
  width: 8px;
  height: 8px;
}
.tooltip .arrow:before {
  width: 8px;
  height: 8px;
  border-radius: 1px;
  border: none;
  background: #333;
  transform: rotateZ(45deg) scale(0.7843137255);
}

.bs-tooltip-auto[x-placement^=top],
.bs-tooltip-top {
  padding: 4px 0;
}
.bs-tooltip-auto[x-placement^=top] .arrow,
.bs-tooltip-auto[x-placement^=top] .tooltip-inner,
.bs-tooltip-top .arrow,
.bs-tooltip-top .tooltip-inner {
  bottom: 3px;
}

.bs-tooltip-auto[x-placement^=bottom],
.bs-tooltip-bottom {
  padding: 4px 0;
}
.bs-tooltip-auto[x-placement^=bottom] .arrow,
.bs-tooltip-auto[x-placement^=bottom] .tooltip-inner,
.bs-tooltip-bottom .arrow,
.bs-tooltip-bottom .tooltip-inner {
  top: 3px;
}

.bs-tooltip-auto[x-placement^=right],
.bs-tooltip-right {
  padding: 0 4px;
}
.bs-tooltip-auto[x-placement^=right] .arrow,
.bs-tooltip-auto[x-placement^=right] .tooltip-inner,
.bs-tooltip-right .arrow,
.bs-tooltip-right .tooltip-inner {
  left: 3px;
}

.bs-tooltip-auto[x-placement^=left],
.bs-tooltip-left {
  padding: 0 4px;
}
.bs-tooltip-auto[x-placement^=left] .arrow,
.bs-tooltip-auto[x-placement^=left] .tooltip-inner,
.bs-tooltip-left .arrow,
.bs-tooltip-left .tooltip-inner {
  right: 3px;
}

/*
// .typography
*/
.typography {
  line-height: 1.625;
}
.typography a:hover {
  text-decoration: underline;
}
.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}
.typography h2, .typography h3 {
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
}
.typography h3 {
  padding-bottom: 6px;
}
.typography p {
  margin-bottom: 1.5em;
}
.typography ol, .typography ul {
  margin: 1.5em 0;
}
[dir=ltr] .typography ol, [dir=ltr] .typography ul {
  padding-left: 2.5rem;
}
[dir=rtl] .typography ol, [dir=rtl] .typography ul {
  padding-right: 2.5rem;
}
.typography li {
  margin-bottom: 0.5em;
}
.typography hr {
  position: relative;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  border: none;
  background: #d9d9d9;
  margin: 2.5em auto;
}
.typography hr:after, .typography hr:before {
  position: absolute;
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
}
.typography hr:after {
  left: -30px;
}
.typography hr:before {
  right: -30px;
}
.typography blockquote {
  margin: 2.25rem 0;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 2.25em 2.25em 2em;
}
.typography blockquote cite {
  margin-top: -0.5em;
  color: #6c757d;
  display: block;
  font-size: 15px;
  font-style: normal;
}
.typography blockquote p {
  margin-bottom: 1.25em;
}
.typography blockquote > *:first-child {
  margin-top: 0;
}
.typography blockquote > *:last-child {
  margin-bottom: 0;
}
.typography > *:first-child {
  margin-top: 0;
}
.typography > *:last-child {
  margin-bottom: 0;
}
.typography img {
  max-width: 100%;
  border-radius: 2px;
}
.typography figure {
  margin: 1.875rem 0 2.25rem;
}
.typography figcaption {
  font-size: 14px;
  text-align: center;
  color: #6c757d;
  padding-top: 0.875rem;
}
@media (max-width: 575.98px) {
  .typography {
    font-size: 15px;
    line-height: 1.75;
  }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
    margin-top: 1.25em;
    margin-bottom: 0.5em;
  }
  .typography h2 {
    font-size: 28px;
  }
  .typography h3 {
    font-size: 24px;
  }
  .typography h4 {
    font-size: 20px;
  }
  .typography h5 {
    font-size: 18px;
  }
  .typography p {
    margin-bottom: 1em;
  }
  .typography ol, .typography ul {
    margin: 1.25em 0;
  }
  [dir=ltr] .typography ol, [dir=ltr] .typography ul {
    padding-left: 2rem;
  }
  [dir=rtl] .typography ol, [dir=rtl] .typography ul {
    padding-right: 2rem;
  }
  .typography hr {
    margin: 1.5em auto;
  }
  .typography blockquote {
    padding: 1em;
    font-size: 16px;
    margin: 1.5rem 0;
  }
  .typography blockquote p {
    margin-bottom: 0.75em;
  }
  .typography figure {
    margin: 1.5rem 0 1.5rem;
  }
}

/*
// .vehicle-form
*/
.vehicle-form {
  display: flex;
  flex-wrap: wrap;
  margin: -6px;
}
.vehicle-form .vehicle-form__item {
  width: 100%;
  margin-bottom: 5px;
}
.vehicle-form .vehicle-form__item:last-child {
  width: 100%;
}

.vehicle-form__divider {
  width: 100%;
  line-height: 1;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: #999;
  margin: 12px 6px;
}
.vehicle-form__divider:before, .vehicle-form__divider:after {
  display: block;
  content: "";
  height: 1px;
  flex-grow: 1;
  background: #ebebeb;
}
[dir=ltr] .vehicle-form__divider:before {
  margin-right: 6px;
}
[dir=rtl] .vehicle-form__divider:before {
  margin-left: 6px;
}
[dir=ltr] .vehicle-form__divider:after {
  margin-left: 6px;
}
[dir=rtl] .vehicle-form__divider:after {
  margin-right: 6px;
}

.vehicle-form__item {
  position: relative;
}

.vehicle-form__item--select .vehicle-form__loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.vehicle-form__item--select .vehicle-form__loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #ebebeb;
  opacity: 0.9;
}
.vehicle-form__item--select .vehicle-form__loader:after {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.vehicle-form__item--select .vehicle-form__loader:before {
  opacity: 1;
  border-radius: 2.5px;
}

.vehicle-form__item-input {
  position: relative;
}
.vehicle-form__item-input .vehicle-form__loader {
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
[dir=ltr] .vehicle-form__item-input .vehicle-form__loader {
  left: auto;
  right: 9px;
}
[dir=rtl] .vehicle-form__item-input .vehicle-form__loader {
  right: auto;
  left: 9px;
}

.vehicle-form__item-row {
  display: flex;
  flex-wrap: wrap;
}

.vehicle-form__item-button {
  width: 33.33333%;
  background-color: transparent;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #E3E3E3;
  transition: box-shadow 0.3s ease, color 0.3s ease;
}
.vehicle-form__item-button:hover, .vehicle-form__item-button.selected {
  color: #F28B00;
  border: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
}

.vehicle-form__item-alert:before {
  display: block;
  content: "";
  height: 0.375rem;
}

.vehicle-form__item--loading .vehicle-form__loader {
  pointer-events: all;
  opacity: 1;
}

.vehicle-form--layout--account .vehicle-form__item {
  width: calc(25% - 12px);
}
@media (max-width: 767.98px) {
  .vehicle-form--layout--account .vehicle-form__item {
    width: calc(50% - 12px);
  }
}
@media (max-width: 459px) {
  .vehicle-form--layout--account .vehicle-form__item {
    width: calc(100% - 12px);
  }
}

@media (max-width: 399px) {
  .vehicle-form--layout--modal .vehicle-form__item {
    width: calc(100% - 12px);
  }
}

/*
// .vehicle-select
*/
.vehicle-featured-border {
  width: 100%;
  height: 0;
  border-top: 1px solid #F28B00;
  margin: 0;
  grid-column: 1/-1;
}

.vehicle-select--brands {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  grid-auto-rows: min-content;
  padding: 1px;
  height: 354px;
  overflow: scroll;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1199.98px) {
  .vehicle-select--brands {
    grid-template-columns: repeat(4, 1fr);
  }
}

.vehicle-select--models {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  grid-auto-rows: min-content;
  padding: 1px;
  height: 354px;
  overflow: scroll;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1199.98px) {
  .vehicle-select--models {
    grid-template-columns: repeat(4, 1fr);
  }
}

.vehicle-select--years {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  grid-auto-rows: min-content;
  padding: 1px;
  height: 354px;
  overflow: scroll;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1199.98px) {
  .vehicle-select--years {
    grid-template-columns: repeat(4, 1fr);
  }
}

.selectable-list-item {
  height: 100%;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-left: 3px solid transparent;
  font-weight: 500;
  background-color: #FFF;
  box-shadow: 0 0 0 1px #E3E3E3;
}
.selectable-list-item .selectable-list-item--center {
  text-align: center;
}
.selectable-list-item .addition {
  color: #646464;
  font-size: clamp(11px, 0.68vw, 13px);
}
.selectable-list-item.selectable-list-item--bold {
  font-weight: 700;
}
.selectable-list-item.selectable-list-item--mark {
  box-shadow: 0 0 6px #F28B00;
}
.selectable-list-item:hover {
  background-color: rgba(227, 227, 227, 0.5);
}
.selectable-list-item .values {
  display: flex;
  gap: 0.5rem;
}
.selectable-list-item .values .title {
  color: #646464;
}

.selectable-list-item--active {
  color: #F28B00;
  background-color: rgba(227, 227, 227, 0.5);
}

.vehicle-card {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.vehicle-card.vehicle-card--mark {
  box-shadow: 0 0 6px #F28B00;
}
.vehicle-card:hover {
  box-shadow: 0 0 12px #242424;
}

.vehicle-component .mdc-tab-indicator__content--underline {
  display: none;
}
.vehicle-component .mdc-tab-indicator--active {
  background-color: #F28B00;
  position: relative;
}
.vehicle-component .mdc-tab-indicator--active .mdc-tab__text-label {
  color: white !important;
}
.vehicle-component .mdc-tab-indicator--active:after {
  content: "";
  position: absolute;
  right: -20px;
  top: -1px;
  border-top: 49px solid transparent;
  border-left: 20px solid #F28B00;
}

.compact .vehicle-select--brands,
.compact .vehicle-select--models,
.compact .vehicle-select--years {
  grid-template-columns: repeat(1, 1fr);
}
.compact .selectable-list-item {
  text-align: left !important;
  position: relative;
  padding-right: 2rem;
}
.compact .selectable-list-item:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 17px;
  border: 1px solid #E3E3E3;
  border-radius: 100%;
}
.compact .selectable-list-item--active:after {
  background-color: #F28B00;
}
@media (max-width: 575.98px) {
  .compact .selectable-list-item {
    padding: 0.6rem 2rem 0.6rem 0.6rem;
  }
}

@media (max-width: 991.98px) {
  .vehicle-select--brands,
  .vehicle-select--models,
  .vehicle-select--years {
    grid-template-columns: repeat(1, 1fr);
  }
  .selectable-list-item {
    text-align: left !important;
    position: relative;
    padding-right: 2rem;
  }
  .selectable-list-item:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 17px;
    height: 17px;
    border: 1px solid #E3E3E3;
    border-radius: 100%;
  }
  .selectable-list-item--active:after {
    background-color: #F28B00;
  }
}
@media (max-width: 991.98px) and (max-width: 575.98px) {
  .selectable-list-item {
    padding: 0.6rem 2rem 0.6rem 0.6rem;
  }
}
/*
// .vehicles-list
*/
.vehicles-list__body {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.vehicles-list__item {
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  margin: 4px 0;
}
.vehicles-list__item:first-child {
  margin-top: 0;
}
.vehicles-list__item:last-child {
  margin-bottom: 0;
}

.vehicles-list__item-radio {
  margin: 8px;
}

.vehicles-list__item-info {
  margin: 2px 8px;
  flex-grow: 1;
  line-height: 1.25;
}

.vehicles-list__item-remove {
  margin: 8px;
}
.vehicles-list__item-remove svg {
  display: block;
}

.vehicles-list__item-name {
  display: block;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
}

.vehicles-list__item-details {
  display: block;
  font-size: clamp(11px, 0.68vw, 13px);
  color: #999;
  margin-top: 2px;
}

.vehicles-list__item-links {
  font-size: 14px;
  margin-top: 12px;
}
.vehicles-list__item-links a:hover {
  text-decoration: underline;
}

.vehicles-list__item-remove {
  position: relative;
  display: flex;
  padding: 8px;
  border: none;
  margin: 0;
  border-radius: 2px;
  fill: currentColor;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.vehicles-list__item-remove:hover {
  background-color: #f2f2f2;
  color: #999;
}
.vehicles-list__item-remove:active {
  background-color: #ebebeb;
  color: #808080;
}
.vehicles-list__item-remove:focus {
  outline: none;
}
.vehicles-list__item-remove.vehicles-list__item-remove--loading {
  cursor: default;
  fill: transparent;
  background: transparent;
}

.vehicles-list__item-remove--loading:before {
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.vehicles-list--layout--account .vehicles-list__body {
  margin: -6px;
}
.vehicles-list--layout--account .vehicles-list__item {
  padding: 10px 8px;
  width: calc(50% - 12px);
  margin: 6px;
}
@media (max-width: 767.98px) {
  .vehicles-list--layout--account .vehicles-list__body {
    margin: -6px;
  }
  .vehicles-list--layout--account .vehicles-list__item {
    width: calc(100% - 12px);
    margin: 6px;
  }
}

.vehicle-addition--actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0.8rem;
}

@media (max-width: 575.98px) {
  .vehicle-addition--actions {
    flex-direction: column;
    align-items: stretch;
  }
}
/*
// .view-options
*/
.view-options {
  font-size: 0.9375rem;
  overflow: hidden;
}

.view-options__body {
  display: flex;
  gap: 1rem;
  align-items: center;
  position: relative;
}
.view-options__body.is-sticky {
  z-index: 999;
  background-color: #242424;
  color: #FFF;
}
.view-options__body.is-sticky select {
  background-color: #242424;
  color: #FFF;
  border: none;
}
.view-options__body.is-sticky .view-options__filters-button {
  border-top: none;
  border-left: none;
  border-bottom: none;
}
.view-options__body.is-sticky .view-options__filters-button:hover {
  background-color: inherit;
}
.view-options__body.is-sticky svg {
  fill: #FFF;
}

.view-options__general {
  display: flex;
  align-items: center;
  flex: 1;
  border: 1px solid #E3E3E3;
}

.view-options__body--filters {
  display: block;
  padding: 0.875rem 1rem;
  margin-top: 0.5rem;
}

.view-options__label {
  position: absolute;
  background: #fff;
  padding: 0 8px;
  top: -5px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  color: #999;
}
[dir=ltr] .view-options__label {
  left: 50%;
  transform: translateX(-50%);
}
[dir=rtl] .view-options__label {
  right: 50%;
  transform: translateX(50%);
}

.view-options__filters-button {
  min-height: 40px;
  border: 1px solid #E3E3E3;
  background: none;
  transition: all 0.15s ease-in-out;
}

.view-options__legend {
  margin-left: 0.5rem;
  white-space: nowrap;
  color: #646464;
}

.view-options__spring {
  flex-grow: 1;
}

.view-options__select {
  display: flex;
  align-items: center;
}
.view-options__select > label {
  margin-bottom: 0;
  white-space: nowrap;
}
[dir=ltr] .view-options__select > label {
  margin-right: 10px;
}
[dir=rtl] .view-options__select > label {
  margin-left: 10px;
}
.view-options__select select {
  height: 40px;
}

@media (min-width: 992px) {
  .view-options--offcanvas--mobile .view-options__filters-button {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .view-options__filters-button {
    width: 50%;
  }
  .view-options__select {
    flex: 1;
  }
  .view-options__general {
    border: none;
  }
}
@media (max-width: 575.98px) {
  .view-options__body + .view-options__body {
    margin-top: 2px;
  }
  .view-options__legend {
    width: 100%;
    padding-top: 2px;
  }
  .view-options__select {
    flex-direction: column;
    align-items: flex-start;
  }
  [dir=ltr] .view-options__select {
    margin-left: 0;
  }
  [dir=rtl] .view-options__select {
    margin-right: 0;
  }
  .view-options__select > label {
    margin: 0 0 2px;
  }
}
/*
// .widget
*/
.widget + .widget {
  margin-top: 20px;
}

.widget-wrapper {
  border: 1px solid #E3E3E3;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.widget__header {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  border-bottom: 1px solid #E3E3E3;
}
.widget__header.close-body {
  background-color: #F28B00;
  color: #FFF;
  border: none;
}
.widget__header.close-body svg {
  fill: #FFF;
}
.widget__header svg {
  flex-shrink: 0;
}

.widget__body {
  overflow: hidden;
}

/* ----------------------------------------
// Filters
// ---------------------------------------- */
/*
// .filter
*/
.filter__title {
  display: block;
  border: none;
  padding: 12px 10px;
  cursor: pointer;
  width: calc(100% + 20px);
  position: relative;
  color: inherit;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  border-radius: 2px;
  margin: 0 -10px;
  transition: background-color 0.2s, fill 0.2s;
  background: transparent;
  fill: #ccc;
}
[dir=ltr] .filter__title {
  text-align: left;
}
[dir=rtl] .filter__title {
  text-align: right;
}
.filter__title:focus {
  outline: none;
}

.filter__arrow {
  position: absolute;
  top: 17px;
  transition: transform 0.2s;
}
[dir=ltr] .filter__arrow {
  right: 10px;
}
[dir=rtl] .filter__arrow {
  left: 10px;
}
.filter__arrow svg {
  display: block;
}

.filter__body {
  margin: 0 -1.5rem;
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.filter__container {
  padding: 10px 1.5rem 16px;
}

.filter__reset-btn {
  text-transform: uppercase;
  border: 1px solid #e3e3e3;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 25px;
  color: #242424;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 700;
  transition: border 0.2s;
}
.filter__reset-btn:hover {
  border-color: #F28B00;
}

.filter--opened .filter__body {
  transition-delay: 0s, 0s;
  visibility: visible;
  height: auto;
  opacity: 1;
}
.filter--opened .filter__arrow {
  transform: rotateZ(180deg);
}

/*
// .filter-category
*/
.filter-category__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 18px;
}
.filter-category__list a {
  color: inherit;
  transition: color 0.15s;
}
.filter-category__list a:hover {
  color: #F28B00;
}

.filter-category__item {
  padding: 5px 0;
  display: flex;
}
.filter-category__item > a {
  flex-grow: 1;
}
[dir=ltr] .filter-category__item > a {
  padding-right: 10px;
}
[dir=rtl] .filter-category__item > a {
  padding-left: 10px;
}

.filter-category__item--parent {
  position: relative;
}
[dir=ltr] .filter-category__item--parent {
  padding-left: 14px;
}
[dir=rtl] .filter-category__item--parent {
  padding-right: 14px;
}

.filter-category__item--current {
  font-weight: 700;
}

.filter-category__item--child {
  position: relative;
  font-size: 15px;
}
[dir=ltr] .filter-category__item--child {
  padding-left: 14px;
}
[dir=rtl] .filter-category__item--child {
  padding-right: 14px;
}

.filter-category__arrow {
  fill: #ccc;
  position: absolute;
  top: 10px;
}
[dir=ltr] .filter-category__arrow {
  left: 0;
  transform: scaleX(1);
}
[dir=rtl] .filter-category__arrow {
  right: 0;
  transform: scaleX(-1);
}
.filter-category__arrow svg {
  display: block;
}

.filter-category__counter {
  font-size: 12px;
  line-height: 12px;
  padding-top: 4px;
  font-weight: 400;
  color: #6c757d;
}

/*
// .filter-color
*/
.filter-color__list {
  padding: 2px 0;
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.filter-color__item {
  cursor: pointer;
  margin: 4px;
}

.filter-color__check {
  display: block;
}

/*
// .filter-list
*/
.filter-list__list {
  margin: -8px 0;
  max-height: 350px;
  overflow-y: auto;
}

.filter-list__item {
  padding: 1px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
  align-items: center;
}

.filter-list__input {
  flex-shrink: 0;
}
[dir=ltr] .filter-list__input {
  margin-right: 7px;
}
[dir=rtl] .filter-list__input {
  margin-left: 7px;
}

.filter-list__title {
  padding: 1px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  flex-grow: 1;
}

.filter-list__title-mark {
  font-weight: 700;
}

.filter-list__link {
  color: inherit;
  flex-grow: 1;
}

.filter-list__counter {
  margin-right: 5px;
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}
[dir=ltr] .filter-list__counter {
  margin-left: 12px;
}
[dir=rtl] .filter-list__counter {
  margin-right: 12px;
}

.filter-list__item--disabled {
  cursor: default;
}
.filter-list__item--disabled .filter-list__title {
  color: #6c757d;
}

/*
// .filter-range
*/
.filter-range__slider {
  margin-top: 6px;
}

.filter-range__title {
  padding-top: 2px;
  font-size: 14px;
}

.filter-range__title-button {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*
// .filter-range
*/
.filter-range-input__form {
  display: flex;
  gap: 8px;
  align-items: center;
}

.filter-range-input__input {
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  border: 1px solid rgb(232, 232, 233);
  transition: border 0.3s ease-in-out;
}
.filter-range-input__input:focus-visible {
  outline: none;
  border-color: #F28B00;
}

.filter-range-input__description {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.filter-range-input__description .filter-range-input__description-count {
  color: #6c757d;
  font-size: 12px;
}

/*
// .filter-rating
*/
.filter-rating__list {
  list-style: none;
  padding: 0;
  margin: -8px 0;
}

.filter-rating__item-label {
  padding: 2px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
}

.filter-rating__item-input {
  flex-shrink: 0;
}
[dir=ltr] .filter-rating__item-input {
  margin-right: 8px;
}
[dir=rtl] .filter-rating__item-input {
  margin-left: 8px;
}

.filter-rating__item-stars {
  padding: 2px 0;
  flex-grow: 1;
}

.filter-rating__item-counter {
  padding-top: 2px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}
[dir=ltr] .filter-rating__item-counter {
  margin-left: 12px;
}
[dir=rtl] .filter-rating__item-counter {
  margin-right: 12px;
}

/*
// .filter-vehicle
*/
.filter-vehicle__empty {
  font-size: clamp(0.703125rem, 0.78125vw, 0.9375rem);
  line-height: 19px;
}

.filter-vehicle__list {
  margin: -8px 0;
  padding: 0;
  list-style: none;
}

.filter-vehicle__item-label {
  padding: 1px 0;
  cursor: pointer;
  display: flex;
  margin: 8px 0;
}

.filter-vehicle__item-input {
  flex-shrink: 0;
}
[dir=ltr] .filter-vehicle__item-input {
  margin-right: 7px;
}
[dir=rtl] .filter-vehicle__item-input {
  margin-left: 7px;
}

.filter-vehicle__item-title {
  padding: 1px 0;
  font-size: 14px;
  line-height: 16px;
  flex-grow: 1;
  transition: color 0.2s;
}

.filter-vehicle__item-counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #6c757d;
}
[dir=ltr] .filter-vehicle__item-counter {
  margin-left: 12px;
}
[dir=rtl] .filter-vehicle__item-counter {
  margin-right: 12px;
}

.filter-vehicle__item--disabled .filter-vehicle__item-label {
  cursor: default;
}
.filter-vehicle__item--disabled .filter-vehicle__item-title {
  color: #999;
}

.filter-vehicle__vehicle {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 6px 9px;
  line-height: 1.375;
  margin-bottom: 6px;
}

.filter-vehicle__vehicle-title {
  font-size: 14px;
  font-weight: 500;
}

.filter-vehicle__vehicle-subtitle {
  font-size: 13px;
  color: #999;
}

.filter-vehicle__button {
  padding-top: 14px;
}

/* ----------------------------------------
// Footer
// ---------------------------------------- */
.pargo-footer {
  width: 100%;
  background-color: #242424;
  padding: 0;
}

.pargo-footer__widgets {
  padding: clamp(1.875rem, 2.0833333333vw, 2.5rem) 0;
  border-bottom: 1px solid rgba(242, 242, 242, 0.1);
}

.pargo-footer__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: clamp(0.703125rem, 0.78125vw, 0.9375rem);
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .pargo-footer__row {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.pargo-footer__logo svg {
  height: auto;
  width: clamp(7.734375rem, 8.59375vw, 10.3125rem);
}

.pargo-footer__bottom {
  width: 100%;
  padding: clamp(0.46875rem, 0.5208333333vw, 0.625rem) 0;
}

.pargo-footer__bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pargo-footer__bottom-row .pargo-footer__copyright {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 991.98px) {
  .pargo-footer__bottom-row {
    flex-direction: column;
    justify-content: center;
  }
}

.footer-login-button {
  background: transparent;
  border: 1px solid #646464;
  padding: clamp(0.46875rem, 0.5208333333vw, 0.625rem) clamp(0.9375rem, 1.0416666667vw, 1.25rem);
}
.footer-login-button span {
  color: rgba(255, 255, 255, 0.6);
  font-size: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
  font-weight: 600;
}
.footer-login-button svg {
  fill: rgba(255, 255, 255, 0.6);
  margin-right: clamp(0.703125rem, 0.78125vw, 0.9375rem);
}
.footer-login-button:hover {
  border: 1px solid #F28B00;
}
.footer-login-button:hover span {
  color: #F28B00;
}
.footer-login-button:hover svg {
  fill: #F28B00;
}

/*
// .pargo-footer-contacts
*/
.pargo-footer-contacts {
  display: block;
  text-align: center;
}
@media (min-width: 1200px) {
  .pargo-footer-contacts {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .pargo-footer-contacts {
    max-width: 30%;
  }
}

.pargo-footer-contacts__title {
  font-size: clamp(14px, 0.83vw, 16px);
  color: #fff;
  margin-bottom: 22px;
  font-weight: 600;
}
@media (max-width: 991.98px) {
  .pargo-footer-contacts__title {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.pargo-footer-contacts__item {
  display: flex;
  align-items: center;
  white-space: wrap;
}
@media (max-width: 1199.98px) {
  .pargo-footer-contacts__item {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .pargo-footer-contacts__item {
    white-space: wrap;
  }
}
.pargo-footer-contacts__item div, .pargo-footer-contacts__item span {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 34px;
}
.pargo-footer-contacts__item svg {
  fill: rgba(255, 255, 255, 0.6);
  margin-right: 15px;
}
@media (max-width: 767.98px) {
  .pargo-footer-contacts__item svg {
    margin-right: 5px;
  }
}

/*
// .pargo-footer-links
*/
.pargo-footer-links {
  display: block;
  text-align: center;
}
@media (min-width: 1200px) {
  .pargo-footer-links {
    text-align: left;
  }
}

.pargo-footer-links__title {
  display: flex;
  gap: 10px;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 600;
  color: #fff;
  margin-bottom: 22px;
  align-items: center;
  justify-content: center;
}

.pargo-footer-links__list {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  line-height: 24px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.pargo-footer-links__list a {
  color: rgba(255, 255, 255, 0.6);
  transition: 0.15s;
}
.pargo-footer-links__list a:hover {
  color: #F28B00;
}

.pargo-footer-links__button-arrow {
  display: none;
  transition: transform 0.2s, color 0.2s;
}
.pargo-footer-links__button-arrow svg {
  display: block;
  height: auto;
  width: 9px;
  fill: #fff;
}

@media (max-width: 991.98px) {
  .pargo-footer-links__list {
    display: none;
    max-height: 0;
    transition: max-height 0.2s, opacity 0.2s, visibility 0.2s, height 0.2s;
  }
  .pargo-footer-links__button-arrow {
    display: block;
  }
  .pargo-footer-links--open .pargo-footer-links__list {
    display: block;
    visibility: visible;
    opacity: 1;
    max-height: max-content;
  }
  .pargo-footer-links--open .pargo-footer-links__button-arrow {
    transform: rotate(180deg);
  }
}
.pargo-footer-links__item {
  padding: 5px 0;
}

@media (max-width: 767.98px) {
  .pargo-footer-links {
    text-align: center;
  }
  .pargo-footer-links__title {
    margin-bottom: 12px;
  }
}
/*
// .pargo-footer-social
*/
.pargo-footer-social {
  display: block;
  text-align: center;
}
@media (min-width: 1200px) {
  .pargo-footer-social {
    text-align: left;
  }
}

.pargo-footer-social__title {
  font-size: 20px;
  color: #fff;
  margin-bottom: 26px;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .pargo-footer-social__title {
    font-weight: 400;
  }
}

.pargo-footer-social__text {
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 14px;
}

.pargo-footer-social__text--social {
  margin-top: 20px;
  margin-bottom: 0;
}

.pargo-footer-social__social-links {
  margin-top: 8px;
}

.pargo-footer-social__form {
  display: flex;
  max-width: 380px;
}

.pargo-footer-social__form-input {
  flex-shrink: 1;
  border-radius: 2px;
  font-size: 15px;
  height: 38px;
  padding: 0 12px;
  flex-basis: 0;
  min-width: 0;
  flex-grow: 1;
  font-family: inherit;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-clip: padding-box;
  transition: border 0.2s, background 0.2s;
  color: #fff;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}
.pargo-footer-social__form-input::placeholder {
  color: #b2b2b2;
}
.pargo-footer-social__form-input::placeholder {
  transition: color 0.2s;
}
.pargo-footer-social__form-input:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}
.pargo-footer-social__form-input:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}
.pargo-footer-social__form-input:focus::placeholder {
  color: #9e9e9e;
}

.pargo-footer-social__form-button {
  flex-shrink: 0;
  border-radius: 2px;
  border: none;
  padding: 0 20px;
  font-family: inherit;
  font-size: 15px;
  transition: background 0.2s, color 0.2s;
  background-color: #F28B00;
  color: #fff;
  background-color: #F28B00;
  color: #fff;
}
.pargo-footer-social__form-button:hover {
  background-color: #545454;
  color: #fff;
}
.pargo-footer-social__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}
[dir=ltr] .pargo-footer-social__form-button {
  margin-left: 8px;
}
[dir=rtl] .pargo-footer-social__form-button {
  margin-right: 8px;
}
.pargo-footer-social__form-button:hover {
  background-color: #545454;
  color: #fff;
}
.pargo-footer-social__form-button:active {
  background-color: #4c4c4c;
  color: #fff;
}
.pargo-footer-social__form-button:active {
  transition-duration: 0.1s, 0.1s;
}
.pargo-footer-social__form-button:focus {
  outline: none;
}

@media (max-width: 767.98px) {
  .pargo-footer-social {
    text-align: center;
    margin-top: 28px;
  }
  .pargo-footer-social__title {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .pargo-footer-social__form {
    margin: 0 auto;
  }
  .pargo-footer-social__text--social {
    margin-top: 24px;
  }
  .pargo-footer-social__social-links {
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }
}
/* ----------------------------------------
// Pages
// ---------------------------------------- */
/*
// .about
*/
.about .block-advantages__item {
  max-width: 28%;
}
@media (max-width: 991.98px) {
  .about .block-advantages__item {
    max-width: unset;
  }
}

.about__header {
  position: relative;
}
.about__header:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.67) 52.242%, rgba(255, 255, 255, 0) 100%);
}

.about__header-content {
  position: relative;
  z-index: 2;
}

.about__body-1 {
  background-color: white;
}

.about-history--item {
  font-size: 1.5rem;
  font-weight: 700;
}

/*
// .contacts
*/
.contacts-block {
  border: 1px solid #E3E3E3;
}

.contacts__block--contact {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  transition: color 0.2s;
}
.contacts__block--contact.map-available:hover, .contacts__block--contact.map-available:focus {
  color: #F28B00;
  outline: none;
}
.contacts__block--contact svg {
  overflow: visible;
}
.contacts__block--contact a {
  color: inherit;
  transition: color 0.2s ease-in-out;
}
.contacts__block--contact a:hover {
  color: #F28B00;
}

.contacts__block--contact-name {
  cursor: pointer;
}

.contacts__block--address {
  color: inherit;
}

/*
// .faq
*/
.faq__header {
  padding: 44px 0 52px;
  text-align: center;
}

.faq__header-title {
  font-weight: 700;
  margin: 0;
}

.faq__section {
  background-color: #fff;
  padding: 48px;
}

.faq__section + .faq__section {
  margin-top: 30px;
}

.faq__section-title {
  font-size: 24px;
  font-weight: 700;
  padding-bottom: 8px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 2.5rem;
  margin-top: -4px;
}

.faq__footer {
  margin-top: 56px;
  text-align: center;
}

.faq__footer-title {
  font-size: 28px;
  margin-bottom: 8px;
}

.faq__footer-subtitle {
  margin-bottom: 36px;
  color: #6c757d;
}

.faq__section-body {
  display: flex;
  flex-wrap: wrap;
  margin: -20px;
}

.faq__question {
  margin: 20px;
  width: calc(50% - 40px);
}

.faq__question-title {
  margin-bottom: 14px;
}

@media (max-width: 1199.98px) {
  .faq__header {
    padding: 48px 0 48px;
  }
  .faq__section {
    padding: 40px;
  }
}
@media (max-width: 991.98px) {
  .faq__section-body {
    margin: -12px;
  }
  .faq__question {
    margin: 12px;
    width: calc(100% - 24px);
  }
}
@media (max-width: 767.98px) {
  .faq__header {
    padding: 36px 0 32px;
  }
  .faq__header-title {
    font-size: 32px;
  }
  .faq__section {
    padding: 32px;
  }
  .faq__section-title {
    margin-bottom: 32px;
  }
  .faq__footer {
    margin-top: 40px;
  }
  .faq__footer-title {
    font-size: 24px;
  }
  .faq__footer-subtitle {
    font-size: 15px;
    margin-bottom: 24px;
  }
}
@media (max-width: 575.98px) {
  .faq__header {
    padding: 32px 0 28px;
  }
  .faq__section {
    padding: 24px;
  }
  .faq__section-title {
    margin-bottom: 28px;
  }
  .faq__question-title {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .faq__question-answer .typography {
    font-size: 15px;
  }
  .faq__footer-subtitle {
    margin-bottom: 20px;
  }
}
/*
// .not-found
*/
.not-found {
  text-align: center;
  padding: 36px 0;
}

.not-found__404 {
  font-size: 80px;
  font-weight: 700;
  color: #bfbfbf;
  padding: 20px 0 4px;
}

.not-found__content {
  width: 480px;
  max-width: 100%;
  margin: 0 auto;
}

.not-found__title {
  margin-bottom: 24px;
  font-weight: 700;
}

.not-found__text {
  margin-bottom: 20px;
}

.not-found__search {
  display: flex;
  margin-bottom: 30px;
}

.not-found__search-input {
  width: 1px;
  flex-grow: 1;
}
[dir=ltr] .not-found__search-input {
  margin-right: 10px;
}
[dir=rtl] .not-found__search-input {
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .not-found__404 {
    font-size: 60px;
  }
}
@media (max-width: 767.98px) {
  .not-found__404 {
    font-size: 50px;
    line-height: 54px;
    padding: 40px 0 32px;
  }
  .not-found__title {
    margin-bottom: 20px;
  }
}
.category-with-banner {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 14px;
}
@media (min-width: 992px) {
  .category-with-banner {
    flex-direction: row;
    align-items: stretch;
  }
}
.category-with-banner .list-categories {
  width: 100%;
  flex: 0;
}
@media (min-width: 992px) {
  .category-with-banner .list-categories {
    gap: clamp(1.40625rem, 1.5625vw, 1.875rem);
    flex: 0 0 22%;
    min-width: 280px;
  }
}
.category-with-banner .block-slideshow {
  width: 100%;
  max-width: 100%;
  flex: 0;
}
@media (min-width: 992px) {
  .category-with-banner .block-slideshow {
    flex: 1;
    overflow: hidden;
    align-self: flex-start;
  }
}
@media (min-width: 1400px) {
  .category-with-banner .block-slideshow {
    align-self: unset;
  }
}

.icon-settings {
  width: 80px;
  height: 80px;
  color: #F28B00;
}
.icon-settings svg {
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .icon-settings {
    width: 120px;
    height: 120px;
  }
}

.header-settings {
  font-size: 24px;
}
@media (min-width: 768px) {
  .header-settings {
    font-size: 32px;
  }
}

.header-settings__subtitle {
  font-size: 14px;
}
@media (min-width: 768px) {
  .header-settings__subtitle {
    font-size: 22px;
  }
}

.form-settings {
  width: 100%;
}
@media (min-width: 768px) {
  .form-settings {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .form-settings {
    width: 40%;
  }
}

.reset-password__email {
  width: 100%;
}

.input-settings {
  min-height: 40px;
  padding: 0 15px;
  font-size: 18px;
}

.btn-reset__password {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .btn-reset__password {
    width: 70% !important;
  }
}

/* ----------------------------------------
// Blocks
// ---------------------------------------- */
.block-advantages {
  padding: 60px 0;
  display: block;
  background: #fff;
}

.block-advantages__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
@media (max-width: 991.98px) {
  .block-advantages__content {
    margin: 30px 0;
  }
}

.block-advantages__content-header {
  color: #242424;
  font-size: clamp(18px, 2vw, 40px);
  font-weight: 700;
  margin-bottom: 24px;
}

.block-advantages__content-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 992px) {
  .block-advantages__content-row {
    flex-direction: row;
  }
}

.block-advantages__item-header {
  color: #242424;
  font-size: clamp(15px, 1vw, 20px);
  font-weight: 600;
}

.block-advantages__item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  max-width: 20%;
  text-align: center;
}
.block-advantages__item .block-advantages__item-description {
  color: rgba(36, 36, 36, 0.7);
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  line-height: 160%;
}
.block-advantages__item svg {
  fill: #242424;
  height: auto;
  width: clamp(5.671875rem, 6.3020833333vw, 7.5625rem);
}
.block-advantages__item .block-advantages__horizontal-divider {
  width: 36px;
  border-top: 1px solid #E3E3E3;
}
@media (max-width: 991.98px) {
  .block-advantages__item {
    max-width: unset;
  }
}

.block-advantages__vertical-divider {
  color: #E3E3E3;
  height: 40px;
  width: 1px;
  border-right: 1px solid #E3E3E3;
  display: none;
}
@media (min-width: 992px) {
  .block-advantages__vertical-divider {
    display: block;
  }
}

/*
// .block-brands
*/
.block-brands__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ebebeb;
}

.block-brands__item {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-brands__item-link {
  position: relative;
  display: block;
  padding: 12px 28px;
  width: 100%;
}
.block-brands__item-link:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.02);
  opacity: 0;
  transition: opacity 0.15s;
}
.block-brands__item-link img {
  max-width: 100%;
  max-height: 72px;
  filter: grayscale(1);
  opacity: 0.7;
  transition: filter 0.15s, opacity 0.15s;
}

.block-brands__item-name {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #b3b3b3;
  margin-top: 4px;
  margin-bottom: -2px;
  transition: color 0.15s;
}

.block-brands__item-link:hover:before {
  opacity: 1;
}
.block-brands__item-link:hover img {
  filter: grayscale(0);
  opacity: 1;
}
.block-brands__item-link:hover .block-brands__item-name {
  color: #737373;
}

.block-brands__divider {
  background: #ebebeb;
}

@media (min-width: 1400px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 8px) / 8);
  }
  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(16n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(16n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 7px) / 7);
  }
  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-brands--layout--columns-8-full .block-brands__item {
    width: calc((100% - 6px) / 6);
  }
  .block-brands--layout--columns-8-full .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-8-full .block-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}

@media (min-width: 1400px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 7px) / 7);
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 6px) / 6);
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-brands--layout--columns-7-sidebar .block-brands__item {
    width: calc((100% - 5px) / 5);
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider {
    width: 1px;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-brands--layout--columns-7-sidebar .block-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-brands__item {
    width: calc((100% - 5px) / 5);
  }
  .block-brands__divider {
    width: 1px;
  }
  .block-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .block-brands__item {
    width: calc((100% - 4px) / 4);
  }
  .block-brands__divider {
    width: 1px;
  }
  .block-brands__divider:nth-child(8n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(8n):last-child {
    display: none;
  }
}
@media (min-width: 420px) and (max-width: 575px) {
  .block-brands__item {
    width: calc((100% - 3px) / 3);
  }
  .block-brands__divider {
    width: 1px;
  }
  .block-brands__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (max-width: 419px) {
  .block-brands__item {
    width: calc((100% - 2px) / 2);
  }
  .block-brands__divider {
    width: 1px;
  }
  .block-brands__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-brands__divider:nth-child(4n):last-child {
    display: none;
  }
}
/*
// .block-categories
*/
.block-categories__list {
  display: flex;
  flex-wrap: wrap;
}

.block-categories__item {
  display: flex;
}

.block-categories__header {
  display: flex;
  justify-content: center;
}

.block-categories__title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  position: relative;
  z-index: 1;
  margin-bottom: -24px;
  padding: 0 64px 28px;
}

.block-categories__title-decor {
  position: absolute;
  width: 100%;
  bottom: 1px;
}
[dir=ltr] .block-categories__title-decor {
  left: 0;
}
[dir=rtl] .block-categories__title-decor {
  right: 0;
}
.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end,
.block-categories__title-decor .decor__center {
  background: #FFF;
}
.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
}
.block-categories__title-decor .decor__body {
  height: 35px;
  margin: 0 -10px -10px;
}
.block-categories__title-decor .decor__start,
.block-categories__title-decor .decor__end {
  height: 25px;
  width: calc(50% - 24.43375px);
}
.block-categories__title-decor .decor__start {
  left: 10px;
  transform: skewX(30deg);
}
.block-categories__title-decor .decor__end {
  right: 10px;
  transform: skewX(-30deg);
}
.block-categories__title-decor .decor__center {
  height: 25px;
  width: 30.8675px;
}

.block-categories__body {
  position: relative;
  background-color: #f6f6f6;
  padding: 76px 0 64px;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.08);
}

.block-categories__body-decor {
  position: absolute;
  bottom: -1px;
  height: 35px;
}
.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end,
.block-categories__body-decor .decor__center {
  background: #FFF;
}
.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
}
.block-categories__body-decor .decor__start,
.block-categories__body-decor .decor__end {
  height: 25px;
}

@media (min-width: 1400px) {
  .block-categories__list {
    margin: -12px;
  }
  .block-categories__item {
    width: calc((100% - 72px) / 3);
    margin: 12px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-categories__list {
    margin: -8px;
  }
  .block-categories__item {
    width: calc((100% - 48px) / 3);
    margin: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-categories__list {
    margin: -8px;
  }
  .block-categories__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .block-categories__list {
    margin: -8px;
  }
  .block-categories__item {
    width: calc((100% - 48px) / 3);
    margin: 8px;
  }
}
@media (max-width: 767.98px) {
  .block-categories__list {
    margin: -8px;
  }
  .block-categories__item {
    width: calc((100% - 32px) / 2);
    margin: 8px;
  }
}
@media (max-width: 474px) {
  .block-categories__list {
    margin: -8px;
  }
  .block-categories__item {
    width: calc((100% - 16px) / 1);
    margin: 8px;
  }
}
/*
// .block-category-slides
*/
.block-category-slides__body {
  padding-top: 30px;
}
@media (max-width: 991.98px) {
  .block-category-slides__body {
    padding-top: 12px;
    padding-bottom: 15px;
  }
}

/*
// .block-list-categories
*/
.list-categories {
  display: block;
  position: relative;
}
@media (min-width: 992px) {
  .list-categories {
    background-color: #fff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    height: max-content;
  }
  .list-categories .list-categories__list {
    visibility: visible;
    opacity: 1;
    max-height: max-content;
  }
  .list-categories .list-categories__button-arrow {
    fill: currentColor;
  }
}

.list-categories__button {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  font-family: inherit;
  padding: clamp(0.75rem, 0.78vw, 1rem) clamp(1.5rem, 1.5vw, 1.875rem);
  transition: background-color 0.2s, color 0.2s;
}
.list-categories__button:focus {
  outline: none;
}
.list-categories__button .list-categories__button-start {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
}
.list-categories__button--mobile {
  display: none;
}

.list-categories__button-icon {
  fill: currentColor;
  transition: color 0.2s;
}
.list-categories__button-icon svg {
  display: block;
  height: auto;
  width: clamp(1.1rem, 1.3vw, 1.6rem);
}

.list-categories__button-title {
  font-size: clamp(13px, 0.9vw, 18px);
  font-weight: 700;
  letter-spacing: 2.7px;
}

.list-categories__button-arrow {
  fill: #F28B00;
}
.list-categories__button-arrow svg {
  display: block;
  height: auto;
  width: clamp(1.1rem, 1.3vw, 1.6rem);
}

.list-categories__container {
  display: flex;
}

.list-categories__body {
  border-radius: 1.5px;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  color: #242424;
  min-width: 100%;
}

.list-categories__list {
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: clamp(13px, 0.83vw, 16px);
  line-height: 20px;
  width: 100%;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: max-height 0.2s, opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
  font-weight: 500;
}

.list-categories--open {
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  height: max-content;
}
.list-categories--open .list-categories__list {
  visibility: visible;
  opacity: 1;
  max-height: max-content;
}
.list-categories--open .list-categories__button-arrow {
  fill: currentColor;
}

.list-categories__item-link {
  color: inherit;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 700;
  gap: 15px;
  overflow: hidden;
  border-bottom: 1px solid #E3E3E3;
  min-height: 62px;
  padding-left: 10px;
  white-space: nowrap;
}
.list-categories__item-link img {
  height: clamp(2.4rem, 2.7vw, 3.25rem);
  max-width: clamp(2.4rem, 2.7vw, 3.25rem);
  object-fit: contain;
}

.list-categories__item-arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 6px);
}
.list-categories__item-arrow svg {
  display: block;
}

.list-categories__item-menu {
  display: none;
}

.list-categories__item--hover {
  background-color: hsla(34, 100%, 47%, 0.2);
  color: #f28b00;
  transition: 0.25s;
}
.list-categories__item--hover .list-categories__item-link {
  color: inherit;
  border-bottom: 1px solid #F28B00;
}

.list-categories__item--arrow_subcategory {
  position: absolute;
  right: -13px;
  z-index: 11;
  height: 100%;
  width: 12px;
  top: 0;
}
.list-categories__item--arrow_subcategory .list-categories__item--arrow_subcategory--content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.list-categories__subcategory-container {
  z-index: 10;
}

.list-categories__subcategory {
  min-height: 100%;
  display: none;
  margin-left: 13px;
  position: absolute;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.list-categories__subcategory.list-categories {
  min-width: fit-content;
}

.list-categories__subcategory--open {
  display: block;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.list-categories__separator {
  margin: unset;
}

.list-categories__button {
  background-color: #242424;
  color: #FFF;
}

@media (min-width: 1400px) {
  .list-categories__list-padding {
    height: clamp(0.375rem, 0.4vw, 0.5rem);
  }
  .list-categories__item-link {
    padding-top: clamp(0.375rem, 0.4vw, 0.5rem);
    padding-bottom: clamp(0.375rem, 0.4vw, 0.5rem);
  }
  [dir=ltr] .list-categories__item-link {
    padding-left: clamp(1rem, 1vw, 1.25rem);
    padding-right: clamp(1rem, 1vw, 1.25rem);
  }
  [dir=rtl] .list-categories__item-link {
    padding-right: clamp(1rem, 1vw, 1.25rem);
    padding-left: clamp(1rem, 1vw, 1.25rem);
  }
  [dir=ltr] .list-categories__item-arrow {
    right: clamp(1rem, 1vw, 1.25rem);
    transform: scaleX(1);
  }
  [dir=rtl] .list-categories__item-arrow {
    left: clamp(1rem, 1vw, 1.25rem);
    transform: scaleX(-1);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .list-categories__list-padding {
    height: clamp(0.375rem, 0.4vw, 0.5rem);
  }
  .list-categories__item-link {
    padding-top: clamp(0.375rem, 0.4vw, 0.5rem);
    padding-bottom: clamp(0.375rem, 0.4vw, 0.5rem);
  }
  [dir=ltr] .list-categories__item-link {
    padding-left: clamp(0.75rem, 0.78vw, 1rem);
    padding-right: clamp(0.75rem, 0.78vw, 1rem);
  }
  [dir=rtl] .list-categories__item-link {
    padding-right: clamp(0.75rem, 0.78vw, 1rem);
    padding-left: clamp(0.75rem, 0.78vw, 1rem);
  }
  [dir=ltr] .list-categories__item-arrow {
    right: clamp(0.75rem, 0.78vw, 1rem);
    transform: scaleX(1);
  }
  [dir=rtl] .list-categories__item-arrow {
    left: clamp(0.75rem, 0.78vw, 1rem);
    transform: scaleX(-1);
  }
}
@media (max-width: 991.98px) {
  .list-categories__container,
  .list-categories__subcategory-container,
  .list-categories__item--arrow_subcategory {
    display: none;
  }
  .list-categories__button {
    justify-content: center;
  }
  .list-categories__button--mobile {
    display: flex;
  }
}
/*
// .block-empty
*/
.block-empty__body {
  text-align: center;
}

.block-empty__title {
  margin-top: 12px;
  font-size: 36px;
  font-weight: 700;
}

.block-empty__message {
  margin-top: 16px;
}

.block-empty__action {
  margin-top: 32px;
}

/*
// .block-header
*/
.block-header__body {
  display: flex;
  flex-direction: column;
}

.block-header__breadcrumb {
  padding-top: 16px;
  padding-bottom: 40px;
}

.block-header__title {
  padding-top: 36px;
  padding-bottom: 20px;
}
.block-header__title h1 {
  font-size: 2.25rem;
  font-weight: 700;
  margin: 0;
  word-wrap: break-word;
}
@media (max-width: 991.98px) {
  .block-header__title h1 {
    font-size: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .block-header__breadcrumb + .block-header__title {
    margin-top: -77px;
  }
}
@media (max-width: 1199.98px) {
  .block-header__title {
    padding-bottom: 6px;
    font-size: 2rem;
    padding-top: 32px;
  }
  .block-header__breadcrumb + .block-header__title {
    margin-top: -44px;
  }
}
.block-home-h1 {
  padding: 0 0 30px 0;
  background-color: #fff;
}
.block-home-h1 h1 {
  font-size: 1.6rem;
}

.block-information__content {
  width: 100%;
  display: flex;
  max-width: 1920px;
  margin: auto;
}
.block-information__content img {
  overflow: hidden;
  align-self: stretch;
  flex: 0;
  object-fit: cover;
  flex-grow: 1.5;
}

.block-information__container {
  flex: 1;
  display: flex;
  padding: 80px 10px;
  justify-content: center;
  flex-grow: 3;
}
@media (max-width: 991.98px) {
  .block-information__container {
    padding: 10px;
  }
}

.block-information__text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #242424;
  gap: 20px;
  max-width: 730px;
}

.block-information__text-header {
  font-size: clamp(18px, 2vw, 40px);
  font-weight: 700;
}

.block-information__text-par {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
}

@media (max-width: 991.98px) {
  .block-information__content {
    flex-direction: column;
  }
  .block-information__content img {
    max-height: 100vw;
  }
}
/*
// .block-parent-categories
*/
.block-parent-categories__body {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 991.98px) {
  .block-parent-categories__body {
    padding: 30px 0;
  }
}

.block-parent-categories__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.block-parent-categories__item {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}

.block-parent-categories__item-link {
  position: relative;
  display: block;
  padding: 7px;
  width: 100%;
  border: 1px solid #E3E3E3;
  background-color: #fff;
  min-height: 120px;
}
.block-parent-categories__item-link img {
  max-width: 100%;
  height: clamp(3.140625rem, 10.15625vw, 12.1875rem);
  transition: filter 0.15s, opacity 0.15s;
  object-fit: contain;
}

.block-parent-categories__item-name {
  display: block;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #242424;
  margin-top: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
  transition: color 0.15s;
}
@media (max-width: 767.98px) {
  .block-parent-categories__item-name {
    font-size: 10px;
    word-break: break-word;
  }
}

.block-parent-categories__item-link:hover {
  border: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.block-parent-categories__item-link:hover:before {
  opacity: 1;
}
.block-parent-categories__item-link:hover img {
  opacity: 1;
}
.block-parent-categories__item-link:hover .block-parent-categories__item-name {
  color: #F28B00;
}

.block-parent-categories__divider {
  background: #E3E3E3;
}

@media (min-width: 1400px) {
  .block-parent-categories--layout--columns-5-full .block-parent-categories__item {
    width: calc((100% - 5px) / 5);
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider:nth-child(10n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-parent-categories--layout--columns-5-full .block-parent-categories__item {
    width: calc((100% - 4px) / 4);
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider:nth-child(8n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider:nth-child(8n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-parent-categories--layout--columns-5-full .block-parent-categories__item {
    width: calc((100% - 5px) / 5);
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories--layout--columns-5-full .block-parent-categories__divider:nth-child(10n):last-child {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-parent-categories__item {
    width: calc((100% - 3px) / 3);
  }
  .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .block-parent-categories__item {
    width: calc((100% - 3px) / 3);
  }
  .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (min-width: 420px) and (max-width: 575px) {
  .block-parent-categories__item {
    width: calc((100% - 2px) / 2);
  }
  .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories__divider:nth-child(4n):last-child {
    display: none;
  }
}
@media (max-width: 419px) {
  .block-parent-categories__item {
    width: calc((100% - 2px) / 2);
  }
  .block-parent-categories__divider {
    width: 1px;
  }
  .block-parent-categories__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-parent-categories__divider:nth-child(4n):last-child {
    display: none;
  }
}
/*
// .block-popular-brands
*/
.block-popular-brands__body {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (max-width: 991.98px) {
  .block-popular-brands__body {
    padding: 30px 0;
  }
}

.block-popular-brands__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.block-popular-brands__item {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
}

.block-popular-brands__item-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: clamp(0.375rem, 0.4166666667vw, 0.5rem);
  width: 100%;
  height: 100%;
  border: 1px solid #E3E3E3;
  background-color: #fff;
}
.block-popular-brands__item-link img {
  max-width: 100%;
  height: clamp(7.03125rem, 7.8125vw, 9.375rem);
  object-fit: contain;
  transition: filter 0.15s, opacity 0.15s;
}

.block-popular-brands__item-link:hover {
  border: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.block-popular-brands__item-link:hover:before {
  opacity: 1;
}
.block-popular-brands__item-link:hover img {
  opacity: 1;
}

@media (min-width: 1400px) {
  .block-popular-brands--layout--columns-7-full .block-popular-brands__item {
    width: calc((100% - 7px) / 7);
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider:nth-child(14n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-popular-brands--layout--columns-7-full .block-popular-brands__item {
    width: calc((100% - 6px) / 6);
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider:nth-child(12n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-popular-brands--layout--columns-7-full .block-popular-brands__item {
    width: calc((100% - 5px) / 5);
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider:nth-child(10n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands--layout--columns-7-full .block-popular-brands__divider:nth-child(10n):last-child {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-popular-brands__item {
    width: calc((100% - 3px) / 3);
  }
  .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .block-popular-brands__item {
    width: calc((100% - 3px) / 3);
  }
  .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (min-width: 420px) and (max-width: 575px) {
  .block-popular-brands__item {
    width: calc((100% - 2px) / 2);
  }
  .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands__divider:nth-child(4n):last-child {
    display: none;
  }
}
@media (max-width: 419px) {
  .block-popular-brands__item {
    width: calc((100% - 2px) / 2);
  }
  .block-popular-brands__divider {
    width: 1px;
  }
  .block-popular-brands__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-popular-brands__divider:nth-child(4n):last-child {
    display: none;
  }
}
/*
// .block-products-carousel
*/
.block-products-carousel {
  padding: 1.5rem 0;
  display: block;
  background: #fff;
}

.block-products-carousel__carousel {
  position: relative;
}
.block-products-carousel__carousel .owl-stage-outer {
  margin: -14px;
  padding: 14px;
}
.block-products-carousel__carousel .owl-stage {
  display: flex;
}
.block-products-carousel__carousel .owl-item {
  flex-shrink: 0;
  display: flex;
}
.block-products-carousel__carousel .owl-item:hover {
  z-index: 3;
}
.block-products-carousel__carousel .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.block-products-carousel__carousel .owl-prev {
  float: left;
  margin-left: 8px;
  transform: rotate(180deg);
}
.block-products-carousel__carousel .owl-next {
  float: right;
  margin-right: 8px;
}
.block-products-carousel__carousel .owl-prev,
.block-products-carousel__carousel .owl-next {
  height: 94px;
  width: 64px;
  border-radius: 5px;
  background-color: rgba(169, 169, 169, 0.3);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 33' fill='%23242424' width='18' height='33'%3E%3Cpath d='M0 2.69L2.67 0L17.25 14.69C17.49 14.93 17.67 15.21 17.8 15.52C17.93 15.83 18 16.16 18 16.49C18 16.83 17.93 17.16 17.8 17.46C17.67 17.77 17.49 18.05 17.25 18.29L2.67 33L0 30.3L13.68 16.5L0 2.69Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 18px 33px;
  background-position: center;
  pointer-events: auto;
}
.block-products-carousel__carousel .owl-prev.disabled,
.block-products-carousel__carousel .owl-next.disabled {
  display: none;
}
.block-products-carousel__carousel .nav-disabled .owl-nav {
  display: none;
}

.block-products-carousel__carousel-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0s 0.3s;
}
.block-products-carousel__carousel-loader:before {
  position: absolute;
  display: block;
  content: "";
  left: -14px;
  top: -14px;
  width: calc(100% + 28px);
  height: calc(100% + 28px);
  background: #FFF;
  opacity: 0.9;
}
.block-products-carousel__carousel-loader:after {
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.block-products-carousel__carousel--loading:not(.block-products-carousel__carousel--has-items) {
  min-height: 120px;
}
.block-products-carousel__carousel--loading:not(.block-products-carousel__carousel--has-items) .owl-nav {
  display: none;
}
.block-products-carousel__carousel--loading .block-products-carousel__carousel-loader {
  visibility: visible;
  transition-delay: 0s;
  opacity: 1;
}

.block-products-carousel__column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.block-products-carousel__cell {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-grow: 1;
}
.block-products-carousel__cell .product-card {
  width: 100%;
}

.block-products-carousel[data-layout=grid-4] .block-products-carousel__cell + .block-products-carousel__cell,
.block-products-carousel[data-layout=grid-5] .block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 20px;
}

.block-products-carousel[data-layout=grid-6] .block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 16px;
}

.block-products-carousel[data-layout|=horizontal] .block-products-carousel__cell + .block-products-carousel__cell {
  margin-top: 14px;
}

@media (max-width: 991.98px) {
  .block-products-carousel__carousel .owl-prev,
  .block-products-carousel__carousel .owl-next {
    height: 43px;
    width: 28px;
    border-radius: 5px;
    background-size: 8px auto;
  }
}
/*
// .block-products-columns
*/
.block-products-columns__title {
  border-bottom: 2px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}

.block-products-columns__list-item + .block-products-columns__list-item {
  margin-top: 16px;
}
.block-products-columns__list-item .product-card {
  position: relative;
  display: flex;
}
.block-products-columns__list-item .product-card .product-card__action--wishlist,
.block-products-columns__list-item .product-card .product-card__action--compare,
.block-products-columns__list-item .product-card .product-card__features,
.block-products-columns__list-item .product-card .product-card__addtocart-icon,
.block-products-columns__list-item .product-card .product-card__addtocart-full,
.block-products-columns__list-item .product-card .product-card__wishlist,
.block-products-columns__list-item .product-card .product-card__compare,
.block-products-columns__list-item .product-card .product-card__meta,
.block-products-columns__list-item .product-card .product-card__fit {
  display: none;
}
.block-products-columns__list-item .product-card .product-card__image {
  padding: 8px 10px;
  width: 112px;
  flex-shrink: 0;
}
.block-products-columns__list-item .product-card .product-card__badges {
  position: absolute;
  top: 10px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__badges {
  left: 10px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__badges {
  right: 10px;
}
.block-products-columns__list-item .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.block-products-columns__list-item .product-card .tag-badge {
  height: 16px;
  padding: 3px 11px 0;
}
.block-products-columns__list-item .product-card .tag-badge:before {
  left: 3px;
  right: 3px;
}
[dir=ltr] .block-products-columns__list-item .product-card .tag-badge:before {
  transform: skewX(-20deg);
}
[dir=rtl] .block-products-columns__list-item .product-card .tag-badge:before {
  transform: skewX(20deg);
}
.block-products-columns__list-item .product-card .product-card__actions-list {
  position: absolute;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__actions-list {
  left: 0;
}
.block-products-columns__list-item .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.block-products-columns__list-item .product-card .product-card__rating-stars,
.block-products-columns__list-item .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.block-products-columns__list-item .product-card .product-card__rating-label {
  line-height: 1;
}
.block-products-columns__list-item .product-card .product-card__prices {
  position: absolute;
  bottom: 14px;
  flex-grow: 1;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
[dir=ltr] .block-products-columns__list-item .product-card .product-card__prices {
  left: 112px;
}
[dir=rtl] .block-products-columns__list-item .product-card .product-card__prices {
  right: 112px;
}

@media (min-width: 1400px) {
  .block-products-columns__list-item .product-card .product-card__image {
    padding: 8px 16px;
    width: 136px;
  }
  .block-products-columns__list-item .product-card .product-card__info {
    padding-top: 14px;
    padding-bottom: 42px;
  }
  .block-products-columns__list-item .product-card .product-card__prices {
    bottom: 16px;
  }
  [dir=ltr] .block-products-columns__list-item .product-card .product-card__prices {
    left: 136px;
  }
  [dir=rtl] .block-products-columns__list-item .product-card .product-card__prices {
    right: 136px;
  }
}
@media (max-width: 1199.98px) {
  .block-products-columns {
    display: none;
  }
}
/*
// .block-slideshow
*/
.block-slideshow {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}

.block-slideshow__item {
  border-radius: 2.5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
}
.block-slideshow__item, .block-slideshow__item:hover {
  color: #242424;
}

.block-slideshow__item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-slideshow__item-link {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2.5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: clamp(3.9375rem, 4.375vw, 5.25rem) clamp(4.6875rem, 5.2083333333vw, 6.25rem);
  z-index: 1;
}
.block-slideshow__item-link, .block-slideshow__item-link:hover {
  color: #242424;
}

.block-slideshow__item-image--mobile {
  display: none;
  background-position: top center;
}

.block-slideshow__item-offer {
  background: #ffdf40;
  color: #262626;
  align-self: flex-start;
  font-size: clamp(1.6875rem, 1.875vw, 2.25rem);
  line-height: 40px;
  font-weight: 700;
  padding: 2px 8px 0;
  margin-bottom: 12px;
  opacity: 0;
  transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;
}
[dir=ltr] .block-slideshow__item-offer {
  transform: translateX(-24px);
}
[dir=rtl] .block-slideshow__item-offer {
  transform: translateX(24px);
}
.owl-item.active .block-slideshow__item-offer {
  transform: translateX(0);
  opacity: 1;
}

.block-slideshow__item-title {
  font-size: clamp(2.25rem, 2.5vw, 3rem);
  line-height: 56px;
  font-weight: 700;
  display: block;
  opacity: 0;
  transition: opacity 0.8s 0.2s;
}
.owl-item.active .block-slideshow__item-title {
  opacity: 1;
}

.block-slideshow__item-details {
  color: #6c757d;
  font-size: clamp(0.84375rem, 0.9375vw, 1.125rem);
  line-height: 30px;
  display: block;
  margin-top: 16px;
  flex-grow: 1;
  transform: translateY(12px);
  opacity: 0;
  transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;
}
.owl-item.active .block-slideshow__item-details {
  transform: translateX(0);
  opacity: 1;
}

.block-slideshow__item-button {
  margin-top: 24px;
  align-self: flex-start;
  font-size: 18px;
  line-height: 28px;
  padding: 10px 40px;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s, opacity 0.5s 0.5s;
  background: #F28B00;
  color: #fff;
  opacity: 0;
}
.owl-item.active .block-slideshow__item-button {
  opacity: 1;
}
.block-slideshow__item-button:hover {
  background: #333;
  color: #fff;
}
.block-slideshow__item-button:active {
  background: #4d4d4d;
  color: #fff;
}

.block-slideshow__carousel {
  height: 100%;
  position: relative;
}
.block-slideshow__carousel .owl-carousel {
  height: 100%;
}
.block-slideshow__carousel .owl-stage-outer {
  height: 100%;
}
.block-slideshow__carousel owl-stage > div {
  display: block;
  height: 100%;
}
.block-slideshow__carousel .owl-stage {
  height: 100%;
  display: flex;
}
.block-slideshow__carousel .owl-item {
  height: 100%;
  display: flex;
  align-items: stretch;
  margin-left: 1px;
}
.block-slideshow__carousel .owl-dots {
  position: absolute;
  bottom: 26px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.block-slideshow__carousel .owl-dot.active span {
  background: #F28B00;
  width: 30px;
}
.block-slideshow__carousel .owl-dot span {
  width: 10px;
  height: 5px;
  margin: 5px 7px;
  background: #646464;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 0.2s ease;
  border-radius: 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.block-slideshow__carousel .owl-nav {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.block-slideshow__carousel .owl-nav .owl-next, .block-slideshow__carousel .owl-nav .owl-prev {
  height: 94px;
  width: 64px;
  border-radius: 3px;
  background-color: rgba(36, 36, 36, 0.3);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 33' fill='%23F28B00' width='18' height='33'%3E%3Cpath d='M0 2.69L2.67 0L17.25 14.69C17.49 14.93 17.67 15.21 17.8 15.52C17.93 15.83 18 16.16 18 16.49C18 16.83 17.93 17.16 17.8 17.46C17.67 17.77 17.49 18.05 17.25 18.29L2.67 33L0 30.3L13.68 16.5L0 2.69Z'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 18px 33px;
  background-position: center;
}
.block-slideshow__carousel .owl-nav .owl-prev {
  float: left;
  margin-left: 10px;
  transform: rotate(180deg);
}
.block-slideshow__carousel .owl-nav .owl-next {
  float: right;
  margin-right: 10px;
}

@media (max-width: 1399.98px) {
  .block-slideshow__item {
    padding: 68px 80px;
    height: 460px;
  }
  .block-slideshow__item-details {
    margin-top: 12px;
  }
}
@media (max-width: 1199.98px) {
  .block-slideshow__item {
    padding: 60px;
    height: 380px;
  }
  .block-slideshow__item-offer {
    font-size: clamp(1.125rem, 1.25vw, 1.5rem);
    line-height: 28px;
    padding: 1px 6px 1px;
    margin-bottom: 8px;
  }
  .block-slideshow__item-title {
    font-size: 36px;
    line-height: 44px;
  }
  .block-slideshow__item-details {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
  }
  .block-slideshow__item-button {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 28px;
  }
  .block-slideshow__carousel .owl-dots {
    bottom: 22px;
  }
}
@media (max-width: 991.98px) {
  .block-slideshow__item {
    padding: 36px 40px 40px;
    height: 320px;
  }
  .block-slideshow__item-offer {
    margin-bottom: 10px;
  }
  .block-slideshow__item-title {
    font-size: 32px;
    line-height: 38px;
  }
  .block-slideshow__item-button {
    font-size: 15px;
    line-height: 24px;
    padding: 6px 24px;
  }
  .block-slideshow__carousel .owl-dots {
    bottom: 10px;
  }
  .block-slideshow__carousel .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 2px;
  }
  .block-slideshow__carousel .owl-dots .owl-dot.active span {
    width: 16px;
  }
}
@media (max-width: 767.98px) {
  .block-slideshow__item {
    align-items: center;
    text-align: center;
    height: 480px;
  }
  .block-slideshow__item-image--desktop {
    display: none;
  }
  .block-slideshow__item-image--mobile {
    display: block;
  }
  .block-slideshow__item-offer {
    align-self: center;
  }
  .block-slideshow__item-details {
    display: none;
  }
  .block-slideshow__item-button {
    align-self: center;
  }
  .block-slideshow__item--dark {
    justify-content: center;
  }
  .block-slideshow__item {
    padding: 28px 32px 32px;
    height: 360px;
  }
  .block-slideshow__item-offer {
    font-size: 20px;
    line-height: 24px;
    padding: 1px 6px 1px;
    margin-bottom: 8px;
  }
  .block-slideshow__item-title {
    font-size: 24px;
    line-height: 30px;
  }
  .block-slideshow__item-button {
    font-size: 14px;
    line-height: 21px;
    padding: 5.5px 20px;
    margin-top: 20px;
  }
  .block-slideshow__carousel .owl-prev,
  .block-slideshow__carousel .owl-next {
    display: none;
  }
}
/*
// .block-space
*/
.block-space {
  display: block;
}

@media (min-width: 1200px) {
  .block-space--layout--after-header {
    height: 84px;
  }
  .block-space--layout--after-header.block-space--header--classic {
    height: 72px;
  }
}
@media (max-width: 1199.98px) {
  .block-space--layout--after-header {
    height: 56px;
  }
}
@media (max-width: 767.98px) {
  .block-space--layout--after-header {
    height: 48px;
  }
}
@media (max-width: 575.98px) {
  .block-space--layout--after-header {
    height: 36px;
  }
}

.block-space--layout--before-footer {
  height: 80px;
}
@media (max-width: 1199.98px) {
  .block-space--layout--before-footer {
    height: 72px;
  }
}
@media (max-width: 575.98px) {
  .block-space--layout--before-footer {
    height: 48px;
  }
}

.block-space--layout--divider-xl {
  height: 72px;
}

.block-space--layout--divider-lg {
  height: 60px;
}

.block-space--layout--divider-nl {
  height: 52px;
}
@media (max-width: 991.98px) {
  .block-space--layout--divider-nl {
    height: 30px;
  }
}

.block-space--layout--divider-sm {
  height: 40px;
}

.block-space--layout--divider-xs {
  height: 36px;
}

.block-space--layout--divider-1rem {
  height: 1rem;
}

.block-space--layout--spaceship-ledge-height {
  height: 24px;
}
@media (max-width: 1199.98px) {
  .block-space--layout--spaceship-ledge-height {
    display: none;
  }
}

/*
// .block-split
*/
@media (min-width: 1400px) {
  .block-split--has-sidebar .block-split__item-sidebar {
    width: 380px;
  }
  .block-split--has-sidebar .block-split__item-content {
    width: calc(100% - 400px);
  }
  [dir=ltr] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-left: 20px;
  }
  [dir=rtl] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-right: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1399.98px) {
  .block-split--has-sidebar .block-split__item-sidebar {
    width: 320px;
  }
  .block-split--has-sidebar .block-split__item-content {
    width: calc(100% - 330px);
  }
  [dir=ltr] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-left: 10px;
  }
  [dir=rtl] .block-split--has-sidebar .block-split__item + .block-split__item {
    margin-right: 10px;
  }
}
@media (max-width: 991.98px) {
  .block-split__item-sidebar {
    width: 100%;
    order: 1;
    margin-top: 52px;
  }
  .block-split__item-content {
    width: 100%;
  }
}
/*
// .block-vehicle-make
*/
.block-vehicle-make {
  display: block;
  padding: 1.5rem 0;
  background: #fff;
}

.block-vehicle-make__body {
  background-color: #F2F2F2;
  padding-top: 64px;
  padding-bottom: 64px;
}

.block-vehicle-make__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.block-vehicle-make__item {
  text-align: center;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-vehicle-make__item-link {
  position: relative;
  display: block;
  padding: clamp(0.375rem, 0.4166666667vw, 0.5rem);
  width: 100%;
  border: 1px solid #E3E3E3;
  background-color: #fff;
}
.block-vehicle-make__item-link img {
  max-width: 100%;
  height: clamp(4.03125rem, 4.4791666667vw, 5.375rem);
  object-fit: contain;
  filter: grayscale(1);
  transition: filter 0.15s, opacity 0.15s;
}

.block-vehicle-make__item-name {
  display: block;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #242424;
  margin-top: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
  margin-bottom: clamp(0.84375rem, 0.9375vw, 1.125rem);
  transition: color 0.15s;
}

.block-vehicle-make__item-link:hover {
  border: none;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.block-vehicle-make__item-link:hover:before {
  opacity: 1;
}
.block-vehicle-make__item-link:hover img {
  filter: grayscale(0);
  opacity: 1;
}
.block-vehicle-make__item-link:hover .block-vehicle-make__item-name {
  color: #F28B00;
}

.block-vehicle-make__divider {
  background: #E3E3E3;
}

@media (min-width: 1400px) {
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__item {
    width: calc((100% - 8px) / 8);
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider:nth-child(16n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider:nth-child(16n):last-child {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__item {
    width: calc((100% - 7px) / 7);
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider:nth-child(14n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider:nth-child(14n):last-child {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__item {
    width: calc((100% - 6px) / 6);
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider:nth-child(12n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make--layout--columns-8-full .block-vehicle-make__divider:nth-child(12n):last-child {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .block-vehicle-make__item {
    width: calc((100% - 3px) / 3);
  }
  .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .block-vehicle-make__item {
    width: calc((100% - 3px) / 3);
  }
  .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make__divider:nth-child(6n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make__divider:nth-child(6n):last-child {
    display: none;
  }
}
@media (min-width: 420px) and (max-width: 575px) {
  .block-vehicle-make__item {
    width: calc((100% - 2px) / 2);
  }
  .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make__divider:nth-child(4n):last-child {
    display: none;
  }
}
@media (max-width: 419px) {
  .block-vehicle-make__item {
    width: calc((100% - 2px) / 2);
  }
  .block-vehicle-make__divider {
    width: 1px;
  }
  .block-vehicle-make__divider:nth-child(4n) {
    height: 1px;
    width: 100%;
  }
  .block-vehicle-make__divider:nth-child(4n):last-child {
    display: none;
  }
}
.car-picker--letters span {
  font-weight: 700;
  cursor: pointer;
}
.car-picker--letters span.active {
  color: #F28B00;
  cursor: default;
}

.car-picker--grid {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(auto-fill, minmax(auto, 1fr));
}
@media (max-width: 1199.98px) {
  .car-picker--grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 767.98px) {
  .car-picker--grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 575.98px) {
  .car-picker--grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.car-picker--item {
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  border: 1px solid #E3E3E3;
  font-weight: 500;
}
.car-picker--item span {
  margin-left: 10px;
  color: #646464;
  transform: scale(0.8);
}
.car-picker--item:hover {
  box-shadow: 0 0 12px #242424;
  background-color: #F7F9FE;
  border-color: #F28B00;
}
.car-picker--item.car-picker--item--bold {
  font-weight: 700;
}

.car-picker-border {
  width: 100%;
  height: 0;
  border-top: 1px solid #F28B00;
  margin: 0 0 20px;
  grid-column: 1/-1;
}

/* ----------------------------------------
// Widgets
// ---------------------------------------- */
/*
// .widget-categories-list
*/
.widget-categories-list__body {
  padding: 1.5rem;
}
.widget-categories-list__body a {
  color: inherit;
  transition: color 0.1s;
}
.widget-categories-list__body a:hover {
  color: #F28B00;
}

.widget-categories-list__root {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-categories-list__root-item {
  line-height: 20px;
}

.widget-categories-list__root-item + .widget-categories-list__root-item {
  margin-top: 0.375rem;
}

.widget-categories-list__root-link {
  font-weight: 500;
  font-size: 17px;
}

.widget-categories-list__root-item--has-children + .widget-categories-list__root-item {
  margin-top: 1.375rem;
}

.widget-categories-list__child {
  list-style: none;
  padding: 0;
  font-size: 15px;
  margin: 10px 0 0;
}

.widget-categories-list__child + .widget-categories-list__child {
  margin-top: 0;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.widget-categories-list__child-item {
  padding: 3px 0;
}

.widget-categories-list__show-more {
  position: relative;
  display: inline;
  border: none;
  background: transparent;
  padding: 0;
  color: #999;
  font-size: 14px;
  font-family: inherit;
  margin-top: 4px;
}
[dir=ltr] .widget-categories-list__show-more {
  padding-left: 16px;
}
[dir=rtl] .widget-categories-list__show-more {
  padding-right: 16px;
}
.widget-categories-list__show-more:hover {
  text-decoration: underline;
}
.widget-categories-list__show-more:focus {
  outline: none;
}

.widget-categories-list__show-more-arrow {
  fill: currentColor;
  position: absolute;
  top: 6px;
  transition: transform 0.2s;
  opacity: 0.78;
}
[dir=ltr] .widget-categories-list__show-more-arrow {
  left: 0;
}
[dir=rtl] .widget-categories-list__show-more-arrow {
  right: 0;
}
.widget-categories-list__show-more-arrow svg {
  display: block;
}

.widget-categories-list__show-more-collapse-text {
  display: none;
}

.widget-categories-list--open .widget-categories-list__child + .widget-categories-list__child {
  height: auto;
  opacity: 1;
}
.widget-categories-list--open .widget-categories-list__show-more-expand-text {
  display: none;
}
.widget-categories-list--open .widget-categories-list__show-more-collapse-text {
  display: inline;
}
.widget-categories-list--open .widget-categories-list__show-more-arrow {
  transform: rotate(180deg);
}

/*
// .widget-categories
*/
.widget-categories {
  display: block;
}

.widget-categories__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget-categories__item {
  position: relative;
}

.widget-categories__link {
  display: block;
  color: inherit;
  padding: 5px 0;
  transition: color 0.12s;
}
[dir=ltr] .widget-categories__link {
  margin-right: 34px;
}
[dir=rtl] .widget-categories__link {
  margin-left: 34px;
}
.widget-categories__link:hover {
  color: #F28B00;
}

.widget-categories__expander {
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  transition: color 0.15s, background 0.15s;
  background-color: #fff;
  color: #ccc;
}
.widget-categories__expander:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.widget-categories__expander:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .widget-categories__expander {
  right: 0;
}
[dir=rtl] .widget-categories__expander {
  left: 0;
}
.widget-categories__expander:focus {
  outline: none;
}
.widget-categories__expander:before, .widget-categories__expander:after {
  display: block;
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background: currentColor;
  top: 12px;
}
[dir=ltr] .widget-categories__expander:before, [dir=ltr] .widget-categories__expander:after {
  left: 8px;
}
[dir=rtl] .widget-categories__expander:before, [dir=rtl] .widget-categories__expander:after {
  right: 8px;
}
.widget-categories__expander:after {
  transition: transform 0.2s ease-in-out;
  transform: rotateZ(90deg);
}
.widget-categories__expander:active {
  transition-duration: 0s;
}

.widget-categories__container {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.widget-categories__container:before, .widget-categories__container:after {
  display: block;
  content: "";
}
.widget-categories__container:before {
  height: 4px;
}
.widget-categories__container:after {
  height: 12px;
}

.widget-categories__list--root {
  padding: 0 1.5rem 1.5rem;
  font-size: 15px;
  line-height: 20px;
}
[dir=ltr] .widget-categories__list--root > .widget-categories__item {
  padding-left: 16px;
}
[dir=rtl] .widget-categories__list--root > .widget-categories__item {
  padding-right: 16px;
}
.widget-categories__list--root > .widget-categories__item:before {
  position: absolute;
  display: block;
  content: "";
  width: 4px;
  height: 4px;
  background: currentColor;
  opacity: 0.2;
  top: 13px;
}
[dir=ltr] .widget-categories__list--root > .widget-categories__item:before {
  left: 2px;
}
[dir=rtl] .widget-categories__list--root > .widget-categories__item:before {
  right: 2px;
}

.widget-categories__list--child {
  background: #f5f5f5;
  border-radius: 3px;
  font-size: 14px;
  line-height: 18px;
  padding: 9px 18px;
}
.widget-categories__list--child .widget-categories__link {
  padding: 5px 0 3px;
}

.widget-categories__item--open .widget-categories__container {
  opacity: 1;
  height: auto;
}
.widget-categories__item--open .widget-categories__expander:after {
  transform: none;
}

/*
// .widget-comments
*/
.widget-comments__body {
  padding: 0 1.5rem 1.5rem;
}
.widget-comments__body a {
  color: inherit;
  transition: color 0.15s;
}
.widget-comments__body a:hover {
  color: #F28B00;
}

.widget-comments__list {
  list-style: none;
  margin: 0;
  padding: 0 0 2px;
}

.widget-comments__item + .widget-comments__item {
  margin-top: 20px;
}

.widget-comments__author {
  font-size: 15px;
  line-height: 18px;
}
.widget-comments__author a {
  border-bottom: 2px solid #ebebeb;
  transition: border 0.15s;
}
.widget-comments__author a:hover {
  border-color: rgba(242, 139, 0, 0.8);
}

.widget-comments__content {
  margin-top: 12px;
  font-size: 15px;
  line-height: 20px;
}

.widget-comments__meta {
  margin-top: 4px;
  font-size: 13px;
  color: #999;
  display: flex;
  white-space: nowrap;
}

.widget-comments__date {
  flex-shrink: 0;
}

.widget-comments__name {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
[dir=ltr] .widget-comments__name {
  padding-left: 20px;
}
[dir=rtl] .widget-comments__name {
  padding-right: 20px;
}
.widget-comments__name:before {
  position: absolute;
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 2px;
  top: 8px;
  opacity: 0.8;
}
[dir=ltr] .widget-comments__name:before {
  left: 8px;
}
[dir=rtl] .widget-comments__name:before {
  right: 8px;
}

/*
// .widget-filters
*/
.widget-filters {
  display: block;
}

.widget-filters__vehicle-picker {
  display: block;
}

.widget-filters__item {
  padding: 4px 1.5rem;
  border-bottom: 1px solid #ebebeb;
}
.widget-filters__item:first-child {
  border-top: 1px solid #ebebeb;
}

.widget-filters__actions {
  padding: 20px 1.5rem 1.5rem;
}
[dir=ltr] .widget-filters__actions > * + * {
  margin-left: 8px;
}
[dir=rtl] .widget-filters__actions > * + * {
  margin-right: 8px;
}

.widget-filters--offcanvas--none {
  background-color: #fff;
}

@media (min-width: 992px) {
  .widget-filters--offcanvas--mobile {
    background-color: #fff;
  }
}
@media (max-width: 991.98px) {
  .widget-filters--offcanvas--mobile .widget-filters__header {
    display: none;
  }
  .widget-filters--offcanvas--mobile .widget-filters__vehicle-picker {
    display: none;
  }
  .widget-filters--offcanvas--mobile .widget-filters__item {
    padding: 4px 20px;
  }
  .widget-filters--offcanvas--mobile .widget-filters__item:first-child {
    border-top: none;
  }
}

.widget-filters--offcanvas--always .widget-filters__header {
  display: none;
}
.widget-filters--offcanvas--always .widget-filters__vehicle-picker {
  display: none;
}
.widget-filters--offcanvas--always .widget-filters__item {
  padding: 4px 20px;
}
.widget-filters--offcanvas--always .widget-filters__item:first-child {
  border-top: none;
}

.widget-filters__value {
  color: #FFF;
  border-radius: 50%;
  background: #F28B00;
  margin-left: 5px;
  font-size: 13px;
  display: inline-flex;
  min-width: 22px;
  min-height: 22px;
  align-items: center;
  justify-content: center;
}

/*
// .widget-newsletter
*/
.widget-newsletter {
  background-color: #fff;
  display: block;
  background: #333;
  border-radius: 2px;
  padding: 2.25rem 2.5rem 2.5rem;
  color: #fff;
  text-align: center;
}

.widget-newsletter__title {
  font-size: 24px;
  letter-spacing: 0.02em;
  position: relative;
  margin-bottom: 1.375rem;
  padding-bottom: 1.25rem;
}
.widget-newsletter__title:after {
  position: absolute;
  display: block;
  content: "";
  height: 1px;
  width: 56px;
  background: #4d4d4d;
  bottom: 0;
}
[dir=ltr] .widget-newsletter__title:after {
  left: calc(50% - 28px);
}
[dir=rtl] .widget-newsletter__title:after {
  right: calc(50% - 28px);
}
.widget-newsletter__title h4 {
  margin-bottom: 0;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .widget-newsletter__title h4 {
    font-weight: 400;
  }
}

.widget-newsletter__text {
  font-size: 15px;
  color: #9e9e9e;
  line-height: 24px;
  margin-bottom: 1.625rem;
}

.widget-newsletter__email {
  display: block;
  width: 100%;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  height: 38px;
  padding: 0 12px;
  font-size: 15px;
  font-family: inherit;
  background-clip: padding-box;
  transition: border 0.2s, background 0.2s;
  color: #fff;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}
.widget-newsletter__email::placeholder {
  color: #b2b2b2;
}
.widget-newsletter__email::placeholder {
  transition: color 0.2s;
}
.widget-newsletter__email:hover {
  background-color: rgba(255, 255, 255, 0.16);
  border-color: rgba(255, 255, 255, 0.16);
}
.widget-newsletter__email:focus {
  outline: none;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.16);
}
.widget-newsletter__email:focus::placeholder {
  color: #9e9e9e;
}

.widget-newsletter__button {
  border: none;
  font-size: 15px;
  border-radius: 2px;
  padding: 0.5rem 1.3125rem;
  margin-top: 1.25rem;
  width: 100%;
  transition: background 0.2s, color 0.2s;
  background-color: #F28B00;
  color: #fff;
}
.widget-newsletter__button:hover {
  background-color: #545454;
  color: #fff;
}
.widget-newsletter__button:active {
  background-color: #4c4c4c;
  color: #fff;
}
.widget-newsletter__button:active {
  transition-duration: 0.1s, 0.1s;
}
.widget-newsletter__button:focus {
  outline: none;
}

/*
// .widget-posts
*/
.widget-posts__list {
  list-style: none;
  padding: 0 1.5rem 1.5rem;
  margin: 0;
}

.widget-posts__item {
  display: flex;
}
.widget-posts__item + .widget-posts__item {
  margin-top: 1rem;
}

.widget-posts__image {
  flex-shrink: 0;
  position: relative;
}
[dir=ltr] .widget-posts__image {
  margin-right: 14px;
}
[dir=rtl] .widget-posts__image {
  margin-left: 14px;
}
.widget-posts__image img {
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 1.5px;
  object-fit: cover;
}
.widget-posts__image .widget-posts__no-image {
  object-fit: contain;
}
.widget-posts__image:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  bottom: 0;
  background: rgba(26, 26, 26, 0.2);
  border-radius: 1.5px;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}
[dir=ltr] .widget-posts__image:before {
  left: 0;
  right: 0;
}
[dir=rtl] .widget-posts__image:before {
  right: 0;
  left: 0;
}
.widget-posts__image:hover:before {
  opacity: 1;
}

.widget-posts__info {
  padding-top: 5px;
}

.widget-posts__name {
  font-size: 15px;
  line-height: 19px;
  overflow: hidden;
  max-height: 38px;
}
.widget-posts__name a {
  color: inherit;
  transition: color 0.12s;
}
.widget-posts__name a:hover {
  color: #F28B00;
}

.widget-posts__date {
  font-size: 13px;
  color: #999;
  margin-top: 3px;
  position: relative;
}
[dir=ltr] .widget-posts__date {
  padding-left: 22px;
}
[dir=rtl] .widget-posts__date {
  padding-right: 22px;
}
.widget-posts__date:before {
  position: absolute;
  display: block;
  content: "";
  height: 1px;
  width: 16px;
  background: currentColor;
  opacity: 0.6;
  top: 10px;
}
[dir=ltr] .widget-posts__date:before {
  left: 0;
}
[dir=rtl] .widget-posts__date:before {
  right: 0;
}

/*
// .widget-products
*/
.widget-products > .btn {
  text-align: end;
  color: #F28B00;
  box-shadow: unset;
}

.widget-products__list {
  margin-bottom: 1.5rem;
  margin-right: 2px;
  max-height: 38vh;
  overflow-y: auto;
}

.widget-products__list--open {
  max-height: unset;
  overflow: visible;
}

.widget-products__loader {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  height: 50px;
  z-index: 10;
  pointer-events: auto;
  transition: opacity 0.2s ease-in-out;
}
.widget-products__loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #ebebeb;
  opacity: 0.9;
}
.widget-products__loader:after {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.widget-products__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0.5rem;
  border: 1px solid #E3E3E3;
  background-color: #F7F9FE;
  transition: border-color 0.5s ease-in-out;
}
.widget-products__item mat-icon {
  transition: all 0.8s ease-in-out;
}
.widget-products__item + .widget-products__item {
  margin-top: 12px;
}
.widget-products__item:hover {
  border-color: #F28B00;
}
.widget-products__item:hover .widget-products__button {
  max-height: 100px;
  opacity: 1;
}
.widget-products__item:hover mat-icon {
  transform: scale(1.5) rotate(360deg);
}
.widget-products__item .product-replace {
  justify-content: flex-end;
}

.widget-products__head {
  display: flex;
  align-items: center;
}

.widget-products__image {
  position: relative;
  border-radius: 0;
  overflow: hidden;
  flex-shrink: 0;
  width: 65px;
  height: 65px;
}
[dir=ltr] .widget-products__image {
  margin-right: 12px;
}
[dir=rtl] .widget-products__image {
  margin-left: 12px;
}
.widget-products__image:before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: inherit;
  pointer-events: none;
  left: 0;
  top: 0;
}
.widget-products__image img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.widget-products__info {
  padding-top: 2px;
}

.widget-products__name {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  height: 34px;
  overflow: hidden;
}
.widget-products__name a {
  color: inherit;
  transition: color 0.12s;
}
.widget-products__name a:hover {
  color: #F28B00;
}

.widget-products__prices {
  display: flex;
  padding-top: 2px;
}

.widget-products__price {
  font-size: 1rem;
  font-weight: 600;
  color: #242424;
}
[dir=ltr] .widget-products__price + .widget-products__price {
  margin-left: 3px;
}
[dir=rtl] .widget-products__price + .widget-products__price {
  margin-right: 3px;
}

.widget-products__price--new {
  color: #F84147;
}

.widget-products__price--old {
  font-weight: 400;
  font-size: 13px;
  text-decoration: line-through;
  color: #999;
  padding-top: 1px;
}

.widget-products__other {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}
.widget-products__other .widget-products__other-title {
  font-weight: 400;
  opacity: 0.7;
}
.widget-products__other .widget-products__other-value {
  text-align: right;
}
.widget-products__other .widget-products__other-value.contrast {
  color: #F28B00;
}

.widget-products__button {
  opacity: 0;
  max-height: 0;
  transition: 0.5s ease-in-out;
}
.widget-products__button .buy-button .buy-button__btn,
.widget-products__button .wait-button .wait-button__btn {
  width: 100%;
}

/* ----------------------------------------
// Shop
// ---------------------------------------- */
.aggregate-category {
  display: block;
  padding: 10px;
  border: 1px solid #E3E3E3;
  border-radius: 2px;
  text-transform: uppercase;
}

/*
// .analogs-table
*/
.analogs-table {
  display: block;
}

.analogs-table__rating {
  display: inline-block;
  vertical-align: middle;
}

.analogs-table__rating-stars {
  display: flex;
  justify-content: center;
}

.analogs-table__rating-label {
  color: #6c757d;
  font-size: clamp(11px, 0.68vw, 13px);
  line-height: 1;
}

.analogs-table__sku,
.analogs-table__country {
  color: #6c757d;
  font-size: clamp(11px, 0.68vw, 13px);
  line-height: 1;
  margin-top: 1px;
  margin-bottom: 4px;
}

.analogs-table__product-name {
  color: inherit;
  transition: color 0.12s;
}
.analogs-table__product-name:hover {
  color: #F28B00;
}

@media (min-width: 768px) {
  .analogs-table table {
    width: 100%;
    font-size: 15px;
  }
  .analogs-table th,
  .analogs-table td {
    padding: 0;
  }
  [dir=ltr] .analogs-table th:first-child,
  [dir=ltr] .analogs-table td:first-child {
    padding-left: 14px;
  }
  [dir=rtl] .analogs-table th:first-child,
  [dir=rtl] .analogs-table td:first-child {
    padding-right: 14px;
  }
  [dir=ltr] .analogs-table th:last-child,
  [dir=ltr] .analogs-table td:last-child {
    padding-right: 14px;
  }
  [dir=rtl] .analogs-table th:last-child,
  [dir=rtl] .analogs-table td:last-child {
    padding-left: 14px;
  }
  [dir=ltr] .analogs-table th:not(:first-child),
  [dir=ltr] .analogs-table td:not(:first-child) {
    padding-left: 16px;
  }
  [dir=rtl] .analogs-table th:not(:first-child),
  [dir=rtl] .analogs-table td:not(:first-child) {
    padding-right: 16px;
  }
  .analogs-table thead {
    font-size: clamp(11px, 0.68vw, 13px);
    text-transform: uppercase;
  }
  .analogs-table thead th,
  .analogs-table thead td {
    padding-bottom: 6px;
  }
  .analogs-table tbody th,
  .analogs-table tbody td {
    border-top: 1px solid #ebebeb;
    padding: 10px 0;
  }
  [dir=ltr] .analogs-table tbody th:first-child,
  [dir=ltr] .analogs-table tbody td:first-child {
    border-left: 1px solid #ebebeb;
  }
  [dir=rtl] .analogs-table tbody th:first-child,
  [dir=rtl] .analogs-table tbody td:first-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=ltr] .analogs-table tbody th:last-child,
  [dir=ltr] .analogs-table tbody td:last-child {
    border-right: 1px solid #ebebeb;
  }
  [dir=rtl] .analogs-table tbody th:last-child,
  [dir=rtl] .analogs-table tbody td:last-child {
    border-left: 1px solid #ebebeb;
  }
  .analogs-table tbody tr:last-child th,
  .analogs-table tbody tr:last-child td {
    border-bottom: 1px solid #ebebeb;
  }
  .analogs-table tbody tr:hover th,
  .analogs-table tbody tr:hover td {
    background: #f7f7f7;
  }
  .analogs-table__column--rating {
    text-align: center;
  }
  .analogs-table__column--brand {
    text-align: center;
  }
  [dir=ltr] .analogs-table__column--price {
    text-align: right;
  }
  [dir=rtl] .analogs-table__column--price {
    text-align: left;
  }
  .analogs-table__rating-label {
    margin-top: 6px;
  }
}
@media (max-width: 767.98px) {
  .analogs-table table {
    display: block;
    font-size: 15px;
  }
  .analogs-table thead {
    display: none;
  }
  .analogs-table tbody {
    display: block;
  }
  .analogs-table tr,
  .analogs-table td {
    display: block;
  }
  .analogs-table tr {
    padding: 14px 16px;
    background: #f5f5f5;
    border-radius: 2px;
  }
  .analogs-table tr + tr {
    margin-top: 12px;
  }
  .analogs-table td {
    padding: 0;
  }
  .analogs-table .analogs-table__column--rating {
    margin-top: 11px;
  }
  .analogs-table .analogs-table__column--brand {
    font-size: clamp(11px, 0.68vw, 13px);
    padding-top: 8px;
    margin-top: 12px;
    border-top: 1px solid #e5e5e5;
  }
  .analogs-table .analogs-table__column--brand:before {
    content: attr(data-title) ": ";
  }
  .analogs-table .analogs-table__column--price {
    font-weight: 500;
    margin-top: 16px;
  }
  .analogs-table .analogs-table__sku {
    margin: 2px 0 0;
  }
  .analogs-table .analogs-table__sku:before {
    content: attr(data-title) ": ";
  }
  .analogs-table .analogs-table__rating {
    display: flex;
  }
  [dir=ltr] .analogs-table .analogs-table__rating-label {
    margin-left: 8px;
  }
  [dir=rtl] .analogs-table .analogs-table__rating-label {
    margin-right: 8px;
  }
  .analogs-table .analogs-table__country {
    display: inline;
  }
}
/*
// .applied-filters
*/
.applied-filters__list {
  list-style: none;
  padding: 0;
  margin: -2px;
  display: flex;
  flex-wrap: wrap;
}

.applied-filters__item {
  margin: 2px;
}

.applied-filters__button {
  padding: 4px 11px;
  display: block;
  height: 21px;
  font-size: clamp(11px, 0.68vw, 13px);
  line-height: 1;
  color: inherit;
  background-color: #f0f0f0;
  transition: background 0.12s, color 0.12s;
  border-radius: 11.5px;
  position: relative;
  border: none;
}
.applied-filters__button:focus {
  outline: none;
}
.applied-filters__button:hover {
  background-color: #e5e5e5;
  color: inherit;
}

[dir=ltr] .applied-filters__button--filter {
  padding-right: 33px;
}
[dir=rtl] .applied-filters__button--filter {
  padding-left: 33px;
}
.applied-filters__button--filter:after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  top: 4px;
  bottom: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}
[dir=ltr] .applied-filters__button--filter:after {
  right: 25px;
}
[dir=rtl] .applied-filters__button--filter:after {
  left: 25px;
}
.applied-filters__button--filter svg {
  position: absolute;
  top: 6px;
  fill: rgba(0, 0, 0, 0.4);
}
[dir=ltr] .applied-filters__button--filter svg {
  right: 10px;
}
[dir=rtl] .applied-filters__button--filter svg {
  left: 10px;
}

.applied-filters__button--clear {
  background: transparent;
  color: #999;
}

.bom .products-list[data-layout=list] .product-card {
  border: 1px solid #E3E3E3;
}
.bom .products-list[data-layout=list] .product-card .product-card__image {
  width: 120px;
}
.bom .products-list[data-layout=list] .product-card .product-card__vd {
  display: none;
}
.bom .products-list[data-layout=list] .product-card .product-card-properties {
  display: none;
}
.bom .products-list[data-layout=list] .product-card .buy-button {
  width: min-content;
}
.bom .products-list[data-layout=list] .product-card .product-card__footer {
  width: min-content;
}
.bom .mat-expansion-panel {
  border-radius: 0 !important;
}
.bom .mat-expansion-panel-body {
  padding: 0 8px 8px;
}
.bom .mat-mdc-chip-selected {
  background-color: #F28B00 !important;
}
.bom .bom-loader {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  height: 50px;
  z-index: 10;
  pointer-events: auto;
  transition: opacity 0.2s ease-in-out;
}
.bom .bom-loader:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #ebebeb;
  opacity: 0.9;
}
.bom .bom-loader:after {
  left: calc(50% - 10px);
  top: calc(50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.bom .bom-loader--content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.bom .bom-loader--content:before {
  position: absolute;
  display: block;
  content: "";
  left: 0px;
  top: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: #ebebeb;
  opacity: 0.9;
}
.bom .bom-loader--content:after {
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.bom .bom-root-header {
  font-weight: 600;
}
.bom .bom-left {
  max-height: 68vh;
  overflow: auto;
  direction: rtl;
  padding-left: 8px;
  padding-right: 8px;
}
.bom .bom-left > * {
  direction: ltr;
}
.bom h2 {
  font-weight: 700;
  color: #646464;
  text-align: center;
  opacity: 0.6;
}
.bom h4 {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
}

/*
// .cart
*/
.modal {
  z-index: 200002;
}

@media (min-width: 768px) {
  .modal-dialog__cart {
    max-width: 80%;
  }
}
@media (min-width: 1200px) {
  .modal-dialog__cart {
    max-width: 1100px;
  }
}

.cart-header {
  font-size: 24px;
}

.modal-header.sticky-top {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding: 0;
  border-top: 1px solid #dee2e6;
}

.modal-scrollable-content {
  max-height: 60vh;
  overflow-y: auto;
}

.cart__table, .cart-items {
  flex-grow: 1;
}

.cart__totals {
  flex-shrink: 0;
}

.cart__totals-table {
  width: 100%;
  margin-bottom: 32px;
  font-size: 15px;
}
.cart__totals-table a:hover {
  text-decoration: underline;
}
.cart__totals-table,
.cart__totals-table thead,
.cart__totals-table tbody,
.cart__totals-table tfoot,
.cart__totals-table tr,
.cart__totals-table th,
.cart__totals-table td {
  display: block;
}
.cart__totals-table tr:after {
  display: block;
  content: "";
  clear: both;
}
.cart__totals-table th,
.cart__totals-table td {
  padding: 0;
}
[dir=ltr] .cart__totals-table th {
  float: left;
}
[dir=rtl] .cart__totals-table th {
  float: right;
}
[dir=ltr] .cart__totals-table td {
  text-align: right;
}
[dir=rtl] .cart__totals-table td {
  text-align: left;
}
.cart__totals-table thead,
.cart__totals-table tbody {
  line-height: 20px;
}
.cart__totals-table tbody tr > * {
  padding-top: 8px;
}
.cart__totals-table tbody tr:last-child > * {
  padding-bottom: 10px;
}
.cart__totals-table tfoot {
  font-size: 20px;
}
.cart__totals-table tfoot th {
  font-weight: 500;
}
.cart__totals-table tfoot td, .cart__totals-table tfoot th {
  padding-top: 12px;
  border-top: 1px solid #ebebeb;
}
.cart__totals-table tfoot td {
  letter-spacing: -0.02em;
}

.footer-total {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  margin-right: 20px;
  align-items: center;
  padding: 6px 0;
}

@media (max-width: 1399.98px) {
  .cart {
    flex-wrap: wrap;
  }
  .cart__table, .cart-items {
    width: 100%;
  }
}
.cart-continue__shopping {
  padding: 20px 0;
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .cart-continue__shopping {
    padding: 0 50px;
    text-align: left;
  }
}

.cart-items {
  display: block;
  margin-bottom: 30px;
}
.cart-items .cart-table {
  padding: 20px 50px 10px 50px;
}
.cart-items .total {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.cart-items .total span {
  font-weight: 700;
}
.cart-items .cart-table__foot-total {
  padding: 10px;
  text-align: right;
}

/*
// .cart-table
*/
.cart-table__table {
  width: 100%;
  border-spacing: 0;
}

.cart-table__head {
  font-size: clamp(11px, 0.68vw, 13px);
  text-transform: uppercase;
}

.cart-table__column--product {
  line-height: 1.25;
}

.cart-table__column--price {
  width: 130px;
}
[dir=ltr] .cart-table__column--price {
  text-align: right;
}
[dir=rtl] .cart-table__column--price {
  text-align: left;
}

.cart-table__column--quantity {
  width: 150px;
  text-align: center;
}
[dir=ltr] .cart-table__column--quantity {
  padding-left: 6px;
}
[dir=rtl] .cart-table__column--quantity {
  padding-right: 6px;
}

.cart-table__column--total {
  width: 130px;
  min-width: 120px;
  font-weight: 700;
}
[dir=ltr] .cart-table__column--total {
  text-align: right;
}
[dir=rtl] .cart-table__column--total {
  text-align: left;
}

.cart-table__column--remove {
  width: 1px;
  white-space: nowrap;
}
[dir=ltr] .cart-table__column--remove {
  padding-left: 0;
}
[dir=rtl] .cart-table__column--remove {
  padding-right: 0;
}

.cart-table__product-name {
  font-weight: 700;
  color: inherit;
  transition: color 0.15s;
}
.cart-table__product-name:hover {
  color: #F28B00;
}

.cart-table__options {
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 1.375;
  color: #6c757d;
}

@media (min-width: 992px) {
  .cart-table {
    align-self: flex-start;
    background-color: #fff;
  }
  .cart-table__foot td {
    padding: 20px 24px;
  }
  .cart-table__column {
    padding: 14px 8px;
    border-bottom: 1px solid #ebebeb;
  }
  [dir=ltr] .cart-table__column:last-child {
    padding-right: 28px;
  }
  [dir=rtl] .cart-table__column:last-child {
    padding-left: 28px;
  }
  .cart-table__column--image {
    width: 1px;
    text-align: center;
  }
  .cart-table__column--image .image {
    width: 44px;
  }
  .cart-table__options li {
    position: relative;
  }
  [dir=ltr] .cart-table__remove {
    margin-right: -12px;
  }
  [dir=rtl] .cart-table__remove {
    margin-left: -12px;
  }
  .cart-table__actions {
    display: flex;
    justify-content: space-between;
  }
  .cart-table__coupon-form {
    width: 360px;
  }
}
@media (max-width: 991.98px) {
  .cart-items .cart-table {
    padding: 20px 0;
  }
  .cart-table__column {
    padding: 8px 10px;
    border-bottom: 1px solid #ebebeb;
  }
  .cart-table__column--image {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .cart-table__coupon-form {
    width: 60%;
  }
}
@media (max-width: 767.98px) {
  .cart-table__update-button {
    border-top: 1px solid #ebebeb;
  }
}
@media (max-width: 767.98px) {
  .cart-table__actions {
    display: block;
  }
  .cart-table__update-button {
    border-top: 1px solid #ebebeb;
    text-align: center;
  }
}
@media (max-width: 474px) {
  .cart-table__row {
    width: 100%;
  }
  .cart-table__column--number {
    display: none;
  }
  .cart-table__row {
    display: flex;
    flex-wrap: wrap;
  }
  .cart-table__column {
    flex: 1 1 auto; /* Позволяет ячейкам гибко адаптироваться */
  }
  .cart-table__column--quantity {
    display: block;
    width: 100%;
    order: 1;
    padding-left: 10px !important;
  }
  .cart-table__column--total {
    display: block;
    width: 100%; /* Занимает всю ширину */
    order: 2; /* Отображается после ячейки с количеством */
  }
  .cart-table__column--checkbox, .cart-table__column--remove {
    flex: 0;
    width: 100%;
  }
  .cart-table__column--product {
    max-width: 60%;
    padding: 8px 0;
  }
  .cart-table__column--remove {
    margin-left: auto;
  }
}
/*
// .categories-list
*/
.categories-list__body {
  background-color: unset;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.categories-list__item {
  background-color: #fff;
  padding: 2rem;
  text-align: center;
  position: relative;
}
.categories-list__item a {
  display: block;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  color: inherit;
}
.categories-list__item .image {
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}
.categories-list__item:before {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.03);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.12s;
  z-index: 1;
}
.categories-list__item:hover:before {
  opacity: 1;
}
.categories-list__item:hover a {
  color: #F28B00;
}

.categories-list__item-products {
  margin-top: 4px;
  font-size: 14px;
  color: #6c757d;
}

.categories-list__divider {
  width: 1px;
  background-color: #ebebeb;
}

@media (min-width: 576px) {
  .categories-list--layout--columns-3-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-3-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-3-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-4-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1400px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 4px) / 5);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(10n) {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-5-sidebar .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-sidebar .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 992px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-4-full .categories-list__item {
    width: calc((100% - 0px) / 1);
  }
  .categories-list--layout--columns-4-full .categories-list__divider:nth-child(2n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-4-full .categories-list__divider:last-child:nth-child(2n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 4px) / 5);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(10n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(10n) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-5-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-5-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-5-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1200px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 5px) / 6);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(12n) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-6-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-6-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-6-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

@media (min-width: 1400px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 6px) / 7);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(14n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(14n) {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 5px) / 6);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(12n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(12n) {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 3px) / 4);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(8n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(8n) {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 2px) / 3);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(6n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(6n) {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .categories-list--layout--columns-7-full .categories-list__item {
    width: calc((100% - 1px) / 2);
  }
  .categories-list--layout--columns-7-full .categories-list__divider:nth-child(4n) {
    width: 100%;
    height: 1px;
  }
  .categories-list--layout--columns-7-full .categories-list__divider:last-child:nth-child(4n) {
    display: none;
  }
}

/*
// .category-card
*/
.category-card__body {
  position: relative;
}

.category-card__overlay-image {
  display: none;
}

.category-card--layout--classic .category-card__body {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  flex-grow: 1;
  width: 100%;
}
.category-card--layout--classic .category-card__content {
  display: flex;
}
.category-card--layout--classic .category-card__image {
  width: 160px;
  padding: 24px 0;
  margin: 0 32px;
  flex-shrink: 0;
}
.category-card--layout--classic .category-card__image img {
  max-width: 100%;
}
.category-card--layout--classic .category-card__info {
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 24px;
}
[dir=ltr] .category-card--layout--classic .category-card__info {
  padding-left: 0;
  padding-right: 28px;
}
[dir=rtl] .category-card--layout--classic .category-card__info {
  padding-right: 0;
  padding-left: 28px;
}
.category-card--layout--classic .category-card__name a,
.category-card--layout--classic .category-card__children a {
  transition: color 0.12s;
}
.category-card--layout--classic .category-card__name a:hover,
.category-card--layout--classic .category-card__children a:hover {
  color: #F28B00;
}
.category-card--layout--classic .category-card__name {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.category-card--layout--classic .category-card__name a {
  color: inherit;
}
.category-card--layout--classic .category-card__children {
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  font-size: 15px;
}
.category-card--layout--classic .category-card__children li {
  position: relative;
  color: #6c757d;
  padding-top: 1px;
  padding-bottom: 1px;
}
[dir=ltr] .category-card--layout--classic .category-card__children li {
  padding-left: 11px;
  padding-right: 0;
}
[dir=rtl] .category-card--layout--classic .category-card__children li {
  padding-right: 11px;
  padding-left: 0;
}
.category-card--layout--classic .category-card__children li:before {
  display: block;
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  background: currentColor;
  opacity: 0.7;
  top: 10px;
}
[dir=ltr] .category-card--layout--classic .category-card__children li:before {
  left: 0;
}
[dir=rtl] .category-card--layout--classic .category-card__children li:before {
  right: 0;
}
.category-card--layout--classic .category-card__children a {
  color: inherit;
}
.category-card--layout--classic .category-card__actions {
  margin-top: 8px;
}
.category-card--layout--classic .category-card__link {
  font-size: 14px;
  font-weight: 500;
  color: #F28B00;
}
.category-card--layout--classic .category-card__link:hover {
  text-decoration: underline;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .category-card--layout--classic .category-card__image {
    width: 120px;
    margin: 0 26px;
  }
}
@media (max-width: 991px) and (min-width: 475px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
    margin: 0 26px;
    padding: 20px 0;
    align-self: center;
  }
  .category-card--layout--classic .category-card__content {
    flex-direction: column;
  }
  .category-card--layout--classic .category-card__info {
    padding-top: 0;
    padding-bottom: 20px;
  }
  [dir=ltr] .category-card--layout--classic .category-card__info {
    padding-left: 26px;
    padding-right: 26px;
  }
  [dir=rtl] .category-card--layout--classic .category-card__info {
    padding-right: 26px;
    padding-left: 26px;
  }
}
@media (max-width: 474px) and (min-width: 360px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
  }
}
@media (max-width: 474px) and (min-width: 360px) and (max-width: 420px) {
  .category-card--layout--classic .category-card__image {
    width: 100px;
    margin: 0 28px;
  }
}
@media (max-width: 359px) {
  .category-card--layout--classic .category-card__image {
    width: 132px;
    margin: 0 26px;
    padding: 24px 0;
    align-self: center;
  }
  .category-card--layout--classic .category-card__content {
    flex-direction: column;
  }
  .category-card--layout--classic .category-card__info {
    padding-top: 0;
    padding-bottom: 24px;
  }
  [dir=ltr] .category-card--layout--classic .category-card__info {
    padding-left: 26px;
    padding-right: 26px;
  }
  [dir=rtl] .category-card--layout--classic .category-card__info {
    padding-right: 26px;
    padding-left: 26px;
  }
}

.category-card--layout--overlay .category-card__body {
  flex-grow: 1;
  z-index: 0;
  overflow: hidden;
  border-radius: 2.5px;
  padding: 32px 20px 40px;
}
.category-card--layout--overlay .category-card__body:before {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(26, 26, 26, 0.6);
  background-image: linear-gradient(to top, rgba(26, 26, 26, 0.4) 20%, rgba(26, 26, 26, 0) 80%);
  z-index: -1;
  transition: background-color 0.2s;
}
.category-card--layout--overlay .category-card__overlay-image {
  display: block;
  position: absolute;
  z-index: -3;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  transition: transform 0.3s ease-out;
}
.category-card--layout--overlay .category-card__overlay-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.category-card--layout--overlay .category-card__overlay-image--blur {
  z-index: -2;
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}
.category-card--layout--overlay .category-card__overlay-image--blur img {
  filter: blur(3px);
}
.category-card--layout--overlay .category-card__content,
.category-card--layout--overlay .category-card__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  flex-grow: 1;
  text-align: center;
}
.category-card--layout--overlay .category-card__content {
  color: #fff;
}
.category-card--layout--overlay .category-card__name,
.category-card--layout--overlay .category-card__children {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.category-card--layout--overlay .category-card__name a,
.category-card--layout--overlay .category-card__children a {
  color: inherit;
  transition: color 0.12s;
}
.category-card--layout--overlay .category-card__name a:hover,
.category-card--layout--overlay .category-card__children a:hover {
  color: #ffdf40;
}
.category-card--layout--overlay .category-card__name {
  font-size: 24px;
  margin-bottom: 20px;
}
.category-card--layout--overlay .category-card__children {
  flex-grow: 1;
  list-style: none;
  padding: 0;
  font-size: 16px;
  margin: 0 0 28px;
  opacity: 1;
}
.category-card--layout--overlay .category-card__children li {
  padding: 5px 0 6px;
}
.category-card--layout--overlay:hover .category-card__overlay-image {
  transform: scale(1.03);
}
.category-card--layout--overlay:hover .category-card__overlay-image--blur {
  opacity: 1;
}
.category-card--layout--overlay:hover .category-card__body:before {
  background-color: rgba(26, 26, 26, 0.7);
}
@media (max-width: 767.98px) {
  .category-card--layout--overlay .category-card__body {
    padding: 22px 28px 28px;
  }
  .category-card--layout--overlay .category-card__content {
    align-items: flex-start;
  }
  .category-card--layout--overlay .category-card__info {
    align-items: flex-start;
    align-content: flex-start;
  }
  .category-card--layout--overlay .category-card__name {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .category-card--layout--overlay .category-card__children {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 24px;
  }
  [dir=ltr] .category-card--layout--overlay .category-card__children {
    text-align: left;
  }
  [dir=rtl] .category-card--layout--overlay .category-card__children {
    text-align: right;
  }
  .category-card--layout--overlay .category-card__children li {
    padding: 1px 0;
  }
  [dir=ltr] .category-card--layout--overlay .category-card__children li {
    margin-right: 16px;
  }
  [dir=rtl] .category-card--layout--overlay .category-card__children li {
    margin-left: 16px;
  }
}

/*
// .checkout
*/
.checkout .alert a {
  color: #F28B00;
}

.checkout__totals {
  width: 100%;
  font-size: 15px;
  line-height: 19px;
  border-spacing: 0;
  margin-bottom: 40px;
}
.checkout__totals th,
.checkout__totals td {
  padding: 0;
}
[dir=ltr] .checkout__totals th:last-child,
[dir=ltr] .checkout__totals td:last-child {
  padding-left: 20px;
  text-align: right;
}
[dir=rtl] .checkout__totals th:last-child,
[dir=rtl] .checkout__totals td:last-child {
  padding-right: 20px;
  text-align: left;
}

.checkout__totals-header {
  font-size: clamp(11px, 0.68vw, 13px);
  text-transform: uppercase;
}
.checkout__totals-header th {
  padding-bottom: 4px;
  border-bottom: 1px solid #ebebeb;
}

.checkout__totals-products td {
  padding: 4px 0;
}
.checkout__totals-products tr:first-child td {
  padding-top: 16px;
}
.checkout__totals-products tr:last-child td {
  padding-bottom: 16px;
}

.checkout__totals-subtotals th {
  font-weight: 500;
}
.checkout__totals-subtotals td,
.checkout__totals-subtotals th {
  padding: 4px 0;
}
.checkout__totals-subtotals tr:first-child td,
.checkout__totals-subtotals tr:first-child th {
  padding-top: 16px;
  border-top: 1px solid #ebebeb;
}
.checkout__totals-subtotals tr:last-child td,
.checkout__totals-subtotals tr:last-child th {
  padding-bottom: 16px;
}

.checkout__totals-footer {
  font-size: 24px;
}
.checkout__totals-footer th {
  font-weight: 500;
}
.checkout__totals-footer tr:first-child td,
.checkout__totals-footer tr:first-child th {
  padding-top: 20px;
  border-top: 1px solid #ebebeb;
}

.checkout__payment-methods {
  margin-bottom: 20px;
}

.checkout__agree {
  margin-bottom: 40px;
}
.checkout__agree a {
  text-decoration: underline;
}
.checkout__agree a:hover {
  text-decoration: underline;
}

.container-level {
  height: calc(100% - 52px);
}

.sticky-element {
  position: sticky;
  transition: all 0.3s ease;
  top: 130px;
  z-index: 10;
  background-color: #fff;
}

.checkout-form--inactive {
  pointer-events: none;
  filter: opacity(0.4);
}

.is-invalid .select2-selection--single {
  border: 1px solid #cc6666 !important;
  background: #f2dada !important;
}

.checkout__body {
  padding: 2rem;
}

.checkout__delivery-options {
  display: flex;
  flex-direction: column;
}
.checkout__delivery-options.is-invalid {
  border: 1px solid #b32d2d;
}

.checkout__payment-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0.5rem;
}
.checkout__payment-options.is-invalid {
  border: 1px solid #b32d2d;
}

.checkout__delivery-item {
  border: 1px solid #E3E3E3;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
}

.checkout__delivery-item-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.checkout__delivery-item-selected {
  border: 1px solid #F28B00;
}

.checkout__payment-options-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkout__options--comment {
  border: 1px solid #F28B00;
  background: rgba(227, 227, 227, 0.2);
  padding: 18px;
}

.checkout__subtitle {
  font-size: 16px;
  font-weight: 600;
}

.checkout-comment {
  margin-top: 1.5rem;
}

@media (max-width: 991.98px) {
  .checkout__body {
    padding: 0;
  }
}
.checkout-login-body {
  border: 1px solid #E3E3E3;
}

.checkout-login-header {
  z-index: 1;
  position: relative;
  margin-bottom: -1px;
}

.checkout-login-tabs {
  height: 56px;
}

.checkout-login-tabs__item {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  border: solid #E3E3E3;
  border-width: 1px 1px 0;
  padding: 0 20px 0 15px;
  height: 54px;
  margin-right: -1px;
  cursor: pointer;
}
.checkout-login-tabs__item span {
  font-weight: 700;
}

.checkout-login-tabs__item--active {
  background: #fff;
  height: 56px;
  cursor: default;
}
.checkout-login-tabs__item--active span {
  color: #F28B00;
}

@media (max-width: 575.98px) {
  .checkout-login-tabs__item-desktop {
    display: none;
  }
}

.checkout-login-tabs__item-mobile {
  display: none;
}
@media (max-width: 575.98px) {
  .checkout-login-tabs__item-mobile {
    display: block;
  }
}

/*
// .compare
*/
.compare__options-list {
  padding: 16px 20px;
  border-bottom: 1px solid #ebebeb;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.compare__option {
  display: flex;
  align-items: center;
}
[dir=ltr] .compare__option + .compare__option {
  margin-left: 16px;
}
[dir=rtl] .compare__option + .compare__option {
  margin-right: 16px;
}

[dir=ltr] .compare__option-label {
  margin-right: 8px;
}
[dir=rtl] .compare__option-label {
  margin-left: 8px;
}

/*
// .compare-table
*/
.compare-table {
  width: 100%;
  font-size: 15px;
  border-spacing: 0;
}
.compare-table tr:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.compare-table__column--header,
.compare-table__column--product {
  padding: 14px 16px;
}

[dir=ltr] .compare-table__column--product,
[dir=ltr] .compare-table__column--fake {
  border-left: 1px solid #ebebeb;
}
[dir=rtl] .compare-table__column--product,
[dir=rtl] .compare-table__column--fake {
  border-right: 1px solid #ebebeb;
}

.compare-table__column--header {
  width: 15%;
  background: #f7f7f7;
  font-weight: 500;
  min-width: 140px;
}
[dir=ltr] .compare-table__column--header {
  text-align: right;
}
[dir=rtl] .compare-table__column--header {
  text-align: left;
}

.compare-table__column--product {
  width: 17%;
  text-align: center;
  vertical-align: top;
  min-width: 180px;
}

.compare-table__column--fake {
  padding: 0;
}

.compare-table__column--product:nth-child(6) + .compare-table__column--fake {
  display: none;
}

.compare-table__product {
  display: block;
  line-height: 20px;
  color: inherit;
  transition: color 0.12s;
}
.compare-table__product:hover {
  color: #F28B00;
}

.compare-table__product-image {
  width: 150px;
  margin: 0 auto;
}

.compare-table__product-name {
  color: inherit;
}

.compare-table__rating-stars {
  display: flex;
  justify-content: center;
  padding-top: 1px;
}

.compare-table__rating-title {
  font-size: clamp(11px, 0.68vw, 13px);
  color: #6c757d;
  margin-top: 5px;
}

.general-properties {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.general-properties__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: clamp(11px, 0.68vw, 13px);
  color: #646464;
}
.general-properties__item .value {
  font-weight: 600;
}

/*
// .order-header
*/
.order-header {
  padding: 1.625rem 2rem 1.375rem;
}
.order-header:after {
  display: block;
  content: "";
  clear: both;
}

.order-header__actions {
  margin-top: -1px;
}
[dir=ltr] .order-header__actions {
  float: right;
}
[dir=rtl] .order-header__actions {
  float: left;
}

.order-header__title {
  border-bottom: 2px solid #e5e5e5;
  padding-bottom: 4px;
  margin-bottom: 0;
}
[dir=ltr] .order-header__title {
  padding-right: 3.5rem;
  float: left;
}
[dir=rtl] .order-header__title {
  padding-left: 3.5rem;
  float: right;
}

.order-header__subtitle {
  color: #6c757d;
  font-size: 14px;
  line-height: 1.375;
  clear: both;
  padding-top: 12px;
}
.order-header__subtitle mark {
  padding: 0;
  color: #242424;
  background: transparent;
  font-weight: 500;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .order-header {
    padding: 1.375rem 1.5rem 1.25rem;
  }
}
@media (max-width: 767.98px) {
  .order-header {
    padding: 1.125rem 1.375rem 1rem;
  }
}
@media (max-width: 440px) {
  .order-header__actions {
    margin-bottom: 0.875rem;
  }
  [dir=ltr] .order-header__actions {
    float: none;
  }
  [dir=rtl] .order-header__actions {
    float: none;
  }
}
/*
// .order-list
*/
.order-list a {
  color: inherit;
}
.order-list a:hover {
  color: inherit;
  text-decoration: underline;
}
.order-list table {
  width: 100%;
  font-size: 15px;
}

.order-list__header th,
.order-list__header td {
  text-transform: uppercase;
  color: #6c757d;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 500;
}

.order-list__footer {
  font-size: 20px;
}
.order-list__footer th {
  font-weight: 500;
}

.order-list__column-image {
  width: 1px;
}
.order-list__column-image .image {
  width: 40px;
}

.order-list__column-product {
  line-height: 1.25;
}

.order-list__column-quantity {
  text-align: center;
}

[dir=ltr] .order-list__column-total {
  text-align: right;
}
[dir=rtl] .order-list__column-total {
  text-align: left;
}

.order-list__options {
  color: #6c757d;
  font-size: clamp(11px, 0.68vw, 13px);
  margin-top: 2px;
}

.order-list__options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.order-list__options-item:not(:last-child) {
  position: relative;
}
[dir=ltr] .order-list__options-item:not(:last-child) {
  padding-right: 19px;
}
[dir=rtl] .order-list__options-item:not(:last-child) {
  padding-left: 19px;
}
.order-list__options-item:not(:last-child):after {
  position: absolute;
  content: "";
  display: block;
  background: #ccc;
  width: 1px;
  height: 10px;
  top: calc(50% - 5px);
  transform: skewX(-20deg);
}
[dir=ltr] .order-list__options-item:not(:last-child):after {
  right: 9px;
}
[dir=rtl] .order-list__options-item:not(:last-child):after {
  left: 9px;
}

@media (min-width: 576px) {
  .order-list th,
  .order-list td {
    padding: 4px 12px;
  }
  [dir=ltr] .order-list th:first-child,
  [dir=ltr] .order-list td:first-child {
    padding-left: 1.5rem;
  }
  [dir=rtl] .order-list th:first-child,
  [dir=rtl] .order-list td:first-child {
    padding-right: 1.5rem;
  }
  [dir=ltr] .order-list th:last-child,
  [dir=ltr] .order-list td:last-child {
    padding-right: 1.5rem;
  }
  [dir=rtl] .order-list th:last-child,
  [dir=rtl] .order-list td:last-child {
    padding-left: 1.5rem;
  }
  .order-list__header th,
  .order-list__header td {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 12px;
  }
  .order-list__products th,
  .order-list__products td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .order-list__products tr:first-child th,
  .order-list__products tr:first-child td {
    padding-top: 14px;
  }
  .order-list__products tr:last-child th,
  .order-list__products tr:last-child td {
    padding-bottom: 14px;
  }
  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 4px;
  }
  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 4px;
  }
  .order-list__subtotals th,
  .order-list__subtotals td {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .order-list__subtotals tr:first-child th,
  .order-list__subtotals tr:first-child td {
    padding-top: 12px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__subtotals tr:last-child th,
  .order-list__subtotals tr:last-child td {
    padding-bottom: 12px;
  }
  .order-list__footer th,
  .order-list__footer td {
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 1px solid #ebebeb;
  }
}
@media (max-width: 575.98px) {
  .order-list table,
  .order-list tr,
  .order-list th,
  .order-list td {
    display: block;
    padding: 0;
  }
  .order-list__header,
  .order-list__products,
  .order-list__subtotals,
  .order-list__footer {
    display: block;
  }
  .order-list__header tr,
  .order-list__products tr,
  .order-list__subtotals tr,
  .order-list__footer tr {
    padding: 0 1rem;
  }
  .order-list__header tr,
  .order-list__subtotals tr,
  .order-list__footer tr {
    display: flex;
  }
  .order-list__header .order-list__column-label,
  .order-list__subtotals .order-list__column-label,
  .order-list__footer .order-list__column-label {
    flex-grow: 1;
  }
  .order-list__header .order-list__column-quantity,
  .order-list__subtotals .order-list__column-quantity,
  .order-list__footer .order-list__column-quantity {
    display: none;
  }
  .order-list__header tr {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
  }
  .order-list__products tr {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    flex-wrap: wrap;
  }
  .order-list__products tr:not(:first-child) {
    border-top: 1px solid #ebebeb;
  }
  .order-list__products .order-list__column-image {
    width: 40px;
    flex-shrink: 0;
  }
  .order-list__products .order-list__column-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 40px);
  }
  [dir=ltr] .order-list__products .order-list__column-product {
    padding-left: 1rem;
  }
  [dir=rtl] .order-list__products .order-list__column-product {
    padding-right: 1rem;
  }
  .order-list__products .order-list__column-quantity:before {
    content: attr(data-title) " ";
  }
  .order-list__products .order-list__column-total {
    flex-grow: 1;
  }
  .order-list__products .order-list__column-quantity,
  .order-list__products .order-list__column-total {
    margin-top: 0.625rem;
    margin-bottom: -0.125rem;
  }
  .order-list__subtotals {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }
  .order-list__subtotals th,
  .order-list__subtotals td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .order-list__footer {
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid #ebebeb;
  }
}
/*
// .order-success
*/
.order-success__body {
  max-width: 690px;
  margin: 0 auto;
}
.order-success__body .default-pargo-button-inverse svg {
  height: 20px;
}

.order-success__header {
  padding: 60px 0 52px;
  text-align: center;
}

.order-success__icon {
  color: #F28B00;
  margin: 0 auto;
  display: block;
}

.order-success__title {
  margin-top: 24px;
  margin-bottom: 2px;
}

.order-success__subtitle {
  font-size: 19px;
  font-weight: 500;
}

.order-success__actions {
  margin-top: 20px;
}

.order-success__meta {
  padding: 16px 0;
  margin-bottom: 20px;
  text-align: center;
}

.order-success__meta-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.order-success__meta-item {
  flex-basis: 25%;
  line-height: 1.25;
  position: relative;
  padding: 2px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.order-success__meta-item:not(:last-child):before {
  position: absolute;
  display: block;
  content: "";
  border-left: 2px dashed rgba(0, 0, 0, 0.1);
  width: 0;
  height: 100%;
  top: 0;
}
[dir=ltr] .order-success__meta-item:not(:last-child):before {
  right: -1px;
}
[dir=rtl] .order-success__meta-item:not(:last-child):before {
  left: -1px;
}

.order-success__meta-title {
  display: block;
  font-size: 13px;
  color: #6c757d;
  margin-bottom: 4px;
}

.order-success__meta-value {
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.order-success__addresses {
  margin-left: -20px;
  display: flex;
}

.order-success__address {
  margin-left: 20px;
  margin-top: 20px;
  flex-basis: 0;
  flex-grow: 1;
}

@media (max-width: 767.98px) {
  .order-success__header {
    padding: 44px 0 40px;
  }
  .order-success__meta-list {
    flex-wrap: wrap;
  }
  .order-success__meta-item {
    flex-basis: 50%;
  }
  .order-success__meta-item:nth-child(2n):before {
    display: none;
  }
  .order-success__meta-item:nth-child(n+3) {
    margin-top: 16px;
  }
  .order-success__addresses {
    display: block;
  }
}
.btn-homepage {
  padding: 8px 20px !important;
  background-color: #F28B00;
  color: #fff;
  transition: color 0.15s, background-color 0.15s;
}
.btn-homepage:hover, .btn-homepage:focus {
  background-color: #fff;
  color: #000;
}

.parent-category__wrapper .image-link {
  height: 260px;
  display: flex;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}
.parent-category__wrapper .image-link img {
  width: 60%;
  margin: auto;
}

/*
// .payment-methods
*/
.payment-methods__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.payment-methods__item {
  border-radius: 2px;
  padding-bottom: 2px;
  border: 1px solid #ebebeb;
  transition: background 0.12s, border-color 0.12s;
}
.payment-methods__item + .payment-methods__item {
  margin-top: 6px;
}
.payment-methods__item:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}

.payment-methods__item-header {
  display: flex;
  align-items: center;
  line-height: 18px;
  margin: 0;
  padding: 10px 12px 8px;
  cursor: pointer;
}

[dir=ltr] .payment-methods__item-radio {
  margin-right: 8px;
}
[dir=rtl] .payment-methods__item-radio {
  margin-left: 8px;
}

.payment-methods__item-details {
  font-size: 14px;
  line-height: 22px;
  padding: 0 12px 8px;
}

.payment-methods__item-container {
  overflow: hidden;
  opacity: 0;
  height: 0;
  transition: height 0.3s, opacity 0.3s;
}

.payment-methods__item--active, .payment-methods__item--active:hover {
  background: #f5f5f5;
  border-color: #e5e5e5;
}
.payment-methods__item--active .payment-methods__item-container {
  opacity: 1;
  height: auto;
}

/*
// .product
*/
.product__header h1 {
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
}

.product__tabs {
  background-color: #fff;
}

.product__subtitle {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

.product__rating {
  display: flex;
  color: #6c757d;
  font-size: 14px;
  line-height: 1;
}

[dir=ltr] .product__rating-stars {
  margin-right: 8px;
}
[dir=rtl] .product__rating-stars {
  margin-left: 8px;
}

.product__rating-label a {
  color: inherit;
}
.product__rating-label a:hover {
  text-decoration: underline;
}

[dir=ltr] .product__fit {
  margin-left: 12px;
}
[dir=rtl] .product__fit {
  margin-right: 12px;
}

.product__info-card {
  position: relative;
  width: 100%;
}

.product__info-body {
  padding: 28px 28px 24px;
}

.product__prices-stock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 22px;
}

.product__meta {
  margin: -3px 0;
}
.product__meta table {
  width: 100%;
  font-size: 14px;
}
.product__meta th,
.product__meta td {
  padding: 3px 0;
}
.product__meta th {
  font-weight: 500;
}
[dir=ltr] .product__meta th {
  padding-right: 12px;
}
[dir=rtl] .product__meta th {
  padding-left: 12px;
}
.product__meta tr + tr > * {
  border-top: 1px solid #ebebeb;
}
.product__meta a {
  color: #F28B00;
}
.product__meta a:hover {
  text-decoration: underline;
}

.product__shop-features {
  background-color: #fff;
  margin-top: 30px;
}

.product__badge {
  top: -7px;
  position: absolute;
}
[dir=ltr] .product__badge {
  left: 22px;
}
[dir=rtl] .product__badge {
  right: 22px;
}

.product__prices {
  padding: 8px 0 7px;
  line-height: 1;
}

.product__price {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #242424;
}

.product__price--new {
  color: #F84147;
}

.product__price--old {
  font-size: 16px;
  text-decoration: line-through;
  font-weight: 400;
  color: #999;
  margin-bottom: 5px;
}

.product__form {
  background: #f9f9f9;
  margin-bottom: 24px;
  margin-top: -4px;
  padding: 16px 28px 18px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.product__actions {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  padding: 0 28px 24px;
  margin-top: -20px;
}

.product__actions-item--quantity {
  width: 100px;
}
[dir=ltr] .product__actions-item--quantity {
  margin-right: 8px;
}
[dir=rtl] .product__actions-item--quantity {
  margin-left: 8px;
}

.product__actions-item--addtocart {
  flex-grow: 1;
}

.product__actions-item--quantity,
.product__actions-item--addtocart {
  margin-top: 20px;
}

.product__actions-item--wishlist,
.product__actions-item--compare {
  position: relative;
  margin-top: 14px;
  margin-bottom: -8px;
  width: 50%;
  font-size: 13px;
  line-height: 16px;
  background: transparent;
  color: #242424;
  fill: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding-top: 7px;
  padding-bottom: 5px;
  border-radius: 2px;
  transition: background 0.15s, fill 0.15s;
}
[dir=ltr] .product__actions-item--wishlist,
[dir=ltr] .product__actions-item--compare {
  padding-left: 9px;
  padding-right: 10px;
}
[dir=rtl] .product__actions-item--wishlist,
[dir=rtl] .product__actions-item--compare {
  padding-right: 9px;
  padding-left: 10px;
}
.product__actions-item--wishlist svg,
.product__actions-item--compare svg {
  position: relative;
  top: -1px;
}
[dir=ltr] .product__actions-item--wishlist svg,
[dir=ltr] .product__actions-item--compare svg {
  margin-right: 8px;
}
[dir=rtl] .product__actions-item--wishlist svg,
[dir=rtl] .product__actions-item--compare svg {
  margin-left: 8px;
}
.product__actions-item--wishlist:focus,
.product__actions-item--compare:focus {
  outline: none;
}
.product__actions-item--wishlist:hover,
.product__actions-item--compare:hover {
  background: #f5f5f5;
  fill: #bfbfbf;
}
.product__actions-item--wishlist:active,
.product__actions-item--compare:active {
  background: #f0f0f0;
  fill: #b3b3b3;
}
.product__actions-item--wishlist.product__actions-item--loading,
.product__actions-item--compare.product__actions-item--loading {
  background: transparent;
  fill: transparent;
  cursor: default;
}
.product__actions-item--wishlist.product__actions-item--loading:before,
.product__actions-item--compare.product__actions-item--loading:before {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
  position: static;
}
[dir=ltr] .product__actions-item--wishlist.product__actions-item--loading:before,
[dir=ltr] .product__actions-item--compare.product__actions-item--loading:before {
  margin-right: -16px;
}
[dir=rtl] .product__actions-item--wishlist.product__actions-item--loading:before,
[dir=rtl] .product__actions-item--compare.product__actions-item--loading:before {
  margin-left: -16px;
}

.product__actions-divider {
  width: 100%;
}

.product__tags-and-share-links {
  border-top: 1px solid #ebebeb;
  padding: 24px 28px;
}

.product__tags + .product__share-links {
  margin-top: 18px;
}

.product--layout--sidebar {
  --product-gutter: 30px;
  --product-gallery-image-max-width: 400px;
}
.product--layout--sidebar .product__body {
  display: grid;
  grid-template-columns: calc(100% - 370px) 370px;
  grid-template-rows: auto auto 1fr;
}
.product--layout--sidebar .product__card--one {
  grid-column: 1;
  grid-row: 1/3;
}
.product--layout--sidebar .product__header {
  grid-column: 1;
  grid-row: 1;
}
.product--layout--sidebar .product__gallery {
  grid-column: 1;
  grid-row: 2;
}
.product--layout--sidebar .product__tabs {
  grid-column: 1;
  grid-row: 3;
}
.product--layout--sidebar .product__info {
  grid-column: 2;
  grid-row: 1/4;
}
.product--layout--sidebar .product__card {
  background-color: #fff;
}
.product--layout--sidebar .product__card--two {
  display: none;
}
.product--layout--sidebar .product__gallery {
  padding: 0 40px 40px;
}
.product--layout--sidebar .product__header {
  padding: 36px 40px 16px;
}
[dir=ltr] .product--layout--sidebar .product__info {
  margin-left: var(--product-gutter);
}
[dir=rtl] .product--layout--sidebar .product__info {
  margin-right: var(--product-gutter);
}
.product--layout--sidebar .product__info-card {
  background-color: #fff;
}
.product--layout--sidebar .product__shop-features,
.product--layout--sidebar .product__tabs {
  margin-top: var(--product-gutter);
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  [dir=ltr] .product--layout--sidebar .product__title {
    padding-right: 80px;
  }
  [dir=rtl] .product--layout--sidebar .product__title {
    padding-left: 80px;
  }
  .product--layout--sidebar .product__actions-item--wishlist,
  .product--layout--sidebar .product__actions-item--compare {
    position: absolute;
    top: 19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }
  [dir=ltr] .product--layout--sidebar .product__actions-item--wishlist,
  [dir=ltr] .product--layout--sidebar .product__actions-item--compare {
    right: 16px;
  }
  [dir=rtl] .product--layout--sidebar .product__actions-item--wishlist,
  [dir=rtl] .product--layout--sidebar .product__actions-item--compare {
    left: 16px;
  }
  .product--layout--sidebar .product__actions-item--wishlist span,
  .product--layout--sidebar .product__actions-item--compare span {
    display: none;
  }
  .product--layout--sidebar .product__actions-item--wishlist svg,
  .product--layout--sidebar .product__actions-item--compare svg {
    top: 0;
    display: block;
    margin: 0;
  }
  [dir=ltr] .product--layout--sidebar .product__actions-item--compare {
    right: 52px;
  }
  [dir=rtl] .product--layout--sidebar .product__actions-item--compare {
    left: 52px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  [dir=ltr] .product--layout--sidebar .product__info-body,
  [dir=ltr] .product--layout--sidebar .product__form,
  [dir=ltr] .product--layout--sidebar .product__actions,
  [dir=ltr] .product--layout--sidebar .product__tags-and-share-links {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .product--layout--sidebar .product__info-body,
  [dir=rtl] .product--layout--sidebar .product__form,
  [dir=rtl] .product--layout--sidebar .product__actions,
  [dir=rtl] .product--layout--sidebar .product__tags-and-share-links {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (min-width: 576px) and (max-width: 1199.98px) {
  .product--layout--sidebar .product__meta table,
  .product--layout--sidebar .product__meta th,
  .product--layout--sidebar .product__meta td {
    display: block;
  }
  .product--layout--sidebar .product__meta tbody {
    display: flex;
    flex-wrap: wrap;
  }
  .product--layout--sidebar .product__meta tr,
  .product--layout--sidebar .product__meta th {
    width: calc(50% - 10px);
  }
  .product--layout--sidebar .product__meta tr {
    display: flex;
  }
  [dir=ltr] .product--layout--sidebar .product__meta tr:nth-child(2n) {
    margin-left: 20px;
  }
  [dir=rtl] .product--layout--sidebar .product__meta tr:nth-child(2n) {
    margin-right: 20px;
  }
}
@media (max-width: 1199.98px) {
  .product--layout--sidebar .product__info-card {
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--sidebar .product__info-card {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--sidebar .product__info-card {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--sidebar .product__info-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--sidebar .product__info-body {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--sidebar .product__info-body {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--sidebar .product__prices-stock {
    padding-top: 0;
    padding-bottom: 10px;
  }
  [dir=ltr] .product--layout--sidebar .product__prices-stock {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--sidebar .product__prices-stock {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--sidebar .product__price {
    font-size: 26px;
  }
  .product--layout--sidebar .product__price--old {
    font-size: 16px;
  }
  .product--layout--sidebar .product__meta {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 8px 0;
  }
  .product--layout--sidebar .product__meta th,
  .product--layout--sidebar .product__meta td {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .product--layout--sidebar .product__meta tr + tr > * {
    border: none;
  }
  .product--layout--sidebar .product__form {
    background: transparent;
    padding: 0;
    border: none;
    margin-bottom: 16px;
  }
  .product--layout--sidebar .product__actions {
    padding: 0;
  }
  .product--layout--sidebar .product__tags-and-share-links {
    border: none;
    padding: 20px 0 0;
  }
}
@media (max-width: 767.98px) {
  .product--layout--sidebar .product__actions {
    margin-bottom: -6px;
  }
  .product--layout--sidebar .product__actions-divider {
    height: 10px;
  }
  .product--layout--sidebar .product__actions-item--wishlist,
  .product--layout--sidebar .product__actions-item--compare {
    width: auto;
    margin-top: 2px;
    margin-bottom: -2px;
  }
}
@media (max-width: 575.98px) {
  .product--layout--sidebar .product__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .product--layout--sidebar .product__fit {
    margin-bottom: 11px;
    order: -1;
  }
  [dir=ltr] .product--layout--sidebar .product__fit {
    margin-left: -4px;
  }
  [dir=rtl] .product--layout--sidebar .product__fit {
    margin-right: -4px;
  }
}
@media (max-width: 1399.98px) {
  .product--layout--sidebar {
    --product-gutter: 20px;
    --product-gallery-image-max-width: 360px;
  }
  .product--layout--sidebar .product__body {
    grid-template-rows: max-content;
    grid-template-columns: calc(100% - 332px) 332px;
  }
  .product--layout--sidebar .product__info {
    grid-column: 2;
    grid-row: 1/3;
  }
  .product--layout--sidebar .product__gallery {
    padding-top: 0;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--sidebar .product__gallery {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--sidebar .product__gallery {
    padding-right: 28px;
    padding-left: 28px;
  }
  .product--layout--sidebar .product__header {
    padding-top: 24px;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--sidebar .product__header {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--sidebar .product__header {
    padding-right: 28px;
    padding-left: 28px;
  }
  .product--layout--sidebar .product__shop-features {
    display: none;
  }
  .product--layout--sidebar .product__tabs {
    grid-column: 1/3;
  }
}
@media (max-width: 1199.98px) {
  .product--layout--sidebar {
    --product-gutter: 16px;
  }
  .product--layout--sidebar .product__body {
    grid-template-columns: 100%;
  }
  .product--layout--sidebar .product__info {
    grid-column: 1;
    grid-row: 3;
  }
  .product--layout--sidebar .product__tabs {
    grid-column: 1;
    grid-row: 4;
  }
  .product--layout--sidebar .product__card--one {
    display: none;
  }
  .product--layout--sidebar .product__header {
    background-color: #fff;
    padding-bottom: 24px;
  }
  .product--layout--sidebar .product__gallery {
    background-color: #fff;
    width: 100%;
    margin-top: var(--product-gutter);
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--sidebar .product__gallery {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--sidebar .product__gallery {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--sidebar .product__info {
    margin-top: var(--product-gutter);
  }
  [dir=ltr] .product--layout--sidebar .product__info {
    margin-left: 0;
  }
  [dir=rtl] .product--layout--sidebar .product__info {
    margin-right: 0;
  }
  .product--layout--sidebar .product__info-card {
    position: static;
  }
  .product--layout--sidebar .product__actions-item--addtocart .btn {
    width: auto;
  }
  .product--layout--sidebar .product__actions {
    margin-top: -12px;
  }
  .product--layout--sidebar .product__actions-item--quantity,
  .product--layout--sidebar .product__actions-item--addtocart {
    margin-top: 10px;
  }
}
@media (max-width: 767.98px) {
  .product--layout--sidebar .product__header {
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 22px;
  }
  [dir=ltr] .product--layout--sidebar .product__header {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--sidebar .product__header {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--sidebar .product__title {
    font-size: 22px;
    line-height: 28px;
  }
}

.product--layout--full {
  --product-gutter: 10px;
  --product-gallery-image-max-width: 560px;
}
.product--layout--full .product__body {
  display: grid;
  grid-template-columns: auto 1fr 370px;
  grid-template-rows: max-content auto auto 1fr;
}
.product--layout--full .product__card--one {
  grid-column: 1/3;
  grid-row: 1/4;
}
.product--layout--full .product__gallery {
  grid-column: 1;
  grid-row: 1/4;
}
.product--layout--full .product__header {
  grid-column: 2;
  grid-row: 1;
}
.product--layout--full .product__main {
  grid-column: 2;
  grid-row: 2;
}
.product--layout--full .product__info {
  grid-column: 3;
  grid-row: 1/5;
}
.product--layout--full .product__tabs {
  grid-column: 1/3;
  grid-row: 4;
}
.product--layout--full .product__card {
  background-color: #fff;
}
.product--layout--full .product__card--two {
  display: none;
}
.product--layout--full .product__gallery {
  width: 440px;
}
.product--layout--full .product__header {
  padding-top: 36px;
  padding-bottom: 16px;
}
[dir=ltr] .product--layout--full .product__header {
  padding-left: 36px;
  padding-right: 40px;
}
[dir=rtl] .product--layout--full .product__header {
  padding-right: 36px;
  padding-left: 40px;
}
.product--layout--full .product__main {
  padding-top: 0;
  padding-bottom: 48px;
}
[dir=ltr] .product--layout--full .product__main {
  padding-left: 36px;
  padding-right: 40px;
}
[dir=rtl] .product--layout--full .product__main {
  padding-right: 36px;
  padding-left: 40px;
}
[dir=ltr] .product--layout--full .product__info {
  margin-left: var(--product-gutter);
}
[dir=rtl] .product--layout--full .product__info {
  margin-right: var(--product-gutter);
}
.product--layout--full .product__shop-features {
  margin-top: var(--product-gutter);
}
.product--layout--full .product__subtitle {
  padding-top: 8px;
}
.product--layout--full .product__gallery-intro {
  display: flex;
}
.product--layout--full .product__excerpt {
  font-size: 15px;
}
.product--layout--full .product__features {
  margin: 20px 0 0;
  font-size: 14px;
  border-radius: 3px;
}
.product--layout--full .product__features ul {
  list-style: none;
  padding: 0;
  color: #6c757d;
  margin: 0 -6px;
  display: flex;
  flex-wrap: wrap;
}
.product--layout--full .product__features li {
  padding-top: 3px;
  padding-bottom: 2px;
  position: relative;
  margin: 0 6px;
  width: calc(100% / 1 - 12px);
}
[dir=ltr] .product--layout--full .product__features li {
  padding-left: 11px;
  padding-right: 0;
}
[dir=rtl] .product--layout--full .product__features li {
  padding-right: 11px;
  padding-left: 0;
}
.product--layout--full .product__features li:before {
  position: absolute;
  display: block;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: currentColor;
  top: 12px;
  opacity: 0.85;
}
[dir=ltr] .product--layout--full .product__features li:before {
  left: 0;
}
[dir=rtl] .product--layout--full .product__features li:before {
  right: 0;
}
.product--layout--full .product__features-title {
  font-weight: 500;
  padding-bottom: 12px;
}
.product--layout--full .product__features-link {
  margin: 20px -2px 0;
  padding: 20px 2px 0;
  font-size: 14px;
  border-top: 1px solid #ebebeb;
}
.product--layout--full .product__features-link a:hover {
  text-decoration: underline;
}
@media (min-width: 1200px) {
  .product--layout--full .product__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .product--layout--full .product__fit {
    margin-bottom: 11px;
    order: -1;
  }
  [dir=ltr] .product--layout--full .product__fit {
    margin-left: -4px;
  }
  [dir=rtl] .product--layout--full .product__fit {
    margin-right: -4px;
  }
}
@media (min-width: 768px) {
  .product--layout--full .product__info-card {
    background-color: #fff;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .product--layout--full .product__gallery {
    background-color: #fff;
  }
  [dir=ltr] .product--layout--full .product__actions-item--addtocart .btn {
    padding-left: 16px;
    padding-right: 16px;
  }
  [dir=rtl] .product--layout--full .product__actions-item--addtocart .btn {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (max-width: 575.98px) {
  .product--layout--full .product__subtitle {
    flex-direction: column;
    align-items: flex-start;
  }
  .product--layout--full .product__fit {
    margin-bottom: 11px;
    order: -1;
  }
  [dir=ltr] .product--layout--full .product__fit {
    margin-left: -4px;
  }
  [dir=rtl] .product--layout--full .product__fit {
    margin-right: -4px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [dir=ltr] .product--layout--full .product__title {
    padding-right: 80px;
  }
  [dir=rtl] .product--layout--full .product__title {
    padding-left: 80px;
  }
  .product--layout--full .product__actions-item--wishlist,
  .product--layout--full .product__actions-item--compare {
    position: absolute;
    top: 19px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    padding: 0;
    margin: 0;
  }
  [dir=ltr] .product--layout--full .product__actions-item--wishlist,
  [dir=ltr] .product--layout--full .product__actions-item--compare {
    right: 16px;
  }
  [dir=rtl] .product--layout--full .product__actions-item--wishlist,
  [dir=rtl] .product--layout--full .product__actions-item--compare {
    left: 16px;
  }
  .product--layout--full .product__actions-item--wishlist span,
  .product--layout--full .product__actions-item--compare span {
    display: none;
  }
  .product--layout--full .product__actions-item--wishlist svg,
  .product--layout--full .product__actions-item--compare svg {
    top: 0;
    display: block;
    margin: 0;
  }
  [dir=ltr] .product--layout--full .product__actions-item--compare {
    right: 52px;
  }
  [dir=rtl] .product--layout--full .product__actions-item--compare {
    left: 52px;
  }
}
@media (max-width: 1399.98px) {
  .product--layout--full {
    --product-gutter: 24px;
  }
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr 336px;
  }
  .product--layout--full .product__gallery {
    padding-top: 24px;
    padding-bottom: 36px;
    width: 348px;
  }
  .product--layout--full .product__header {
    padding-top: 24px;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--full .product__header {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--full .product__header {
    padding-right: 28px;
    padding-left: 28px;
  }
  .product--layout--full .product__main {
    padding-top: 0;
    padding-bottom: 36px;
  }
  [dir=ltr] .product--layout--full .product__main {
    padding-left: 28px;
    padding-right: 28px;
  }
  [dir=rtl] .product--layout--full .product__main {
    padding-right: 28px;
    padding-left: 28px;
  }
  [dir=ltr] .product--layout--full .product__info-body,
  [dir=ltr] .product--layout--full .product__form,
  [dir=ltr] .product--layout--full .product__actions,
  [dir=ltr] .product--layout--full .product__tags-and-share-links {
    padding-left: 20px;
    padding-right: 20px;
  }
  [dir=rtl] .product--layout--full .product__info-body,
  [dir=rtl] .product--layout--full .product__form,
  [dir=rtl] .product--layout--full .product__actions,
  [dir=rtl] .product--layout--full .product__tags-and-share-links {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 1199.98px) {
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr 336px;
    grid-template-rows: max-content auto auto 1fr;
  }
  .product--layout--full .product__card {
    grid-column: 1/3;
    grid-row: 1/4;
  }
  .product--layout--full .product__gallery {
    grid-column: 1/3;
    grid-row: 3;
  }
  .product--layout--full .product__header {
    grid-column: 1/3;
    grid-row: 1;
  }
  .product--layout--full .product__main {
    grid-column: 1/3;
    grid-row: 2;
  }
  .product--layout--full .product__info {
    grid-column: 3;
    grid-row: 1/4;
  }
  .product--layout--full .product__tabs {
    grid-column: 1/4;
    grid-row: 4;
  }
  .product--layout--full .product__features {
    display: none;
  }
  .product--layout--full .product__main {
    padding-bottom: 16px;
  }
  .product--layout--full .product__excerpt {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 14px;
  }
  .product--layout--full .product__gallery {
    --product-gallery-image-max-width: 400px;
    padding-top: 0;
    width: 100%;
  }
  .product--layout--full .product__header {
    padding-bottom: 10px;
  }
  .product--layout--full .product__info {
    display: flex;
  }
  .product--layout--full .product__shop-features {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .product--layout--full {
    --product-gutter: 16px;
  }
  .product--layout--full .product__body {
    grid-template-columns: auto 1fr;
    grid-template-rows: max-content auto auto 1fr;
  }
  .product--layout--full .product__card {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  .product--layout--full .product__header {
    grid-column: 1/3;
    grid-row: 1;
  }
  .product--layout--full .product__main {
    grid-column: 1/3;
    grid-row: 2;
  }
  .product--layout--full .product__gallery {
    grid-column: 1;
    grid-row: 3;
  }
  .product--layout--full .product__info {
    grid-column: 2;
    grid-row: 3;
  }
  .product--layout--full .product__gallery {
    margin-top: var(--product-gutter);
    width: 385px;
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--full .product__info {
    margin-left: var(--product-gutter);
  }
  [dir=rtl] .product--layout--full .product__info {
    margin-right: var(--product-gutter);
  }
  .product--layout--full .product__excerpt {
    border: none;
    padding-bottom: 8px;
  }
  .product--layout--full .product__info-card {
    position: static;
    margin-top: var(--product-gutter);
    padding-top: 24px;
    padding-bottom: 28px;
  }
  [dir=ltr] .product--layout--full .product__info-card {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__info-card {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--full .product__info-body {
    padding-top: 0;
    padding-bottom: 16px;
  }
  [dir=ltr] .product--layout--full .product__info-body {
    padding-left: 0px;
    padding-right: 0px;
  }
  [dir=rtl] .product--layout--full .product__info-body {
    padding-right: 0px;
    padding-left: 0px;
  }
  .product--layout--full .product__prices-stock {
    padding-top: 0;
    padding-bottom: 10px;
  }
  [dir=ltr] .product--layout--full .product__prices-stock {
    padding-left: 0px;
    padding-right: 0px;
  }
  [dir=rtl] .product--layout--full .product__prices-stock {
    padding-right: 0px;
    padding-left: 0px;
  }
  .product--layout--full .product__price {
    font-size: 26px;
  }
  .product--layout--full .product__price--old {
    font-size: 16px;
  }
  .product--layout--full .product__meta {
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 0;
    margin: 8px 0;
  }
  .product--layout--full .product__meta th,
  .product--layout--full .product__meta td {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .product--layout--full .product__meta tr + tr > * {
    border: none;
  }
  .product--layout--full .product__form {
    background: transparent;
    border: none;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
  }
  [dir=ltr] .product--layout--full .product__form {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__form {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--full .product__actions {
    padding-top: 0;
    padding-bottom: 0;
  }
  [dir=ltr] .product--layout--full .product__actions {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__actions {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--full .product__tags-and-share-links {
    border: none;
    padding-top: 20px;
    padding-bottom: 0;
  }
  [dir=ltr] .product--layout--full .product__tags-and-share-links {
    padding-left: 0;
    padding-right: 0;
  }
  [dir=rtl] .product--layout--full .product__tags-and-share-links {
    padding-right: 0;
    padding-left: 0;
  }
  .product--layout--full .product-gallery[data-layout=product-full] .product-gallery__zoom {
    top: -15px;
  }
  [dir=ltr] .product--layout--full .product-gallery[data-layout=product-full] .product-gallery__zoom {
    right: -15px;
  }
  [dir=rtl] .product--layout--full .product-gallery[data-layout=product-full] .product-gallery__zoom {
    left: -15px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .product--layout--full .product__meta table,
  .product--layout--full .product__meta th,
  .product--layout--full .product__meta td {
    display: block;
  }
  .product--layout--full .product__meta tbody {
    display: flex;
    flex-wrap: wrap;
  }
  .product--layout--full .product__meta tr,
  .product--layout--full .product__meta th {
    width: calc(50% - 10px);
  }
  .product--layout--full .product__meta tr {
    display: flex;
  }
  [dir=ltr] .product--layout--full .product__meta tr:nth-child(2n) {
    margin-left: 20px;
  }
  [dir=rtl] .product--layout--full .product__meta tr:nth-child(2n) {
    margin-right: 20px;
  }
}
@media (max-width: 767.98px) {
  .product--layout--full .product__body {
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
  .product--layout--full .product__header {
    grid-column: 1;
    grid-row: 1;
  }
  .product--layout--full .product__gallery {
    grid-column: 1;
    grid-row: 2;
  }
  .product--layout--full .product__card--two {
    grid-column: 1;
    grid-row: 3/5;
  }
  .product--layout--full .product__main {
    grid-column: 1;
    grid-row: 3;
  }
  .product--layout--full .product__info {
    grid-column: 1;
    grid-row: 4;
  }
  .product--layout--full .product__tabs {
    grid-column: 1;
    grid-row: 5;
  }
  .product--layout--full .product__card--one {
    display: none;
  }
  .product--layout--full .product__card--two {
    display: block;
  }
  .product--layout--full .product__header {
    background-color: #fff;
    padding-top: 24px;
    padding-bottom: 22px;
  }
  [dir=ltr] .product--layout--full .product__header {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__header {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--full .product__title {
    font-size: 22px;
    line-height: 28px;
  }
  .product--layout--full .product__subtitle {
    padding-top: 8px;
  }
  .product--layout--full .product__body {
    width: 100%;
  }
  .product--layout--full .product__gallery {
    background-color: #fff;
    --product-gallery-image-max-width: 360px;
    width: 100%;
  }
  .product--layout--full .product__main,
  .product--layout--full .product__card--two {
    margin-top: var(--product-gutter);
  }
  .product--layout--full .product__main {
    padding-top: 24px;
    padding-bottom: 18px;
  }
  [dir=ltr] .product--layout--full .product__main {
    padding-left: 24px;
    padding-right: 24px;
  }
  [dir=rtl] .product--layout--full .product__main {
    padding-right: 24px;
    padding-left: 24px;
  }
  .product--layout--full .product__excerpt {
    padding-bottom: 0;
  }
  .product--layout--full .product__info {
    width: 100%;
  }
  [dir=ltr] .product--layout--full .product__info {
    margin-left: 0;
  }
  [dir=rtl] .product--layout--full .product__info {
    margin-right: 0;
  }
  .product--layout--full .product__info-card {
    margin-top: 0;
    padding-top: 0;
  }
  .product--layout--full .product__actions {
    margin-top: -12px;
    margin-bottom: -6px;
  }
  .product--layout--full .product__actions-item--quantity,
  .product--layout--full .product__actions-item--addtocart {
    margin-top: 10px;
  }
  .product--layout--full .product__actions-item--addtocart {
    flex-grow: 0;
  }
  .product--layout--full .product__actions-divider {
    height: 10px;
  }
  .product--layout--full .product__actions-item--wishlist,
  .product--layout--full .product__actions-item--compare {
    width: auto;
    margin-top: 2px;
    margin-bottom: -2px;
  }
}

.product-applicability-brand .product-applicability-brand__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #E3E3E3;
  transition: background-color 0.4s ease-in-out;
}
.product-applicability-brand .product-applicability-brand__title span {
  font-size: clamp(16px, 0.83vw, 18px);
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.4s ease-in-out;
}
.product-applicability-brand .product-applicability-brand__title svg {
  width: 21px;
  height: 12px;
  transition: all 0.4s ease-in-out;
  fill: #E3E3E3;
}
.product-applicability-brand .product-applicability-brand__title.open {
  background-color: #F28B00;
  border-color: #F28B00;
}
.product-applicability-brand .product-applicability-brand__title.open span {
  color: #FFF;
}
.product-applicability-brand .product-applicability-brand__title.open svg {
  fill: #FFF;
  transform: rotate(180deg);
}

.product-bom-item__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #E3E3E3;
  transition: background-color 0.4s ease-in-out;
}
.product-bom-item__title span {
  font-size: clamp(16px, 0.83vw, 18px);
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.4s ease-in-out;
}
.product-bom-item__title svg {
  width: 21px;
  height: 12px;
  transition: all 0.4s ease-in-out;
  fill: #E3E3E3;
}
.product-bom-item__title.open {
  background-color: #F28B00;
  border-color: #F28B00;
}
.product-bom-item__title.open span {
  color: #FFF;
}
.product-bom-item__title.open svg {
  fill: #FFF;
  transform: rotate(180deg);
}
.product-bom-item__content {
  display: flex;
  gap: 1rem;
  align-items: stretch;
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;
}
.product-bom-item__groups {
  flex: 1;
}
.product-bom-item__groups--wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 1rem;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .product-bom-item__groups--wrapper {
    position: sticky;
    top: 200px;
  }
}
.product-bom-item__products {
  flex: 3;
  display: flex;
  flex-direction: column;
}
.product-bom-item__chip {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  font-weight: 500;
  border: 1px solid #F28B00;
  border-radius: 20px;
  width: min-content;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}
.product-bom-item__chip.active {
  background-color: #F28B00;
  color: #FFF;
}
.product-bom-item__product .product-card {
  padding: 10px 24px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}
.product-bom-item__product .product-card .product-card__addtocart-icon,
.product-bom-item__product .product-card .product-card__header,
.product-bom-item__product .product-card .product-card__action--wishlist,
.product-bom-item__product .product-card .product-card__action--compare {
  display: none;
}
.product-bom-item__product .product-card .image__body {
  padding-bottom: 0;
}
.product-bom-item__product .product-card .product-card__available_not_info {
  margin-bottom: -4px;
  width: 100%;
}
.product-bom-item__product .product-card .product-card__content {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.product-bom-item__product .product-card .product-card__vd {
  display: block;
  align-self: stretch;
  border-left: 1px solid rgb(36, 36, 36);
  opacity: 0.1;
  margin: 0 10px;
}
.product-bom-item__product .product-card .product-card-properties {
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: flex-start;
  width: 100%;
  margin: -5px 5px 5px;
  overflow: hidden;
}
.product-bom-item__product .product-card .product-properties {
  max-width: 60%;
}
.product-bom-item__product .product-card .complex-pictogram {
  height: 40px;
}
.product-bom-item__product .product-card .complex-pictogram .complex-pictogram__item-value {
  bottom: -20px;
}
.product-bom-item__product .product-card .product-card__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  width: 100%;
  max-width: 245px;
  margin: auto 10px;
  align-items: flex-start;
}
@media (max-width: 767.98px) {
  .product-bom-item__product .product-card .product-card__info {
    min-width: 220px;
  }
}
.product-bom-item__product .product-card .product-card__info-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.product-bom-item__product .product-card .product-card__info-row .product-card__info-name {
  margin-left: 6px;
}
.product-bom-item__product .product-card .product-card__info-row .product-card__info-amount {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-bom-item__product .product-card .product-card__info-row .product-card__info-amount svg {
  height: 20px;
  width: 20px;
  transition: transform 0.3s ease;
}
.product-bom-item__product .product-card .product-card__info-row .product-card__info-amount:hover svg, .product-bom-item__product .product-card .product-card__info-row .product-card__info-amount:focus-within svg {
  transform: scale(1.1);
}
.product-bom-item__product .product-card .product-card__info-row .product-card__available-group {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-wrap: wrap;
  margin-bottom: 5px;
  white-space: normal;
}
.product-bom-item__product .product-card .product-card__info-row .product-card__available-group:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.product-bom-item__product .product-card .product-card__general-name {
  display: block;
}
.product-bom-item__product .product-card .product-card__general-name .product-card__name {
  padding-right: 32px;
}
.product-bom-item__product .product-card .product-card__general-name .product-card__name--bom-button svg {
  width: 32px;
}
.product-bom-item__product .product-card .buy-button {
  width: 100%;
}
.product-bom-item__product .product-card .product-card__image {
  flex-shrink: 0;
  height: clamp(8.4375rem, 9.375vw, 11.25rem);
}
.product-bom-item__product .product-card .product-card__image .image__tag {
  height: clamp(8.4375rem, 9.375vw, 11.25rem);
}
.product-bom-item__product .product-card .product-card__badges {
  position: absolute;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
.product-bom-item__product .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.product-bom-item__product .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__actions-list {
  left: 0;
}
.product-bom-item__product .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.product-bom-item__product .product-card .product-card__rating-stars,
.product-bom-item__product .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.product-bom-item__product .product-card .product-card__rating-label {
  line-height: 1;
}
.product-bom-item__product .product-card .product-card__fit .status-badge__text {
  display: block;
}
.product-bom-item__product .product-card .product-card__fit .status-badge__tooltip {
  display: none;
}
.product-bom-item__product .product-card .product-card__labels {
  order: 1;
  padding: 0;
  margin: 5px 0;
}
.product-bom-item__product .product-card .shared-article__text {
  font-size: clamp(14px, 0.83vw, 16px);
}
.product-bom-item__product .product-card .product-card__title {
  font-size: clamp(14px, 0.83vw, 16px);
  padding: 0;
  margin: 5px 0;
}
.product-bom-item__product .product-card .product-card__general {
  width: 100%;
  max-width: 286px;
}
.product-bom-item__product .product-card .product-card__price--wrapper {
  min-height: 40px;
}
.product-bom-item__product .product-card .product-card__price--old {
  order: 1;
}
.product-bom-item__product .product-card .product-card__image {
  padding: 0;
  width: 200px;
}
.product-bom-item__product .product-card .product-card__badges {
  top: 16px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__badges {
  left: 16px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__badges {
  right: 16px;
}
.product-bom-item__product .product-card .product-card__fit {
  position: absolute;
  bottom: 24px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__fit {
  left: 198px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__fit {
  right: 198px;
}
.product-bom-item__product .product-card .product-card__meta {
  position: absolute;
  bottom: 0;
  padding: 0;
  height: 38px;
  line-height: 36px;
  border-top: 1px solid #ebebeb;
  text-align: center;
  width: 180px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__meta {
  right: 20px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__meta {
  left: 20px;
}
.product-bom-item__product .product-card .product-card__features {
  font-size: 14px;
  padding-top: 10px;
}
.product-bom-item__product .product-card .product-card__features li + li {
  margin-top: 2px;
}
.product-bom-item__product .product-card .product-card__footer {
  width: 190px;
  align-items: stretch;
  display: flex;
  order: 2;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0;
}
.product-bom-item__product .product-card .product-card__prices {
  padding: 8px 0;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: stretch;
}
.product-bom-item__product .product-card .product-card__addtocart-full {
  display: block;
  height: 36px;
  padding: 0 20px;
  width: 100%;
  margin-bottom: 10px;
}
.product-bom-item__product .product-card .product-card__wishlist,
.product-bom-item__product .product-card .product-card__compare {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px 5px;
}
.product-bom-item__product .product-card .product-card__wishlist svg,
.product-bom-item__product .product-card .product-card__compare svg {
  position: relative;
  top: -1px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__wishlist svg,
[dir=ltr] .product-bom-item__product .product-card .product-card__compare svg {
  margin-right: 8px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__wishlist svg,
[dir=rtl] .product-bom-item__product .product-card .product-card__compare svg {
  margin-left: 8px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__wishlist svg {
  margin-left: -3px;
  margin-right: 7px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__wishlist svg {
  margin-right: -3px;
  margin-left: 7px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__compare svg {
  margin-left: -2px;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__compare svg {
  margin-right: -2px;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__wishlist--loading:after {
  left: 7px;
  right: auto;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__wishlist--loading:after {
  right: 7px;
  left: auto;
}
[dir=ltr] .product-bom-item__product .product-card .product-card__compare--loading:after {
  left: 8px;
  right: auto;
}
[dir=rtl] .product-bom-item__product .product-card .product-card__compare--loading:after {
  right: 8px;
  left: auto;
}
@media (max-width: 1399.98px) {
  .product-bom-item__product .product-card {
    padding: 10px;
  }
  .product-bom-item__product .product-card .product-card__image {
    height: clamp(4.6875rem, 5.2083333333vw, 6.25rem);
    width: 140px;
  }
  .product-bom-item__product .product-card .product-card__image .image__tag {
    height: clamp(4.6875rem, 5.2083333333vw, 6.25rem);
  }
  .product-bom-item__product .product-card .product-card__footer {
    width: 120px;
  }
  .product-bom-item__product .product-card .product-card__info {
    max-width: 160px;
  }
  .product-bom-item__product .product-card .product-card__general {
    margin-left: 10px;
  }
}
@media (max-width: 767.98px) {
  .product-bom-item__product .product-card {
    padding: 10px;
  }
  .product-bom-item__product .product-card .product-card__image {
    min-height: unset;
    height: clamp(2.34375rem, 2.6041666667vw, 3.125rem);
    width: 60px;
  }
  .product-bom-item__product .product-card .product-card__image .image__tag {
    height: clamp(2.34375rem, 2.6041666667vw, 3.125rem);
    min-height: unset;
  }
  .product-bom-item__product .product-card .product-card__footer {
    width: auto;
    align-items: flex-end;
  }
  .product-bom-item__product .product-card .product-card__vd,
  .product-bom-item__product .product-card .product-card-properties,
  .product-bom-item__product .product-card .product-card__name--bom-button,
  .product-bom-item__product .product-card .product-card__info {
    display: none;
  }
  .product-bom-item__product .product-card .product-label {
    min-height: unset;
  }
  .product-bom-item__product .product-card .product-card__name {
    padding: 0;
  }
  .product-bom-item__product .product-card .product-card__labels {
    margin: 0 5px 0 0;
  }
  .product-bom-item__product .product-card .product-card__general {
    max-width: unset;
    margin: 0 14px;
  }
  .product-bom-item__product .product-card .product-card__title {
    margin: 0;
  }
  .product-bom-item__product .product-card .shared-article__text {
    font-size: clamp(14px, 0.83vw, 16px) !important;
  }
  .product-bom-item__product .product-card .product-card__prices {
    padding: 0;
    margin-bottom: 5px;
  }
  .product-bom-item__product .product-card .product-card__price--wrapper {
    min-height: unset;
  }
  .product-bom-item__product .product-card .buy-button {
    width: 50px;
  }
  .product-bom-item__product .product-card .buy-button .btn span {
    display: none;
  }
  .product-bom-item__product .product-card .owl-nav {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .product-bom-item__content {
    flex-direction: column;
    align-items: unset;
  }
  .product-bom-item__groups {
    flex: 0;
  }
  .product-bom-item__products {
    flex: 0;
  }
}
/*
// .product-card
*/
.product-card {
  display: block;
  box-shadow: 0 0 0 1px rgba(227, 227, 227, 0.6);
  background-color: #fff;
}
.product-card.product-card--out-of-stock .product-card__image::before {
  position: absolute;
  content: "";
  width: calc(100% - 2px);
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.8);
}
.product-card:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
  z-index: 3;
  transition: box-shadow 0.3s ease-in-out;
}
.product-card .product-card__title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  height: 3em; /* 1.5 (line-height) × 2 строки = 3em */
  white-space: normal;
  margin: 15px 0;
  color: inherit;
  font-size: 18px;
  font-weight: 500;
  padding-right: 16px;
  padding-left: 16px;
}
.product-card .product-card__title:hover {
  color: #F28B00;
}
.product-card .product-card__labels {
  margin: 10px 0;
  padding-right: 16px;
  padding-left: 16px;
}
.product-card .product-card__available_not_info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-size: clamp(11px, 0.68vw, 13px);
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px;
}
.product-card .product-card__general-name {
  display: none;
}
.product-card .product-card_vd {
  display: none;
}
.product-card .product-card__action {
  position: relative;
  display: block;
  padding: 8px;
  margin: 0;
  border: none;
  transition: color 0.08s, background 0.08s;
  background-color: #fff;
  color: #ccc;
}
.product-card .product-card__action:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.product-card .product-card__action:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
.product-card .product-card__action svg {
  fill: currentColor;
  display: block;
}
.product-card .product-card__action:focus {
  outline: none;
}
.product-card .product-card__action:active {
  transition-duration: 0s;
}
[dir=ltr] .product-card .product-card__action:last-child {
  border-bottom-left-radius: 2px;
}
[dir=rtl] .product-card .product-card__action:last-child {
  border-bottom-right-radius: 2px;
}
.product-card .product-card__action--loading, .product-card .product-card__action--loading:hover {
  cursor: default;
  background: transparent;
  color: transparent;
  transition-duration: 0s;
}
.product-card .product-card__action--loading:before {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__image {
  display: block;
  height: clamp(8.90625rem, 9.8958333333vw, 11.875rem);
  min-height: 100px;
  position: relative;
}
.product-card .product-card__image .image__tag {
  height: clamp(8.90625rem, 9.8958333333vw, 11.875rem);
  min-height: 100px;
}
.product-card .product-card__rating {
  color: #6c757d;
  font-size: 0.8125rem;
}
.product-card .product-card__features {
  color: #6c757d;
  font-size: 13px;
  line-height: 21px;
}
.product-card .product-card__features ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.product-card .product-card__features li {
  position: relative;
}
[dir=ltr] .product-card .product-card__features li {
  padding-left: 12px;
}
[dir=rtl] .product-card .product-card__features li {
  padding-right: 12px;
}
.product-card .product-card__features li:before {
  display: block;
  position: absolute;
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: currentColor;
  top: 9px;
  opacity: 0.7;
}
[dir=ltr] .product-card .product-card__features li:before {
  left: 3px;
}
[dir=rtl] .product-card .product-card__features li:before {
  right: 3px;
}
.product-card .product-card__info-name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: clamp(14px, 0.83vw, 16px);
  line-height: 160%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.product-card .product-card__info-title {
  font-weight: 400;
  font-size: clamp(14px, 0.83vw, 16px);
  line-height: 160%;
  opacity: 0.7;
}
.product-card .product-card__meta {
  font-size: 12px;
  line-height: 1;
  color: #999;
}
.product-card .product-card__prices {
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1;
  color: #242424;
}
.product-card .product-card__price--new {
  color: #F84147;
}
.product-card .product-card__price--old {
  font-weight: 400;
  font-size: 14px;
  text-decoration: line-through;
  color: #999;
}
.product-card .product-card__addtocart-full {
  position: relative;
  background: #F28B00;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  transition: background-color 0.15s, color 0.15s;
}
.product-card .product-card__addtocart-full:focus {
  outline: none;
}
.product-card .product-card__addtocart-full:hover {
  background: #333;
  color: #fff;
}
.product-card .product-card__addtocart-full:active {
  background: #4d4d4d;
  color: #fff;
}
.product-card .product-card__addtocart-full.product-card__addtocart-full--loading {
  background: #F28B00;
  color: transparent;
  cursor: default;
}
.product-card .product-card__addtocart-full.product-card__addtocart-full--loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__wishlist,
.product-card .product-card__compare {
  position: relative;
  border: none;
  background: transparent;
  color: #242424;
  fill: #d9d9d9;
  border-radius: 2px;
  transition: background 0.15s, fill 0.15s;
}
.product-card .product-card__wishlist:focus,
.product-card .product-card__compare:focus {
  outline: none;
}
.product-card .product-card__wishlist:hover,
.product-card .product-card__compare:hover {
  background: #f5f5f5;
  fill: #bfbfbf;
}
.product-card .product-card__wishlist:active,
.product-card .product-card__compare:active {
  background: #f0f0f0;
  fill: #b3b3b3;
}
.product-card .product-card__wishlist.product-card__wishlist--loading,
.product-card .product-card__compare.product-card__compare--loading {
  background: transparent;
  fill: transparent;
  cursor: default;
}
.product-card .product-card__wishlist--loading:after,
.product-card .product-card__compare--loading:after {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__buy-button {
  width: 50%;
  border-radius: unset;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 700;
  padding-top: clamp(0.703125rem, 0.78125vw, 0.9375rem);
  padding-bottom: clamp(0.703125rem, 0.78125vw, 0.9375rem);
}
.product-card .product-card__buy-button .product-card__buy-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.product-card .product-card__buy-button .product-card__buy-button-content svg {
  height: auto;
  width: clamp(0.890625rem, 0.9895833333vw, 1.1875rem);
}
.product-card .product-card__header {
  padding-top: 15px;
  padding-left: 16px;
  padding-right: 16px;
}
.product-card .product-card__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 36px;
  position: relative;
  padding-right: 40px;
}
.product-card .product-card__name .product-card__name--okt .shared-article__text {
  font-size: 18px;
}
.product-card .product-card__name .product-card__name--bom {
  position: absolute;
  right: 0;
  z-index: 3;
}
.product-card .product-card__name .product-card__name--bom-button {
  border: none;
  background-color: transparent;
  padding: 0;
}
.product-card .product-card__name .product-card__name--bom-button svg {
  fill: #8E8E8E;
  height: auto;
  width: clamp(2.296875rem, 2.5520833333vw, 3.0625rem);
  transition: fill 0.2s;
}
.product-card .product-card__name .product-card__name--bom-button:hover svg {
  fill: #F28B00;
}
.product-card .product-card__addtocart-icon {
  position: relative;
  border: none;
  padding: 8px;
  border-radius: 2px;
  background: #F28B00;
  color: #fff;
  z-index: 0;
  transition: color 0.2s;
}
.product-card .product-card__addtocart-icon svg {
  display: block;
  fill: currentColor;
}
.product-card .product-card__addtocart-icon:before {
  top: -2px;
  left: -2px;
  display: block;
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: transparent;
  z-index: -1;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.2s, background 0.2s;
}
.product-card .product-card__addtocart-icon:focus {
  outline: none;
}
.product-card:hover .product-card__addtocart-icon, .product-card:active .product-card__addtocart-icon {
  color: #fff;
}
.product-card:hover .product-card__addtocart-icon:before, .product-card:active .product-card__addtocart-icon:before {
  transform: scale(1);
  background: #F28B00;
}
.product-card:hover .product-card__addtocart-icon:hover, .product-card:active .product-card__addtocart-icon:hover {
  color: #fff;
}
.product-card:hover .product-card__addtocart-icon:hover:before, .product-card:active .product-card__addtocart-icon:hover:before {
  background: #333;
}
.product-card:hover .product-card__addtocart-icon:active, .product-card:active .product-card__addtocart-icon:active {
  color: #fff;
}
.product-card:hover .product-card__addtocart-icon:active:before, .product-card:active .product-card__addtocart-icon:active:before {
  transition-duration: 0.05s;
  background: #4d4d4d;
}
.product-card .product-card__addtocart-icon--loading {
  cursor: default;
}
.product-card .product-card__addtocart-icon--loading:after {
  left: calc(50% - 11px);
  top: calc(50% - 11px);
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.product-card .product-card__addtocart-icon--loading, .product-card .product-card__addtocart-icon--loading:hover, .product-card .product-card__addtocart-icon--loading:active, .product-card:hover .product-card__addtocart-icon--loading, .product-card:hover .product-card__addtocart-icon--loading:hover, .product-card:hover .product-card__addtocart-icon--loading:active, .product-card:active .product-card__addtocart-icon--loading, .product-card:active .product-card__addtocart-icon--loading:hover, .product-card:active .product-card__addtocart-icon--loading:active {
  color: transparent;
}
.product-card .product-card__addtocart-icon--loading:before, .product-card .product-card__addtocart-icon--loading:hover:before, .product-card .product-card__addtocart-icon--loading:active:before, .product-card:hover .product-card__addtocart-icon--loading:before, .product-card:hover .product-card__addtocart-icon--loading:hover:before, .product-card:hover .product-card__addtocart-icon--loading:active:before, .product-card:active .product-card__addtocart-icon--loading:before, .product-card:active .product-card__addtocart-icon--loading:hover:before, .product-card:active .product-card__addtocart-icon--loading:active:before {
  transform: scale(1);
  background: #F28B00;
}
.product-card .product-card__price--wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-card .product-card__price--wrapper .product-card__price--new,
.product-card .product-card__price--wrapper .product-card__price--current {
  font-size: clamp(1.03125rem, 1.1458333333vw, 1.375rem);
  font-weight: 700;
}
.product-card .product-card__general {
  display: flex;
  flex-direction: column;
}
.product-card .product-card__stocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  right: 0;
  bottom: 120%;
  padding: 10px;
  border-radius: 4px;
  color: #FFF;
  background-color: #a9a9a9;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 500;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}
.product-card .product-card__stocks.visible {
  opacity: 1;
  visibility: visible;
}

.product-card--layout--grid {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 400px;
}
.product-card--layout--grid .product-card__addtocart-full,
.product-card--layout--grid .product-card__wishlist,
.product-card--layout--grid .product-card__compare {
  display: none;
}
.product-card--layout--grid:after {
  display: block;
  position: static;
  content: "";
  order: 1;
  flex-grow: 1;
}
.product-card--layout--grid .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .product-card--layout--grid .product-card__badges {
  left: 16px;
}
[dir=rtl] .product-card--layout--grid .product-card__badges {
  right: 16px;
}
.product-card--layout--grid .product-card__badges > * + * {
  margin-top: 3px;
}
.product-card--layout--grid .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .product-card--layout--grid .product-card__actions-list {
  right: 0;
}
[dir=rtl] .product-card--layout--grid .product-card__actions-list {
  left: 0;
}
.product-card--layout--grid .product-card__image {
  display: block;
  position: relative;
}
.product-card--layout--grid .product-card__image .image__body {
  padding-bottom: unset;
}
.product-card--layout--grid .product-card__fit {
  position: absolute;
  bottom: 8px;
}
.product-card--layout--grid .product-card__fit .status-badge__text {
  display: none;
}
.product-card--layout--grid .product-card__fit .status-badge__tooltip {
  display: block;
}
[dir=ltr] .product-card--layout--grid .product-card__fit {
  left: calc(16px - 3px);
}
[dir=rtl] .product-card--layout--grid .product-card__fit {
  right: calc(16px - 3px);
}
.product-card--layout--grid .product-card__meta {
  padding: 6px 16px;
}
.product-card--layout--grid .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--grid .product-card__rating-stars,
.product-card--layout--grid .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-card--layout--grid .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .product-card--layout--grid .product-card__rating-stars {
  margin-left: 7px;
}
.product-card--layout--grid .product-card__rating-label {
  line-height: 1;
}
.product-card--layout--grid .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}
.product-card--layout--grid .product-card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.product-card--layout--grid .product-card__stocks {
  width: 100%;
}
.product-card--layout--grid .product-card__footer {
  padding: 16px;
  align-items: flex-end;
  display: flex;
  order: 2;
}
@media (max-width: 767.98px) {
  .product-card--layout--grid .product-card__footer {
    padding: 10px;
  }
}
.product-card--layout--grid .product-card__prices {
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.product-card--layout--grid .product-card__addtocart-icon {
  margin: -3px;
}
@media (hover: hover) {
  .product-card--layout--grid .product-card__action--wishlist,
  .product-card--layout--grid .product-card__action--compare {
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    opacity: 0;
    will-change: transform;
  }
  [dir=ltr] .product-card--layout--grid .product-card__action--wishlist,
  [dir=ltr] .product-card--layout--grid .product-card__action--compare {
    transform: translateX(100%) translateX(6px);
  }
  [dir=rtl] .product-card--layout--grid .product-card__action--wishlist,
  [dir=rtl] .product-card--layout--grid .product-card__action--compare {
    transform: translateX(-100%) translateX(6px);
  }
  .product-card--layout--grid:hover .product-card__action--wishlist,
  .product-card--layout--grid:hover .product-card__action--compare, .product-card--layout--grid:active .product-card__action--wishlist,
  .product-card--layout--grid:active .product-card__action--compare {
    transform: none;
    opacity: 1;
  }
}
.product-card--layout--grid .product-card-properties {
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  margin-bottom: 0;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
  overflow: hidden;
}
.product-card--layout--grid .product-card__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}
.product-card--layout--grid .product-card__info-row {
  display: flex;
  gap: 10px;
  cursor: default !important;
}
.product-card--layout--grid .product-card__info-row .product-card__info-amount {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: help;
}
.product-card--layout--grid .product-card__info-row .product-card__info-amount svg {
  transition: transform 0.3s ease;
  width: 20px;
  height: 20px;
}
.product-card--layout--grid .product-card__info-row .product-card__info-amount:hover svg, .product-card--layout--grid .product-card__info-row .product-card__info-amount:focus-within svg {
  transform: scale(1.1);
}
.product-card--layout--grid .product-card__info-row .product-card__available-group {
  display: flex;
  justify-content: space-between;
  text-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 4px;
  white-space: normal;
}
.product-card--layout--grid .product-card__info-row .product-card__available-group:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.product-card--layout--grid .buy-button {
  width: unset;
}
.product-card--layout--grid .buy-button .buy-button__btn {
  padding-left: 20px;
  padding-right: 20px;
}
.product-card--layout--grid .buy-button .buy-button__btn .buy-button__content > span {
  display: none;
}
.product-card--layout--grid .buy-button .buy-button__btn svg {
  width: 30px;
}
.product-card--layout--grid .product-card__replace {
  position: absolute;
  top: 48px;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  padding: 2px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .product-card--layout--grid:hover {
    box-shadow: 0 0 0 1px rgba(227, 227, 227, 0.6);
  }
  .product-card--layout--grid .product-card__title {
    font-size: 12px;
    margin: 5px 0;
  }
  .product-card--layout--grid .product-card__labels {
    margin: 1px 0;
  }
  .product-card--layout--grid .product-card-properties {
    margin-top: -8px;
    margin-bottom: 0;
  }
  .product-card--layout--grid .complex-pictogram {
    scale: 0.6;
  }
  .product-card--layout--grid .product-card__info-title,
  .product-card--layout--grid .product-card__info-name,
  .product-card--layout--grid .shared-article__text,
  .product-card--layout--grid .product-card__available-group,
  .product-card--layout--grid .product-property__item-name,
  .product-card--layout--grid .product-property__item-value {
    font-size: 10px !important;
  }
  .product-card--layout--grid .product-card__image {
    height: clamp(5.625rem, 6.25vw, 7.5rem);
    min-height: 100px;
    position: relative;
    margin: 0.8rem 0;
  }
  .product-card--layout--grid .product-card__image .image__tag {
    height: clamp(5.625rem, 6.25vw, 7.5rem);
    min-height: 100px;
  }
  .product-card--layout--grid .product-card__name {
    padding-right: 28px;
  }
  .product-card--layout--grid .product-card__name--bom-button svg {
    width: 28px;
    height: auto;
  }
  .product-card--layout--grid .product-card__info-name mat-icon {
    display: none;
  }
  .product-card--layout--grid .product-card__info-row {
    align-items: center;
  }
  .product-card--layout--grid .product-card__replace {
    top: 34px;
  }
}

.product-card--layout--horizontal {
  position: relative;
  display: flex;
}
.product-card--layout--horizontal .product-card__action--wishlist,
.product-card--layout--horizontal .product-card__action--compare,
.product-card--layout--horizontal .product-card__features,
.product-card--layout--horizontal .product-card__addtocart-icon,
.product-card--layout--horizontal .product-card__addtocart-full,
.product-card--layout--horizontal .product-card__wishlist,
.product-card--layout--horizontal .product-card__compare,
.product-card--layout--horizontal .product-card__meta,
.product-card--layout--horizontal .product-card__fit {
  display: none;
}
.product-card--layout--horizontal .product-card__image {
  padding: 8px 10px;
  width: 112px;
  flex-shrink: 0;
}
.product-card--layout--horizontal .product-card__badges {
  position: absolute;
  top: 10px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .product-card--layout--horizontal .product-card__badges {
  left: 10px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__badges {
  right: 10px;
}
.product-card--layout--horizontal .product-card__badges > * + * {
  margin-top: 3px;
}
.product-card--layout--horizontal .tag-badge {
  height: 16px;
  padding: 3px 11px 0;
}
.product-card--layout--horizontal .tag-badge:before {
  left: 3px;
  right: 3px;
}
[dir=ltr] .product-card--layout--horizontal .tag-badge:before {
  transform: skewX(-20deg);
}
[dir=rtl] .product-card--layout--horizontal .tag-badge:before {
  transform: skewX(20deg);
}
.product-card--layout--horizontal .product-card__actions-list {
  position: absolute;
}
[dir=ltr] .product-card--layout--horizontal .product-card__actions-list {
  right: 0;
}
[dir=rtl] .product-card--layout--horizontal .product-card__actions-list {
  left: 0;
}
.product-card--layout--horizontal .product-card__rating {
  margin: -3px 0 -2px 0;
  padding-top: 7px;
  display: flex;
  flex-wrap: wrap;
}
.product-card--layout--horizontal .product-card__rating-stars,
.product-card--layout--horizontal .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .product-card--layout--horizontal .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__rating-stars {
  margin-left: 7px;
}
.product-card--layout--horizontal .product-card__rating-label {
  line-height: 1;
}
.product-card--layout--horizontal .product-card__prices {
  position: absolute;
  bottom: 14px;
  flex-grow: 1;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
[dir=ltr] .product-card--layout--horizontal .product-card__prices {
  left: 112px;
}
[dir=rtl] .product-card--layout--horizontal .product-card__prices {
  right: 112px;
}

/*
// .product-form
*/
.product-form {
  display: block;
}

.product-form__row + .product-form__row {
  margin-top: 12px;
}

.product-form__title {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: #6c7177;
  margin-bottom: 4px;
}

/*
// .product-gallery
*/
.product-gallery__wrapper {
  display: flex;
  justify-content: space-between;
  max-height: 600px;
}
.product-gallery__wrapper .product-gallery__thumbnails {
  width: 68px;
  position: relative;
}
.product-gallery__wrapper .product-gallery__thumbnails .slick-slider {
  margin: 34px 0;
}
.product-gallery__wrapper .product-gallery__featured {
  height: auto;
  width: calc(100% - 78px);
  margin-left: 10px;
}
.product-gallery__wrapper .product-gallery__thumbnails--nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.product-gallery__wrapper .product-gallery__thumbnails--nav.up {
  top: 0;
}
.product-gallery__wrapper .product-gallery__thumbnails--nav.up svg {
  transform: rotate(-90deg);
}
.product-gallery__wrapper .product-gallery__thumbnails--nav.down {
  bottom: 0;
}
.product-gallery__wrapper .product-gallery__thumbnails--nav.down svg {
  transform: rotate(90deg);
}
.product-gallery__wrapper .product-gallery__thumbnails--nav svg {
  fill: rgba(169, 169, 169, 0.3);
}
@media (min-width: 1400px) {
  .product-gallery__wrapper .product-gallery__thumbnails {
    width: 88px;
  }
  .product-gallery__wrapper .product-gallery__featured {
    width: calc(100% - 98px);
  }
}
@media (max-width: 991.98px) {
  .product-gallery__wrapper .product-gallery__thumbnails {
    display: none;
  }
  .product-gallery__wrapper .product-gallery__featured {
    width: 100%;
    margin-left: 0;
  }
}

.product-gallery__featured {
  position: relative;
  border: 1px solid #E3E3E3;
}
.product-gallery__featured .owl-dots {
  position: absolute;
  bottom: 26px;
  width: 100%;
  display: none;
  justify-content: center;
}
.product-gallery__featured .owl-dot.active span {
  background: #F28B00;
  transform: scale(1.3);
}
.product-gallery__featured .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 5px;
  background: #242424;
  display: block;
  -webkit-backface-visibility: visible;
  transition: 0.3s ease;
  border-radius: 50%;
}
.product-gallery__featured .owl-carousel a {
  display: block;
}
.product-gallery__featured .image {
  max-width: 420px;
  margin: 0 auto;
}

.product-gallery__zoom {
  display: var(--product-gallery-zoom-display, block);
  top: 0;
  position: absolute;
  padding: 9px;
  margin: 0;
  border: none;
  border-radius: 21px;
  fill: currentColor;
  z-index: 2;
  transition: background 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.product-gallery__zoom:hover {
  background-color: #f2f2f2;
  color: #999;
}
.product-gallery__zoom:active {
  background-color: #ebebeb;
  color: #808080;
}
[dir=ltr] .product-gallery__zoom {
  right: -21px;
}
[dir=rtl] .product-gallery__zoom {
  left: -21px;
}
.product-gallery__zoom:focus {
  outline: none;
}
.product-gallery__zoom svg {
  display: block;
}

.product-gallery__thumbnails-item {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 1.5px;
  padding: 2px;
  cursor: pointer;
}
.product-gallery__thumbnails-item:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 2px #f2f2f2 inset;
  transition: box-shadow 0.12s;
}
[dir=ltr] .product-gallery__thumbnails-item:before {
  left: 0;
}
[dir=rtl] .product-gallery__thumbnails-item:before {
  right: 0;
}
.product-gallery__thumbnails-item:hover:before {
  box-shadow: 0 0 0 2px #e0e0e0 inset;
}

.product-gallery__thumbnails-item--active {
  cursor: default;
}
.product-gallery__thumbnails-item--active:before, .product-gallery__thumbnails-item--active:hover:before {
  box-shadow: 0 0 0 2px #F28B00 inset;
}

.product-gallery--layout--quickview .product-gallery__featured .image {
  width: 320px;
  max-width: 100%;
}
.product-gallery--layout--quickview .product-gallery__zoom {
  display: none;
}

.product-gallery--layout--product-sidebar .product-gallery__featured .image {
  max-width: var(--product-gallery-image-max-width, 100%);
}

.product-gallery--layout--product-full .product-gallery__featured .image {
  max-width: var(--product-gallery-image-max-width, 100%);
}
.product-gallery--layout--product-full .product-gallery__zoom {
  top: 0;
}
[dir=ltr] .product-gallery--layout--product-full .product-gallery__zoom {
  right: 0;
}
[dir=rtl] .product-gallery--layout--product-full .product-gallery__zoom {
  left: 0;
}

@media (max-width: 991.98px) {
  .product-gallery__featured .owl-dots {
    display: flex;
  }
  .product-gallery__thumbnails {
    display: none;
  }
}
.product-option {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
  justify-content: center;
}

.product-option__item {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: #F7F9FE;
  padding: 5px 10px;
}

.product-option__icon {
  display: flex;
  color: #F28B00;
}

.product-option__label {
  color: #F28B00;
}

.product-page__body {
  display: flex;
  padding: 2rem 0;
  margin-bottom: 30px;
}
@media (max-width: 1199.98px) {
  .product-page__body {
    padding: 1rem 0;
  }
}

.product-page--hide {
  display: none;
}

.product-page__section h1,
.product-page__section-product h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: clamp(1.40625rem, 1.5625vw, 1.875rem);
}
.product-page__section .product-page__section-head,
.product-page__section-product .product-page__section-head {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.product-page__section-product {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.product-page__section-product .product-buy-block {
  display: grid;
  grid-template-columns: auto 1fr 1.3fr;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 0;
}
.product-page__section-product .product-buy-block .input-number {
  margin-left: auto;
}
@media (max-width: 1199.98px) {
  .product-page__section-product .product-buy-block {
    grid-template-columns: 1fr 1fr;
  }
  .product-page__section-product .product-buy-block .buy-button {
    grid-column: 1/span 2;
    width: auto;
    max-width: 100%;
  }
}
.product-page__section-product .product-buy-block__price {
  font-size: 1.4rem;
  font-weight: 700;
  white-space: nowrap;
}
.product-page__section-product .product-buy-block__price .product-card__prices {
  position: absolute;
  bottom: 14px;
  flex-grow: 1;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.product-page__section-product .product-buy-block__price .product-card__price--old {
  font-size: 14px;
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}
.product-page__section-product .product-buy-block__price .product-card__price--new {
  color: #F84147;
}
.product-page__section-product .input-number {
  flex: 1;
  height: 50px;
  max-width: 180px;
  min-width: 100px;
}
.product-page__section-product .input-number__input {
  height: 100%;
}
.product-page__section-product .buy-button {
  flex: 1;
  width: 100%;
}

.product-page__section table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #E3E3E3;
}
.product-page__section table tr:nth-child(even) {
  background-color: rgba(3, 66, 229, 0.03);
}
.product-page__section table td {
  color: #646464;
  font-size: clamp(11px, 0.68vw, 13px);
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #E3E3E3;
}
.product-page__section table td:first-child {
  padding-left: 10px;
}
.product-page__section table td:last-child {
  font-weight: 600;
}
.product-page__section .specification-table td:first-child {
  text-align: left;
  width: 20%;
}
.product-page__section .specification-table td:last-child {
  text-align: left;
  width: 30%;
}
.product-page__section .product-page__gallery {
  position: relative;
  max-width: 580px;
}
.product-page__section .product-page__brand-description h2 {
  font-size: clamp(1.171875rem, 1.3020833333vw, 1.5625rem);
  font-weight: 700;
}
.product-page__section .product-page__brand-description .description {
  font-size: clamp(11px, 0.68vw, 13px);
  color: #646464;
  text-align: justify;
}
.product-page__section .product-page__brand-description .product-page__brand-description-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
}
.product-page__section .product-page__brand-description .product-page__brand-description-header img {
  max-width: 100px;
}
.product-page__section .description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-page__section .description .description-item {
  display: flex;
  gap: 10px;
  align-items: baseline;
}
.product-page__section .description .description-item.inside-box {
  align-items: center;
  margin-bottom: 10px;
}
.product-page__section .description .description-item:nth-child(3) {
  align-items: baseline;
}
.product-page__section .description .description-item .description-item__name {
  font-size: clamp(14px, 0.83vw, 16px);
  opacity: 0.7;
}
.product-page__section .description .description-item .description-item__name.color-base {
  color: #242424;
}
.product-page__section .description .description-item .description-item__value {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 700;
  color: #646464;
  transition: 0.25s;
}
.product-page__section .description .description-item .description-item__value:hover.value-contrast, .product-page__section .description .description-item .description-item__value:focus.value-contrast {
  color: #F28B00;
}
.product-page__section .description .description-item .description-item__spacer {
  flex: 1;
  border-bottom: 1px dotted #E3E3E3;
}
.product-page__section .description .description-item svg {
  width: 19px;
  height: 19px;
}
.product-page__section .product-sidebar .block-products-carousel {
  padding: 0;
}
.product-page__section .h4 {
  font-size: 1.2rem;
}
@media (max-width: 767.98px) {
  .product-page__section .h4 {
    font-size: 1rem;
  }
}

.stock-available__map-container {
  border-top: 1px solid #F28B00;
}
@media (min-width: 768px) {
  .stock-available__map-container {
    display: grid;
    grid-template-columns: 1fr 200px;
  }
}
@media (min-width: 992px) {
  .stock-available__map-container {
    grid-template-columns: 1fr 300px;
  }
}

.cross-table__link {
  color: inherit;
}
.cross-table__link:hover {
  color: #f28b00;
  transition: 0.25s;
}

.product-properties {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
  max-width: 100%;
}
.product-properties .product-property__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: help;
}
.product-properties .product-property__item-name {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 400;
  opacity: 0.7;
}
.product-properties .product-property__item-value {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  margin-left: 4px;
}

.product-short {
  display: flex;
  background-color: #000;
  align-items: center;
  padding-left: 1rem;
  margin-left: auto;
  min-width: 380px;
  max-width: 480px;
}
.product-short .buy-button {
  width: min-content;
}
.product-short .buy-button__btn {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.product-short__info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.product-short__info .product-short__name {
  font-size: 0.75rem;
  color: #FFF;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-short__info .product-short__name a {
  color: #FFF;
}
.product-short__info .product-short__price {
  font-size: 0.75rem;
  font-weight: 700;
  color: #dc1f1f;
  white-space: nowrap;
}
.product-short__info .product-short__price--old {
  font-size: 0.65rem;
  font-weight: 400;
  text-decoration: line-through;
  color: #999;
}

/*
// .product-tabs
*/
.product-tabs__panel.is-sticky {
  z-index: 4;
  background-color: #242424;
}
.product-tabs__panel.is-sticky .container {
  max-width: 1920px;
}
@media (min-width: 576px) {
  .product-tabs__panel.is-sticky .container {
    padding: 0 10px;
  }
}
@media (min-width: 768px) {
  .product-tabs__panel.is-sticky .container {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .product-tabs__panel.is-sticky .container {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .product-tabs__panel.is-sticky .container {
    padding: 0 80px;
  }
}
@media (min-width: 1400px) {
  .product-tabs__panel.is-sticky .container {
    padding: 0 clamp(80px, 5.7vw, 110px);
  }
}
.product-tabs__panel.is-sticky .product-tabs__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-tabs__panel.is-sticky .product-tabs__wrapper .product-tabs__list {
  background-color: #242424;
  margin: 0;
}
.product-tabs__panel.is-sticky .product-tabs__wrapper .product-tabs__item a:hover h2 {
  color: #F28B00;
}
.product-tabs__panel.is-sticky .product-tabs__wrapper .product-tabs__item a:hover, .product-tabs__panel.is-sticky .product-tabs__wrapper .product-tabs__item a:focus {
  background-color: unset;
  box-shadow: none;
}
.product-tabs__panel.is-sticky .product-tabs__wrapper .product-tabs__item:not(.product-tabs__item--active) a {
  border-color: rgba(169, 169, 169, 0.3);
}
.product-tabs__panel.is-sticky .product-tabs__wrapper .product-tabs__item:not(.product-tabs__item--active) h2 {
  color: #FFF;
  transition: color 0.3s ease;
}

.product-tabs__list {
  display: flex;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  overflow-x: auto;
  gap: 1.2rem;
}

.product-tabs__content {
  border-top: 1px solid #ebebeb;
}

.product-tabs__item {
  position: relative;
}
.product-tabs__item a {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 13px 25px;
  white-space: nowrap;
  text-transform: uppercase;
  border-bottom: 1px solid #E3E3E3;
  transition: background 0.12s, box-shadow 0.12s;
}
.product-tabs__item a:hover {
  background: #f7f7f7;
}
.product-tabs__item a:active {
  background: #f2f2f2;
}
@media (max-width: 767.98px) {
  .product-tabs__item a {
    padding: 8px 10px 13px;
  }
}
[dir=ltr] .product-tabs__item:last-child {
  margin-right: auto;
}
[dir=rtl] .product-tabs__item:last-child {
  margin-left: auto;
}
.product-tabs__item h2 {
  font-size: 14px;
  font-weight: 600;
  color: #646464;
  margin: 0;
}
@media (max-width: 767.98px) {
  .product-tabs__item h2 {
    font-size: 11px;
  }
}

.product-tabs__item-counter {
  color: #F28B00;
  font-size: 14px;
  top: -4px;
  position: relative;
}
[dir=ltr] .product-tabs__item-counter {
  margin-left: 3px;
  margin-right: -3px;
}
[dir=rtl] .product-tabs__item-counter {
  margin-right: 3px;
  margin-left: -3px;
}

.product-tabs__item--active a {
  border-bottom: 1px solid #F28B00;
}
.product-tabs__item--active a, .product-tabs__item--active a:hover, .product-tabs__item--active a:active {
  background: transparent;
}
.product-tabs__item--active a h2 {
  color: #F28B00;
}

.product-tabs__pane {
  display: block;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 0.5s;
}

.product-tabs__pane--active {
  padding: 42px 48px 48px;
  overflow: visible;
  height: auto;
  opacity: 1;
  max-width: calc(100% - 0.001px);
}

.product-tabs--layout--full .product-tabs__pane--active {
  padding: 48px;
}
@media (max-width: 991.98px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 40px 44px 44px;
  }
}
@media (max-width: 767.98px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 24px 28px 28px;
  }
}
@media (max-width: 419px) {
  .product-tabs--layout--full .product-tabs__pane--active {
    padding: 20px 24px 24px;
  }
}

@media (min-width: 1400px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 36px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 48px;
  }
}
@media (max-width: 1199.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 36px;
  }
}
@media (max-width: 767.98px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 28px;
  }
}
@media (max-width: 419px) {
  .product-tabs--layout--sidebar .product-tabs__pane--active {
    padding: 24px;
  }
}

.scroll-offset {
  scroll-margin-top: 160px;
}
@media (min-width: 992px) {
  .scroll-offset {
    scroll-margin-top: 200px;
  }
}

@media (max-width: 991.98px) {
  .product-tabs__wrapper .product-short {
    display: none;
  }
}
.products-empty__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin: 1rem;
  text-align: center;
}
.products-empty__wrapper .h5 {
  color: #646464;
  font-weight: 700;
}
.products-empty__image {
  width: 300px;
  height: 300px;
  border-radius: 100%;
  background-color: #242424;
  padding: 70px;
  animation: bounce 2s infinite;
  transform-origin: bottom center;
}
.products-empty__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@keyframes bounce {
  0% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(0.9); /* Сжатие перед прыжком */
  }
  30% {
    transform: translateY(-50px) scaleY(1.1); /* Растяжение в высшей точке */
  }
  50% {
    transform: translateY(0) scaleY(1);
  }
  60% {
    transform: scaleY(0.95); /* Небольшое сжатие после приземления */
  }
  70% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(1);
  }
}
.products-empty__actions {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;
}
.products-empty__actions .btn {
  flex: 1;
}
@media (max-width: 991.98px) {
  .products-empty {
    margin: 1rem;
  }
  .products-empty__image {
    width: 200px;
    height: 200px;
    padding: 40px;
  }
  .products-empty__actions {
    flex-direction: column;
  }
}

/*
// .products-list
*/
.products-list__content {
  display: flex;
  flex-wrap: wrap;
}

.products-list__content + .products-list__content {
  margin-top: 0px;
}

.products-list__item {
  flex-shrink: 0;
  display: flex;
}
.products-list__item .product-card {
  width: 100%;
}

.products-list[data-layout=grid] .product-card {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 400px;
}
.products-list[data-layout=grid] .product-card .product-card__addtocart-full,
.products-list[data-layout=grid] .product-card .product-card__wishlist,
.products-list[data-layout=grid] .product-card .product-card__compare {
  display: none;
}
.products-list[data-layout=grid] .product-card:after {
  display: block;
  position: static;
  content: "";
  order: 1;
  flex-grow: 1;
}
.products-list[data-layout=grid] .product-card .product-card__badges {
  position: absolute;
  top: 16px;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__badges {
  left: 16px;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__badges {
  right: 16px;
}
.products-list[data-layout=grid] .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.products-list[data-layout=grid] .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__actions-list {
  left: 0;
}
.products-list[data-layout=grid] .product-card .product-card__image {
  display: block;
  position: relative;
}
.products-list[data-layout=grid] .product-card .product-card__image .image__body {
  padding-bottom: unset;
}
.products-list[data-layout=grid] .product-card .product-card__fit {
  position: absolute;
  bottom: 8px;
}
.products-list[data-layout=grid] .product-card .product-card__fit .status-badge__text {
  display: none;
}
.products-list[data-layout=grid] .product-card .product-card__fit .status-badge__tooltip {
  display: block;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__fit {
  left: calc(16px - 3px);
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__fit {
  right: calc(16px - 3px);
}
.products-list[data-layout=grid] .product-card .product-card__meta {
  padding: 6px 16px;
}
.products-list[data-layout=grid] .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 16px 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=grid] .product-card .product-card__rating-stars,
.products-list[data-layout=grid] .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .products-list[data-layout=grid] .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=grid] .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.products-list[data-layout=grid] .product-card .product-card__rating-label {
  line-height: 1;
}
.products-list[data-layout=grid] .product-card .product-card__features {
  padding: 10px 16px 0;
  margin-bottom: -2px;
  display: none;
}
.products-list[data-layout=grid] .product-card .product-card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.products-list[data-layout=grid] .product-card .product-card__stocks {
  width: 100%;
}
.products-list[data-layout=grid] .product-card .product-card__footer {
  padding: 16px;
  align-items: flex-end;
  display: flex;
  order: 2;
}
@media (max-width: 767.98px) {
  .products-list[data-layout=grid] .product-card .product-card__footer {
    padding: 10px;
  }
}
.products-list[data-layout=grid] .product-card .product-card__prices {
  flex-grow: 1;
  font-size: 18px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.products-list[data-layout=grid] .product-card .product-card__addtocart-icon {
  margin: -3px;
}
@media (hover: hover) {
  .products-list[data-layout=grid] .product-card .product-card__action--wishlist,
  .products-list[data-layout=grid] .product-card .product-card__action--compare {
    transition: transform 0.2s, opacity 0.2s, color 0.12s, background 0.12s;
    opacity: 0;
    will-change: transform;
  }
  [dir=ltr] .products-list[data-layout=grid] .product-card .product-card__action--wishlist,
  [dir=ltr] .products-list[data-layout=grid] .product-card .product-card__action--compare {
    transform: translateX(100%) translateX(6px);
  }
  [dir=rtl] .products-list[data-layout=grid] .product-card .product-card__action--wishlist,
  [dir=rtl] .products-list[data-layout=grid] .product-card .product-card__action--compare {
    transform: translateX(-100%) translateX(6px);
  }
  .products-list[data-layout=grid] .product-card:hover .product-card__action--wishlist,
  .products-list[data-layout=grid] .product-card:hover .product-card__action--compare, .products-list[data-layout=grid] .product-card:active .product-card__action--wishlist,
  .products-list[data-layout=grid] .product-card:active .product-card__action--compare {
    transform: none;
    opacity: 1;
  }
}
.products-list[data-layout=grid] .product-card .product-card-properties {
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  margin-bottom: 0;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
  overflow: hidden;
}
.products-list[data-layout=grid] .product-card .product-card__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
}
.products-list[data-layout=grid] .product-card .product-card__info-row {
  display: flex;
  gap: 10px;
  cursor: default !important;
}
.products-list[data-layout=grid] .product-card .product-card__info-row .product-card__info-amount {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: help;
}
.products-list[data-layout=grid] .product-card .product-card__info-row .product-card__info-amount svg {
  transition: transform 0.3s ease;
  width: 20px;
  height: 20px;
}
.products-list[data-layout=grid] .product-card .product-card__info-row .product-card__info-amount:hover svg, .products-list[data-layout=grid] .product-card .product-card__info-row .product-card__info-amount:focus-within svg {
  transform: scale(1.1);
}
.products-list[data-layout=grid] .product-card .product-card__info-row .product-card__available-group {
  display: flex;
  justify-content: space-between;
  text-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 4px;
  white-space: normal;
}
.products-list[data-layout=grid] .product-card .product-card__info-row .product-card__available-group:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.products-list[data-layout=grid] .product-card .buy-button {
  width: unset;
}
.products-list[data-layout=grid] .product-card .buy-button .buy-button__btn {
  padding-left: 20px;
  padding-right: 20px;
}
.products-list[data-layout=grid] .product-card .buy-button .buy-button__btn .buy-button__content > span {
  display: none;
}
.products-list[data-layout=grid] .product-card .buy-button .buy-button__btn svg {
  width: 30px;
}
.products-list[data-layout=grid] .product-card .product-card__replace {
  position: absolute;
  top: 48px;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(2px);
  border-radius: 6px;
  padding: 2px;
  width: 100%;
}
@media (max-width: 991.98px) {
  .products-list[data-layout=grid] .product-card:hover {
    box-shadow: 0 0 0 1px rgba(227, 227, 227, 0.6);
  }
  .products-list[data-layout=grid] .product-card .product-card__title {
    font-size: 12px;
    margin: 5px 0;
  }
  .products-list[data-layout=grid] .product-card .product-card__labels {
    margin: 1px 0;
  }
  .products-list[data-layout=grid] .product-card .product-card-properties {
    margin-top: -8px;
    margin-bottom: 0;
  }
  .products-list[data-layout=grid] .product-card .complex-pictogram {
    scale: 0.6;
  }
  .products-list[data-layout=grid] .product-card .product-card__info-title,
  .products-list[data-layout=grid] .product-card .product-card__info-name,
  .products-list[data-layout=grid] .product-card .shared-article__text,
  .products-list[data-layout=grid] .product-card .product-card__available-group,
  .products-list[data-layout=grid] .product-card .product-property__item-name,
  .products-list[data-layout=grid] .product-card .product-property__item-value {
    font-size: 10px !important;
  }
  .products-list[data-layout=grid] .product-card .product-card__image {
    height: clamp(5.625rem, 6.25vw, 7.5rem);
    min-height: 100px;
    position: relative;
    margin: 0.8rem 0;
  }
  .products-list[data-layout=grid] .product-card .product-card__image .image__tag {
    height: clamp(5.625rem, 6.25vw, 7.5rem);
    min-height: 100px;
  }
  .products-list[data-layout=grid] .product-card .product-card__name {
    padding-right: 28px;
  }
  .products-list[data-layout=grid] .product-card .product-card__name--bom-button svg {
    width: 28px;
    height: auto;
  }
  .products-list[data-layout=grid] .product-card .product-card__info-name mat-icon {
    display: none;
  }
  .products-list[data-layout=grid] .product-card .product-card__info-row {
    align-items: center;
  }
  .products-list[data-layout=grid] .product-card .product-card__replace {
    top: 34px;
  }
}

.products-list--grid--3[data-layout=grid] .products-list__content {
  margin: 0px;
}
.products-list--grid--3[data-layout=grid] .products-list__item {
  margin: 0px;
  width: calc((100% - 0px) / 3);
}
.products-list--grid--3[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--3[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--3[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--3[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--3[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--3[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--3[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 1);
  }
}

.products-list--grid--4[data-layout=grid] .products-list__content {
  margin: 0px;
}
.products-list--grid--4[data-layout=grid] .products-list__item {
  margin: 0px;
  width: calc((100% - 0px) / 4);
}
.products-list--grid--4[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 576px) and (max-width: 1399.98px) {
  .products-list--grid--4[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--4[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 3);
  }
}
@media (min-width: 400px) and (max-width: 575px) {
  .products-list--grid--4[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--4[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 2);
  }
}
@media (max-width: 399px) {
  .products-list--grid--4[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--4[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 1);
  }
}

.products-list--grid--5[data-layout=grid] .products-list__content {
  margin: 0px;
}
.products-list--grid--5[data-layout=grid] .products-list__item {
  margin: 0px;
  width: calc((100% - 0px) / 5);
}
.products-list--grid--5[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 4);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--5[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--5[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 1);
  }
}

.products-list--grid--6[data-layout=grid] .products-list__content {
  margin: 0px;
}
.products-list--grid--6[data-layout=grid] .products-list__item {
  margin: 0px;
  width: calc((100% - 0px) / 6);
}
.products-list--grid--6[data-layout=grid] .products-list__head {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 5);
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 4);
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 3);
  }
}
@media (min-width: 420px) and (max-width: 767px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 2);
  }
}
@media (max-width: 419px) {
  .products-list--grid--6[data-layout=grid] .products-list__content {
    margin: 0px;
  }
  .products-list--grid--6[data-layout=grid] .products-list__item {
    margin: 0px;
    width: calc((100% - 0px) / 1);
  }
}

.products-list[data-with-features=true] .product-card .product-card__features {
  display: block;
}

.products-list[data-layout=list] .products-list__content {
  margin: 0px;
}
.products-list[data-layout=list] .products-list__item {
  margin: 0px;
  width: calc((100% - 0px) / 1);
}
.products-list[data-layout=list] .products-list__head {
  display: none;
}
.products-list[data-layout=list] .product-card {
  padding: 10px 24px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}
.products-list[data-layout=list] .product-card .product-card__addtocart-icon,
.products-list[data-layout=list] .product-card .product-card__header,
.products-list[data-layout=list] .product-card .product-card__action--wishlist,
.products-list[data-layout=list] .product-card .product-card__action--compare {
  display: none;
}
.products-list[data-layout=list] .product-card .image__body {
  padding-bottom: 0;
}
.products-list[data-layout=list] .product-card .product-card__available_not_info {
  margin-bottom: -4px;
  width: 100%;
}
.products-list[data-layout=list] .product-card .product-card__content {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.products-list[data-layout=list] .product-card .product-card__vd {
  display: block;
  align-self: stretch;
  border-left: 1px solid rgb(36, 36, 36);
  opacity: 0.1;
  margin: 0 10px;
}
.products-list[data-layout=list] .product-card .product-card-properties {
  display: flex;
  justify-content: space-between;
  padding: 0;
  align-items: flex-start;
  width: 100%;
  margin: -5px 5px 5px;
  overflow: hidden;
}
.products-list[data-layout=list] .product-card .product-properties {
  max-width: 60%;
}
.products-list[data-layout=list] .product-card .complex-pictogram {
  height: 40px;
}
.products-list[data-layout=list] .product-card .complex-pictogram .complex-pictogram__item-value {
  bottom: -20px;
}
.products-list[data-layout=list] .product-card .product-card__info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  width: 100%;
  max-width: 245px;
  margin: auto 10px;
  align-items: flex-start;
}
@media (max-width: 767.98px) {
  .products-list[data-layout=list] .product-card .product-card__info {
    min-width: 220px;
  }
}
.products-list[data-layout=list] .product-card .product-card__info-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.products-list[data-layout=list] .product-card .product-card__info-row .product-card__info-name {
  margin-left: 6px;
}
.products-list[data-layout=list] .product-card .product-card__info-row .product-card__info-amount {
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-list[data-layout=list] .product-card .product-card__info-row .product-card__info-amount svg {
  height: 20px;
  width: 20px;
  transition: transform 0.3s ease;
}
.products-list[data-layout=list] .product-card .product-card__info-row .product-card__info-amount:hover svg, .products-list[data-layout=list] .product-card .product-card__info-row .product-card__info-amount:focus-within svg {
  transform: scale(1.1);
}
.products-list[data-layout=list] .product-card .product-card__info-row .product-card__available-group {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  text-wrap: wrap;
  margin-bottom: 5px;
  white-space: normal;
}
.products-list[data-layout=list] .product-card .product-card__info-row .product-card__available-group:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid #d9d9d9;
}
.products-list[data-layout=list] .product-card .product-card__general-name {
  display: block;
}
.products-list[data-layout=list] .product-card .product-card__general-name .product-card__name {
  padding-right: 32px;
}
.products-list[data-layout=list] .product-card .product-card__general-name .product-card__name--bom-button svg {
  width: 32px;
}
.products-list[data-layout=list] .product-card .buy-button {
  width: 100%;
}
.products-list[data-layout=list] .product-card .product-card__image {
  flex-shrink: 0;
  height: clamp(8.4375rem, 9.375vw, 11.25rem);
}
.products-list[data-layout=list] .product-card .product-card__image .image__tag {
  height: clamp(8.4375rem, 9.375vw, 11.25rem);
}
.products-list[data-layout=list] .product-card .product-card__badges {
  position: absolute;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;
}
.products-list[data-layout=list] .product-card .product-card__badges > * + * {
  margin-top: 3px;
}
.products-list[data-layout=list] .product-card .product-card__actions-list {
  position: absolute;
  overflow: hidden;
  z-index: 1;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__actions-list {
  right: 0;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__actions-list {
  left: 0;
}
.products-list[data-layout=list] .product-card .product-card__rating {
  margin: -3px 0 -2px 0;
  padding: 8px 0 0;
  display: flex;
  flex-wrap: wrap;
}
.products-list[data-layout=list] .product-card .product-card__rating-stars,
.products-list[data-layout=list] .product-card .product-card__rating-label {
  margin: 3px 0 2px 0;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__rating-stars {
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__rating-stars {
  margin-left: 7px;
}
.products-list[data-layout=list] .product-card .product-card__rating-label {
  line-height: 1;
}
.products-list[data-layout=list] .product-card .product-card__fit .status-badge__text {
  display: block;
}
.products-list[data-layout=list] .product-card .product-card__fit .status-badge__tooltip {
  display: none;
}
.products-list[data-layout=list] .product-card .product-card__labels {
  order: 1;
  padding: 0;
  margin: 5px 0;
}
.products-list[data-layout=list] .product-card .shared-article__text {
  font-size: clamp(14px, 0.83vw, 16px);
}
.products-list[data-layout=list] .product-card .product-card__title {
  font-size: clamp(14px, 0.83vw, 16px);
  padding: 0;
  margin: 5px 0;
}
.products-list[data-layout=list] .product-card .product-card__general {
  width: 100%;
  max-width: 286px;
}
.products-list[data-layout=list] .product-card .product-card__price--wrapper {
  min-height: 40px;
}
.products-list[data-layout=list] .product-card .product-card__price--old {
  order: 1;
}
.products-list[data-layout=list] .product-card .product-card__image {
  padding: 0;
  width: 200px;
}
.products-list[data-layout=list] .product-card .product-card__badges {
  top: 16px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__badges {
  left: 16px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__badges {
  right: 16px;
}
.products-list[data-layout=list] .product-card .product-card__fit {
  position: absolute;
  bottom: 24px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__fit {
  left: 198px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__fit {
  right: 198px;
}
.products-list[data-layout=list] .product-card .product-card__meta {
  position: absolute;
  bottom: 0;
  padding: 0;
  height: 38px;
  line-height: 36px;
  border-top: 1px solid #ebebeb;
  text-align: center;
  width: 180px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__meta {
  right: 20px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__meta {
  left: 20px;
}
.products-list[data-layout=list] .product-card .product-card__features {
  font-size: 14px;
  padding-top: 10px;
}
.products-list[data-layout=list] .product-card .product-card__features li + li {
  margin-top: 2px;
}
.products-list[data-layout=list] .product-card .product-card__footer {
  width: 190px;
  align-items: stretch;
  display: flex;
  order: 2;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0;
}
.products-list[data-layout=list] .product-card .product-card__prices {
  padding: 8px 0;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: stretch;
}
.products-list[data-layout=list] .product-card .product-card__addtocart-full {
  display: block;
  height: 36px;
  padding: 0 20px;
  width: 100%;
  margin-bottom: 10px;
}
.products-list[data-layout=list] .product-card .product-card__wishlist,
.products-list[data-layout=list] .product-card .product-card__compare {
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px 5px;
}
.products-list[data-layout=list] .product-card .product-card__wishlist svg,
.products-list[data-layout=list] .product-card .product-card__compare svg {
  position: relative;
  top: -1px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist svg,
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare svg {
  margin-right: 8px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist svg,
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare svg {
  margin-left: 8px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist svg {
  margin-left: -3px;
  margin-right: 7px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist svg {
  margin-right: -3px;
  margin-left: 7px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare svg {
  margin-left: -2px;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare svg {
  margin-right: -2px;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__wishlist--loading:after {
  left: 7px;
  right: auto;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__wishlist--loading:after {
  right: 7px;
  left: auto;
}
[dir=ltr] .products-list[data-layout=list] .product-card .product-card__compare--loading:after {
  left: 8px;
  right: auto;
}
[dir=rtl] .products-list[data-layout=list] .product-card .product-card__compare--loading:after {
  right: 8px;
  left: auto;
}
@media (max-width: 1399.98px) {
  .products-list[data-layout=list] .product-card {
    padding: 10px;
  }
  .products-list[data-layout=list] .product-card .product-card__image {
    height: clamp(4.6875rem, 5.2083333333vw, 6.25rem);
    width: 140px;
  }
  .products-list[data-layout=list] .product-card .product-card__image .image__tag {
    height: clamp(4.6875rem, 5.2083333333vw, 6.25rem);
  }
  .products-list[data-layout=list] .product-card .product-card__footer {
    width: 120px;
  }
  .products-list[data-layout=list] .product-card .product-card__info {
    max-width: 160px;
  }
  .products-list[data-layout=list] .product-card .product-card__general {
    margin-left: 10px;
  }
}
@media (max-width: 767.98px) {
  .products-list[data-layout=list] .product-card {
    padding: 10px;
  }
  .products-list[data-layout=list] .product-card .product-card__image {
    min-height: unset;
    height: clamp(2.34375rem, 2.6041666667vw, 3.125rem);
    width: 60px;
  }
  .products-list[data-layout=list] .product-card .product-card__image .image__tag {
    height: clamp(2.34375rem, 2.6041666667vw, 3.125rem);
    min-height: unset;
  }
  .products-list[data-layout=list] .product-card .product-card__footer {
    width: auto;
    align-items: flex-end;
  }
  .products-list[data-layout=list] .product-card .product-card__vd,
  .products-list[data-layout=list] .product-card .product-card-properties,
  .products-list[data-layout=list] .product-card .product-card__name--bom-button,
  .products-list[data-layout=list] .product-card .product-card__info {
    display: none;
  }
  .products-list[data-layout=list] .product-card .product-label {
    min-height: unset;
  }
  .products-list[data-layout=list] .product-card .product-card__name {
    padding: 0;
  }
  .products-list[data-layout=list] .product-card .product-card__labels {
    margin: 0 5px 0 0;
  }
  .products-list[data-layout=list] .product-card .product-card__general {
    max-width: unset;
    margin: 0 14px;
  }
  .products-list[data-layout=list] .product-card .product-card__title {
    margin: 0;
  }
  .products-list[data-layout=list] .product-card .shared-article__text {
    font-size: clamp(14px, 0.83vw, 16px) !important;
  }
  .products-list[data-layout=list] .product-card .product-card__prices {
    padding: 0;
    margin-bottom: 5px;
  }
  .products-list[data-layout=list] .product-card .product-card__price--wrapper {
    min-height: unset;
  }
  .products-list[data-layout=list] .product-card .buy-button {
    width: 50px;
  }
  .products-list[data-layout=list] .product-card .buy-button .btn span {
    display: none;
  }
  .products-list[data-layout=list] .product-card .owl-nav {
    display: none !important;
  }
}

/*
// .products-view
*/
.products-view__body {
  position: relative;
}

.products-view__loader {
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  z-index: 4;
  background: rgba(255, 255, 255, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.products-view--loading .products-view__loader {
  opacity: 1;
  pointer-events: auto;
}

.products-view__options {
  background-color: #fff;
  margin-bottom: 20px;
}

.products-view__pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0.75rem 1rem;
}

.products-view__pagination-legend {
  font-size: 0.9375rem;
  padding: 0 0.375rem;
  color: #646464;
}

.products-view__empty {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2.5rem;
  text-align: center;
}

.products-view__empty-title {
  font-size: 22px;
  font-weight: 500;
}

.products-view__empty-subtitle {
  margin-top: 4px;
}

.products-view__empty-actions {
  margin-top: 1.25rem;
}

@media (max-width: 1199.98px) {
  .products-view__pagination {
    padding-top: 1rem;
    flex-direction: column;
  }
  .products-view__pagination-legend {
    padding-top: 1rem;
    text-transform: uppercase;
  }
}
@media (max-width: 767.98px) {
  .products-view__pagination {
    padding: 1rem 0 0 0;
  }
}
/*
// .quickview
*/
.quickview {
  max-width: 800px;
}

.quickview__body {
  padding: 32px 32px 36px;
  display: flex;
  flex-direction: row;
}

.quickview__close {
  z-index: 2;
  position: absolute;
  padding: 16px;
  border: none;
  top: 0;
  border-radius: 0 2.5px 0 2.5px;
  background: transparent;
  fill: currentColor;
  transition: background 0.2s, color 0.2s;
  background-color: #fff;
  color: #ccc;
}
.quickview__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.quickview__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .quickview__close {
  right: 0;
}
[dir=rtl] .quickview__close {
  left: 0;
}
.quickview__close svg {
  display: block;
}
.quickview__close:active {
  transition-duration: 0s;
}
.quickview__close:focus {
  outline: none;
}

.quickview__gallery {
  width: 320px;
  flex-shrink: 0;
}
[dir=ltr] .quickview__gallery {
  margin-right: 28px;
}
[dir=rtl] .quickview__gallery {
  margin-left: 28px;
}

.quickview__product {
  flex-grow: 1;
}

.quickview__product-name {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-top: -2px;
}

.quickview__product-rating {
  display: flex;
  margin-top: 6px;
}

.quickview__product-rating-title {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
[dir=ltr] .quickview__product-rating-title {
  margin-left: 8px;
}
[dir=rtl] .quickview__product-rating-title {
  margin-right: 8px;
}

.quickview__product-description {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
}

.quickview__product-meta {
  margin: 12px 0 0;
}
.quickview__product-meta table {
  display: block;
  margin: -3px;
}
.quickview__product-meta tbody {
  display: flex;
  flex-wrap: wrap;
}
.quickview__product-meta tr,
.quickview__product-meta th,
.quickview__product-meta td {
  display: block;
}
.quickview__product-meta tr {
  background: #f2f2f2;
  border-radius: 1.5px;
  margin: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}
[dir=ltr] .quickview__product-meta tr {
  padding-left: 7px;
  padding-right: 14px;
}
[dir=rtl] .quickview__product-meta tr {
  padding-right: 7px;
  padding-left: 14px;
}
.quickview__product-meta th {
  font-size: 11px;
  font-weight: 400;
  color: #808080;
  line-height: 1;
}
.quickview__product-meta td {
  font-size: 13px;
  line-height: 1;
  font-weight: 500;
}
.quickview__product-meta a {
  color: inherit;
}

.quickview__product-prices-stock {
  display: flex;
  align-items: center;
  margin: 12px 0 16px;
}

.quickview__product-prices {
  line-height: 1;
  padding: 6px 0;
}

.quickview__product-price {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.04em;
  color: #242424;
}

.quickview__product-price--new {
  color: #F84147;
}

.quickview__product-price--old {
  font-size: 14px;
  text-decoration: line-through;
  font-weight: 400;
  color: #999;
  margin-bottom: 3px;
}

.quickview__product-stock {
  margin-bottom: 2px;
}
[dir=ltr] .quickview__product-stock {
  margin-left: 12px;
}
[dir=rtl] .quickview__product-stock {
  margin-right: 12px;
}

.quickview__product-form {
  margin-bottom: 16px;
}

.quickview__product-actions {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.quickview__product-actions-item {
  margin: 4px;
}

.quickview__product-actions-item--quantity {
  width: 100px;
}

.quickview__see-details {
  border-radius: 0 0 2.5px 2.5px;
  border-top: 1px solid #ebebeb;
  display: block;
  text-align: center;
  color: #6c757d;
  font-size: 15px;
  height: 52px;
  line-height: 50px;
  transition: background 0.15s, border-color 0.15s;
}
.quickview__see-details:hover {
  color: #6c757d;
  background: #f7f7f7;
}
.quickview__see-details:active {
  background: #f0f0f0;
  transition-duration: 0s;
}

@media (max-width: 991.98px) {
  .quickview {
    max-width: 520px;
  }
  .quickview__body {
    flex-direction: column;
  }
  .quickview__gallery {
    width: 100%;
    margin-bottom: 28px;
  }
  [dir=ltr] .quickview__gallery {
    margin-right: 0;
  }
  [dir=rtl] .quickview__gallery {
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .quickview {
    max-width: 100%;
  }
}
@media (max-width: 474px) {
  .quickview__body {
    padding: 20px 20px 24px;
  }
}
/*
// .review
*/
.review__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.review__content {
  width: 100%;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 12px;
}

.review__avatar {
  overflow: hidden;
  border-radius: 1.5px;
  width: 42px;
}
[dir=ltr] .review__avatar {
  margin-right: 12px;
}
[dir=rtl] .review__avatar {
  margin-left: 12px;
}
.review__avatar img {
  max-width: 100%;
  height: auto;
}

.review__meta {
  flex-grow: 1;
}
[dir=ltr] .review__meta {
  margin-right: 20px;
}
[dir=rtl] .review__meta {
  margin-left: 20px;
}

.review__author {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  margin-top: 2px;
}

.review__date {
  font-size: 13px;
  color: #999;
}

@media (max-width: 474px) {
  .review__rating {
    width: 100%;
    margin-top: 12px;
    margin-bottom: -3px;
  }
}
/*
// .reviews-list
*/
.reviews-list__content {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reviews-list__item + .reviews-list__item {
  margin-top: 18px;
  padding-top: 24px;
  border-top: 1px solid #ebebeb;
}

.reviews-list__pagination {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #ebebeb;
}

/*
// .reviews-view
*/
.reviews-view__form {
  margin-top: 48px;
}

.reviews-view__header {
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 500;
}

/*
// .spec
*/
.spec {
  font-size: 14px;
  line-height: 18px;
}

.spec__header {
  margin-bottom: 50px;
}

.spec__section + .spec__section {
  margin-top: 40px;
}

.spec__section-title {
  font-weight: 500;
  font-size: 17px;
  letter-spacing: -0.03em;
  line-height: 20px;
  margin: 0 0 12px;
}

.spec__row {
  padding: 8px 0;
  display: flex;
  border-bottom: 1px solid #ebebeb;
}
.spec__row:first-of-type {
  border-top: 1px solid #ebebeb;
}

.spec__name {
  width: 230px;
  flex-shrink: 0;
  color: #6c757d;
}
[dir=ltr] .spec__name {
  padding-right: 30px;
}
[dir=rtl] .spec__name {
  padding-left: 30px;
}

.spec__disclaimer {
  margin-top: 40px;
  font-size: clamp(11px, 0.68vw, 13px);
  line-height: 20px;
  color: #6c757d;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .spec__name {
    width: 170px;
  }
}
@media (max-width: 575.98px) {
  .spec__header {
    margin-bottom: 30px;
  }
  .spec__section + .spec__section {
    margin-top: 24px;
  }
  .spec__row {
    display: block;
    padding: 8px 0;
  }
  .spec__name {
    width: auto;
    margin-bottom: 2px;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 15px;
  }
  .spec__disclaimer {
    margin-top: 28px;
  }
}
.stock-available {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.stock-available-item {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.stock-available-item .stock-available-item__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 12px;
}
.stock-available-item .stock-available-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.stock-available-item .stock-available-group__show-map {
  cursor: pointer;
}
.stock-available-item .stock-available-group__item {
  display: flex;
  gap: 12px;
  row-gap: 0;
  justify-content: space-between;
  flex-wrap: wrap;
}
.stock-available-item .stock-available-group__item.receiving {
  background-color: #efefef;
  padding: 20px;
}
.stock-available-item .stock-available-group__item.open {
  flex-direction: column;
  align-items: start;
  border-bottom: 1px dotted #F28B00;
  margin-bottom: 12px;
  cursor: pointer;
}
.stock-available-item .stock-available-group__item.partner {
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-template-rows: auto auto;
  grid-template-areas: "group-name group-partner" "group-available group-partner";
  grid-gap: 4px;
  align-items: center;
}
.stock-available-item .stock-available-group__item.partner .group-name {
  grid-area: group-name;
}
.stock-available-item .stock-available-group__item.partner .group-available {
  grid-area: group-available;
}
.stock-available-item .stock-available-group__item.partner .group-partner {
  grid-area: group-partner;
}
.stock-available-item .stock-available-group__item .stock-available-group__line {
  display: flex;
  gap: 10px;
}
.stock-available-item .stock-available-group__item.map-available:hover, .stock-available-item .stock-available-group__item.map-available:focus {
  color: #F28B00;
  outline: none;
}
.stock-available-item .stock-available-group__item--name, .stock-available-item .stock-available-group__item--amount {
  font-weight: 600;
  white-space: nowrap;
  color: #F28B00;
}
.stock-available-item .stock-available-group__item--partner {
  color: #a9a9a9;
  font-size: small;
}

/*
// .vehicle-picker-modal
*/
.vehicle-picker-modal__panel {
  padding: 32px 32px 36px;
  display: none;
}

.vehicle-picker-modal__panel--active {
  display: block;
}

.vehicle-picker-modal__close {
  z-index: 2;
  position: absolute;
  padding: 16px;
  border: none;
  top: 0;
  border-radius: 0 2.5px 0 2.5px;
  background: transparent;
  fill: currentColor;
  transition: background 0.2s, color 0.2s;
  background-color: #fff;
  color: #ccc;
}
.vehicle-picker-modal__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.vehicle-picker-modal__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .vehicle-picker-modal__close {
  right: 0;
}
[dir=rtl] .vehicle-picker-modal__close {
  left: 0;
}
.vehicle-picker-modal__close svg {
  display: block;
}
.vehicle-picker-modal__close:active {
  transition-duration: 0s;
}
.vehicle-picker-modal__close:focus {
  outline: none;
}

.vehicle-picker-modal__text {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 10px;
  margin-top: -10px;
}

.vehicle-picker-modal__add-button {
  min-height: 44px;
}

.vehicle-picker-modal__actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
[dir=ltr] .vehicle-picker-modal__actions > *:not(:last-child) {
  margin-right: 12px;
}
[dir=rtl] .vehicle-picker-modal__actions > *:not(:last-child) {
  margin-left: 12px;
}

@media (max-width: 479px) {
  .vehicle-picker-modal__panel {
    padding: 24px 24px 28px;
  }
  .vehicle-picker-modal__actions {
    margin-top: 32px;
  }
}
@media (max-width: 399px) {
  .vehicle-picker-modal__panel {
    padding: 20px 20px 24px;
  }
  .vehicle-picker-modal__actions {
    margin-top: 28px;
  }
}
/*
// .wishlist
*/
.wishlist__table {
  width: 100%;
  border-spacing: 0;
}

.wishlist__column--head {
  font-size: 13px;
  text-transform: uppercase;
}

.wishlist__product-name {
  line-height: 20px;
}
.wishlist__product-name a {
  color: inherit;
  transition: color 0.15s;
}
.wishlist__product-name a:hover {
  color: #F28B00;
}

.wishlist__product-rating {
  display: flex;
  margin-top: 6px;
}

.wishlist__product-rating-title {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
[dir=ltr] .wishlist__product-rating-title {
  margin-left: 8px;
}
[dir=rtl] .wishlist__product-rating-title {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .wishlist {
    background-color: #fff;
  }
  .wishlist__column {
    padding: 14px 16px;
  }
  [dir=ltr] .wishlist__column:first-child {
    padding-left: 28px;
  }
  [dir=rtl] .wishlist__column:first-child {
    padding-right: 28px;
  }
  [dir=ltr] .wishlist__column:last-child {
    padding-right: 28px;
  }
  [dir=rtl] .wishlist__column:last-child {
    padding-left: 28px;
  }
  .wishlist__column--image {
    text-align: center;
    width: 1px;
  }
  .wishlist__column--image .image {
    width: 80px;
  }
  .wishlist__column--body {
    border-top: 1px solid #ebebeb;
  }
  .wishlist__column--stock {
    text-align: center;
    width: 1px;
    white-space: nowrap;
  }
  .wishlist__column--price {
    white-space: nowrap;
    width: 140px;
  }
  [dir=ltr] .wishlist__column--price {
    text-align: right;
  }
  [dir=rtl] .wishlist__column--price {
    text-align: left;
  }
  .wishlist__column--button {
    white-space: nowrap;
    width: 200px;
  }
  [dir=ltr] .wishlist__column--button {
    text-align: right;
  }
  [dir=rtl] .wishlist__column--button {
    text-align: left;
  }
  .wishlist__column--remove {
    width: 1px;
    white-space: nowrap;
  }
  [dir=ltr] .wishlist__column--remove {
    padding-left: 0;
  }
  [dir=rtl] .wishlist__column--remove {
    padding-right: 0;
  }
  [dir=ltr] .wishlist__remove {
    margin-right: -12px;
  }
  [dir=rtl] .wishlist__remove {
    margin-left: -12px;
  }
}
@media (min-width: 768px) and (max-width: 1199.98px) {
  .wishlist__column--button {
    width: 1px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  [dir=ltr] .wishlist__column:first-child {
    padding-left: 16px;
  }
  [dir=rtl] .wishlist__column:first-child {
    padding-right: 16px;
  }
  [dir=ltr] .wishlist__column:last-child {
    padding-right: 16px;
  }
  [dir=rtl] .wishlist__column:last-child {
    padding-left: 16px;
  }
  [dir=ltr] .wishlist__column--product,
  [dir=ltr] .wishlist__column--stock,
  [dir=ltr] .wishlist__column--price,
  [dir=ltr] .wishlist__column--button {
    padding-left: 0;
  }
  [dir=rtl] .wishlist__column--product,
  [dir=rtl] .wishlist__column--stock,
  [dir=rtl] .wishlist__column--price,
  [dir=rtl] .wishlist__column--button {
    padding-right: 0;
  }
  .wishlist__column--price {
    width: 1px;
  }
  .wishlist__remove {
    margin-top: 0;
    margin-bottom: 0;
  }
  [dir=ltr] .wishlist__remove {
    margin-left: -8px;
    margin-right: -8px;
  }
  [dir=rtl] .wishlist__remove {
    margin-right: -8px;
    margin-left: -8px;
  }
}
@media (max-width: 767.98px) {
  .wishlist__table {
    display: block;
  }
  .wishlist__head {
    display: none;
  }
  .wishlist__body {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }
  .wishlist__row,
  .wishlist__column {
    display: block;
  }
  .wishlist__column {
    padding: 0;
  }
  .wishlist__row {
    background-color: #fff;
    position: relative;
    margin: 8px;
    width: calc(50% - 16px);
    text-align: center;
    padding: 16px;
  }
  .wishlist__product-rating {
    display: flex;
    justify-content: center;
  }
  .wishlist__column--stock {
    display: flex;
    justify-content: center;
  }
  .wishlist__column--image {
    padding-bottom: 12px;
  }
  .wishlist__column--image .image {
    max-width: 220px;
    margin: 0 auto;
  }
  .wishlist__column--stock {
    margin-top: 16px;
    display: none;
  }
  .wishlist__column--price {
    margin-top: 8px;
    font-weight: 500;
  }
  .wishlist__column--button {
    padding: 20px 0 12px;
  }
  .wishlist__remove {
    position: absolute;
    top: 0;
  }
  [dir=ltr] .wishlist__remove {
    right: 0;
  }
  [dir=rtl] .wishlist__remove {
    left: 0;
  }
}
@media (max-width: 474px) {
  .wishlist__row {
    width: calc(100% - 16px);
  }
}
/* ----------------------------------------
// Blog
// ---------------------------------------- */
/*
// .blog-view
*/
.blog-view__body {
  display: flex;
  justify-content: center;
}

.blog-view__item-posts {
  flex-grow: 1;
}

.blog-view__item-sidebar {
  width: 332px;
  flex-shrink: 0;
}

[dir=ltr] .blog-view__item + .blog-view__item {
  margin-left: 48px;
}
[dir=rtl] .blog-view__item + .blog-view__item {
  margin-right: 48px;
}

.blog-view--layout--classic .blog-view__item-posts {
  max-width: 730px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .blog-view__item-sidebar {
    width: 290px;
  }
  [dir=ltr] .blog-view__item + .blog-view__item {
    margin-left: 36px;
  }
  [dir=rtl] .blog-view__item + .blog-view__item {
    margin-right: 36px;
  }
}
@media (max-width: 991.98px) {
  .blog-view__body {
    flex-direction: column;
  }
  .blog-view__item-sidebar {
    width: 100%;
    margin-top: 48px;
    order: 1;
  }
  [dir=ltr] .blog-view__item + .blog-view__item {
    margin-left: 0;
  }
  [dir=rtl] .blog-view__item + .blog-view__item {
    margin-right: 0;
  }
}
/*
// .comment
*/
.comment__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.comment__content {
  width: 100%;
  background: #f5f5f5;
  padding: 12px 17px;
  border-radius: 5px;
  margin-top: 12px;
  font-size: 15px;
  position: relative;
}
.comment__content:before {
  position: absolute;
  content: "";
  display: block;
  left: 14px;
  top: -10px;
  border: 5px solid transparent;
  border-bottom-color: #f5f5f5;
  pointer-events: none;
}

.comment__avatar {
  overflow: hidden;
  border-radius: 50%;
  width: 38px;
}
[dir=ltr] .comment__avatar {
  margin-right: 14px;
}
[dir=rtl] .comment__avatar {
  margin-left: 14px;
}
.comment__avatar img {
  width: 38px;
  height: 38px;
  object-fit: cover;
}

.comment__meta {
  flex-grow: 1;
}
[dir=ltr] .comment__meta {
  margin-right: 12px;
}
[dir=rtl] .comment__meta {
  margin-left: 12px;
}

.comment__author {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  margin-top: 3px;
}

.comment__date {
  font-size: 13px;
  color: #999;
  margin-top: -1px;
}

@media (max-width: 419px) {
  .comment__content {
    font-size: 14px;
  }
  .comment__avatar {
    display: none;
  }
}
/*
// .comments-list
*/
.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.comments-list__item + .comments-list__item {
  margin-top: 28px;
}

.comments-list__children {
  margin-top: 14px;
  padding-top: 18px;
  margin-bottom: -12px;
  padding-bottom: 15px;
}
[dir=ltr] .comments-list__children {
  border-left: 1px solid #ebebeb;
  padding-left: 31px;
  margin-left: 18px;
}
[dir=rtl] .comments-list__children {
  border-right: 1px solid #ebebeb;
  padding-right: 31px;
  margin-right: 18px;
}

.comments-list__item:last-child > .comments-list__children {
  padding-bottom: 0;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .comments-list__item + .comments-list__item {
    margin-top: 24px;
  }
  .comments-list__children {
    padding-top: 14px;
    margin-bottom: -8px;
  }
  .comments-list__item:last-child > .comments-list__children {
    padding-bottom: 0;
  }
}
@media (max-width: 419px) {
  .comments-list__item + .comments-list__item {
    margin-top: 20px;
  }
  .comments-list__children {
    padding-top: 12px;
  }
  [dir=ltr] .comments-list__children {
    padding-left: 20px;
    margin-left: 12px;
  }
  [dir=rtl] .comments-list__children {
    padding-right: 20px;
    margin-right: 12px;
  }
}
/*
// .comments-view
*/
.comments-view__pagination {
  margin-top: 36px;
}

@media (max-width: 991.98px) {
  .comments-view__pagination {
    margin-top: 32px;
  }
}
@media (max-width: 575.98px) {
  .comments-view__pagination {
    margin-top: 24px;
  }
}
/*
// .post
*/
.post__body {
  padding: 48px;
}

.post__pagination {
  border-top: 1px solid #ebebeb;
  margin: -28px 48px 0;
  padding-top: 20px;
  padding-bottom: 48px;
}

.post__pagination-title {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.post__pagination-list {
  font-size: 15px;
}
.post__pagination-list ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: -3px;
}
.post__pagination-list li {
  margin: 3px;
}

.post__pagination-link {
  padding: 2px 8px;
  display: block;
  border-radius: 1.5px;
  transition: background 0.2s;
  background-color: unset;
  color: #262626;
}
.post__pagination-link:hover {
  background-color: #e5e5e5;
  color: #262626;
}
.post__pagination-link:active {
  transition-duration: 0s;
  background-color: #e0e0e0;
  color: #262626;
}

.post__pagination-link--current {
  cursor: default;
}
.post__pagination-link--current, .post__pagination-link--current:hover, .post__pagination-link--current:active {
  background-color: #F28B00;
  color: #fff;
}

.post__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 48px 48px;
  margin-top: -24px;
}

[dir=ltr] .post__tags {
  margin-right: 32px;
}
[dir=rtl] .post__tags {
  margin-left: 32px;
}

.post__share-links {
  padding-bottom: 1px;
}

.post__author {
  display: flex;
  align-items: center;
  margin: -24px 48px 0;
  padding: 24px 0 48px;
  border-top: 1px solid #ebebeb;
}
.post__author img {
  display: block;
}

.post__author-avatar {
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 2px;
}
[dir=ltr] .post__author-avatar {
  margin-right: 20px;
}
[dir=rtl] .post__author-avatar {
  margin-left: 20px;
}

.post__author-name {
  font-weight: 500;
}

.post__author-about {
  font-size: 15px;
  margin-top: 4px;
}

@media (max-width: 767.98px) {
  .post__body {
    padding: 32px;
  }
  .post__pagination {
    margin: 0 32px 0;
  }
  .post__pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .post__footer {
    flex-direction: column;
    align-items: center;
    padding: 0 32px 24px;
  }
  [dir=ltr] .post__tags {
    margin-right: 0;
  }
  [dir=rtl] .post__tags {
    margin-left: 0;
  }
  .post__tags .tags__list {
    justify-content: center;
  }
  .post__share-links {
    margin-top: 20px;
    padding-bottom: 0;
  }
  .post__author {
    margin: 0 32px 0;
    padding: 32px 0 32px;
    flex-direction: column;
  }
  .post__author-avatar {
    border-radius: 50%;
  }
  [dir=ltr] .post__author-avatar {
    margin-right: 0;
  }
  [dir=rtl] .post__author-avatar {
    margin-left: 0;
  }
  .post__author-info {
    text-align: center;
    margin-top: 16px;
  }
  .post__author-about {
    margin-top: 6px;
  }
}
@media (max-width: 575.98px) {
  .post__body {
    padding: 24px;
  }
  .post__pagination {
    margin: 0 24px 0;
  }
  .post__author {
    margin: 0 24px 0;
    padding: 24px 0 24px;
  }
}
/*
// .post-card
*/
.post-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #E3E3E3;
}

.post-card--layout--grid {
  background-color: #fff;
  flex: 1;
}
.post-card--layout--grid .post-card__image img {
  max-width: 100%;
  height: auto;
}
.post-card--layout--grid .post-card__content {
  position: relative;
}
.post-card--layout--grid .post-card__title h2 {
  margin: 0;
}
.post-card--layout--grid .post-card__title a {
  color: inherit;
  font-weight: 700;
}
.post-card--layout--grid .post-card__title a:hover {
  text-decoration: underline;
}
.post-card--layout--grid .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}
[dir=ltr] .post-card--layout--grid .post-card__category {
  left: 0;
}
[dir=rtl] .post-card--layout--grid .post-card__category {
  right: 0;
}
.post-card--layout--grid .post-card__category a {
  color: #F28B00;
  display: block;
}
.post-card--layout--grid .post-card__category a:hover {
  text-decoration: underline;
}
.post-card--layout--grid .post-card__category:before, .post-card--layout--grid .post-card__category:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}
.post-card--layout--grid .post-card__category:before {
  width: 100%;
}
[dir=ltr] .post-card--layout--grid .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  transform: skewX(30deg);
  transform-origin: left top;
}
[dir=rtl] .post-card--layout--grid .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  transform: skewX(-30deg);
  transform-origin: right top;
}
.post-card--layout--grid .post-card__category:after {
  width: 50px;
}
[dir=ltr] .post-card--layout--grid .post-card__category:after {
  left: 0;
}
[dir=rtl] .post-card--layout--grid .post-card__category:after {
  right: 0;
}
.post-card--layout--grid .post-card__date {
  font-size: 14px;
  color: #999;
}
.post-card--layout--grid .post-card__date a {
  color: inherit;
}
.post-card--layout--grid .post-card__date a:hover {
  color: #F28B00;
}
.post-card--layout--grid .post-card__excerpt {
  overflow: hidden;
}
.post-card--layout--grid .post-card__more a {
  text-transform: uppercase;
  color: inherit;
  font-weight: 700;
  font-size: clamp(11px, 0.68vw, 13px);
  transition: all 0.2s ease-in-out;
}
.post-card--layout--grid .post-card__more a svg {
  transition: all 0.2s ease-in-out;
}
.post-card--layout--grid .post-card__more a:hover {
  color: #F28B00;
}
.post-card--layout--grid .post-card__more a:hover svg {
  transform: scale(1.2);
}
.post-card--layout--grid .post-card__details-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #F28B00;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .post-card--layout--grid .post-card__content {
    padding: 1.875rem 2.1875rem 2.1875rem;
  }
  .post-card--layout--grid .post-card__title h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
  }
  .post-card--layout--grid .post-card__category {
    height: 26px;
    padding: 1.125rem 2.1875rem 0;
  }
  .post-card--layout--grid .post-card__date {
    margin-top: 11px;
    margin-bottom: 15px;
  }
  .post-card--layout--grid .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid .post-card__more {
    margin-top: 0;
  }
}
@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--grid .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--grid .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--grid .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--grid .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--grid .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid .post-card__more {
    margin-top: 0;
  }
}
@media (max-width: 474px) {
  .post-card--layout--grid .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--grid .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--grid .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--grid .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--grid .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--grid .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--grid .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--grid .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--grid .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--grid .post-card__more {
    display: none;
  }
}

@media (min-width: 768px) {
  .post-card--layout--list {
    background-color: #fff;
    display: flex;
  }
  .post-card--layout--list .post-card__image {
    display: flex;
    flex-shrink: 0;
  }
  .post-card--layout--list .post-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .post-card--layout--list .post-card__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
  }
  .post-card--layout--list .post-card__title h2 {
    margin-bottom: 0;
  }
  .post-card--layout--list .post-card__title a {
    color: inherit;
  }
  .post-card--layout--list .post-card__title a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__category {
    font-size: 14px;
    font-weight: 500;
  }
  .post-card--layout--list .post-card__category a {
    color: #F28B00;
  }
  .post-card--layout--list .post-card__category a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__excerpt {
    overflow: hidden;
    margin-bottom: auto;
  }
  .post-card--layout--list .post-card__date {
    font-size: 14px;
    color: #999;
  }
  .post-card--layout--list .post-card__date a {
    color: inherit;
  }
  .post-card--layout--list .post-card__date a:hover {
    color: #F28B00;
  }
  .post-card--layout--list .post-card__date:before {
    content: "";
    height: 1px;
    background: currentColor;
    opacity: 0.6;
  }
}
@media (min-width: 1400px) {
  .post-card--layout--list .post-card__image {
    width: 400px;
    min-height: 300px;
  }
  .post-card--layout--list .post-card__content {
    padding: 30px 32px 32px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 22px;
    line-height: 30px;
  }
  .post-card--layout--list .post-card__date {
    position: absolute;
    bottom: 36px;
  }
  [dir=ltr] .post-card--layout--list .post-card__date {
    right: 32px;
  }
  [dir=rtl] .post-card--layout--list .post-card__date {
    left: 32px;
  }
  .post-card--layout--list .post-card__date:before {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--list .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--list .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--list .post-card__category {
    margin-bottom: 10px;
    margin-top: -4px;
  }
  .post-card--layout--list .post-card__excerpt {
    max-height: 104px;
    margin-bottom: 48px;
  }
  .post-card--layout--list .post-card__more {
    position: absolute;
    bottom: 32px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .post-card--layout--list .post-card__image {
    width: 320px;
    min-height: 260px;
  }
  .post-card--layout--list .post-card__content {
    padding: 26px 28px 24px;
  }
  .post-card--layout--list .post-card__category {
    margin-top: -2px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .post-card--layout--list .post-card__excerpt {
    max-height: 78px;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 36px;
    margin-top: 14px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-card--layout--list .post-card__image {
    width: 280px;
    min-height: 220px;
  }
  .post-card--layout--list .post-card__content {
    padding: 20px 26px 22px;
  }
  .post-card--layout--list .post-card__category {
    margin-bottom: 6px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 8px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 17px;
    line-height: 24px;
  }
  .post-card--layout--list .post-card__excerpt {
    font-size: 15px;
    max-height: 66px;
  }
  .post-card--layout--list .post-card__excerpt .typography {
    line-height: 22px;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .post-card--layout--list .post-card__image {
    width: 320px;
    min-height: 260px;
  }
  .post-card--layout--list .post-card__content {
    padding: 26px 28px 24px;
  }
  .post-card--layout--list .post-card__category {
    margin-top: -2px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 26px;
  }
  .post-card--layout--list .post-card__excerpt {
    max-height: 78px;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 36px;
    margin-top: 14px;
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .post-card--layout--list {
    background-color: #fff;
    flex: 1;
  }
  .post-card--layout--list .post-card__image img {
    max-width: 100%;
    height: auto;
  }
  .post-card--layout--list .post-card__content {
    position: relative;
  }
  .post-card--layout--list .post-card__title h2 {
    margin: 0;
  }
  .post-card--layout--list .post-card__title a {
    color: inherit;
    font-weight: 700;
  }
  .post-card--layout--list .post-card__title a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__category {
    position: absolute;
    bottom: calc(100% - 1px);
    font-weight: 500;
    z-index: 0;
    font-size: 14px;
  }
  [dir=ltr] .post-card--layout--list .post-card__category {
    left: 0;
  }
  [dir=rtl] .post-card--layout--list .post-card__category {
    right: 0;
  }
  .post-card--layout--list .post-card__category a {
    color: #F28B00;
    display: block;
  }
  .post-card--layout--list .post-card__category a:hover {
    text-decoration: underline;
  }
  .post-card--layout--list .post-card__category:before, .post-card--layout--list .post-card__category:after {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    background: #fff;
    z-index: -1;
    height: 100%;
  }
  .post-card--layout--list .post-card__category:before {
    width: 100%;
  }
  [dir=ltr] .post-card--layout--list .post-card__category:before {
    left: 0;
    border-top-right-radius: 2px;
    transform: skewX(30deg);
    transform-origin: left top;
  }
  [dir=rtl] .post-card--layout--list .post-card__category:before {
    right: 0;
    border-top-left-radius: 2px;
    transform: skewX(-30deg);
    transform-origin: right top;
  }
  .post-card--layout--list .post-card__category:after {
    width: 50px;
  }
  [dir=ltr] .post-card--layout--list .post-card__category:after {
    left: 0;
  }
  [dir=rtl] .post-card--layout--list .post-card__category:after {
    right: 0;
  }
  .post-card--layout--list .post-card__date {
    font-size: 14px;
    color: #999;
  }
  .post-card--layout--list .post-card__date a {
    color: inherit;
  }
  .post-card--layout--list .post-card__date a:hover {
    color: #F28B00;
  }
  .post-card--layout--list .post-card__excerpt {
    overflow: hidden;
  }
  .post-card--layout--list .post-card__more a {
    text-transform: uppercase;
    color: inherit;
    font-weight: 700;
    font-size: clamp(11px, 0.68vw, 13px);
    transition: all 0.2s ease-in-out;
  }
  .post-card--layout--list .post-card__more a svg {
    transition: all 0.2s ease-in-out;
  }
  .post-card--layout--list .post-card__more a:hover {
    color: #F28B00;
  }
  .post-card--layout--list .post-card__more a:hover svg {
    transform: scale(1.2);
  }
  .post-card--layout--list .post-card__details-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: #F28B00;
    margin-left: 10px;
  }
}
@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--list .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--list .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--list .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--list .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--list .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--list .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--list .post-card__more {
    margin-top: 0;
  }
}
@media (max-width: 474px) {
  .post-card--layout--list .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--list .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--list .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--list .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--list .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--list .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--list .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--list .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--list .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--list .post-card__more {
    display: none;
  }
}

.post-card--layout--grid-sm {
  background-color: #fff;
  flex: 1;
}
.post-card--layout--grid-sm .post-card__image img {
  max-width: 100%;
  height: auto;
}
.post-card--layout--grid-sm .post-card__content {
  position: relative;
}
.post-card--layout--grid-sm .post-card__title h2 {
  margin: 0;
}
.post-card--layout--grid-sm .post-card__title a {
  color: inherit;
  font-weight: 700;
}
.post-card--layout--grid-sm .post-card__title a:hover {
  text-decoration: underline;
}
.post-card--layout--grid-sm .post-card__category {
  position: absolute;
  bottom: calc(100% - 1px);
  font-weight: 500;
  z-index: 0;
  font-size: 14px;
}
[dir=ltr] .post-card--layout--grid-sm .post-card__category {
  left: 0;
}
[dir=rtl] .post-card--layout--grid-sm .post-card__category {
  right: 0;
}
.post-card--layout--grid-sm .post-card__category a {
  color: #F28B00;
  display: block;
}
.post-card--layout--grid-sm .post-card__category a:hover {
  text-decoration: underline;
}
.post-card--layout--grid-sm .post-card__category:before, .post-card--layout--grid-sm .post-card__category:after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  background: #fff;
  z-index: -1;
  height: 100%;
}
.post-card--layout--grid-sm .post-card__category:before {
  width: 100%;
}
[dir=ltr] .post-card--layout--grid-sm .post-card__category:before {
  left: 0;
  border-top-right-radius: 2px;
  transform: skewX(30deg);
  transform-origin: left top;
}
[dir=rtl] .post-card--layout--grid-sm .post-card__category:before {
  right: 0;
  border-top-left-radius: 2px;
  transform: skewX(-30deg);
  transform-origin: right top;
}
.post-card--layout--grid-sm .post-card__category:after {
  width: 50px;
}
[dir=ltr] .post-card--layout--grid-sm .post-card__category:after {
  left: 0;
}
[dir=rtl] .post-card--layout--grid-sm .post-card__category:after {
  right: 0;
}
.post-card--layout--grid-sm .post-card__date {
  font-size: 14px;
  color: #999;
}
.post-card--layout--grid-sm .post-card__date a {
  color: inherit;
}
.post-card--layout--grid-sm .post-card__date a:hover {
  color: #F28B00;
}
.post-card--layout--grid-sm .post-card__excerpt {
  overflow: hidden;
}
.post-card--layout--grid-sm .post-card__more a {
  text-transform: uppercase;
  color: inherit;
  font-weight: 700;
  font-size: clamp(11px, 0.68vw, 13px);
  transition: all 0.2s ease-in-out;
}
.post-card--layout--grid-sm .post-card__more a svg {
  transition: all 0.2s ease-in-out;
}
.post-card--layout--grid-sm .post-card__more a:hover {
  color: #F28B00;
}
.post-card--layout--grid-sm .post-card__more a:hover svg {
  transform: scale(1.2);
}
.post-card--layout--grid-sm .post-card__details-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #F28B00;
  margin-left: 10px;
}
@media (min-width: 1400px) {
  .post-card--layout--grid-sm .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--grid-sm .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid-sm .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid-sm .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid-sm .post-card__more {
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 1399.98px) {
  .post-card--layout--grid-sm .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--grid-sm .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--grid-sm .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--grid-sm .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--grid-sm .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--grid-sm .post-card__more {
    display: none;
  }
}
@media (max-width: 767.98px) and (min-width: 475px) {
  .post-card--layout--grid-sm .post-card__content {
    padding: 1.675rem 1.675rem 1.75rem;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 26px;
    padding: 1.125rem 1.675rem 0;
  }
  .post-card--layout--grid-sm .post-card__date {
    margin-top: 10px;
    margin-bottom: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
  }
  [dir=ltr] .post-card--layout--grid-sm .post-card__date:before {
    margin-right: 4px;
  }
  [dir=rtl] .post-card--layout--grid-sm .post-card__date:before {
    margin-left: 4px;
  }
  .post-card--layout--grid-sm .post-card__more {
    margin-top: 0;
  }
}
@media (max-width: 474px) {
  .post-card--layout--grid-sm .post-card__category {
    font-size: 13px;
  }
  .post-card--layout--grid-sm .post-card__title {
    margin-bottom: 10px;
  }
  .post-card--layout--grid-sm .post-card__title h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__excerpt {
    font-size: 15px;
    max-height: 96px;
  }
  .post-card--layout--grid-sm .post-card__excerpt .typography {
    line-height: 24px;
  }
  .post-card--layout--grid-sm .post-card__category {
    height: 22px;
    padding: 0.875rem 1.25rem 0;
  }
  .post-card--layout--grid-sm .post-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.25rem 1.125rem;
  }
  .post-card--layout--grid-sm .post-card__date {
    order: 1;
    margin-top: 12px;
  }
  .post-card--layout--grid-sm .post-card__date:before {
    display: block;
    width: 32px;
    margin-bottom: 8px;
  }
  .post-card--layout--grid-sm .post-card__more {
    display: none;
  }
}

/*
// .post-header
*/
.post-header {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 0;
  overflow: hidden;
}

.post-header__decor {
  position: absolute;
  bottom: -1px;
}
.post-header__decor .decor__start,
.post-header__decor .decor__end,
.post-header__decor .decor__center {
  background: #FFF;
}
.post-header__decor .decor__start,
.post-header__decor .decor__end {
  box-shadow: none;
}

.post-header__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  opacity: 0.22;
}

.post-header__body {
  max-width: 620px;
  margin: 0 auto;
  text-align: center;
  padding: 52px 30px;
}

.post-header__title {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
}

.post-header__categories {
  margin-bottom: 28px;
}

.post-header__categories-list {
  list-style: none;
  margin: -3px;
  padding: 0;
  display: flex;
  justify-content: center;
}

.post-header__categories-item {
  margin: 3px;
}

.post-header__categories-link {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  background: #F28B00;
  padding: 3px 9px;
  border-radius: 1.5px;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .post-header__categories-link {
    font-weight: 400;
  }
}
.post-header__categories-link, .post-header__categories-link:hover {
  color: #fff;
}

.post-header__meta {
  font-size: 14px;
  margin-top: 24px;
  color: #999;
}

.post-header__meta-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.post-header__meta-item + .post-header__meta-item {
  position: relative;
}
[dir=ltr] .post-header__meta-item + .post-header__meta-item {
  margin-left: 24px;
}
[dir=rtl] .post-header__meta-item + .post-header__meta-item {
  margin-right: 24px;
}
.post-header__meta-item + .post-header__meta-item:before {
  display: block;
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background: currentColor;
  top: 9px;
}
[dir=ltr] .post-header__meta-item + .post-header__meta-item:before {
  left: -14px;
}
[dir=rtl] .post-header__meta-item + .post-header__meta-item:before {
  right: -14px;
}

.post-header__meta-link {
  color: inherit;
  transition: color 0.1s;
}
.post-header__meta-link:hover {
  color: #F28B00;
}

.post-header--has-image {
  background: #262626;
  margin-bottom: -150px;
  padding-bottom: 150px;
}
.post-header--has-image .post-header__body {
  color: #fff;
  padding: 60px 30px;
}
.post-header--has-image .post-header__title,
.post-header--has-image .post-header__meta {
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
}
.post-header--has-image .post-header__meta {
  color: inherit;
}
.post-header--has-image .post-header__meta-link:hover {
  color: #ffdf40;
}

@media (max-width: 1199.98px) {
  .post-header {
    padding-top: 0;
  }
}
@media (max-width: 575.98px) {
  .post-header__body {
    padding: 36px 30px;
  }
  .post-header__categories {
    margin-bottom: 20px;
  }
  .post-header__title {
    font-size: 28px;
  }
  .post-header__meta {
    margin-top: 16px;
  }
  .post-header--has-image .post-header__body {
    padding: 48px 30px;
  }
}
@media (max-width: 419px) {
  .post-header--has-image {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
/*
// .post-navigation
*/
.post-navigation__body {
  display: flex;
}

.post-navigation__item {
  padding: 20px;
  display: flex;
  align-items: center;
  width: 50%;
  color: inherit;
  transition: background 0.12s;
}
.post-navigation__item:hover {
  background: #f2f2f2;
  color: inherit;
}

.post-navigation__item-image {
  overflow: hidden;
  border-radius: 2px;
  flex-shrink: 0;
}
.post-navigation__item-image img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.post-navigation__item-title {
  font-size: 15px;
  line-height: 22px;
  margin-top: 3px;
}

.post-navigation__direction {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  color: #999;
}

.post-navigation__direction-arrow {
  display: flex;
  align-items: center;
  fill: currentColor;
  padding-bottom: 1px;
  opacity: 0.8;
}
.post-navigation__direction-arrow svg {
  display: block;
}
[dir=ltr] .post-navigation__direction-arrow svg {
  transform: scaleX(1);
}
[dir=rtl] .post-navigation__direction-arrow svg {
  transform: scaleX(-1);
}

[dir=ltr] .post-navigation__item--prev {
  text-align: left;
}
[dir=rtl] .post-navigation__item--prev {
  text-align: right;
}
[dir=ltr] .post-navigation__item--prev .post-navigation__item-image {
  margin-right: 18px;
}
[dir=rtl] .post-navigation__item--prev .post-navigation__item-image {
  margin-left: 18px;
}
.post-navigation__item--prev .post-navigation__direction {
  justify-content: flex-start;
}
[dir=ltr] .post-navigation__item--prev .post-navigation__direction-arrow {
  margin-right: 8px;
}
[dir=rtl] .post-navigation__item--prev .post-navigation__direction-arrow {
  margin-left: 8px;
}

[dir=ltr] .post-navigation__item--next {
  text-align: right;
}
[dir=rtl] .post-navigation__item--next {
  text-align: left;
}
[dir=ltr] .post-navigation__item--next .post-navigation__item-image {
  margin-left: 18px;
}
[dir=rtl] .post-navigation__item--next .post-navigation__item-image {
  margin-right: 18px;
}
.post-navigation__item--next .post-navigation__direction {
  justify-content: flex-end;
}
[dir=ltr] .post-navigation__item--next .post-navigation__direction-arrow {
  margin-left: 8px;
}
[dir=rtl] .post-navigation__item--next .post-navigation__direction-arrow {
  margin-right: 8px;
}

@media (max-width: 767.98px) {
  .post-navigation__body {
    flex-direction: column;
  }
  .post-navigation__item {
    width: 100%;
  }
  .post-navigation__item--next {
    border-top: 1px solid #ebebeb;
  }
}
/*
// .post-view
*/
.post-view__body {
  display: flex;
  justify-content: center;
}

.post-view__item-post {
  flex-grow: 1;
  max-width: 760px;
}

.post-view__item-sidebar {
  width: 332px;
  flex-shrink: 0;
}

[dir=ltr] .post-view__item + .post-view__item {
  margin-left: 48px;
}
[dir=rtl] .post-view__item + .post-view__item {
  margin-right: 48px;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .post-view__item-sidebar {
    width: 290px;
  }
  [dir=ltr] .post-view__item + .post-view__item {
    margin-left: 36px;
  }
  [dir=rtl] .post-view__item + .post-view__item {
    margin-right: 36px;
  }
}
@media (max-width: 991.98px) {
  .post-view__body {
    flex-direction: column;
  }
  .post-view__item-sidebar {
    width: 100%;
    margin-top: 20px;
    order: 1;
  }
  [dir=ltr] .post-view__item + .post-view__item {
    margin-left: 0;
  }
  [dir=rtl] .post-view__item + .post-view__item {
    margin-right: 0;
  }
}
.post-view__card {
  background-color: #fff;
  position: relative;
}

.post-view__card + .post-view__card {
  margin-top: 24px;
}

.post-view__card-title {
  padding: 44px 0 8px;
  margin: 0 48px 40px;
  font-size: 28px;
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
}

.post-view__card-body {
  padding: 0 48px 48px;
}

@media (max-width: 767.98px) {
  .post-view__card-title {
    padding: 28px 0 8px;
    margin: 0 32px 28px;
  }
  .post-view__card-body {
    padding: 0 32px 32px;
  }
}
@media (max-width: 575.98px) {
  .post-view__card-title {
    padding: 22px 0 4px;
    margin: 0 24px 24px;
    font-size: 24px;
  }
  .post-view__card-body {
    padding: 0 24px 24px;
  }
}
@media (max-width: 419px) {
  .post-view__card {
    margin-left: -15px;
    margin-right: -15px;
  }
}
/*
// .posts-list
*/
.posts-list__body {
  display: flex;
  flex-wrap: wrap;
}

.posts-list__item {
  display: flex;
}

.posts-list--layout--classic .posts-list__body {
  margin: -20px;
}
.posts-list--layout--classic .posts-list__item {
  margin: 20px;
  width: calc(100% - 40px);
}

.posts-list--layout--grid-2 .posts-list__body {
  margin: -14px;
}
.posts-list--layout--grid-2 .posts-list__item {
  margin: 14px;
  width: calc(25% - 28px);
}
@media (max-width: 1399.98px) {
  .posts-list--layout--grid-2 .posts-list__body {
    margin: -10px;
  }
  .posts-list--layout--grid-2 .posts-list__item {
    margin: 10px;
    width: calc(50% - 20px);
  }
}
@media (max-width: 767.98px) {
  .posts-list--layout--grid-2 .posts-list__body {
    margin: -16px;
  }
  .posts-list--layout--grid-2 .posts-list__item {
    margin: 16px;
    width: calc(100% - 32px);
  }
}

.posts-list--layout--list .posts-list__body {
  margin: -14px;
}
.posts-list--layout--list .posts-list__item {
  margin: 14px;
  width: calc(100% - 28px);
}

/*
// .posts-view
*/
.posts-view__pagination {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}

/* ----------------------------------------
// Account
// ---------------------------------------- */
/*
// .account-nav
*/
.account-nav {
  background-color: #fff;
}

.account-nav__title {
  padding: 1.375rem 1.5rem;
  margin-bottom: 0;
  background-color: #242424;
}
.account-nav__title .account-nav__title-name {
  font-weight: 700;
  color: #FFF;
}
.account-nav__title .account-nav__title-email {
  font-size: clamp(11px, 0.68vw, 13px);
  color: #646464;
}

.account-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 15px;
  line-height: 20px;
}

.account-nav__item a {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #646464;
  padding: 14px 1.5rem;
  font-weight: 600;
}
.account-nav__item a svg {
  fill: #646464;
}

.account-nav__item:hover a {
  background: #f2f2f2;
}

.account-nav__item--active a {
  color: inherit;
  background-color: rgba(242, 139, 0, 0.07);
}
.account-nav__item--active a svg {
  fill: #F28B00;
}

.account-nav__divider {
  height: 1px;
  background: #ebebeb;
  margin: 10px 0;
}

@media (min-width: 992px) {
  .account-nav__list {
    padding-bottom: 1.375rem;
  }
  [dir=ltr] .account-nav__item--active a {
    box-shadow: 3px 0 #F28B00 inset;
  }
  [dir=rtl] .account-nav__item--active a {
    box-shadow: -3px 0 #F28B00 inset;
  }
}
@media (max-width: 991.98px) {
  .account-nav {
    overflow-x: auto;
    max-width: 100%;
  }
  .account-nav__list {
    display: flex;
    white-space: nowrap;
    padding: 0;
  }
  .account-nav__title {
    display: none;
  }
  .account-nav__item a {
    padding: 14px 20px;
  }
  .account-nav__item--active a {
    box-shadow: 0 -3px #F28B00 inset;
    height: 100%;
  }
}
.account-table {
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.account-table tr {
  padding: 0.3em;
  font-size: clamp(14px, 0.83vw, 16px);
}
@media (max-width: 575.98px) {
  .account-table tr {
    font-size: clamp(10px, 0.68vw, 14px);
  }
}

.account-table th,
.account-table td {
  padding: 0.2em;
  text-align: center;
}

.account-table th {
  font-size: clamp(14px, 0.83vw, 16px);
}
@media (max-width: 767.98px) {
  .account-table th {
    font-size: clamp(10px, 0.68vw, 14px);
  }
}

@media (max-width: 575.98px) {
  .account-table {
    border: 0;
  }
  .account-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .account-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 10px;
  }
  .account-table td {
    border-bottom: 1px solid #b5b3b3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: clamp(10px, 0.68vw, 14px);
    text-align: right;
  }
  .account-table td::before {
    content: attr(data-label);
    color: #000;
    float: left;
    font-weight: bold;
  }
  .account-table tfoot td {
    border-bottom: 0;
  }
  .row-total td {
    border-bottom: 0;
    color: #fff;
  }
  .row-total td .dark-text {
    color: #000;
  }
  .dark-text td {
    color: #000;
  }
}
/*
// .dashboard
*/
.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dashboard__orders {
  margin-top: 24px;
  width: 100%;
}

@media (min-width: 768px) {
  .dashboard__profile,
  .dashboard__address {
    width: calc(50% - 12px);
  }
}
@media (max-width: 767.98px) {
  .dashboard__profile,
  .dashboard__address {
    width: 100%;
  }
  .dashboard__address {
    margin-top: 24px;
  }
}
.order-panel {
  box-shadow: none !important;
  --mat-expansion-container-shape: 0 !important;
  --mat-expansion-header-indicator-color: #f18c16;
  border: 1px solid #E3E3E3;
}
.order-panel .expansion-content-title {
  font-size: 1rem;
}
@media (max-width: 767.98px) {
  .order-panel .expansion-content-title {
    font-size: 0.8rem;
  }
}

.order-panel__header {
  border-left: 5px solid blue;
  min-height: 80px;
  padding: 10px 24px;
}
@media (max-width: 575.98px) {
  .order-panel__header {
    min-height: 140px;
  }
  .order-panel__header.mat-expanded {
    min-height: 80px;
  }
}
.order-panel__header.mat-expanded .mat-content .order-panel__title > *:not(:first-child) {
  display: none;
}

.order-panel__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.order-panel__title-item {
  flex-grow: 1;
  flex-shrink: 1;
}
.order-panel__title-item:first-child {
  flex-basis: 200px;
}
.order-panel__title-item:last-child {
  flex-basis: 180px;
}
@media (max-width: 991.98px) {
  .order-panel__title-item:first-child {
    flex-basis: 150px;
  }
}
@media (max-width: 575.98px) {
  .order-panel__title-item:first-child {
    flex-basis: 120px;
  }
  .order-panel__title-item:last-child {
    flex-basis: 140px;
  }
}

.order-panel__title-item-name {
  color: #646464;
  font-size: clamp(11px, 0.68vw, 13px);
}

.order-panel__title-item-value {
  font-weight: 700;
  font-size: clamp(14px, 0.83vw, 16px);
  display: flex;
  gap: 10px;
}
@media (max-width: 991.98px) {
  .order-panel__title-item-value {
    font-size: clamp(11px, 0.68vw, 13px);
  }
}
.order-panel__title-item-value .ttn {
  color: #F28B00;
  font-weight: 700;
}

.order-panel__content-buttons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.order-panel__content-buttons button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 20px;
  transition: all ease-in-out 0.2s;
}
.order-panel__content-buttons button span {
  font-size: clamp(11px, 0.68vw, 13px);
  text-transform: uppercase;
  font-weight: 700;
  transition: all ease-in-out 0.2s;
}
.order-panel__content-buttons .order-panel__content-buttons-invoice {
  border: 1px solid #E3E3E3;
}
.order-panel__content-buttons .order-panel__content-buttons-invoice svg {
  fill: #242424;
  transition: all ease-in-out 0.2s;
}
.order-panel__content-buttons .order-panel__content-buttons-invoice:hover {
  border-color: #F28B00;
}
.order-panel__content-buttons .order-panel__content-buttons-invoice:hover span {
  color: #F28B00;
}
.order-panel__content-buttons .order-panel__content-buttons-invoice:hover svg {
  fill: #F28B00;
}

.order-product {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 8px 0;
  gap: 4px;
}
.order-product > a {
  grid-column: span 4;
}
@media (max-width: 767.98px) {
  .order-product > a {
    grid-column: span 2;
  }
}
@media (max-width: 767.98px) {
  .order-product {
    grid-template-columns: repeat(2, 1fr);
  }
}

.order-product__title {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.order-product__title img {
  height: 74px;
  width: 74px;
  object-fit: contain;
  border: 1px solid #E3E3E3;
}
.order-product__title span {
  max-width: 300px;
  font-weight: 700;
  font-size: clamp(11px, 0.68vw, 13px);
  text-align: center;
  font-size: clamp(14px, 0.83vw, 16px);
}

.order-product__property {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 767.98px) {
  .order-product__property {
    padding-top: 10px;
  }
}

.order-product__property-name {
  font-size: clamp(11px, 0.68vw, 13px);
  color: #646464;
}

.order-product__property-value {
  font-weight: 700;
  font-size: clamp(14px, 0.83vw, 16px);
}

.order-product__return {
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
}
.order-product__return span {
  color: #d0d0d0;
  font-weight: 600;
  transition: color ease-in-out 0.2ms;
}
.order-product__return svg {
  fill: #d0d0d0;
  transition: fill ease-in-out 0.2ms;
}
.order-product__return:hover span {
  color: #F28B00;
}
.order-product__return:hover svg {
  fill: #F28B00;
}

.order-product-images {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}
@media (max-width: 575.98px) {
  .order-product-images {
    gap: 1px;
  }
}

.order-product-images__item {
  width: 60px;
  height: 60px;
  border: 1px solid #E3E3E3;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575.98px) {
  .order-product-images__item {
    width: 40px;
    height: 40px;
  }
}
.order-product-images__item img {
  object-fit: contain;
  overflow: auto;
}
.order-product-images__item span {
  color: #646464;
  font-weight: 700;
}

/*
// .profile-card
*/
.profile-card__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-card__avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 16px;
}
.profile-card__avatar img {
  border-radius: 50%;
  max-width: 100%;
}

.profile-card__name {
  font-weight: 500;
  line-height: 20px;
}

.profile-card__email {
  font-size: 15px;
  margin-bottom: 24px;
}

.wait-list-button {
  border: 1px solid #E3E3E3;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all ease-in-out 0.2s;
}
.wait-list-button span {
  font-weight: 600;
  font-size: clamp(11px, 0.68vw, 13px);
  transition: all ease-in-out 0.2s;
}
.wait-list-button svg {
  fill: #242424;
  transition: all ease-in-out 0.2s;
}
.wait-list-button:hover {
  background-color: #242424;
  border-color: #242424;
}
.wait-list-button:hover span {
  color: #FFF;
}
.wait-list-button:hover svg {
  fill: #FFF;
}

.wait-list-button-add-cart {
  border-color: #F28B00;
  background-color: #F28B00;
}
.wait-list-button-add-cart span {
  color: #FFF;
}
.wait-list-button-add-cart svg {
  fill: #FFF;
}

.switch-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: none;
  padding-left: 0;
}

.switch-input {
  display: none;
}

.switch-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  background-color: #E3E3E3;
  border-radius: 4px;
  transition: 0.3s;
}
.switch-label:hover, .switch-label:focus {
  cursor: pointer;
}

.switch-label::after {
  content: "";
  position: absolute;
  width: 22px;
  height: 20px;
  background-color: white;
  border-radius: 4px;
  top: 2px;
  left: 2px;
  transition: transform 0.3s;
}

.switch-input:checked + .switch-label {
  background-color: #4caf50;
}

.switch-input:checked + .switch-label::after {
  transform: translateX(24px);
}

.switch-label::before {
  position: absolute;
  right: 5px;
  width: 18px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #ba5757;
}

.switch-input:checked + .switch-label::before {
  left: 5px;
  right: 0;
  color: #fff;
}

.form-group-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
}
@media (max-width: 767.98px) {
  .form-group-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
}

.only-available-styled {
  display: block;
  text-align: end;
}
@media (max-width: 767.98px) {
  .only-available-styled {
    text-align: start;
  }
}

.form-group-checkbox {
  border: 1px dotted #d1d1d1;
  margin-bottom: 10px;
  padding: 16px 4px;
}
@media (max-width: 767.98px) {
  .form-group-checkbox {
    width: 100%;
  }
  .form-group-checkbox > label {
    display: block;
    text-align: center;
  }
}

.email-warning {
  font-size: clamp(11px, 0.68vw, 13px);
  color: #F28B00;
}

.switch-wrapper {
  justify-content: flex-end;
  padding: 0;
}
@media (max-width: 767.98px) {
  .switch-wrapper {
    justify-content: flex-start;
  }
}

.checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .checkbox-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

.checkbox-label {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  margin: 8px;
  font-size: 16px;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-custom {
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  background-color: #eee;
  outline: 1px solid #ccc;
}

.checkbox-text {
  margin-left: 6px;
}

.checkbox-input:checked + .checkbox-custom:after {
  content: "";
  position: absolute;
  display: block;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #F28B00;
}

.catalog-email-wrapper {
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 767.98px) {
  .catalog-email-wrapper {
    margin-bottom: 10px;
  }
}

.catalog-button-edit {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  min-width: 140px;
  height: 22px;
  border: none;
  outline: none;
  background-color: #F28B00;
  color: #fff;
  transition: 0.3s;
  font-size: clamp(14px, 0.83vw, 16px);
}
@media (max-width: 767.98px) {
  .catalog-button-edit {
    min-width: 80px;
    font-size: clamp(10px, 0.68vw, 14px);
  }
}
.catalog-button-edit:hover, .catalog-button-edit:focus {
  cursor: pointer;
  box-shadow: 0 0 1px #242424;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.request-inn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.request-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.request-label {
  width: 100%;
  margin: 0 auto;
  font-weight: 500;
}
@media (min-width: 992px) {
  .request-label {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .request-pending {
    min-height: 350px;
  }
}

.request-button-apply {
  width: 100%;
  margin-left: auto;
  max-height: 40px;
  padding: 8px;
  cursor: pointer;
}
.request-button-apply:disabled {
  opacity: 0.7;
  cursor: no-drop;
}
@media (min-width: 576px) {
  .request-button-apply {
    width: 250px;
  }
}

@media (min-width: 576px) {
  .brands-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/*---------------------------------------
// Vehicle
// -------------------------------------- */
.compatible-vehicle__wrapper {
  display: flex;
  align-items: stretch;
}
.compatible-vehicle__message {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #E3E3E3;
  line-height: 1.3;
  font-size: clamp(10px, 0.68vw, 14px);
}
.compatible-vehicle__message.wrong {
  background-color: rgba(222, 11, 15, 0.05);
}
.compatible-vehicle__message.wrong svg {
  fill: #DE0B0F;
}
.compatible-vehicle__add-button {
  min-width: 80px;
}
.compatible-vehicle__add-button span {
  font-size: 22px;
  font-weight: 700;
}

.current-vehicle {
  position: relative;
  container: current-vehicle/inline-size;
}

.current-vehicle-wrapper {
  display: flex;
  gap: 1rem;
  background: #F28B00;
  color: #FFF;
  position: relative;
  container: current-vehicle-wrapper/inline-size;
}
@container current-vehicle (width < 900px) {
  .current-vehicle-wrapper .current-vehicle__subtitle {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .current-vehicle-wrapper .current-vehicle__subtitle {
    display: none;
  }
}

.current-vehicle__title .current-vehicle__title--icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 21px;
  height: 21px;
  border-radius: 100%;
  background-color: #4caf50;
  margin: 0 1rem;
}
.current-vehicle__title .current-vehicle__title--icon svg {
  width: 12px;
  height: auto;
}

.current-vehicle__subtitle {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.current-vehicle__subtitle .title {
  margin-right: 0.3rem;
}

.current-vehicle__button {
  background-color: transparent;
  border: 0;
  padding: 0.8rem 1rem;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.current-vehicle__button:hover {
  background-color: rgba(36, 36, 36, 0.2);
}

/*
// .garage-vehicle-selector
*/
.garage-vehicle-selector_pop-up {
  pointer-events: none;
  position: absolute;
  z-index: 4;
  top: 100%;
  right: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.garage-vehicle-selector_pop-up.show {
  pointer-events: auto;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}
@container current-vehicle (width < 900px) {
  .garage-vehicle-selector_pop-up {
    position: inherit;
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    display: none;
  }
  .garage-vehicle-selector_pop-up.show {
    display: block;
  }
  .garage-vehicle-selector_pop-up .garage-vehicle-selector__wrapper {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .garage-vehicle-selector_pop-up {
    position: inherit;
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    display: none;
  }
  .garage-vehicle-selector_pop-up.show {
    display: block;
  }
  .garage-vehicle-selector_pop-up .garage-vehicle-selector__wrapper {
    width: 100%;
  }
}

.garage-vehicle-selector__wrapper {
  display: flex;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  color: #242424;
  padding: 1.5rem 1rem;
  flex-direction: column;
  gap: 1rem;
}
.garage-vehicle-selector__wrapper .default-pargo-button-inverse {
  width: 100%;
}
.garage-vehicle-selector__wrapper .default-pargo-button-inverse svg {
  height: auto;
}
.garage-vehicle-selector__wrapper .garage-vehicle-selector__item {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E3E3E3;
}
.garage-vehicle-selector__wrapper .garage-vehicle-selector__item .car-content {
  display: flex;
  align-items: center;
}
.garage-vehicle-selector__wrapper .garage-vehicle-selector__item .car-content .leading {
  width: 17px;
  height: 17px;
  border: 1px solid #a9a9a9;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.garage-vehicle-selector__wrapper .garage-vehicle-selector__item .car-content .leading.active {
  background-color: #4caf50;
  border-color: #4caf50;
}
.garage-vehicle-selector__wrapper .garage-vehicle-selector__item .car-content .title {
  padding: 10px;
  font-weight: 600;
  line-height: 1.2;
}

/*
// .vehicles-list
*/
.vehicles-list__body {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
}

.vehicles-list__item {
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  margin: 4px 0;
}
.vehicles-list__item:first-child {
  margin-top: 0;
}
.vehicles-list__item:last-child {
  margin-bottom: 0;
}

.vehicles-list__item-radio {
  margin: 8px;
}

.vehicles-list__item-info {
  margin: 2px 8px;
  flex-grow: 1;
  line-height: 1.25;
}

.vehicles-list__item-remove {
  margin: 8px;
}
.vehicles-list__item-remove svg {
  display: block;
}

.vehicles-list__item-name {
  display: block;
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
}

.vehicles-list__item-details {
  display: block;
  font-size: clamp(11px, 0.68vw, 13px);
  color: #999;
  margin-top: 2px;
}

.vehicles-list__item-links {
  font-size: 14px;
  margin-top: 12px;
}
.vehicles-list__item-links a:hover {
  text-decoration: underline;
}

.vehicles-list__item-remove {
  position: relative;
  display: flex;
  padding: 8px;
  border: none;
  margin: 0;
  border-radius: 2px;
  fill: currentColor;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.vehicles-list__item-remove:hover {
  background-color: #f2f2f2;
  color: #999;
}
.vehicles-list__item-remove:active {
  background-color: #ebebeb;
  color: #808080;
}
.vehicles-list__item-remove:focus {
  outline: none;
}
.vehicles-list__item-remove.vehicles-list__item-remove--loading {
  cursor: default;
  fill: transparent;
  background: transparent;
}

.vehicles-list__item-remove--loading:before {
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}

.vehicles-list--layout--account .vehicles-list__body {
  margin: -6px;
}
.vehicles-list--layout--account .vehicles-list__item {
  padding: 10px 8px;
  width: calc(50% - 12px);
  margin: 6px;
}
@media (max-width: 767.98px) {
  .vehicles-list--layout--account .vehicles-list__body {
    margin: -6px;
  }
  .vehicles-list--layout--account .vehicles-list__item {
    width: calc(100% - 12px);
    margin: 6px;
  }
}

.vehicle-addition--actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 0.8rem;
}

@media (max-width: 575.98px) {
  .vehicle-addition--actions {
    flex-direction: column;
    align-items: stretch;
  }
}
.vehicle-picker__wrapper {
  width: 100%;
}
.vehicle-picker__wrapper.hide {
  visibility: hidden;
  height: 0;
}
.vehicle-picker__wrapper.without-borders .vehicle-picker__start-panel,
.vehicle-picker__wrapper.without-borders .vehicle-picker__tabs-wrapper {
  border: none;
  box-shadow: none;
}
.vehicle-picker__wrapper .no-section .section-header__title {
  font-weight: 500;
}

.vehicle-picker__start-panel {
  display: flex;
  align-items: center;
  border: 1px solid #E3E3E3;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
}
.vehicle-picker__start-panel.hide {
  display: none;
}
.vehicle-picker__start-panel .brand-button {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px 20px;
  font-weight: 700;
  color: #FFF;
  border-radius: 5px 0 0 5px;
  background-color: #F28B00;
  font-size: 22px;
}
.vehicle-picker__start-panel .search-wrapper {
  flex: 3;
  display: flex;
  gap: 35px;
  padding: 0 35px 0 80px;
}
.vehicle-picker__start-panel .search-wrapper .form-group {
  margin: 0;
}
.vehicle-picker__start-panel .search__car-plate {
  flex: 2;
}
.vehicle-picker__start-panel .search__car-vin {
  flex: 3;
}
.vehicle-picker__start-panel.compact {
  flex-direction: column-reverse;
  padding: 1rem;
  gap: 0.4rem;
}
.vehicle-picker__start-panel.compact .brand-button {
  font-weight: normal;
  font-size: clamp(14px, 0.83vw, 16px);
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  gap: 2rem;
}
.vehicle-picker__start-panel.compact .brand-button svg {
  height: 12px;
  width: 22px;
}
.vehicle-picker__start-panel.compact .search-wrapper {
  flex-direction: column;
  gap: 0.4rem;
  padding: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .vehicle-picker__start-panel {
    flex-direction: column-reverse;
    padding: 1rem;
    gap: 0.4rem;
  }
  .vehicle-picker__start-panel .brand-button {
    font-weight: normal;
    font-size: clamp(14px, 0.83vw, 16px);
    border-radius: 4px;
    width: 100%;
    justify-content: center;
    gap: 2rem;
  }
  .vehicle-picker__start-panel .brand-button svg {
    height: 12px;
    width: 22px;
  }
  .vehicle-picker__start-panel .search-wrapper {
    flex-direction: column;
    gap: 0.4rem;
    padding: 0;
    width: 100%;
  }
}

.vehicle-picker__content {
  padding: 0;
}

.vehicle-picker__search-wrapper {
  position: relative;
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
}
.vehicle-picker__search-wrapper .form-control {
  width: 100%;
  font-size: 14px;
  height: unset;
  padding: 0.8rem 3rem 0.8rem 0.8rem;
  border-radius: 4px;
}
.vehicle-picker__search-wrapper svg {
  position: absolute;
  right: 18px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.vehicle-picker__tabs-wrapper {
  border: 1px solid #E3E3E3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.vehicle-picker__tabs-wrapper .tabs-header {
  display: flex;
}
.vehicle-picker__tabs-wrapper .tab-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  flex: 1;
  font-weight: 700;
  color: rgba(100, 100, 100, 0.6);
  text-align: center;
  transition: background-color 0.3s;
}
.vehicle-picker__tabs-wrapper .tab-item:hover {
  background-color: #f5f5f5;
}
.vehicle-picker__tabs-wrapper .tab-item.active {
  background-color: #F28B00;
  color: #FFF !important;
}
.vehicle-picker__tabs-wrapper .tab-item.active svg {
  fill: #FFF !important;
}
.vehicle-picker__tabs-wrapper .tab-item.selected {
  background-color: #F28B00;
  color: rgba(255, 255, 255, 0.5);
}
.vehicle-picker__tabs-wrapper .tab-item.selected svg {
  fill: rgba(255, 255, 255, 0.5);
}
.vehicle-picker__tabs-wrapper .tab-item.active .tab-open-icon {
  display: block;
}
.vehicle-picker__tabs-wrapper .tab-item.disabled {
  cursor: default;
  pointer-events: none;
}
.vehicle-picker__tabs-wrapper .tab-item .select-contain {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.vehicle-picker__tabs-wrapper .tab-open-icon {
  display: none;
  margin-left: 1rem;
}
.vehicle-picker__tabs-wrapper .tabs-dropdown {
  overflow: hidden;
}
.vehicle-picker__tabs-wrapper .dropdown-close {
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}
.vehicle-picker__tabs-wrapper .dropdown-close:hover {
  background-color: #e0e0e0;
}
.vehicle-picker__tabs-wrapper.compact {
  padding: 1rem;
  border-radius: 10px;
}
.vehicle-picker__tabs-wrapper.compact .tabs-header {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.vehicle-picker__tabs-wrapper.compact .tab-item {
  display: none;
  font-weight: normal;
  border-radius: 6px;
  min-height: 54px;
  padding: 12px 20px;
}
.vehicle-picker__tabs-wrapper.compact .tab-item.selected {
  display: flex;
  background-color: rgba(227, 227, 227, 0.5);
  color: rgba(100, 100, 100, 0.7);
}
.vehicle-picker__tabs-wrapper.compact .tab-item.selected svg {
  fill: rgba(100, 100, 100, 0.7);
  flex-shrink: 0;
}
.vehicle-picker__tabs-wrapper.compact .tab-item.selected .select-contain {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.vehicle-picker__tabs-wrapper.compact .tab-item.active {
  display: flex;
  background-color: #F28B00 !important;
}
.vehicle-picker__tabs-wrapper.compact .tab-item.active .select-contain {
  flex-direction: row !important;
  justify-content: flex-start !important;
}
.vehicle-picker__tabs-wrapper.compact .tab-item.disabled {
  display: none;
}
.vehicle-picker__tabs-wrapper.compact .vehicle-picker__content {
  margin: 0 10px;
}
.vehicle-picker__tabs-wrapper.compact .vehicle-picker__search-wrapper {
  margin: 1rem 0 1rem 0;
}
@media (max-width: 991.98px) {
  .vehicle-picker__tabs-wrapper {
    padding: 1rem;
    border-radius: 10px;
  }
  .vehicle-picker__tabs-wrapper .tabs-header {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }
  .vehicle-picker__tabs-wrapper .tab-item {
    display: none;
    font-weight: normal;
    border-radius: 6px;
    min-height: 54px;
    padding: 12px 20px;
  }
  .vehicle-picker__tabs-wrapper .tab-item.selected {
    display: flex;
    background-color: rgba(227, 227, 227, 0.5);
    color: rgba(100, 100, 100, 0.7);
  }
  .vehicle-picker__tabs-wrapper .tab-item.selected svg {
    fill: rgba(100, 100, 100, 0.7);
    flex-shrink: 0;
  }
  .vehicle-picker__tabs-wrapper .tab-item.selected .select-contain {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    text-align: left;
  }
  .vehicle-picker__tabs-wrapper .tab-item.active {
    display: flex;
    background-color: #F28B00 !important;
  }
  .vehicle-picker__tabs-wrapper .tab-item.active .select-contain {
    flex-direction: row !important;
    justify-content: flex-start !important;
  }
  .vehicle-picker__tabs-wrapper .tab-item.disabled {
    display: none;
  }
  .vehicle-picker__tabs-wrapper .vehicle-picker__content {
    margin: 0 10px;
  }
  .vehicle-picker__tabs-wrapper .vehicle-picker__search-wrapper {
    margin: 1rem 0 1rem 0;
  }
}

/*
// .vehicle-select
*/
.vehicle-featured-border {
  width: 100%;
  height: 0;
  border-top: 1px solid #F28B00;
  margin: 0;
  grid-column: 1/-1;
}

.vehicle-select--brands {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  grid-auto-rows: min-content;
  padding: 1px;
  height: 354px;
  overflow: scroll;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1199.98px) {
  .vehicle-select--brands {
    grid-template-columns: repeat(4, 1fr);
  }
}

.vehicle-select--models {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  grid-auto-rows: min-content;
  padding: 1px;
  height: 354px;
  overflow: scroll;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1199.98px) {
  .vehicle-select--models {
    grid-template-columns: repeat(4, 1fr);
  }
}

.vehicle-select--years {
  display: grid;
  grid-auto-flow: row;
  gap: 1px;
  grid-auto-rows: min-content;
  padding: 1px;
  height: 354px;
  overflow: scroll;
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 1199.98px) {
  .vehicle-select--years {
    grid-template-columns: repeat(4, 1fr);
  }
}

.selectable-list-item {
  height: 100%;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-left: 3px solid transparent;
  font-weight: 500;
  background-color: #FFF;
  box-shadow: 0 0 0 1px #E3E3E3;
}
.selectable-list-item .selectable-list-item--center {
  text-align: center;
}
.selectable-list-item .addition {
  color: #646464;
  font-size: clamp(11px, 0.68vw, 13px);
}
.selectable-list-item.selectable-list-item--bold {
  font-weight: 700;
}
.selectable-list-item.selectable-list-item--mark {
  box-shadow: 0 0 6px #F28B00;
}
.selectable-list-item:hover {
  background-color: rgba(227, 227, 227, 0.5);
}
.selectable-list-item .values {
  display: flex;
  gap: 0.5rem;
}
.selectable-list-item .values .title {
  color: #646464;
}

.selectable-list-item--active {
  color: #F28B00;
  background-color: rgba(227, 227, 227, 0.5);
}

.vehicle-card {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.vehicle-card.vehicle-card--mark {
  box-shadow: 0 0 6px #F28B00;
}
.vehicle-card:hover {
  box-shadow: 0 0 12px #242424;
}

.vehicle-component .mdc-tab-indicator__content--underline {
  display: none;
}
.vehicle-component .mdc-tab-indicator--active {
  background-color: #F28B00;
  position: relative;
}
.vehicle-component .mdc-tab-indicator--active .mdc-tab__text-label {
  color: white !important;
}
.vehicle-component .mdc-tab-indicator--active:after {
  content: "";
  position: absolute;
  right: -20px;
  top: -1px;
  border-top: 49px solid transparent;
  border-left: 20px solid #F28B00;
}

.compact .vehicle-select--brands,
.compact .vehicle-select--models,
.compact .vehicle-select--years {
  grid-template-columns: repeat(1, 1fr);
}
.compact .selectable-list-item {
  text-align: left !important;
  position: relative;
  padding-right: 2rem;
}
.compact .selectable-list-item:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 17px;
  height: 17px;
  border: 1px solid #E3E3E3;
  border-radius: 100%;
}
.compact .selectable-list-item--active:after {
  background-color: #F28B00;
}
@media (max-width: 575.98px) {
  .compact .selectable-list-item {
    padding: 0.6rem 2rem 0.6rem 0.6rem;
  }
}

@media (max-width: 991.98px) {
  .vehicle-select--brands,
  .vehicle-select--models,
  .vehicle-select--years {
    grid-template-columns: repeat(1, 1fr);
  }
  .selectable-list-item {
    text-align: left !important;
    position: relative;
    padding-right: 2rem;
  }
  .selectable-list-item:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 17px;
    height: 17px;
    border: 1px solid #E3E3E3;
    border-radius: 100%;
  }
  .selectable-list-item--active:after {
    background-color: #F28B00;
  }
}
@media (max-width: 991.98px) and (max-width: 575.98px) {
  .selectable-list-item {
    padding: 0.6rem 2rem 0.6rem 0.6rem;
  }
}
.vehicle-table_wrapper {
  width: 100%;
  overflow: hidden;
}
.vehicle-table_wrapper .selectable-list-item {
  display: none;
}
.vehicle-table_wrapper .table-header {
  background-color: #E3E3E3;
}
.vehicle-table_wrapper .table-header-row {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.vehicle-table_wrapper .table-header-cell {
  display: table-cell;
  padding: 12px 15px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}
.vehicle-table_wrapper .table-body {
  display: block;
  height: 307px;
  overflow-y: auto;
}
.vehicle-table_wrapper .table-row {
  display: table;
  width: 100%;
  table-layout: fixed;
  cursor: pointer;
}
.vehicle-table_wrapper .table-row:hover {
  background-color: rgba(227, 227, 227, 0.5);
}
.vehicle-table_wrapper .table-cell {
  display: table-cell;
  padding: 10px 15px;
  border-bottom: 1px solid #E3E3E3;
  text-align: left;
}
.vehicle-table_wrapper .col-name {
  width: 25%;
}
.vehicle-table_wrapper .col-engine {
  width: 20%;
}
@media (max-width: 991.98px) {
  .vehicle-table_wrapper {
    height: 354px;
    overflow-y: auto;
  }
  .vehicle-table_wrapper .table-header, .vehicle-table_wrapper .table-body {
    display: none;
  }
  .vehicle-table_wrapper .selectable-list-item {
    display: flex;
    flex-direction: column;
    height: min-content;
  }
  .vehicle-table_wrapper .selectable-list-item:after {
    top: unset;
    bottom: unset;
  }
}

.compact .vehicle-table_wrapper {
  height: 354px;
  overflow-y: auto;
}
.compact .vehicle-table_wrapper .table-header, .compact .vehicle-table_wrapper .table-body {
  display: none;
}
.compact .vehicle-table_wrapper .selectable-list-item {
  display: flex;
  flex-direction: column;
  height: min-content;
}
.compact .vehicle-table_wrapper .selectable-list-item:after {
  top: unset;
  bottom: unset;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #ff9800;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #4caf50;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff9800;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff9800;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #4caf50;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4caf50;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #4caf50;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #4caf50;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ff9800;
  --mdc-linear-progress-track-color: rgba(255, 152, 0, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 152, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 152, 0, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 152, 0, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 152, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 152, 0, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #4caf50;
  --mdc-linear-progress-track-color: rgba(76, 175, 80, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(76, 175, 80, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(76, 175, 80, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(76, 175, 80, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(76, 175, 80, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(76, 175, 80, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #ff9800;
  --mdc-filled-text-field-focus-active-indicator-color: #ff9800;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 152, 0, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #ff9800;
  --mdc-outlined-text-field-focus-outline-color: #ff9800;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 152, 0, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(255, 152, 0, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(76, 175, 80, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #4caf50;
  --mdc-filled-text-field-focus-active-indicator-color: #4caf50;
  --mdc-filled-text-field-focus-label-text-color: rgba(76, 175, 80, 0.87);
  --mdc-outlined-text-field-caret-color: #4caf50;
  --mdc-outlined-text-field-focus-outline-color: #4caf50;
  --mdc-outlined-text-field-focus-label-text-color: rgba(76, 175, 80, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 152, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(76, 175, 80, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #ff9800;
  --mdc-chip-elevated-disabled-container-color: #ff9800;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #4caf50;
  --mdc-chip-elevated-disabled-container-color: #4caf50;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #fb8c00;
  --mdc-switch-selected-handle-color: #fb8c00;
  --mdc-switch-selected-hover-state-layer-color: #fb8c00;
  --mdc-switch-selected-pressed-state-layer-color: #fb8c00;
  --mdc-switch-selected-focus-handle-color: #e65100;
  --mdc-switch-selected-hover-handle-color: #e65100;
  --mdc-switch-selected-pressed-handle-color: #e65100;
  --mdc-switch-selected-focus-track-color: #ffb74d;
  --mdc-switch-selected-hover-track-color: #ffb74d;
  --mdc-switch-selected-pressed-track-color: #ffb74d;
  --mdc-switch-selected-track-color: #ffb74d;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #43a047;
  --mdc-switch-selected-handle-color: #43a047;
  --mdc-switch-selected-hover-state-layer-color: #43a047;
  --mdc-switch-selected-pressed-state-layer-color: #43a047;
  --mdc-switch-selected-focus-handle-color: #1b5e20;
  --mdc-switch-selected-hover-handle-color: #1b5e20;
  --mdc-switch-selected-pressed-handle-color: #1b5e20;
  --mdc-switch-selected-focus-track-color: #81c784;
  --mdc-switch-selected-hover-track-color: #81c784;
  --mdc-switch-selected-pressed-track-color: #81c784;
  --mdc-switch-selected-track-color: #81c784;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff9800;
  --mdc-radio-selected-hover-icon-color: #ff9800;
  --mdc-radio-selected-icon-color: #ff9800;
  --mdc-radio-selected-pressed-icon-color: #ff9800;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff9800;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4caf50;
  --mdc-radio-selected-hover-icon-color: #4caf50;
  --mdc-radio-selected-icon-color: #4caf50;
  --mdc-radio-selected-pressed-icon-color: #4caf50;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #4caf50;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ff9800;
  --mdc-slider-focus-handle-color: #ff9800;
  --mdc-slider-hover-handle-color: #ff9800;
  --mdc-slider-active-track-color: #ff9800;
  --mdc-slider-inactive-track-color: #ff9800;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff9800;
  --mat-mdc-slider-ripple-color: #ff9800;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 152, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 152, 0, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #4caf50;
  --mdc-slider-focus-handle-color: #4caf50;
  --mdc-slider-hover-handle-color: #4caf50;
  --mdc-slider-active-track-color: #4caf50;
  --mdc-slider-inactive-track-color: #4caf50;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #4caf50;
  --mat-mdc-slider-ripple-color: #4caf50;
  --mat-mdc-slider-hover-ripple-color: rgba(76, 175, 80, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(76, 175, 80, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff9800;
  --mdc-radio-selected-hover-icon-color: #ff9800;
  --mdc-radio-selected-icon-color: #ff9800;
  --mdc-radio-selected-pressed-icon-color: #ff9800;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #4caf50;
  --mdc-radio-selected-hover-icon-color: #4caf50;
  --mdc-radio-selected-icon-color: #4caf50;
  --mdc-radio-selected-pressed-icon-color: #4caf50;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff9800;
  --mdc-checkbox-selected-hover-icon-color: #ff9800;
  --mdc-checkbox-selected-icon-color: #ff9800;
  --mdc-checkbox-selected-pressed-icon-color: #ff9800;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff9800;
  --mdc-checkbox-selected-hover-state-layer-color: #ff9800;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff9800;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #4caf50;
  --mdc-checkbox-selected-hover-icon-color: #4caf50;
  --mdc-checkbox-selected-icon-color: #4caf50;
  --mdc-checkbox-selected-pressed-icon-color: #4caf50;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4caf50;
  --mdc-checkbox-selected-hover-state-layer-color: #4caf50;
  --mdc-checkbox-selected-pressed-state-layer-color: #4caf50;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ff9800;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ff9800;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ff9800;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff9800;
  --mat-tab-header-active-ripple-color: #ff9800;
  --mat-tab-header-inactive-ripple-color: #ff9800;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff9800;
  --mat-tab-header-active-hover-label-text-color: #ff9800;
  --mat-tab-header-active-focus-indicator-color: #ff9800;
  --mat-tab-header-active-hover-indicator-color: #ff9800;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #4caf50;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #4caf50;
  --mat-tab-header-active-ripple-color: #4caf50;
  --mat-tab-header-inactive-ripple-color: #4caf50;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #4caf50;
  --mat-tab-header-active-hover-label-text-color: #4caf50;
  --mat-tab-header-active-focus-indicator-color: #4caf50;
  --mat-tab-header-active-hover-indicator-color: #4caf50;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ff9800;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #4caf50;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #4caf50;
  --mdc-checkbox-selected-hover-icon-color: #4caf50;
  --mdc-checkbox-selected-icon-color: #4caf50;
  --mdc-checkbox-selected-pressed-icon-color: #4caf50;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4caf50;
  --mdc-checkbox-selected-hover-state-layer-color: #4caf50;
  --mdc-checkbox-selected-pressed-state-layer-color: #4caf50;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ff9800;
  --mdc-checkbox-selected-hover-icon-color: #ff9800;
  --mdc-checkbox-selected-icon-color: #ff9800;
  --mdc-checkbox-selected-pressed-icon-color: #ff9800;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff9800;
  --mdc-checkbox-selected-hover-state-layer-color: #ff9800;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff9800;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ff9800;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #4caf50;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ff9800;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #4caf50;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ff9800;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #4caf50;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ff9800;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #4caf50;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #ff9800;
  --mat-mdc-button-ripple-color: rgba(255, 152, 0, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #4caf50;
  --mat-mdc-button-ripple-color: rgba(76, 175, 80, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ff9800;
  --mat-mdc-button-persistent-ripple-color: #ff9800;
  --mat-mdc-button-ripple-color: rgba(255, 152, 0, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #4caf50;
  --mat-mdc-button-persistent-ripple-color: #4caf50;
  --mat-mdc-button-ripple-color: rgba(76, 175, 80, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #ff9800;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #4caf50;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #4caf50;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #ff9800;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #4caf50;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #ff9800;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #4caf50;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #ff9800;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 152, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 152, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 152, 0, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ff9800;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 152, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #4caf50;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(76, 175, 80, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(76, 175, 80, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(76, 175, 80, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(76, 175, 80, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #4caf50;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #ff9800;
}
.mat-icon.mat-accent {
  --mat-icon-color: #4caf50;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #ff9800;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #ff9800;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #ff9800;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #4caf50;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #4caf50;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #4caf50;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ff9800;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #4caf50;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #f18c16;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f18c16;
  --mat-tab-header-active-ripple-color: #f18c16;
  --mat-tab-header-inactive-ripple-color: #f18c16;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f18c16;
  --mat-tab-header-active-hover-label-text-color: #f18c16;
  --mat-tab-header-active-focus-indicator-color: #f18c16;
  --mat-tab-header-active-hover-indicator-color: #f18c16;
}

.mat-mdc-icon-button.mat-default {
  --mdc-icon-button-icon-color: #f18c16;
  --mat-mdc-button-persistent-ripple-color: #f18c16;
  --mat-mdc-button-ripple-color: rgba(242, 139, 0, 0.1);
}

mat-checkbox {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f18c16;
  --mdc-checkbox-selected-hover-icon-color: #f18c16;
  --mdc-checkbox-selected-icon-color: #f18c16;
  --mdc-checkbox-selected-pressed-icon-color: #f18c16;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f18c16;
  --mdc-checkbox-selected-hover-state-layer-color: #f18c16;
  --mdc-checkbox-selected-pressed-state-layer-color: #f18c16;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mdc-data-table__header-row {
  --mat-table-header-headline-color: #fff;
  background-color: #242424 !important;
}

.reverse-checkbox .mdc-form-field {
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
}

table tr:nth-child(even) {
  background-color: rgba(3, 66, 229, 0.03);
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field {
  --mat-datepicker-toggle-active-state-icon-color: #f18c16;
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(242, 139, 0, 0.3);
}

mat-calendar {
  --mat-datepicker-calendar-date-selected-state-background-color: #f18c16;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(
      242,
      139,
      0,
      0.4
  );
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(
      242,
      139,
      0,
      0.3
  );
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(
      242,
      139,
      0,
      0.3
  );
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(
      242,
      139,
      0,
      0.2
  );
}

.mdc-text-field--filled {
  --mdc-filled-text-field-container-shape: 0 !important;
}

html {
  --mdc-filled-text-field-container-shape: 0;
  --mdc-filled-text-field-caret-color: #f18c16;
  --mdc-filled-text-field-focus-active-indicator-color: #f18c16;
  --mdc-filled-text-field-focus-label-text-color: rgba(242, 139, 0, 0.87);
}

@media (max-width: 767.98px) {
  .mat-expansion-panel-header {
    padding: 4px !important;
  }
}

@media (max-width: 575.98px) {
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    margin-right: 8px !important;
    display: grid !important;
    grid-template-columns: 1fr auto;
    grid-template-rows: 3/1;
  }
  .mat-expansion-panel-header-title > div,
  .mat-expansion-panel-header-description > div {
    grid-column: 1/2;
  }
  .mat-expansion-panel-header-title:nth-child(4),
  .mat-expansion-panel-header-description:nth-child(4) {
    grid-column: 2;
    grid-row: 1/span 3;
  }
}

@media (max-width: 575.98px) {
  .mat-expansion-panel-body {
    padding: 0 8px 8px !important;
  }
}

.mat-date-range-input-wrapper {
  font-size: clamp(14px, 0.83vw, 16px);
}
@media (max-width: 575.98px) {
  .mat-date-range-input-wrapper {
    font-size: clamp(10px, 0.68vw, 14px);
  }
}

.mdc-text-field {
  padding: 0 2px !important;
}

@media (max-width: 575.98px) {
  .mat-mdc-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@media (max-width: 575.98px) {
  .mat-mdc-row {
    height: auto !important;
  }
}

@media (max-width: 575.98px) {
  .mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: 1px solid #b5b3b3 !important;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("/assets/font/montserrat/regular.woff2") format("woff2");
  src: url("/assets/font/montserrat/regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/font/montserrat/medium.woff2") format("woff2");
  src: url("/assets/font/montserrat/medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/font/montserrat/semiBold.woff2") format("woff2");
  src: url("/assets/font/montserrat/semiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/font/montserrat/bold.woff2") format("woff2");
  src: url("/assets/font/montserrat/bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/font/montserrat/extraBold.woff2") format("woff2");
  src: url("/assets/font/montserrat/extraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
.image-bg {
  background-color: #EBEDEC !important;
}

.gap-2rem {
  gap: 2rem !important;
}

.gap-1rem {
  gap: 1rem !important;
}

.gap-05rem {
  gap: 0.5rem !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-8 {
  gap: 8px !important;
}

.flex-3 {
  flex: 3 !important;
}

.flex-2 {
  flex: 2 !important;
}

.flex-1 {
  flex: 1 !important;
}

.background-main {
  background-color: #F28B00;
}

.color-main {
  color: #F28B00;
}

.fill-svg-main svg {
  fill: #F28B00;
}

.color-font {
  color: #242424;
}

.color-contrast {
  color: #FFF;
}

.cursor-pointer {
  cursor: pointer;
}